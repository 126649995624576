import React from 'react';
import { useDispatch } from 'react-redux';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { __ } from '../../services/translation';
import { ButtonsContainer } from '../../components/Layout';
import { Button, NarrowParagraph } from '../../components/Common';

import { modal } from '../../actions';

const InvoiceGenerated = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(modal.hideModal());
  };
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.offer" />
      </ModalHeader>
      <NarrowParagraph text="application.offer_accepted" />
      <ButtonsContainer marginTop>
        <Button primary text="application.ok" click={handleClick} />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default InvoiceGenerated;
