import React from 'react';
import { CostEntity } from 'app-types';
import { useDispatch } from 'react-redux';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { ButtonsContainer } from '../../components/Layout';
import { __ } from '../../services/translation';
import AddCostButton from '../../views/DashboardClient/Invoices/AddInvoice/AddCostButton';
import AddServiceButton from '../../views/DashboardClient/Invoices/AddInvoice/AddServiceButton';
import { Button } from '../../components/Common';
import { modal } from '../../actions';
import SelectInvoiceWeek from '../SelectInvoiceWeek';

interface Props {
  index: number;
  formName: string;
  callback?: (index: number, cost: CostEntity) => void;
  noCosts?: boolean;
}

const ChooseServiceFill = ({ callback, index, formName, noCosts }: Props) => {
  const dispatch = useDispatch();
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.choose" />
      </ModalHeader>
      <ButtonsContainer marginLittle>
        {!noCosts && (
          <AddCostButton
            formName={formName}
            inline
            callback={(cost: CostEntity) => {
              if (typeof callback === 'function') callback(index, cost);
            }}
          />
        )}
        <AddServiceButton index={index} />
        <Button
          secondary
          text="application.invoice_week_scheme"
          click={() => {
            dispatch(modal.showModal(<SelectInvoiceWeek index={index} />));
          }}
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ChooseServiceFill;
