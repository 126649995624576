import React, { useEffect, useState } from 'react';
import { ApiErrorCode, DocumentRes } from 'app-types';
import { Field, reduxForm, InjectedFormProps, formValueSelector, change } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { FormContainer, ButtonsContainer } from '../../../../components/Layout';
import { Spinner, Input, Button, DateInput, FileInput } from '../../../../components/Common';
import { notifications } from '../../../../actions';
import ValidationService from '../../../../services/validation-service';
import { __ } from '../../../../services/translation';
import { ApplicationState } from '../../../../reducers';
import ApiService from '../../../../services/api-service';

interface Props extends InjectedFormProps {
    errorNotification: (error: ApiErrorCode) => void;
    errorNotificationText: (error: string) => void;
    changeField: (form: string, field: string, value: any) => void;
    defaultValue?: DocumentRes;
    id?: string;
    callback?: () => void;
}

const DocumentForm = ({
    handleSubmit,
    callback,
    id,
}: Props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const submitInvoice = async (data: any) => {
        const fd = new FormData();

        fd.append('name', data.name);
        fd.append('date', data.date);

        if (data.documents) {
            data.documents.forEach((item: any) => {
                fd.append('documents', item);
            });
        }

        setLoading(true);

        if (id) {
            await ApiService.patch(dispatch, '', '', callback, undefined, `document/one/${id}`, '', fd);
        } else {
            await ApiService.post(dispatch, '', '', callback, undefined, 'document', '', fd, true);
        }

        setLoading(false);
    };

    return (
        <div className="invoice-form">
            {loading && <Spinner halfTransparent overlay />}
            <FormContainer>
                <form onSubmit={handleSubmit(submitInvoice)} name="add-invoice">
                    <Field
                        name="name"
                        type="text"
                        component={Input}
                        label="application.name"
                        validate={ValidationService.required}
                        required
                    />
                    <Field
                        name="date"
                        type="date"
                        component={DateInput}
                        label="application.date"
                        validate={ValidationService.required}
                        required
                    />
                    <Field
                        name="documents"
                        component={FileInput}
                        accept=".jpg,.jpeg,.png,.pdf"
                        hasImage={id ? `${ApiService.url}document/file/one/${id}` : undefined}
                        multiple
                    />

                    <ButtonsContainer>
                        <Button type="submit" text="application.next" primary />
                    </ButtonsContainer>
                </form>
            </FormContainer>
        </div>
    );
};

const selector = formValueSelector('costDocument');
const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
    initialValues: ownProps.defaultValues ?? undefined,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            changeField: change,
            errorNotification: notifications.errorNotification,
            errorNotificationText: notifications.errorNotificationText,
        },
        dispatch,
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm<any, any>({
        form: 'costDocument',
        // important to leave it like that, so the form does not get repopulated
        enableReinitialize: false,
    })(DocumentForm),
);
