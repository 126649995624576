import React from 'react';

interface Props {
  icon: string;
  className?: string;
  onClick?: () => void;
}

const Icon = ({ icon, className, onClick }: Props) => (
  <img role="button" className={className} src={`/assets/icons/${icon}.svg`} onClick={onClick} />
);

export default Icon;
