import React from 'react';
import { CreateUpdateTimeSheetReq, TimeSheetEntity } from 'app-types';
import { useDispatch } from 'react-redux';

import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
} from 'itf_formbuilder_react';
import { ButtonsContainer, TopHeader, FormContainer } from '../../components/Layout';
import {
  Button,
  SectionHeader,
  RequiredIndicator,
  Spinner,
  FieldsMapper,
  DateInput,
} from '../../components/Common';

import { apiList, modal, notifications } from '../../actions';

import ApiService from '../../services/api-service';
import { formOnTranslateString } from '../../utils/trans-form';
import { history, flatpickr } from '../../App';
import DateTimeInput from '../../components/Common/DateTimeInput';
import useHeader from '../../hooks/useHeader';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';

interface Props {
  id: string;
}

// eslint-disable-next-line react/prefer-stateless-function
const EditTimeSheet = ({ id }: Props) => {
  const dispatch = useDispatch();
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.edit_time_sheet" />
      </ModalHeader>
      <FormContainer>
        <ItfApiForm
          formId="time-sheet-form"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(`time-sheet/form/update-item/${id}`)}
          sendFetcher={(formId: string, values: CreateUpdateTimeSheetReq) =>
            ApiService.sendForm(`time-sheet/${id}`, values, 'PATCH')
          }
          onSavedSuccessfully={(data: TimeSheetEntity) => {
            history.push('/dashboard/time-sheets/list');
            dispatch(modal.hideModal());
            dispatch(apiList.refreshApiList());
            dispatch(notifications.successNotification('application.time_sheet_was_edited'));
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text="application.save"
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={(string: string) => formOnTranslateString(string)}
          onRenderFullField={(
            field: FormFieldToRender,
            errors: any,
            fieldHtmlId: string,
            currentValue: any,
            onChange: ChangeFunction,
            onFocus: FieldAndEventFunction,
            onBlur: FieldAndEventFunction,
          ) => {
            if (field.id === 'start') {
              return (
                <>
                  <DateTimeInput
                    value={currentValue}
                    required={field.required}
                    onChange={onChange}
                    multiple={field.options.multiple}
                    labels={['application.start_date', 'application.start_time']}
                    ids={['startDate', 'startTime']}
                    meta={{
                      touched: errors.length > 0,
                      error: errors.length
                        ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
                        : null,
                    }}
                  />
                </>
              );
            } else {
              return FieldsMapper(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            }
          }}
          thirdPartyComponents={{ flatpickr }}
        />
      </FormContainer>
    </ModalContent>
  );
};

export default EditTimeSheet;
