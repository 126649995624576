import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'src/reducers';
import { TrackingStatus } from 'app-types';

const WakeLock = () => {
  const { trackingStatus } = useSelector((state: ApplicationState) => state.mileage);
  const wakeLock = useRef<null | any>();

  const requestWakeLock = async () => {
    try {
      if ('wakeLock' in navigator) {
        // type is incorrect
        wakeLock.current = await (navigator as any).wakeLock.request("screen");
        console.log("Screen Wake Lock is active"); // Let the user know somewhere in the UI
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (trackingStatus === TrackingStatus.Working) {
      requestWakeLock();
    } else if (wakeLock.current) {
      wakeLock.current.release();
      wakeLock.current = null;
    }
  }, [trackingStatus]);


  return null;
}

export { WakeLock }