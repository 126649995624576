import { MileageCarReq } from 'app-types';
import { Dispatch } from 'redux';
import { apiList, modal, notifications } from '.';
import ApiService from '../services/api-service';
import { history } from '../App';

export const deleteMileageCar = (mileageCarId: string, redirect?: string) => async (
  dispatch: Dispatch<any>,
) => {
  await ApiService.delete(
    dispatch,
    'DELETE_MILEAGE_CAR_SUCCESS',
    'DELETE_MILEAGE_CAR_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
      dispatch(notifications.successNotification('application.car_deleted'));
      if (redirect) {
        history.push(redirect);
      }
    },
    null,
    `mileage-car/${mileageCarId}`,
  );
};

export const addMileageCar = (data: MileageCarReq) => async (dispatch: Dispatch<any>) => {
  await ApiService.post(
    dispatch,
    'ADD_MILEAGE_CAR_SUCCESS',
    'ADD_MILEAGE_CAR_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
      dispatch(notifications.successNotification('application.mileage_car_created'));
      history.push('/dashboard/mileage-cars/list');
    },
    null,
    'mileage-car/new',
    'ADD_MILEAGE_CAR_LOADING',
    data,
  );
};

export const editMileageCar = (data: MileageCarReq, id: string) => async (
  dispatch: Dispatch<any>,
) => {
  await ApiService.patch(
    dispatch,
    'EDIT_MILEAGE_CAR_SUCCESS',
    'EDIT_MILEAGE_CAR_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
      dispatch(notifications.successNotification('application.mileage_car_edited'));
      history.push('/dashboard/mileage-cars/list');
    },
    null,
    `mileage-car/${id}`,
    'EDIT_MILEAGE_CAR_LOADING',
    data,
  );
};
