import { getQuarter } from 'date-fns';
import { TaxRate } from 'app-types';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Input } from '../../../../components/Common';
import { ApplicationState } from '../../../../reducers';
import { __ } from '../../../../services/translation';
import { SelectOption } from '../../../../types/select';
import TaxPreviewCard from './TaxPreviewCard';
import './TaxPreview.scss';
import { isEmptyObject } from '../../../../utils/is-empty-object';

const TaxPreview = () => {
  const { summary, loading } = useSelector((state: ApplicationState) => state.statistics);
  const [years, setYears] = useState<string[]>([]);
  const [year, setYear] = useState<number | null>();
  const [yearsOptions, setYearsOptions] = useState<SelectOption[]>([]);
  const [displayed, setDisplayed] = useState<number>(getQuarter(new Date()) - 1);

  const rates = [
    TaxRate['21%'],
    TaxRate['9%'],
    TaxRate['0%'],
    TaxRate['BTW Verlegd'],
    TaxRate['BTW vrijgesteld'],
  ];
  const quarters: SelectOption[] = [
    { name: 'I', value: 0 },
    { name: 'II', value: 1 },
    { name: 'III', value: 2 },
    { name: 'IV', value: 3 },
  ];

  useEffect(() => {
    const years: string[] = Object.keys(summary).sort(
      (a: string, b: string) => parseInt(b) - parseInt(a),
    );
    setYears(years);
    setYear(parseInt(years[0]));
    setYearsOptions(years.map((year) => ({ name: year, value: year })));
  }, [summary]);

  if (isEmptyObject(summary)) return <Alert type="notice" text="application.no_data" />;

  if (loading || !year) return null;
  const getSummary = (year: number) => {
    const taxTable: any = {
      tax: {},
      net: {},
      costsTax: {},
      toPay: {},
      toReceive: {},
    };

    const yearSummary: any = { tax: {}, net: {}, costsTax: 0, toPay: 0, toReceive: 0 };
    let totalYearlyCostsTax = 0;
    let totalYearlyTax = 0;
    for (let quarter = 0; quarter <= 3; quarter++) {
      let totalTax = 0;
      let totalNet = 0;
      const stats = Object.values(summary[year]).slice(quarter * 3, (quarter + 1) * 3);
      taxTable.net[quarter] = {};
      taxTable.tax[quarter] = {};
      rates.forEach((rate) => {
        taxTable.tax[quarter][rate] = stats.reduce(
          (acc: number, curr: any) => acc + curr.incomeTax[rate],
          0,
        );
        taxTable.net[quarter][rate] = stats.reduce(
          (acc: number, curr: any) => acc + curr.incomeNet[rate],
          0,
        );
        if (!yearSummary.tax[rate]) yearSummary.tax[rate] = 0;
        if (!yearSummary.net[rate]) yearSummary.net[rate] = 0;

        yearSummary.tax[rate] += taxTable.tax[quarter][rate];
        yearSummary.net[rate] += taxTable.net[quarter][rate];

        totalTax += taxTable.tax[quarter][rate];
        totalNet += taxTable.net[quarter][rate];
      });
      taxTable.costsTax[quarter] = stats.reduce((acc: number, curr: any) => acc + curr.costsTax, 0);
      totalYearlyCostsTax += taxTable.costsTax[quarter];
      totalYearlyTax += totalTax;
      const difference = totalTax - taxTable.costsTax[quarter];
      taxTable.toPay[quarter] = difference > 0 ? difference : 0;
      taxTable.toReceive[quarter] = difference > 0 ? 0 : difference * -1;
    }
    yearSummary.costsTax = totalYearlyCostsTax;
    const difference = totalYearlyTax - totalYearlyCostsTax;
    yearSummary.toPay = difference > 0 ? difference : 0;
    yearSummary.toReceive = difference > 0 ? 0 : difference * -1;
    return { taxTable, yearSummary };
  };

  const next = () => {
    if (displayed < 4) setDisplayed(displayed + 1);
  };
  const prev = () => {
    if (displayed > 0) setDisplayed(displayed - 1);
  };

  const { taxTable: quarterData, yearSummary } = getSummary(year);
  return (
    <div className="tax-preview">
      <Input
        type="select"
        options={yearsOptions}
        input={{
          value: year,
          onChange: (e: ChangeEvent<any>) => {
            setYear(e.currentTarget.value);
          },
        }}
        meta={{}}
      />
      {quarters
        .filter((quarter: SelectOption) => quarter.value === displayed)
        .map((quarter: SelectOption) => (
          <TaxPreviewCard
            rates={rates}
            title={__('application.statistics_quarter', { quarter: quarter.name })}
            net={quarterData.net[quarter.value]}
            tax={quarterData.tax[quarter.value]}
            costsTax={quarterData.costsTax[quarter.value]}
            toPay={quarterData.toPay[quarter.value]}
            toReceive={quarterData.toReceive[quarter.value]}
            prev={prev}
            next={next}
          />
        ))}
      {displayed === 4 && (
        <TaxPreviewCard
          rates={rates}
          title={year.toString()}
          net={yearSummary.net}
          tax={yearSummary.tax}
          costsTax={yearSummary.costsTax}
          toPay={yearSummary.toPay}
          toReceive={yearSummary.toReceive}
          prev={prev}
          next={next}
        />
      )}
    </div>
  );
};

export default TaxPreview;
