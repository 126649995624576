import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Input, SectionHeader, Spinner, Subheader } from '../../../../components/Common';
import { ApplicationState } from '../../../../reducers';
import { __ } from '../../../../services/translation';
import { SelectOption } from '../../../../types/select';
import ProfitsCard from './ProfitsCard';
import './Profits.scss';
import { isEmptyObject } from '../../../../utils/is-empty-object';

const Profits = () => {
  const { summary, loading } = useSelector((state: ApplicationState) => state.statistics);
  const [years, setYears] = useState<string[]>([]);
  const [yearsOptions, setYearsOptions] = useState<SelectOption[]>([]);

  const [firstYear, setFirstYear] = useState<number | null>(null);
  const [secondYear, setSecondYear] = useState<number | null>(null);

  useEffect(() => {
    const years: string[] = Object.keys(summary).sort(
      (a: string, b: string) => parseInt(b) - parseInt(a),
    );
    setYears(years);
    setFirstYear(parseInt(years[0]));
    if (years.length > 1) setSecondYear(parseInt(years[1]));
    setYearsOptions(years.map((year) => ({ name: year, value: year })));
  }, [summary]);

  if (isEmptyObject(summary)) return <Alert type="notice" text="application.no_data" />;

  if (loading || !firstYear) return <Spinner />;

  const getSummaryForYear = (year: number) => {
    const stats = Object.values(summary[year]);
    const incomeSummary: number = stats.reduce(
      (acc: number, curr: any) => acc + curr.incomeNetTotal,
      0,
    );
    const costsSummary: number = stats.reduce((acc: number, curr: any) => acc + curr.costsNet, 0);
    const mileageSummary: number = stats.reduce((acc: number, curr: any) => acc + curr.mileage, 0);
    const result = incomeSummary - costsSummary - mileageSummary;

    return {
      incomeSummary,
      costsSummary,
      mileageSummary,
      result,
    };
  };

  const getSummaryForQuarter = (year: number, quarter = 0) => {
    const stats = Object.values(summary[year]).slice(quarter * 3, (quarter + 1) * 3); // end index for slice does not include end value
    const incomeSummary: number = stats.reduce(
      (acc: number, curr: any) => acc + curr.incomeNetTotal,
      0,
    );
    const costsSummary: number = stats.reduce((acc: number, curr: any) => acc + curr.costsNet, 0);
    const mileageSummary: number = stats.reduce((acc: number, curr: any) => acc + curr.mileage, 0);
    const result = incomeSummary - costsSummary - mileageSummary;

    return {
      incomeSummary,
      costsSummary,
      mileageSummary,
      result,
    };
  };
  const getSummaryForMonth = (year: number, month = 0) => {
    const stats = Object.values(summary[year])[month];
    const incomeSummary: number = stats.incomeNetTotal;
    const costsSummary: number = stats.costsNet;
    const mileageSummary: number = stats.mileage;
    const result = incomeSummary - costsSummary - mileageSummary;

    return {
      incomeSummary,
      costsSummary,
      mileageSummary,
      result,
    };
  };

  return (
    <>
      <div className="profits">
        {years.length > 1 ? (
          <>
            <Subheader text="application.statistics_compare_years" />
            <div className="statistics-year-select">
              <Input
                type="select"
                options={yearsOptions}
                input={{
                  value: firstYear,
                  onChange: (e: ChangeEvent<any>) => {
                    setFirstYear(e.currentTarget.value);
                  },
                }}
                meta={{}}
              />
              <span className="select-separator">/</span>
              <Input
                type="select"
                options={yearsOptions}
                input={{
                  value: secondYear,
                  onChange: (e: ChangeEvent<any>) => {
                    setSecondYear(e.currentTarget.value);
                  },
                }}
                meta={{}}
              />
            </div>
          </>
        ) : (
          <Subheader rawText={__('application.statistics_for_year', { year: firstYear })} />
        )}
        <ProfitsCard
          title="application.statistics_results_yearly"
          options={yearsOptions}
          firstYear={firstYear}
          secondYear={secondYear}
          values={years}
          getSummary={getSummaryForYear}
          type="yearly"
        />
        <ProfitsCard
          title="application.statistics_results_quarterly"
          options={yearsOptions}
          firstYear={firstYear}
          secondYear={secondYear}
          values={years}
          getSummary={getSummaryForQuarter}
          type="quarterly"
        />
        <ProfitsCard
          title="application.statistics_results_monthly"
          options={yearsOptions}
          firstYear={firstYear}
          secondYear={secondYear}
          values={years}
          getSummary={getSummaryForMonth}
          type="monthly"
        />
      </div>
    </>
  );
};

export default Profits;
