import { Dispatch } from 'react';
import ApiService from '../services/api-service';

export const getPendingInvitationsCount = () => async (dispatch: Dispatch<any>) => {
  await ApiService.get(
    dispatch,
    'GET_PENDING_INVITATIONS_COUNT',
    '',
    null,
    null,
    'accountant-connector/pending-invitations-count',
  );
};
