import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { invoice } from '../../../../../actions';
import InvoiceForm from '../InvoiceForm';

const BlankInvoice = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(invoice.setInvoiceClient(null));
  }, []);
  return (
    <>
      <InvoiceForm />
    </>
  );
};

export default BlankInvoice;
