import React from 'react';
import { Field, FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import { UserBlockBecausePaymentRequest } from 'app-types';
import { useDispatch } from 'react-redux';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { Button, Input } from '../../components/Common';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import { blockUserBecausePayment } from '../../actions/user';

interface Props extends InjectedFormProps<UserBlockBecausePaymentRequest> {
  id: string;
}

const BlockBecausePayment = ({ id, handleSubmit }: Props) => {
  const dispatch = useDispatch();

  const submit = (data: UserBlockBecausePaymentRequest) => {
    dispatch(blockUserBecausePayment(id, data));
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.block_account_because_payment_has_been_canceled" />
      </ModalHeader>
      <FormContainer>
        <form onSubmit={handleSubmit(submit)} name="blockBecausePaymentForm">
          <Field
            name="blockDescription"
            type="textarea"
            component={Input}
            label="application.description"
            required
            darkBackground
          />
          <Field
            name="amount"
            type="cost"
            component={Input}
            label="application.amount_to_pay"
            required
            darkBackground
          />
          <ButtonsContainer>
            <Button type="submit" text="application.save" primary />
          </ButtonsContainer>
        </form>
      </FormContainer>
    </ModalContent>
  );
};

export default reduxForm<UserBlockBecausePaymentRequest, any>({
  form: 'blockBecausePaymentForm',
  // important to leave it like that, so the form does not get repopulated
  enableReinitialize: false,
})(BlockBecausePayment);
