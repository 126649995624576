import { ReminderType } from '../../../heyzzp-back/types';

interface SetReminderData {
  type: 'SET_REMINDER_DATA';
  payload: {
    ids: string[];
    type: ReminderType;
  };
}

export interface RemindersState {
  invoiceIds: string[];
  type: ReminderType | null;
}

const initialState: RemindersState = {
  invoiceIds: [],
  type: null,
};

type Action = SetReminderData;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_REMINDER_DATA': {
      return { ...state, invoiceIds: action.payload.ids, type: action.payload.type };
    }
    default:
      return state;
  }
};
