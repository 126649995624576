import { FieldType, TableSchema } from '../typings';

export const userDocuments: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.name',
      field: 'name',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
    {
      name: 'application.date',
      field: 'date',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
    },
    {
      name: 'application.sent',
      field: 'sent',
      mobile: true,
      searchable: false,
      sortable: false,
      defaultSort: false,
      type: FieldType.Boolean,
    },
  ],
};
