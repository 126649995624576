import React from 'react';
import ReactPaginate from 'react-paginate';

import './Pagination.scss';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '..';

interface Props {
  pageCount: number;
  initialPage?: number;
  onPageChange?: (page: number) => void;
}

const Pagination = ({ pageCount, initialPage, onPageChange }: Props) => {
  if (pageCount < 2) return null;
  return (
    <ReactPaginate
      containerClassName="pagination"
      pageLinkClassName="button button-link"
      activeLinkClassName="button-link-active"
      onPageChange={({ selected }) => onPageChange && onPageChange(selected + 1)}
      previousLabel={<Button leftIcon faIcon={faArrowLeft} text="" />}
      nextLabel={<Button rightIcon faIcon={faArrowRight} text="" />}
      initialPage={(initialPage || 1) - 1}
      pageCount={pageCount}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
    />
  );
};

export default Pagination;
