import React from 'react';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../components/Common';
import { Page, ButtonsContainer, BottomContainer, Container } from '../../components/Layout';
import { __ } from '../../services/translation';
import useHeader from '../../hooks/useHeader';
import './PaymentSuccess.scss';

const PaymentSuccess = () => {
  useHeader('application.your_payment_is_being_processed');
  return (
    <>
      <Page withNavigation light>
        <Container>
          <div className="payment-processing-container">
            <div className="payment-processing">
              <FontAwesomeIcon icon={faHourglassHalf} className="payment-icon-success" />
            </div>
          </div>
          <div className="payment-processing-paragraph-container">
            <p>{__('application.it_can_take_couple_minutes.')}</p>
            {/* <p>
          <Link to="/contact-us">{__('application.contact_the_administration')}</Link>
        </p> */}
          </div>
          <BottomContainer>
            <ButtonsContainer marginTop max>
              <Button text="application.go_back_to_home_page" to="/" />
            </ButtonsContainer>
          </BottomContainer>
        </Container>
      </Page>
    </>
  );
};

export default PaymentSuccess;
