import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { TableSchema, mileageForUserTable } from 'app-tables';
import { MileageType } from 'app-types';
import { ApplicationState } from '../../../../reducers';
import MileageDescription from '../../../../modals/MileageDescription';
import { ApiList } from '../../../../components/Common';
import MileageListItem from './ListItem';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { SingleTab } from '../../../../types/tabs';
import { modal, header } from '../../../../actions';
import { CustomSearchType } from '../../../../types/api-list';

interface Props {
  showModal: (content: React.ReactNode) => void;
  setHeader: (title: string, back?: string, info?: { click: () => void }) => void;
  user: any;
}

class MileageList extends Component<Props> {
  private scheme: TableSchema;

  private tabs: SingleTab[] = [
    {
      label: 'application.all_mileages',
      value: null,
    },
    {
      label: 'application.company',
      value: MileageType.company,
      to: '/dashboard/mileages/list/company',
    },
    {
      label: 'application.private',
      value: MileageType.private,
    },
  ];

  constructor(props: Props) {
    super(props);
    this.scheme = new InteractiveTableSchema(mileageForUserTable);
  }

  componentDidMount() {
    const { showModal, user, setHeader } = this.props;
    if (!user.acceptedMileageInfo) {
      showModal(<MileageDescription />);
    }
    setHeader('application.mileage_list', '/', {
      click: () => showModal(<MileageDescription />),
    });
  }

  render() {
    return (
      <>
        <ApiList
          apiEndpointSubUrl="mileage/list"
          customSearchTabs={[
            {
              field: 'type',
              default: null,
              options: this.tabs,
              type: CustomSearchType.MODIFY_URL_ADDRESS,
            },
          ]}
          scheme={this.scheme}
          component={MileageListItem}
          padded
        />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ showModal: modal.showModal, setHeader: header.setHeader }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MileageList);
