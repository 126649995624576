import React from 'react';

import './SuccessIndicator.scss';

const SuccessIndicator = () => (
  <div className="success-indicator">
    <div className="success-icon-wrapper">
      <div className="success-icon">
        <div id="halfclip">
          <div className="halfcircle" id="clipped" />
        </div>
        <div className="halfcircle" id="fixed" />
        <svg version="1.1" viewBox="0 0 128 128">
          <polygon points="41.371 115.43 0 74.057 16.228 57.83 41.371 82.972 111.77 12.573 128 28.801" />
        </svg>
      </div>
    </div>
  </div>
);

export default SuccessIndicator;
