import React, { ReactElement, createContext, ReactNode, useContext, useState } from 'react';
import { SharedFile, SharedFileWithCb } from '../types/share';

interface ShareContentProviderProps {
  children: ReactNode;
}

export type ShareContentState = {
  file: SharedFileWithCb | undefined;
  setFile: (data: SharedFile | undefined) => void;
};

const ShareContentContext = createContext<ShareContentState | undefined>(undefined);

const ShareContentProvider = ({ children }: ShareContentProviderProps): ReactElement => {
  const [file, setFile] = useState<SharedFile>();

  return (
    <ShareContentContext.Provider
      value={{
        file: file
          ? {
              ...file,
              cb: () => {
                setFile(undefined);
              },
            }
          : undefined,
        setFile,
      }}
    >
      {children}
    </ShareContentContext.Provider>
  );
};

const useShareContent = (): {
  shareContent: ShareContentState;
} => {
  const shareContent = useContext(ShareContentContext);
  if (shareContent === undefined) {
    throw new Error('useShareContent must be used within a ShareContentProvider');
  }
  return { shareContent };
};

export { ShareContentProvider, useShareContent };
