import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Paragraph } from '../../../components/Common';
import { BottomContainer } from '../../../components/Layout';
import { __ } from '../../../services/translation';
import { isMobileBuild } from '../../../utils/is-mobile';

const WelcomeFooter = () => (
  <BottomContainer>
    {isMobileBuild() ? (
      <>
        <Button primary to="/login" text="application.sign_in" />
        <Paragraph translation="application.subscription_is_necessary_to_use" strong />
      </>
    ) : (
      <>
        <Button primary to="/registration" text="application.create_account" />
        <Button to="/register-accountant" primary text='application.register_as_accountant' />
        <p>
          {__('application.already_have_an_account?')}{' '}
          <Link to="/login">{__('application.sign_in')}</Link>
        </p>
       
      </>
    )}
  </BottomContainer>
);

export default WelcomeFooter;
