import React from 'react';
import { TimeSheetSchemeForm } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';

const AddTimeSheetScheme = () => {
  useHeader('application.add_time_sheet_scheme', '/dashboard/time-sheets-schemes/list');
  return (
    <>
      <TimeSheetSchemeForm
        formSendUrl="time-sheet/template/new"
        formUrl="time-sheet/template/form/add-item"
        method="POST"
      />
    </>
  );
};

export default AddTimeSheetScheme;
