import React, { useEffect } from 'react';
import { FieldType, TableSchema, sentInvoiceHistory as sentInvoiceHistoryTable } from 'app-tables';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { SentInvoiceHistoryRes } from 'app-types';
import { InteractiveTableSchema } from '../../../../../utils/table-shcema-utils';
import { ApiTable } from '../../../../../components/Common';
import { modal } from '../../../../../actions';
import { DownloadService } from '../../../../../services/download-service';
import { AddAttachmentToSentInvoiceHistory } from '../../../../../modals';
import { __ } from '../../../../../services/translation';

const SentInvoicesHistory = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();

  const scheme: TableSchema = new InteractiveTableSchema(sentInvoiceHistoryTable)
    .addFieldAtTheEnd({
      name: 'application.actions',
      field: 'editButton',
      mobile: true,
      buttons: [
        {
          name: 'application.add_attachment',
          type: 'secondary',
          click: (item: SentInvoiceHistoryRes) => {
            dispatch(modal.showModal(<AddAttachmentToSentInvoiceHistory id={item.id} />));
          },
        },

        {
          name: 'application.download',
          type: 'first',
          click: async (item: SentInvoiceHistoryRes) => {
            await DownloadService.download(`sent-invoice-history/${item.id}`);
          },
        },
      ],
    })
    .addFieldAtTheBeginning({
      name: 'application.type',
      field: 'type',
      type: FieldType.Custom,
      customRender: (value, field, values: SentInvoiceHistoryRes) => {
        if (values.reminder && values.reminder.client) return __('application.reminder');
        return __('application.invoice');
      },
    });

  return (
    <>
      <ApiTable apiEndpointSubUrl={`sent-invoice-history/list/${id}`} scheme={scheme} />
    </>
  );
};

export default SentInvoicesHistory;
