import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import AddCostScheme from './Add';
import CostTemplatesList from './List';
import SingleCostScheme from './Single';

const invoiceTabs = [
  {
    title: 'application.costs',
    link: '/dashboard/costs',
    icon: 'back',
  },
  {
    title: 'application.cost_scheme_list',
    link: '/dashboard/costs-schemes/list',
    icon: 'list',
  },
  {
    title: 'application.add_cost_scheme',
    link: '/dashboard/costs-schemes/add',
    icon: 'plus',
  },
];

class CostSchemes extends Component<{}> {
  render() {
    return (
      <>
        <Page light withNavigation>
          <Dashboard navigation={invoiceTabs}>
            <Switch>
              <Route
                exact
                path="/dashboard/costs-schemes"
                render={() => <Redirect to="/dashboard/costs-schemes/list" />}
              />
              <Route exact path="/dashboard/costs-schemes/add" component={AddCostScheme} />
              <Route exact path="/dashboard/costs-schemes/list" component={CostTemplatesList} />
              <Route exact path="/dashboard/costs-schemes/:id" component={SingleCostScheme} />
            </Switch>
          </Dashboard>
        </Page>
      </>
    );
  }
}

export default CostSchemes;
