import { MileageSchemaEntity, MileageType } from 'app-types';

interface GetMileageSchemeList {
  type: 'GET_MILEAGE_SCHEME_LIST_SUCCESS';
  payload: any;
}

interface LoadingMileageSchemeList {
  type: 'LOADING_MILEAGE_SCHEME_LIST';
  payload: boolean;
}

interface GetSingleMileageScheme {
  type: 'GET_SINGLE_MILEAGE_SCHEME_SUCCESS';
  payload: MileageSchemaEntity;
}

interface LoadingSingleMileageScheme {
  type: 'LOADING_SINGLE_MILEAGE_SCHEME';
  payload: boolean;
}

export interface MileageSchemesState {
  loading: boolean;
  loadingSingle: boolean;
  single: MileageSchemaEntity;
  list: any;
}

const initialState: MileageSchemesState = {
  loading: true,
  list: [],
  loadingSingle: true,
  single: {
    id: '',
    startPoint: '',
    finishPoint: '',
    name: '',
    time: 0,
    type: MileageType.private,
    distance: 0,
    startPointReturn: '',
    finishPointReturn: '',
    timeReturn: 0,
    distanceReturn: 0,
    regNumber: '',
    carModel: '',
    carType: '',
  },
};

type Action =
  | GetMileageSchemeList
  | LoadingMileageSchemeList
  | GetSingleMileageScheme
  | LoadingSingleMileageScheme;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_MILEAGE_SCHEME_LIST_SUCCESS': {
      return { ...state, list: action.payload };
    }
    case 'LOADING_MILEAGE_SCHEME_LIST': {
      return { ...state, loading: action.payload };
    }
    case 'GET_SINGLE_MILEAGE_SCHEME_SUCCESS': {
      return { ...state, single: action.payload };
    }
    case 'LOADING_SINGLE_MILEAGE_SCHEME': {
      return { ...state, loadingSingle: action.payload };
    }
    default:
      return state;
  }
};
