import React from 'react';

import { Field, getFormMeta } from 'redux-form';
import uuid from 'uuid';
import { InvoiceFile } from 'app-types';
import { connect, useSelector } from 'react-redux';
import {
  Input,
  FileInput,
  ListElementWrapper,
  DateInput,
  Button,
  ListElementsWrapper,
} from '../../../../../../components/Common';
import ValidationService from '../../../../../../services/validation-service';
import ApiService from '../../../../../../services/api-service';
import { __ } from '../../../../../../services/translation';
import { ApplicationState } from '../../../../../../reducers';

interface Props {
  fields: any;
  meta: any;
  data: any;
  isDirty: boolean[];
}

const RenderFiles = ({ fields, data, isDirty }: Props) => {
  const handleButton = () => {
    fields.push({ uuid: uuid.v4(), date: new Date() });
  };

  const isFileValid = (file: any, index: number) => {
    if (!isDirty[index]) return true;
    if (!file.name) return false;
    if (!file.date) return false;
    if (!file.file && !file.id) return false;
    return true;
  };

  return (
    <>
      <ListElementsWrapper empty={fields.length === 0}>
        {fields.map(
          (file: any, index: any) =>
            data[index] && (
              <li
                key={data[index].id || data[index].uuid}
                id={`cost-${data[index].id || data[index].uuid}`}
              >
                <ListElementWrapper
                  defaultMinimized={false}
                  header={data[index].name || __('application.file')}
                  icon="cross"
                  click={() => fields.remove(index)}
                  invalid={!isFileValid(data[index], index)}
                >
                  <Field
                    name={`${file}.file`}
                    hasImage={
                      data[index].id ? `${ApiService.url}invoice-file/one/${data[index].id}` : null
                    }
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    component={FileInput}
                    label="application.attach_file"
                  />
                  {data[index].file || data[index].name ? (
                    <>
                      <Field
                        name={`${file}.name`}
                        type="text"
                        component={Input}
                        label="application.file_name"
                        placeholder="application.file_name_placeholder"
                        required
                        validate={ValidationService.required}
                      />
                      <Field
                        name={`${file}.date`}
                        type="date"
                        component={DateInput}
                        label="application.file_date"
                        required
                        validate={[ValidationService.required, ValidationService.minDateValidator]}
                      />
                    </>
                  ) : null}
                </ListElementWrapper>
              </li>
            ),
        )}
      </ListElementsWrapper>
      <Button
        constant
        leftIcon
        secondary
        type="button"
        icon="plus"
        click={handleButton}
        text={
          data.length > 0
            ? 'application.attach_other_file_button'
            : 'application.attach_file_button'
        }
      />
    </>
  );
};

const anyTouched = (file: any) => file.name || file.date || file.file;

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const meta: any = getFormMeta('invoiceForm')(state);
  return {
    isDirty: ownProps.fields.map(
      (file: InvoiceFile, index: number) =>
        meta.files && meta.files[index] && anyTouched(meta.files[index]),
    ),
  };
};

export default connect(mapStateToProps)(RenderFiles);
