import { FieldType, TableSchema } from '../typings';

export const sentInvoiceHistory: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.sentDate',
      field: 'sentDate',
      mobile: true,
      sortable: true,
      oppositeSortDir: true,
      defaultSort: true,
      searchable: true,
      type: FieldType.Date,
    },
    {
      name: 'application.invoice_number',
      field: 'invoiceNumber',
      mobile: true,
      oppositeSortDir: true,
      type: FieldType.Text,
    },
    {
      name: 'application.firstName',
      field: 'firstName',
      mobile: true,
      oppositeSortDir: true,
      type: FieldType.Text,
    },
    {
      name: 'application.lastName',
      field: 'lastName',
      mobile: true,
      oppositeSortDir: true,
      type: FieldType.Text,
    },
    {
      name: 'application.name',
      field: 'name',
      mobile: true,
      oppositeSortDir: true,
      type: FieldType.Text,
    },
    {
      name: 'application.email',
      field: 'email',
      mobile: true,
      oppositeSortDir: true,
      type: FieldType.Text,
    },
    {
      name: 'application.sender',
      field: 'senderName',
      mobile: true,
      oppositeSortDir: true,
      type: FieldType.Text,
    },
    {
      name: 'application.sender_email',
      field: 'senderEmail',
      mobile: true,
      oppositeSortDir: true,
      type: FieldType.Text,
    },
    {
      name: 'application.sent_copy_to_self',
      field: 'sentToSelf',
      mobile: true,
      oppositeSortDir: true,
      type: FieldType.Boolean,
    },
  ],
};
