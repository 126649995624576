import React from 'react';
import { useDispatch } from 'react-redux';
import { UserNotificationTableRes } from 'app-types';
import clsx from 'clsx';
import { ListItem } from '../../../../components/Common';
import { modal } from '../../../../actions';
import { NotificationPreview } from '../../../../modals';
import { formatDate } from '../../../../utils/format-date';
import './Item.scss';

interface Props {
  item: UserNotificationTableRes;
}

const NotificationItem = ({ item }: Props) => {
  const dispatch = useDispatch();
  const displayNotification = () => {
    dispatch(modal.showModal(<NotificationPreview id={item.id} />));
  };

  return (
    <ListItem
      className={clsx('notification-item', { read: item.read })}
      header={item.title}
      buttons={[{ text: 'application.read_notification', click: displayNotification }]}
      paragraphs={[
        {
          text: formatDate(item.date),
          icon: 'calendar',
        },
      ]}
    />
  );
};

export default NotificationItem;
