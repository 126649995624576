import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { CheaterUser } from 'app-types';
import { SectionHeader, Spinner, Alert } from '../../../components/Common';
import { client } from '../../../actions';
import Cheater from './Item';
import { ApplicationState } from '../../../reducers';
import useHeader from '../../../hooks/useHeader';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';

interface Props {
  data: CheaterUser[];
  loading: boolean;
  getCheatersList: () => void;
}

const CheatersList = ({ getCheatersList, data, loading }: Props) => {
  useEffect(() => {
    getCheatersList();
  }, []);

  useHeader('application.cheaters');

  if (loading) {
    return <Spinner />;
  }
  return (
    <Page light>
      <Dashboard>
        {data.length === 0 ? (
          <Alert simple type="error" text="application.nothing_to_show." />
        ) : (
          data.map((item: CheaterUser) => <Cheater item={item} />)
        )}
      </Dashboard>
    </Page>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  data: state.client.cheatersList,
  loading: state.client.loadingCheatersList,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCheatersList: client.getCheatersList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheatersList);
