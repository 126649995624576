import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Capacitor } from '@capacitor/core';
import { SomeUserEntity } from 'app-types';
import { AnalitycsEvent, Screens } from '../types/analitycs';

export class AnalitycsService {
  static async setUser(user: SomeUserEntity) {
    if (!Capacitor.isNativePlatform()) return;
    await FirebaseAnalytics.setUserId({
      userId: user.id,
    });

    await FirebaseAnalytics.setUserProperty({
      key: 'email',
      value: user.email,
    });
  }

  static async clearUser() {
    if (!Capacitor.isNativePlatform()) return;
    await FirebaseAnalytics.setUserId({
      userId: null,
    });

    await FirebaseAnalytics.setUserProperty({
      key: 'email',
      value: null,
    });
  }

  static async setScreen(screen: Screens) {
    if (!Capacitor.isNativePlatform()) return;
    await FirebaseAnalytics.setCurrentScreen({
      screenName: screen,
      screenClassOverride: `${screen}Page`,
    });
  }

  static async logEvent(event: AnalitycsEvent) {
    if (!Capacitor.isNativePlatform()) return;
    await FirebaseAnalytics.logEvent({
      name: event,
    });
  }
}
