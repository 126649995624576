import React, { FC } from 'react';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { ButtonsContainer } from '../../components/Layout';
import { Button } from '../../components/Common';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../actions/modal';

interface Props {
  selectCb: () => void;
}

const ShareFile: FC<Props> = ({ selectCb }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCostClick = () => {
    selectCb();
    dispatch(hideModal());
    history.push('/dashboard/costs/add');
  };

  const handleOtherInvoiceClick = () => {
    selectCb();
    dispatch(hideModal());
    history.push('/dashboard/other-income/add');
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.share_file" />
      </ModalHeader>
      <ButtonsContainer max>
        <Button text="application.cost" click={handleCostClick} />
        <Button secondary text="application.other_income" click={handleOtherInvoiceClick} />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ShareFile;
