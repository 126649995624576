import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { mileage, header } from '../../../../actions';
import { Spinner, Alert } from '../../../../components/Common';
import SingleMileageSummary from '../SingleMileageSummary';
import { ApplicationState } from '../../../../reducers';

interface Props {
  getMileageYears: () => void;
  loading: boolean;
  years: number[];
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class YearSummary extends Component<Props> {
  componentDidMount() {
    const { getMileageYears, setHeader } = this.props;
    getMileageYears();
    setHeader('application.annual_summary', '/dashboard/mileages/list', {
      title: 'application.annual_summary',
      text: 'annotations.mileage_annual_summary',
    });
  }

  render() {
    const { years, loading } = this.props;
    return (
      <>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {years.length === 0 && <Alert simple type="error" text="application.no_data." />}
            {years.map((year: number) => (
              <SingleMileageSummary key={year} year={year} />
            ))}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  years: state.mileage.mileageYears,
  loading: state.mileage.loadingMileageYears,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getMileageYears: mileage.getMileageYears, setHeader: header.setHeader },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(YearSummary);
