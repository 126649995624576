import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import TimeSheetList from './List';
import AddTimeSheetScheme from './Add';
import SingleTimeSheetScheme from './Single';

const invoiceTabs = [
  {
    title: 'application.timesheet_periods',
    link: '/dashboard/time-sheets',
    icon: 'back',
  },
  {
    title: 'application.time_sheet_schemes',
    link: '/dashboard/time-sheets-schemes/list',
    icon: 'list',
  },
  {
    title: 'application.add_time_sheet_scheme',
    link: '/dashboard/time-sheets-schemes/add',
    icon: 'plus',
  },
];

class TimeSheetSchemes extends Component<{}> {
  render() {
    return (
      <>
        <Page light withNavigation>
          <Dashboard navigation={invoiceTabs}>
            <Switch>
              <Route
                exact
                path="/dashboard/time-sheets-schemes"
                render={() => <Redirect to="/dashboard/time-sheets-schemes/list" />}
              />
              <Route exact path="/dashboard/time-sheets-schemes/list" component={TimeSheetList} />
              <Route
                exact
                path="/dashboard/time-sheets-schemes/add"
                component={AddTimeSheetScheme}
              />
              <Route
                exact
                path="/dashboard/time-sheets-schemes/:id"
                component={SingleTimeSheetScheme}
              />
            </Switch>
          </Dashboard>
        </Page>
      </>
    );
  }
}

export default TimeSheetSchemes;
