import React from 'react';
import './BottomContainer.scss';

interface Props {
  children: React.ReactNode;
}

const BottomContainer = ({ children }: Props) => (
  <div className="bottom-container">
    <div className="bottom-wrapper">{children}</div>
  </div>
);

export default BottomContainer;
