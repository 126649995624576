import React, { useState, useEffect } from 'react';
import { VofStatus, ClientUserEntity, UserVofStatusResponse, UserVofStatus } from 'app-types';
import { useSelector, useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import {
  Button,
  Spinner,
  FieldsMapper,
  RequiredIndicator,
  Paragraph,
} from '../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../components/Layout';
import ApiService from '../../../services/api-service';
import { notifications, modal } from '../../../actions';
import './Vof.scss';

import { formOnTranslateString } from '../../../utils/trans-form';
import useHeader from '../../../hooks/useHeader';
import Info from './Info';

const VOF = () => {
  useHeader('application.add_partner', '', {
    text: 'annotations.vof',
    title: 'application.add_partner',
  });
  const dispatch = useDispatch();
  const [vofStatus, setVofStatus] = useState<UserVofStatusResponse | null>(null);

  const getStatus = async () => {
    await ApiService.callFetch('GET', 'vof/status', (data: UserVofStatusResponse) => {
      setVofStatus(data);
    });
  };

  useEffect(() => {
    getStatus();
  }, []);

  if (!vofStatus) return <Spinner />;

  const displayForm = () => {
    if (!vofStatus) return false;
    if (vofStatus.status === UserVofStatus.NONE) return true;
    if (vofStatus.status === UserVofStatus.PARENT && vofStatus.size && vofStatus.size < 2) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Paragraph translation="application.vof_description" narrow />
      <FormContainer>
        {displayForm() && (
          <ItfApiForm
            formId="add-subuser"
            schemaMayBeDynamic
            schemaCacheEnabled={false}
            requiredIndicator={<RequiredIndicator />}
            loadingInfoAboveContent
            schemaFetcher={() => ApiService.loadForm('vof/form/email')}
            sendFetcher={(formId: string, values: any) =>
              ApiService.sendForm('vof/add-subuser', values, 'POST')
            }
            onSavedSuccessfully={() => {
              dispatch(notifications.successNotification('application.invitation_sent'));
              getStatus();
            }}
            loadingInfo={<Spinner overlay halfTransparent />}
            submitButton={
              <ButtonsContainer marginTop>
                <Button type="submit" text="application.add" primary />
              </ButtonsContainer>
            }
            onTranslateString={formOnTranslateString}
            onRenderFullField={FieldsMapper}
          />
        )}
      </FormContainer>
      <Info vofStatus={vofStatus} getStatus={getStatus} />
    </>
  );
};

export default VOF;
