import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import useHeader from '../../../hooks/useHeader';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import AddMileageCar from './Add';
import MileageCarList from './List';
// import SingleCostScheme from './Single';

const mileageCarsTabs = [
  {
    title: 'application.costs',
    link: '/dashboard/mileages',
    icon: 'back',
  },
  {
    title: 'application.cars_list',
    link: '/dashboard/mileage-cars/list',
    icon: 'list',
  },
  {
    title: 'application.add_car',
    link: '/dashboard/mileage-cars/add',
    icon: 'plus',
  },
];

const MileageCars = () => {
  useHeader('application.cars', '/dashboard/mileages/list');

  return (
    <>
      <Page light withNavigation>
        <Dashboard navigation={mileageCarsTabs}>
          <Switch>
            <Route
              exact
              path="/dashboard/mileage-cars"
              render={() => <Redirect to="/dashboard/mileage-cars/list" />}
            />
            <Route exact path="/dashboard/mileage-cars/add" component={AddMileageCar} />
            <Route exact path="/dashboard/mileage-cars/list" component={MileageCarList} />
            {/* <Route exact path="/dashboard/costs-schemes/:id" component={SingleCostScheme} /> */}
          </Switch>
        </Dashboard>
      </Page>
    </>
  );
};

export default MileageCars;
