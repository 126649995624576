import {
  faAngleRight,
  faArrowRight,
  faCheckCircle,
  faExpandArrowsAlt,
  faInfo,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import InfoButton from '../../../../../components/Common/InfoButton';
import { __ } from '../../../../../services/translation';
import './SingleFeature.scss';

interface Props {
  feature: {
    header: string;
    count: number;
    additionalInfo?: number[];
    children?: {
      header: string;
      count: number;
    }[];
  };
}

const SingleFeature = ({ feature }: Props) => {
  const [visible, setVisible] = useState(false);
  const getArray = (n: number) => {
    const arr = [];
    for (let i = 0; i < n; i++) {
      arr.push(i);
    }
    return arr;
  };
  return (
    <li>
      <div className="feature-header" onClick={() => setVisible(!visible)}>
        {feature.count > 0 && (
          <div className="feature-toggle-icon">
            <FontAwesomeIcon icon={faAngleRight} className={`${visible ? 'toggled' : ''}`} />
          </div>
        )}
        {__(`price_list.${feature.header}`)}
      </div>
      <ul className={`features-sublist ${visible ? 'visible' : ''}`}>
        {!feature.children &&
          getArray(feature.count).map((el) => (
            <li key={el}>
              <FontAwesomeIcon className="feature-icon" icon={faCheckCircle} />{' '}
              <div className="feature-content">
                {__(`price_list.${feature.header}_${el + 1}`)}
                {feature.additionalInfo && feature.additionalInfo.includes(el + 1) && (
                  <InfoButton
                    text={`price_list.${feature.header}_${el + 1}_i`}
                    header={`price_list.${feature.header}_${el + 1}`}
                  />
                )}
              </div>
            </li>
          ))}
        {feature.children &&
          feature.children.map((child) => (
            <>
              <div className="feature-subheader">{__(`price_list.${child.header}`)}</div>
              <ul className="features-sub-sublist">
                {getArray(child.count).map((el) => (
                  <li key={el}>
                    <FontAwesomeIcon className="feature-icon" icon={faCheckCircle} />{' '}
                    {__(`price_list.${child.header}_${el + 1}`)}
                  </li>
                ))}
              </ul>
            </>
          ))}
      </ul>
    </li>
  );
};

export default SingleFeature;
