import React from 'react';
import { useDispatch } from 'react-redux';
import { VofStatus, UserVofStatusResponse, UserVofStatus } from 'app-types';
import { Button, InfoParagraph, Alert, Subheader } from '../../../../components/Common';
import { ButtonsContainer } from '../../../../components/Layout';
import './Info.scss';

import { __ } from '../../../../services/translation';
import ApiService from '../../../../services/api-service';
import { notifications, modal } from '../../../../actions';
import { Confirmation } from '../../../../modals';

interface Props {
  vofStatus: UserVofStatusResponse;
  getStatus: () => void;
}

const Info = ({ vofStatus, getStatus }: Props) => {
  const dispatch = useDispatch();

  const deny = async (vofId: string) => {
    await ApiService.callFetch('DELETE', `vof/deny/${vofId}`, () => {
      dispatch(notifications.successNotification('application.vof_denied'));
      dispatch(modal.hideModal());
      getStatus();
    });
  };

  const remove = async (vofId: string) => {
    await ApiService.callFetch('DELETE', `vof/remove/${vofId}`, () => {
      dispatch(notifications.successNotification('application.vof_removed'));
      dispatch(modal.hideModal());
      getStatus();
    });
  };

  const leave = async (vofId: string) => {
    await ApiService.callFetch('DELETE', `vof/leave/${vofId}`, () => {
      dispatch(notifications.successNotification('application.vof_removed'));
      dispatch(modal.hideModal());
      getStatus();
    });
  };

  const accept = async (vofId: string) => {
    await ApiService.callFetch('PATCH', `vof/accept/${vofId}`, () => {
      dispatch(notifications.successNotification('application.vof_accepted'));
      dispatch(modal.hideModal());
      getStatus();
    });
  };

  const confirmDeny = (vofId: string) => {
    dispatch(
      modal.showModal(
        <Confirmation confirmCallback={() => deny(vofId)} text="application.confirm_vof_deny" />,
      ),
    );
  };

  const confirmRemove = (vofId: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          confirmCallback={() => remove(vofId)}
          text="application.confirm_vof_remove"
        />,
      ),
    );
  };

  const confirmLeave = (vofId: string) => {
    dispatch(
      modal.showModal(
        <Confirmation confirmCallback={() => leave(vofId)} text="application.confirm_vof_remove" />,
      ),
    );
  };

  const content = () => {
    if (!vofStatus || vofStatus.status === UserVofStatus.NONE) {
      return <InfoParagraph name="application.vof_status" translate="application.unrelated" />;
    } else if (vofStatus.status === UserVofStatus.PARENT) {
      return (
        <>
          {vofStatus.size && vofStatus.size >= 2 && (
            <Alert simple type="notice" text="application.vof_limit_reached" />
          )}
          <InfoParagraph name="application.vof_status" translate="application.vof_main" />
          {vofStatus.children && (
            <div className="vof-members">
              <Subheader text="application.vof_members" />
              <ul>
                {vofStatus.children.map((child) => (
                  <li>
                    <div>{child.email}</div>
                    {child.status === VofStatus.ACTIVE ? (
                      <span className="vof-status active">{__('application.vof_active')}</span>
                    ) : (
                      <span className="vof-status inactive">{__('application.vof_inactive')}</span>
                    )}
                    <ButtonsContainer>
                      <Button
                        small
                        text="application.delete"
                        danger
                        click={() => confirmRemove(child.vofId)}
                      />
                    </ButtonsContainer>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      );
    } else if (vofStatus.status === UserVofStatus.CHILD) {
      return (
        <>
          <InfoParagraph name="application.vof_status" translate="application.vof_partner" />
          {vofStatus.parent && (
            <>
              <InfoParagraph name="application.vof_creator" email={vofStatus.parent.email} />
              <ButtonsContainer marginTop>
                {vofStatus.parent.status === VofStatus.ACTIVE ? (
                  <Button
                    danger
                    text="application.leave_vof_group"
                    click={() => confirmLeave(vofStatus.parent!.vofId)}
                  />
                ) : (
                  <>
                    <Button
                      success
                      text="application.accept"
                      click={() => accept(vofStatus.parent!.vofId)}
                    />
                    <Button
                      danger
                      text="application.deny"
                      click={() => confirmDeny(vofStatus.parent!.vofId)}
                    />
                  </>
                )}
              </ButtonsContainer>
            </>
          )}
        </>
      );
    }
  };
  return <div className="vof-status-wrapper">{content()}</div>;
};

export default Info;
