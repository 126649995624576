import { NewServiceForInvoice, ServiceEntity } from '..';
import { Client } from '../../src/client/client.entity';
import { Service } from '../../src/service-schema/entity/service.entity';
import { ClientEntity } from '../client';
import { InvoiceUser } from '../user';

export interface InvoiceEntity extends InvoiceParamsEntity {
  id: string;
  number: string;
  createdAt: Date;
  client: ClientEntity;
  issuer: InvoiceUser;
  isPaid: boolean;
  isCreditNote?: boolean;
  creditNoteFor?: string;
  sentTo: string[];
  companyName: InvoiceCompanyName;
  defaultAccount: boolean;
  secondAccount: boolean;
}

export enum InvoiceCompanyName {
  FIRST,
  SECOND,
}

export interface SentInvoiceHistoryEntity {
  sentDate: Date;
  attachment: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  sentToSelf: boolean;
  senderEmail: string;
  senderName: string;
  clientData: Client;
  senderData: InvoiceUser;
  invoiceNumber: string;
}

export interface InvoiceParamsEntity {
  mark: string;
  totalVal: number;
  totalTaxVal: number;
  paymentTerm?: Date;
}

export interface InvoiceSchemaEntity extends InvoiceParamsEntity {
  name: string;
}

export interface InvoiceSettings {
  nextNumber: number;
  prefix: string;
  defaultNumber: string;
  isDefault: boolean;
}

export interface InvoiceNumeration extends InvoiceSettings {
  numberCompiled: string;
}

export interface InvoiceToGenerate {
  number: string;
  createdAt: Date;
  client: Client;
  issuer: InvoiceUser;
  mark: string;
  paymentTerm: Date;
  totalVal: number;
  totalTaxVal: number;
  services: NewServiceForInvoice[];
  isCreditNote?: boolean;
  creditNoteFor?: string;
}
