import { CostRes, TaxRate } from 'app-types';

interface LoadingSingleCost {
    type: 'LOADING_SINGLE_COST';
    payload: boolean;
}

interface GetSingleCostDetails {
    type: 'GET_SINGLE_COST_DETAILS_SUCCESS';
    payload: CostRes;
}

interface SetUnpaidCostsCount {
    type: 'SET_COSTS_UNPAID_COUNT_SUCCESS';
    payload: number;
}

export interface CostState {
    loading: boolean;
    details: CostRes;
    image: string;
    unpaidCount: 0;
}

const initialState: CostState = {
    loading: true,
    details: {
        id: '',
        date: new Date(),
        fileName: null,
        name: '',
        value: 0,
        invoiceId: null,
        taxRate: TaxRate['21%'],
        paymentType: 0,
    },
    image: '',
    unpaidCount: 0,
};

type Action =
    | LoadingSingleCost
    | GetSingleCostDetails
    | SetUnpaidCostsCount

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case 'LOADING_SINGLE_COST': {
            return { ...state, loading: action.payload };
        }
        case 'GET_SINGLE_COST_DETAILS_SUCCESS': {
            return { ...state, details: action.payload };
        }
        case 'SET_COSTS_UNPAID_COUNT_SUCCESS': {
            return { ...state, unpaidCount: action.payload }
        }
        default:
            return state;
    }
};
