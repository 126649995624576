import React from 'react';
import { Link } from 'react-router-dom';
import { __ } from '../../../services/translation';

const LoginParagraph = () => (
  <p>
    {__('application.already_have_an_account?')} <Link to="login">{__('application.sign_in')}</Link>
  </p>
);

export default LoginParagraph;
