import React from 'react';
import { TaxRate, ServiceEntity, ServiceType, DiscountType } from 'app-types';
import TaxRateRow from '../TaxRateRow';
import { percentFromTaxRate } from '../../../utils/tax-rate-percent';
import { __ } from '../../../services/translation';
import { formatPrice } from '../../../utils/format-price';
import './TaxRateTable.scss';
import { formatDuration } from '../../../utils/format-duration';

interface Props {
  data: ServiceEntity[];
  showSum?: boolean;
  showNames?: boolean;
  includeNetSwitch?: boolean;
  isNet?: boolean;
  details?: boolean;
  overall?: boolean;
}

const TaxRateTable = ({ data, showSum, showNames, overall }: Props) => {
  const getTaxValue = (tax: TaxRate) => percentFromTaxRate(tax) / 100;

  const getQuantity = (service: ServiceEntity) => {
    if (typeof service.quantity !== 'number' || Number.isNaN(service.quantity)) return 0;
    if (service.type === ServiceType.UNIT) return service.quantity;
    else return service.quantity / 60; // getting hours from minutes
  };

  const getDisplayedQuantity = (service: ServiceEntity) => {
    if (typeof service.quantity !== 'number' || Number.isNaN(service.quantity)) return '-';
    if (service.type === ServiceType.UNIT) return service.quantity;
    else return formatDuration(service.quantity, true);
  };

  const getNetPrice = (service: ServiceEntity) => {
    const taxRate = getTaxValue(service.taxRate);
    const price = service.net ? service.price : service.price / (1 + taxRate);
    return price;
  };

  const getValueWithoutVat = (service: ServiceEntity) => {
    const net = getNetPrice(service);
    const quantity = getQuantity(service);
    return quantity * net;
  };

  const getValueWithVat = (service: ServiceEntity) => {
    const net = getNetPrice(service);
    const taxRate = getTaxValue(service.taxRate);
    const quantity = getQuantity(service);
    const total = quantity * net * (1 + taxRate);

    return total;
  };

  const getValueWithDiscount = (service: ServiceEntity) => {
    const val = getValueWithVat(service);
    if (service.discountType === DiscountType.PERCENT) return (1 - service.discount / 100) * val;
    else {
      return val - (val >= 0 ? service.discount : -service.discount);
    }
  };

  const getVatValue = (service: ServiceEntity) => {
    const net = getNetPrice(service);
    const taxRate = getTaxValue(service.taxRate);
    const quantity = getQuantity(service);

    const total = quantity * net * taxRate;
    return total;
  };

  const getTotalNet = (services: ServiceEntity[]) => {
    const totalDue = services.reduce((acc, curr) => {
      const quantity = getQuantity(curr);
      const withoutVat = getNetPrice(curr) * quantity;
      return acc + withoutVat;
    }, 0);
    return totalDue;
  };

  const getTotalNetWithDiscount = (services: ServiceEntity[]) => {
    const totalDue = services.reduce((acc, curr) => {
      const withVat = getValueWithDiscount(curr);

      return acc + withVat / (1 + getTaxValue(curr.taxRate));
      // return acc + withoutVat;
    }, 0);
    return totalDue;
  };

  const getTotalVat = (services: ServiceEntity[]) => {
    const totalVat = services.reduce((acc, curr) => {
      const vat = getVatValue(curr);
      return acc + vat;
    }, 0);
    return totalVat;
  };

  const getTotalGross = (services: ServiceEntity[]) => {
    const totalDue = services.reduce((acc, curr) => {
      const withVat = getValueWithDiscount(curr);
      return acc + withVat;
    }, 0);
    return totalDue;
  };

  const getTotalDiscount = (services: ServiceEntity[]) => {
    const totalDiscount = services.reduce((acc, curr) => {
      const withVat = getValueWithVat(curr);
      if (curr.discountType === DiscountType.PERCENT) return acc + withVat * (curr.discount / 100);
      else return acc + (curr.quantity >= 0 ? curr.discount : -curr.discount);
    }, 0);
    return totalDiscount;
  };

  const getTotalAfterDiscount = (services: ServiceEntity[]) => {
    const totalDiscount = services.reduce((acc, curr) => acc + getValueWithDiscount(curr), 0);
    return totalDiscount;
  };

  return (
    <>
      <table className="tax-rate-table">
        <thead>
          {!overall && (
            <tr className="time-sheet-for-invoice-table-header">
              {showNames && <th className="tax-rate-header-item" />}
              <th className="tax-rate-header-item">{__('application.excluded_btw')}</th>
              <th className="tax-rate-header-item">{__('application.quantity')}</th>
              <th className="tax-rate-header-item">{__('application.btw_value')}</th>
              <th className="tax-rate-header-item">{__('application.discount')}</th>
              <th className="tax-rate-header-item">{__('application.tax_rate')}</th>
              <th className="tax-rate-header-item">{__('application.total')}</th>
            </tr>
          )}
          {overall && (
            <tr className="time-sheet-for-invoice-table-header">
              <th className="tax-rate-header-item">{__('application.net_sum')}</th>
              <th className="tax-rate-header-item">{__('application.btw_value_sum')}</th>
              <th className="tax-rate-header-item">{__('application.discount_sum')}</th>
              <th className="tax-rate-header-item">{__('application.gross_sum')}</th>
              <th className="tax-rate-header-item">{__('application.total_sum')}</th>
            </tr>
          )}
        </thead>
        <tbody>
          {!overall &&
            data.map((item: ServiceEntity) => (
              <>
                <TaxRateRow
                  name={showNames ? item.name : undefined}
                  noBtw={getNetPrice(item)}
                  quantity={getDisplayedQuantity(item)}
                  btw={getVatValue(item)}
                  discount={item.discount}
                  discountType={item.discountType}
                  taxRate={percentFromTaxRate(item.taxRate)}
                  sum={getValueWithDiscount(item)}
                />
              </>
            ))}
          {overall && (
            <tr>
              <td className="tax-rate-row">{formatPrice(getTotalNet(data))}</td>
              <td className="tax-rate-row">{formatPrice(getTotalVat(data))}</td>
              <td className="tax-rate-row">{formatPrice(getTotalDiscount(data))}</td>
              <td className="tax-rate-row">{formatPrice(getTotalGross(data))}</td>
              <td className="tax-rate-row">{formatPrice(getTotalAfterDiscount(data))}</td>
            </tr>
          )}
        </tbody>
      </table>
      {showSum ? (
        <>
          <div className="tax-table-sum-wrapper">
            <span className="tax-table-sum-label">{__('application.net_sum')}:</span>{' '}
            <span className="tax-table-sum-sum">{formatPrice(getTotalNetWithDiscount(data))}</span>
          </div>
          <div className="tax-table-sum-wrapper">
            <span className="tax-table-sum-label">{__('application.gross_sum')}:</span>{' '}
            <span className="tax-table-sum-sum">{formatPrice(getTotalGross(data))}</span>
          </div>
        </>
      ) : null}
    </>
  );
};

export default TaxRateTable;
