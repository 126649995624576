import { ApiErrorCode } from 'app-types';
import { __ } from '../services/translation';

export const successNotification = (text: string) => ({
  type: 'SUCCESS_NOTIFICATION',
  payload: __(text),
});
export const errorNotification = (error: ApiErrorCode) => ({
  type: 'ERROR_NOTIFICATION',
  payload: error,
});
export const errorNotificationText = (error: string) => ({
  type: 'ERROR_NOTIFICATION_TEXT',
  payload: error,
});
export const hideNotification = (id: string) => ({ type: 'HIDE_NOTIFICATION', payload: id });
