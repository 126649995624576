import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { __ } from '../../../services/translation';
import './Price.scss';

interface Props {
  big: string;
  small: string;
  period: string;
  smaller?: boolean;
}

const Price = ({ smaller, small, big, period }: Props) => (
  <div className={`price-container ${smaller ? 'smaller' : ''}`}>
    <div className="price-inner-container">
      <p className="price-big">{big}</p>
    </div>
    <div className="price-inner-container-sec">
      <div className="change">{small}€</div>
      <div className="tax-info">excl. 21% BTW</div>
      <div className="period">/{__(period)}</div>
    </div>
  </div>
);

export default Price;
