export enum StatisticsTab {
  INCOME = 'income',
  MILEAGE = 'mileage',
}

export enum StatisticPeriod {
  ALL = 'all',
  THIS_YEAR = 'this-year',
}

export interface StatisticsSummaryItem {
  label: string;
  amount: string;
}

export interface StatisticsItem {
  incomeSummary: number;
  costsSummary: number;
  mileageSummary: number;
  result: number;
}
