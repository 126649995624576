import React from 'react';
import Logo from '../../Logo';
import { HamburgerButton } from '../..';
import './MenuHeader.scss';

const MenuHeader = () => (
  <div className="menu-header">
    <HamburgerButton />
    <div className="header-logo-container">
      <Logo dark />
    </div>
  </div>
);

export default MenuHeader;
