import React from 'react';
import { GetClientKvKRes, TimeSheetTemplateEntity } from 'app-types';
import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
} from 'itf_formbuilder_react';
import { useDispatch } from 'react-redux';
import { ButtonsContainer, FormContainer } from '../../../components/Layout';
import { Button, FieldsMapper, RequiredIndicator, Spinner } from '../../../components/Common';
import { formOnTranslateString } from '../../../utils/trans-form';
import { history, flatpickr } from '../../../App';
import { apiList, modal, notifications } from '../../../actions';
import ApiService from '../../../services/api-service';
import { __ } from '../../../services/translation';
import Periods from '../Periods';

interface Props {
  formUrl: string;
  formSendUrl: string;
  method: string;
  edit?: boolean;
  callback?: (data: TimeSheetTemplateEntity) => void;
  buttonText?: string;
}

const TimeSheetSchemeForm = ({
  formUrl,
  formSendUrl,
  method,
  edit,
  callback,
  buttonText,
}: Props) => {
  const dispatch = useDispatch();

  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  return (
    <>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="time-sheet-form"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(formUrl)}
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm(formSendUrl, values, method)
          }
          onSavedSuccessfully={(data: TimeSheetTemplateEntity) => {
            if (callback) {
              callback(data);
              dispatch(notifications.successNotification('application.time_sheet_scheme_created'));
              dispatch(modal.hideModal());
            } else if (edit) {
              dispatch(notifications.successNotification('application.time_sheet_scheme_edited'));
              dispatch(modal.hideModal());
              dispatch(apiList.refreshApiList());
            } else {
              dispatch(notifications.successNotification('application.time_sheet_scheme_created'));
              history.push('/dashboard/time-sheets-schemes/list');
            }
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text={buttonText || 'application.save'}
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={(string: string) => formOnTranslateString(string)}
          onRenderFullField={(
            field: FormFieldToRender,
            errors: any,
            fieldHtmlId: string,
            currentValue: any,
            onChange: ChangeFunction,
            onFocus: FieldAndEventFunction,
            onBlur: FieldAndEventFunction,
          ) => {
            if (field.id === 'periods') {
              return (
                <Periods
                  updatePeriods={onChange}
                  errors={errors ? errors[0] : {}}
                  initialPeriods={currentValue}
                />
              );
            } else {
              return FieldsMapper(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            }
          }}
          thirdPartyComponents={{ flatpickr }}
        />
      </FormContainer>
    </>
  );
};

export default TimeSheetSchemeForm;
