import React, { useEffect, useState } from 'react';
import { CreatedClientAccountRes } from 'app-types';
import Dashboard from '../../../views/DashboardCommon';
import { Page } from '../../../components/Layout';
import useHeader from '../../../hooks/useHeader';
import CreateNewAccountForm from './CreateNewAccountForm';
import { List } from './List';
import ApiService from '../../../services/api-service';

const AccountsForClients = () => {
  useHeader('application.accounts_for_clients_header', '/dashboard/accounts_for_clients/');
  const [records, setRecords] = useState<CreatedClientAccountRes[]>([]);

  const fetchData = async () => {
    await ApiService.callFetch(
      'GET',
      'user/created-clients-accounts',
      (records: CreatedClientAccountRes[]) => {
        setRecords(records);
      },
    );
  };

  const appendRecord = (record: CreatedClientAccountRes) => {
    setRecords([...records, record]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page light withNavigation>
      <Dashboard>
        <CreateNewAccountForm appendRecord={appendRecord} />
        <List records={records} />
      </Dashboard>
    </Page>
  );
};

export default AccountsForClients;
