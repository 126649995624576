import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { CostRes } from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { EditCost, Confirmation } from '../../../../modals';

import { modal, cost, header } from '../../../../actions';
import { ButtonsContainer } from '../../../../components/Layout';
import {
  Button,
  OptionalImage,
  PDFPreview,
  SingleCostPreview,
  Spinner,
  Subheader,
} from '../../../../components/Common';

import { ApplicationState } from '../../../../reducers';

import './SingleCost.scss';
import { __ } from '../../../../services/translation';
import { DownloadService } from '../../../../services/download-service';
import ApiService from '../../../../services/api-service';

interface Props extends RouteComponentProps {
  showModal: (content: React.ReactNode) => void;
  deleteCost: (id: string) => void;
  hideModal: () => void;
  details: CostRes;
  match: any;
  loading: boolean;
  getCostDetails: (id: string) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class SingleCost extends React.Component<Props> {
  componentDidMount() {
    const { match, getCostDetails, setHeader } = this.props;
    getCostDetails(match.params.id);
    setHeader('application.cost', '/dashboard/costs');
  }

  private confirmCostRemoval = () => {
    const { showModal, deleteCost, hideModal, match } = this.props;
    const { id } = match.params;
    showModal(
      <Confirmation
        text="application.are_you_sure_you_want_to_delete_this_cost?"
        confirmCallback={() => deleteCost(id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  private editCost = () => {
    const { showModal, match } = this.props;
    const { id } = match.params;
    showModal(<EditCost id={id} />);
  };

  private downloadCost = async () => {
    const { match } = this.props;
    const { id } = match.params;
    await DownloadService.download(`cost/one/${id}`);
  };

  render() {
    const { details, loading } = this.props;
    return (
      <>
        {loading ? (
          <Spinner overlay />
        ) : (
          <div className="single-cost">
            <SingleCostPreview cost={details} />
            {details.fileName && details.fileName.includes('.pdf') && (
              <PDFPreview url={`${ApiService.url}cost/one/${details.id}`} />
            )}
            {details.fileName && !details.fileName.includes('.pdf') && (
              <OptionalImage url={`cost/one/${details.id}`} alt="cost" />
            )}

            {details.invoiceId && (
              <div className="single-const-invoice-info">
                <Subheader highlight level={1} text="application.related_invoice">
                  <Button
                    small
                    to={`/dashboard/invoices/${details.invoiceId}`}
                    text="application.see"
                  />
                </Subheader>
                <p>
                  <FontAwesomeIcon icon={faFileInvoice} className="invoice-info-icon" />
                  {__('application.cost_is_in_invoice')}
                </p>
              </div>
            )}
            <ButtonsContainer marginTop>
              {details.fileName && (
                <Button
                  text="application.download"
                  noShadow
                  rightMargin
                  click={this.downloadCost}
                  bottomMargin
                />
              )}
              <Button
                text="application.edit"
                secondary
                noShadow
                rightMargin
                click={this.editCost}
                bottomMargin
              />
              <Button
                text="application.delete"
                danger
                noShadow
                click={this.confirmCostRemoval}
                bottomMargin
              />
            </ButtonsContainer>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.cost.loading,
  details: state.cost.details,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: modal.showModal,
      deleteCost: cost.deleteCost,
      hideModal: modal.hideModal,
      getCostDetails: cost.getCostDetails,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleCost);
