import React from 'react';
import { Page, Content, BottomContainer } from '../../components/Layout';
import { Logo, WelcomeHeader, Button } from '../../components/Common';
import './PageNotFound.scss';
import useHeader from '../../hooks/useHeader';

const PageNotFound = () => {
  useHeader('');
  return (
    <Page padded center className="page-not-found" noMenu>
      <Logo big center dark />
      <Content center>
        <WelcomeHeader text="application.page_not_found" />
      </Content>
      {/* For flexbox to work properly */}
      <span />
      <BottomContainer>
        <Button to="/" text="application.go_to_home_page" />
      </BottomContainer>
    </Page>
  );
};

export default PageNotFound;
