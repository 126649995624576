import React from 'react';
import { Content } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import { __ } from '../../../../services/translation';
import { Paragraph } from '../../../../components/Common';

const Incasso = () => {
  useHeader('application.incasso');
  return (
    <>
      <Content center>
        <Paragraph translation="application.incasso_description" />
      </Content>
    </>
  );
};

export default Incasso;
