import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Field, reduxForm, InjectedFormProps, change, formValueSelector } from 'redux-form';
import { FormContainer, Page, Container, ButtonsContainer } from '../../../components/Layout';
import { Button, ClientAutosuggestion, Input, Subheader } from '../../../components/Common';
import { user } from '../../../actions';
import ValidationService from '../../../services/validation-service';
import { __ } from '../../../services/translation';
import useHeader from '../../../hooks/useHeader';
import { ApplicationState } from '../../../reducers';

interface Props extends InjectedFormProps {
  receiveKvk: (values: any) => void;
  change: (field: string, value: any) => void;
  kvk: string;
}

const EnterKvk = ({ receiveKvk, change, kvk, handleSubmit }: Props) => {
  useHeader('application.fill_data');
  const handleClientSelect = (option: any) => {
    change('search', option.name);
    change('kvk', option.kvk);
  };
  return (
    <>
      <Page light center>
        <Container paddingTop>
          <Subheader text="application.first_use_subheader" />
          <FormContainer>
            <form onSubmit={handleSubmit((values: any) => receiveKvk(values))}>
              <Field
                name="search"
                type="text"
                component={ClientAutosuggestion}
                kvkOnly
                validate={ValidationService.required}
                onOptionSelect={handleClientSelect}
                placeholder="application.first_use_placeholder"
              />
              <Field
                name="kvk"
                type="hidden"
                component={Input}
                placeholder="application.kvk_placeholder"
              />

              <ButtonsContainer>
                <Button text="application.next" disabled={!kvk} />
              </ButtonsContainer>
            </form>
          </FormContainer>
        </Container>
      </Page>
    </>
  );
};

const selector = formValueSelector('enterKvk');

const mapStateToProps = (state: ApplicationState) => ({
  kvk: selector(state, 'kvk'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ receiveKvk: user.receiveKvk, changeField: change }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, any>({
    form: 'enterKvk',
    enableReinitialize: true,
  })(EnterKvk),
);
