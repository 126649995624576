import React from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';

import './Chart.scss';

interface Props {
  data: any;
}

const Chart = ({ data }: Props) => (
  <div className="income-chart-container">
    <ResponsiveContainer width="100%">
      <LineChart data={data} margin={{ left: 0, right: 20, top: 20 }}>
        <Line type="monotone" dataKey="value" stroke="#faba1a" dot={false} strokeWidth={3} />
        <XAxis dataKey="week" />
        <YAxis dataKey="value" />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default Chart;
