import React, { ChangeEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { SelectOption } from '../../../../../types/select';
import { StatisticsItem, StatisticsSummaryItem } from '../../../../../types/statistics';
import { Input, Subheader, Card } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import { formatPrice } from '../../../../../utils/format-price';
import './ProfitsCard.scss';

interface Props {
  title: string;
  values: string[];
  firstYear: number;
  secondYear: number | null;
  options: SelectOption[];
  type: 'yearly' | 'quarterly' | 'monthly';
  getSummary: (value: number, part?: number) => StatisticsItem;
}

const ProfitsCard = ({
  options,
  firstYear,
  secondYear,
  type,
  values,
  getSummary,
  title,
}: Props) => {
  const [value, setValue] = useState<number>(0);
  const [secondValue, setSecondValue] = useState<number>(0);
  const [summaryFirst, setSummaryFirst] = useState<StatisticsItem>(getSummary(firstYear));
  const [summarySecond, setSummarySecond] = useState<StatisticsItem | null>(
    secondYear ? getSummary(secondYear) : null,
  );

  useEffect(() => {
    const summaryFirst = getSummary(firstYear, value);
    const summarySecond = secondYear ? getSummary(secondYear, secondValue) : null;
    setSummaryFirst(summaryFirst);
    setSummarySecond(summarySecond);
  }, [value, secondValue, firstYear, secondYear]);

  const months: SelectOption[] = [
    { name: __('months.january'), value: 0 },
    { name: __('months.febuary'), value: 1 },
    { name: __('months.march'), value: 2 },
    { name: __('months.april'), value: 3 },
    { name: __('months.may'), value: 4 },
    { name: __('months.june'), value: 5 },
    { name: __('months.july'), value: 6 },
    { name: __('months.august'), value: 7 },
    { name: __('months.september'), value: 8 },
    { name: __('months.october'), value: 9 },
    { name: __('months.november'), value: 10 },
    { name: __('months.december'), value: 11 },
  ];

  const quarters: SelectOption[] = [
    { name: 'I', value: 0 },
    { name: 'II', value: 1 },
    { name: 'III', value: 2 },
    { name: 'IV', value: 3 },
  ];

  const getOptions = () => {
    if (type === 'monthly') return months;
    if (type === 'quarterly') return quarters;
    return [];
  };

  return (
    <Card
      header={
        <div className="statistics-header">
          <Subheader noMarginBottom text={title} />
        </div>
      }
      body={
        <div className="statistics-details">
          <div className="statistics-select">
            <span />
            {['monthly', 'quarterly'].includes(type) ? (
              <>
                <Input
                  type="select"
                  options={getOptions().map((option) => ({
                    name: `${option.name} ${firstYear}`,
                    value: option.value,
                  }))}
                  input={{
                    value,
                    onChange: (e: ChangeEvent<any>) => {
                      setValue(Number(parseInt(e.currentTarget.value))); // important to convert there to number
                    },
                  }}
                  meta={{}}
                />
                {secondYear && (
                  <Input
                    type="select"
                    options={getOptions().map((option) => ({
                      name: `${option.name} ${secondYear}`,
                      value: option.value,
                    }))}
                    input={{
                      value: secondValue,
                      onChange: (e: ChangeEvent<any>) => {
                        setSecondValue(Number(e.currentTarget.value)); // important to convert there to number
                      },
                    }}
                    meta={{}}
                  />
                )}
              </>
            ) : (
              <>
                <h3 className="profits-subheader">{firstYear}</h3>
                {secondYear && <h3 className="profits-subheader">{secondYear}</h3>}
              </>
            )}
          </div>

          <div className="results-line">
            <span>{__('application.statistics_income')}</span>
            <span>{formatPrice(summaryFirst.incomeSummary)}</span>
            {values.length > 1 && summarySecond && (
              <span>{formatPrice(summarySecond.incomeSummary)}</span>
            )}
          </div>
          <div className="results-line">
            <span>{__('application.statistics_costs')}</span>
            <span>{formatPrice(summaryFirst.costsSummary)}</span>
            {values.length > 1 && summarySecond && (
              <span>{formatPrice(summarySecond.costsSummary)}</span>
            )}
          </div>
          <div className="results-line">
            <span>{__('application.statistics_mileage')}</span>
            <span>{formatPrice(summaryFirst.mileageSummary)}</span>
            {values.length > 1 && summarySecond && (
              <span>{formatPrice(summarySecond.mileageSummary)}</span>
            )}
          </div>
        </div>
      }
      footer={
        <div className="results-line">
          <span>{__('application.statistics_result')}</span>
          <span
            className={clsx('result', {
              positive: summaryFirst.result > 0,
              negative: summaryFirst.result < 0,
            })}
          >
            {formatPrice(summaryFirst.result)}
          </span>
          {values.length > 1 && summarySecond && (
            <span
              className={clsx('result', {
                positive: summarySecond.result > 0,
                negative: summarySecond.result < 0,
              })}
            >
              {formatPrice(summarySecond.result)}
            </span>
          )}
        </div>
      }
    />
  );
};

export default ProfitsCard;
