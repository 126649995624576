import React, { FC } from 'react';
import { TimeSheetCalendarItem } from '../../../../../types/timeSheetCalendar';
import Icon from '../../../Icon';

import './tile.scss';

interface Item extends TimeSheetCalendarItem {
  direction: 'top' | 'middle' | 'bottom' | 'single';
}

interface Props {
  data: {
    start: string;
    end: string;
    title: string;
    index: number;
  };
  removeItem: (index: number) => void;
  minute: number;
  hour: number;
}

const Tile: FC<Props> = ({ data, removeItem, hour, minute }) => {
  const prepareData = (): Item => {
    const splitStart = data.start.split(':');
    const splitEnd = data.end.split(':');

    const timeCurrent = hour * 60 + minute;
    const timeStart = Number(splitStart[0]) * 60 + Number(splitStart[1]);
    const timeEnd = Number(splitEnd[0]) * 60 + Number(splitEnd[1]);

    if (
      timeStart >= timeCurrent &&
      Math.abs(timeCurrent - timeStart) <= 15 &&
      timeEnd >= timeCurrent &&
      Math.abs(timeCurrent - timeEnd) <= 15
    ) {
      return { ...data, direction: 'single' };
    }

    if (timeStart >= timeCurrent && Math.abs(timeCurrent - timeStart) < 15) {
      return { ...data, direction: 'top' };
    }

    if (timeEnd >= timeCurrent && Math.abs(timeCurrent - timeEnd) < 15) {
      return { ...data, direction: 'bottom' };
    }

    return { ...data, direction: 'middle' };
  };

  const item: Item = prepareData();

  const getColor = (index: number) => {
    if (index % 3 === 0) {
      return 'third';
    }

    if (index % 2 === 0) {
      return 'second';
    }

    if (index % 1 === 0) {
      return 'first';
    }

    return 'first';
  };

  if (!item) {
    return null;
  }

  return (
    <div
      className={`time-calendar-tile ${getColor(item.index)} ${item.direction}`}
      key={item.title}
    >
      {['top', 'single'].includes(item.direction) && (
        <p>
          <span className="time-calendar-tile__title">{item.title}</span> ({item.start} - {item.end}
          )
        </p>
      )}
      {['top', 'single'].includes(item.direction) && (
        <div className="time-calendar-tile__button-container">
          <Icon
            icon="cross"
            className="time-calendar-tile__button-remove"
            onClick={() => {
              removeItem(item.index);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Tile;
