import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { TableSchema, notificationsTableSchema } from 'app-tables';
import { NotificationTableRes } from 'app-types';
import { modal, header } from '../../../../actions';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { ApiTable } from '../../../../components/Common';
import { NotificationPreview } from '../../../../modals';

interface Props {
  showModal: (content: React.ReactNode) => void;
  hideModal: () => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class NotificationList extends Component<Props> {
  private scheme: TableSchema;

  constructor(props: Props) {
    super(props);
    this.scheme = new InteractiveTableSchema(notificationsTableSchema).addFieldAtTheEnd({
      name: 'application.actions',
      field: 'actions',
      mobile: true,
      buttons: [{ name: 'application.see', type: 'secondary', click: this.showNotification }],
    });
  }

  componentDidMount() {
    const { setHeader } = this.props;
    setHeader('application.notification_list', '/');
  }

  private showNotification = (notification: NotificationTableRes) => {
    this.props.showModal(<NotificationPreview id={notification.id} />);
  };

  render() {
    return (
      <>
        <ApiTable apiEndpointSubUrl="notification/list" scheme={this.scheme} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(NotificationList);
