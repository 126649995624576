import React from 'react';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { OtherIncomeDetails } from 'app-types';
import { useDispatch } from 'react-redux';
import { ListItem, SentStatus } from '../../../../../components/Common';
import { formatDate } from '../../../../../utils/format-date';
import { formatPrice } from '../../../../../utils/format-price';
import { modal, otherIncome } from '../../../../../actions';
import { EditOtherIncome, Confirmation } from '../../../../../modals';

interface Props {
  item: OtherIncomeDetails;
}

const OtherIncomeListItem = ({ item }: Props) => {
  const dispatch = useDispatch();
  const { date, id, name, value, sentTo } = item;

  const editOtherIncome = () => {
    dispatch(modal.showModal(<EditOtherIncome id={id} />));
  };

  const confirmDelete = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.other_income_delete_confirmation"
          confirmCallback={() => dispatch(otherIncome.deleteOtherIncome(item.id))}
          cancelCallback={() => dispatch(modal.hideModal())}
        />,
      ),
    );
  };

  return (
    <ListItem
      header={name}
      icon={faEuroSign}
      paragraphs={[
        { icon: 'calendar', text: formatDate(date) },
        { icon: 'other-income', text: formatPrice(value || 0) },
      ]}
      headerRight={(sentTo || []).length > 0 ? <SentStatus /> : null}
      buttons={[
        {
          text: 'application.details',
          to: `/dashboard/other-income/${id}`,
        },
        {
          text: 'application.edit',
          type: 'secondary',
          click: editOtherIncome,
        },
        {
          text: 'application.delete',
          type: 'danger',
          click: confirmDelete,
        },
      ]}
    />
  );
};

export default OtherIncomeListItem;
