import React, { ClassicComponent, Component, ComponentType, FunctionComponent } from 'react';
import {
  UserRole,
  SomeUserEntity,
  AccountantConnectorDetails,
  AuthLoginUserResult,
} from 'app-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Spinner } from '../../Common';
import { ApplicationState } from '../../../reducers';

interface Props {
  user: SomeUserEntity | null;
  exact?: boolean;
  path?: string;
  component: any;
  loading: boolean;
  managedUser: AuthLoginUserResult | null;
  needsClient?: boolean;
}

class RouteAccountant extends Component<Props> {
  render() {
    const { user, exact, path, component, loading, needsClient, managedUser } = this.props;
    let content;
    if (!user || user.role !== UserRole.Accountant || (needsClient && !managedUser)) {
      content = <Route render={() => <Redirect to="/" />} />;
    } else if (user.isBlocked === true) {
      content = <Route render={() => <Redirect to="/blocked" />} />;
    } else {
      content = <Route exact={exact} path={path} component={component} />;
    }
    return <>{loading ? <Spinner overlay /> : content}</>;
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
  loading: state.user.loading,
  managedUser: state.accounting.managedUser,
});

export default connect(mapStateToProps)(RouteAccountant);
