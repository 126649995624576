import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { modal } from '../../../actions';
import { Description } from '../../../modals';

import './InfoButton.scss';

interface Props {
  click?: () => void;
  header?: string;
  text?: string;
  mobileOnly?: boolean;
  small?: boolean;
}

const InfoButton = ({ click, header, text, mobileOnly, small }: Props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (header && text) {
      dispatch(modal.showModal(<Description text={text} header={header} />));
    } else if (click) click();
  };

  return (
    <button
      type="button"
      className={`info-button ${mobileOnly ? 'mobile-only' : ''} ${small ? 'small' : ''}`}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
    </button>
  );
};

export default InfoButton;
