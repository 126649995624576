import { costTemplates, TableSchema } from 'app-tables';
import React from 'react';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { ApiList } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';
import CostSchemeItem from './Item';

const CostTemplatesList = () => {
  useHeader('application.costs_schemes', '/dashboard/costs');

  const scheme: TableSchema = new InteractiveTableSchema(costTemplates);

  return (
    <>
      <ApiList
        apiEndpointSubUrl="cost/template/list"
        scheme={scheme}
        component={CostSchemeItem}
        padded
        noSearch
      />
    </>
  );
};
export default CostTemplatesList;
