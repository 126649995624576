import React from 'react';
import MenuItem from './MenuItem';
import { MenuItemType } from '../../../../types/menu';
import './MenuItems.scss';
import MenuFooter from '../MenuFooter';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import { UserRole } from 'app-types';

interface Props {
  items: MenuItemType[];
}

const MenuItems = ({ items }: Props) => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  const { managedUser } = useSelector((state: ApplicationState) => state.accounting);
  if (!user) return null;
  return (
    <nav className="sliding-menu">
      {user.role === UserRole.Accountant}
      <div className="menu-primary">
        {items
          .filter(
            (item: MenuItemType) =>
              !item.inactive && (!item.restrict || item.restrict.includes(user.role)),
          )
          .filter((item: MenuItemType) => !item.secondary)
          .map((item: MenuItemType) => (
            <MenuItem item={item} key={`menu-items-${item.to}`} />
          ))}
      </div>
      <div className="menu-secondary">
        {items
          .filter(
            (item: MenuItemType) =>
              !item.inactive && (!item.restrict || item.restrict.includes(user.role)),
          )
          .filter((item: MenuItemType) => item.secondary)
          .map((item: MenuItemType) => (
            <MenuItem item={item} key={`menu-items-${item.to}`} />
          ))}
      </div>
      <MenuFooter />
    </nav>
  );
};

export default MenuItems;
