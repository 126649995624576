import { FieldType, TableSchema } from '../typings';

export const requestSettlementLog: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.date',
      field: 'sentDate',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      oppositeSortDir: true,
      type: FieldType.Date,
    },
  ],
};
