import { Dispatch } from 'redux';
import ApiService from '../services/api-service';
import { modal, notifications } from '../actions';

import { history } from '../App';

export const deleteDocument = (id: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.delete(
    dispatch,
    'DELETE_DOCUMENT_SUCCESS',
    'DELETE_DOCUMENT_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(notifications.successNotification('application.document_was_deleted'));
      dispatch({ type: 'REFRESH_API_TABLE' });
      dispatch({ type: 'REFRESH_API_LIST' });
      history.push('/dashboard/costs-documents/list');
    },
    null,
    `document/${id}`,
    'LOADING_DELETE_DOCUMENT',
  );
};

export const getDocumentDetails = (id: string) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_SINGLE_DOCUMENT_DETAILS_SUCCESS',
    'GET_SINGLE_DOCUMENT_DETAILS_FAILED',
    null,
    null,
    `document/detail/${id}`,
    'LOADING_SINGLE_DOCUMENT',
  );
};
