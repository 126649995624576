import { SomeUserEntity, AuthLoginResult } from 'app-types';
import SessionService from '../services/session-service';

interface FetchUserDetailsSuccess {
  type: 'FETCH_USER_DETAILS_SUCCESS';
  payload: SomeUserEntity;
}

interface InitializeMobile {
  type: 'INITIALIZE_MOBILE';
  payload: string;
}

interface LoadingUserDetails {
  type: 'LOADING_FETCH_USER_DETAILS';
  payload: boolean;
}

interface LoginSuccess {
  type: 'LOGIN_SUCCESS';
  payload: AuthLoginResult;
}

interface Logout {
  type: 'LOGOUT';
}

interface RegistrationLoading {
  type: 'REGISTRATION_LOADING';
  payload: boolean;
}

interface ReceiveKvkFormData {
  type: 'RECEIVE_KVK_FORM_DATA';
  payload: boolean;
}

interface LoginLoading {
  type: 'LOGIN_LOADING';
  payload: boolean;
}

interface ClearUserSession {
  type: 'CLEAR_USER_SESSION';
}

interface ReceiveKvk {
  type: 'RECEIVE_KVK_NUMBER';
  payload: string;
}

interface ValidateKvk {
  type: 'VALIDATE_KVK_SUCCESS';
  payload: any;
}

interface LoadingValidateKvk {
  type: 'LOADING_VALIDATE_KVK';
  payload: any;
}

interface ShowActivationMessage {
  type: 'SHOW_ACTIVATION_MESSAGE';
}

interface HideActivationMessage {
  type: 'HIDE_ACTIVATION_MESSAGE';
}

type Action =
  | FetchUserDetailsSuccess
  | InitializeMobile
  | LoginLoading
  | LoginSuccess
  | Logout
  | ClearUserSession
  | LoadingUserDetails
  | RegistrationLoading
  | ReceiveKvkFormData
  | ReceiveKvk
  | ValidateKvk
  | LoadingValidateKvk
  | ShowActivationMessage
  | HideActivationMessage;

export interface UserState {
  token?: string | null;
  details: SomeUserEntity | null;
  loading: boolean;
  registrationLoading: boolean;
  validKvk: boolean;
  loadingKvkValidation: boolean;
  showActivationMessage: boolean;
}

const initialState: UserState = {
  token: null,
  details: null,
  loading: false, // should not be loading at start because of potential problems on iOS
  registrationLoading: false,
  validKvk: false,
  loadingKvkValidation: true,
  showActivationMessage: false,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'INITIALIZE_MOBILE': {
      const token = action.payload;
      return {
        ...state,
        token,
      };
    }
    case 'FETCH_USER_DETAILS_SUCCESS': {
      const user = action.payload;
      return {
        ...state,
        details: action.payload,
      };
    }
    case 'LOADING_FETCH_USER_DETAILS': {
      return { ...state, loading: action.payload };
    }
    case 'LOADING_VALIDATE_KVK': {
      return { ...state, loadingKvkValidation: action.payload };
    }
    case 'VALIDATE_KVK_SUCCESS': {
      const isValid = action.payload !== 54;
      return { ...state, validKvk: isValid };
    }
    case 'LOGIN_LOADING': {
      return { ...state, loading: action.payload };
    }
    case 'REGISTRATION_LOADING': {
      return { ...state, loading: action.payload };
    }
    case 'RECEIVE_KVK_FORM_DATA': {
      return { ...state, registrationForm: action.payload };
    }

    case 'LOGIN_SUCCESS': {
      const { user, token } = action.payload;
      SessionService.setToken(token.accessToken);
      SessionService.setCurrentLanguage(user.language);
      return { ...state, details: user, token: token.accessToken };
    }
    case 'LOGOUT':
      SessionService.clearUserSession();
      return { ...state, details: null };
    case 'SHOW_ACTIVATION_MESSAGE':
      return { ...state, showActivationMessage: true };
    case 'HIDE_ACTIVATION_MESSAGE':
      return { ...state, showActivationMessage: false };
    case 'CLEAR_USER_SESSION':
      SessionService.clearUserSession();
      return { ...state, details: null };
    default:
      return state;
  }
};
