import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Redirect } from 'react-router';
import { ClientEntity, OfferFormData, OfferReq } from 'app-types';
import { isEmptyObject } from '../../../../../utils/is-empty-object';
import { offer } from '../../../../../actions';
import { ButtonsContainer } from '../../../../../components/Layout';
import { InfoParagraph, Button, Subheader, Spinner } from '../../../../../components/Common';
import { ApplicationState } from '../../../../../reducers';
import useHeader from '../../../../../hooks/useHeader';
import { history } from '../../../../../App';
import OfferPreview from './OfferPreview';
import './Preview.scss';

interface Props {
  offerData: OfferFormData | null;
  nextNumber: string;
  saveOffer: (values: OfferReq, id?: string, redirectToSend?: boolean) => any;
  loadingOfferSend: boolean;
  client: ClientEntity | null;
}

const Preview = ({ offerData, saveOffer, loadingOfferSend, client }: Props) => {
  useHeader('application.offer');
  // we do not send the offer number

  useEffect(() => {
    if (offerData && offerData.services.length === 0) history.push('/dashboard/offers/list');
  }, []);

  if (!client || !offerData) return null;

  const offerDataToSend: OfferReq = {
    createdAt: offerData.createdAt,
    client,
    validTo: offerData.validTo,
    annotation: offerData.annotation,
    mark: offerData.mark,
    services: offerData.services,
    files: offerData.files,
  };

  if (isEmptyObject(offerData.services) || !offerData.services) {
    return <Redirect to="/" />;
  }

  const { id, mark, createdAt, files, validTo } = offerData;

  return (
    <>
      <div className="offer-preview">
        {loadingOfferSend && <Spinner halfTransparent overlay />}
        <InfoParagraph text={mark} name="application.mark" />
        <InfoParagraph date={createdAt} name="application.date" />
        <InfoParagraph date={validTo} name="application.offer_valid_to" />

        <OfferPreview data={offerDataToSend} />

        {files && files.length > 0 && files[0].name && (
          <>
            <Subheader level={1} text="application.files" highlight />
            {files.map((file: any) => (
              <div key={file.name} className="offer-file-preview">
                <InfoParagraph text={file.name} name="application.name" />
                <InfoParagraph date={file.date} name="application.date" />
                {file.file && <InfoParagraph text={file.file.name} name="application.file_name" />}
              </div>
            ))}
          </>
        )}
        <ButtonsContainer marginTop>
          <Button
            text="application.edit"
            to="/dashboard/offers/add/preview/edit"
            bottomMargin
            secondary
            disabled={loadingOfferSend}
          />
          <Button
            text="application.save_and_send"
            disabled={loadingOfferSend}
            click={() => {
              saveOffer(offerDataToSend, id, true);
            }}
            bottomMargin
          />

          <Button
            text="application.save"
            disabled={loadingOfferSend}
            primary
            click={() => {
              saveOffer(offerDataToSend, id);
            }}
            bottomMargin
          />
        </ButtonsContainer>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  offerData: state.offer.receivedOfferData,
  client: state.offer.client,
  loadingOfferSend: state.offer.loadingOfferSend,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveOffer: offer.saveOffer,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
