import clsx from 'clsx';
import React from 'react';
import './Card.scss';

interface Props {
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  light?: boolean;
  compact?: boolean;
}

const Card = ({ header, body, footer, light, compact }: Props) => (
  <div className={clsx('card', { light, compact })}>
    {header && <div className="card-header">{header}</div>}
    <div className="card-body">{body}</div>
    {footer && <div className="card-footer">{footer}</div>}
  </div>
);

export default Card;
