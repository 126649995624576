import React from 'react';
import { TableSchema, paymentsForUser, FieldType } from 'app-tables';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { InteractiveTableSchema } from '../../../utils/table-shcema-utils';
import { AdminPaymentButtons, ApiTable, Button } from '../../../components/Common';
import useHeader from '../../../hooks/useHeader';
import ApiService from '../../../services/api-service';
import { __ } from '../../../services/translation';
import { ButtonsContainer, Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import { showModal } from '../../../actions/modal';
import BlockBecausePayment from '../../../modals/BlockBecausePayment';

const UserPayments = () => {
  useHeader('application.payment_list');
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const scheme: TableSchema = new InteractiveTableSchema(paymentsForUser).addFieldAtTheEnd({
    name: 'application.actions',
    field: 'editButton',
    mobile: true,
    type: FieldType.Custom,
    customRender: (value, field, values) => <AdminPaymentButtons payment={values} />,
  });

  const downloadPDF = (id: string) => {
    saveAs(`${ApiService.url}payment/invoice-pdf/${id}`);
  };

  const showBlockBecausePaymentModal = () => {
    dispatch(showModal(<BlockBecausePayment id={id} />));
  };

  return (
    <>
      <ButtonsContainer>
        <Button
          text="application.block_account_because_payment_has_been_canceled"
          click={showBlockBecausePaymentModal}
        />
      </ButtonsContainer>
      <ApiTable apiEndpointSubUrl={`payment/list/${id}`} scheme={scheme} />
    </>
  );
};

export default UserPayments;
