import React from 'react';
import { Button, ListParagraph } from '..';
import { SingleListParagraph } from '../../../types/list-paragraph';
import Subheader from '../Subheader';
import './ListItem.scss';

interface Props {
  buttons: any[];
  paragraphs?: SingleListParagraph[];
  data?: React.ReactNode;
  subheader?: React.ReactNode;
  header?: string | number;
  singleRow?: boolean;
  noShadow?: boolean;
  icon?: any;
  level?: number;
  className?: string;
  headerRight?: React.ReactNode;
}

const ListItem = ({
  header,
  subheader,
  className,
  data,
  icon,
  buttons,
  paragraphs,
  noShadow,
  singleRow,
  level,
  headerRight,
}: Props) => (
  <div
    className={`list-item ${className} ${noShadow ? 'no-shadow' : ''} ${
      singleRow ? 'single-row' : ''
    }`}
  >
    {header && (
      <Subheader
        rawText={header}
        level={level}
        halfMarginBottom={!singleRow}
        noMarginBottom={singleRow}
      >
        <div className="list-item-header-right">{headerRight}</div>
      </Subheader>
    )}
    {subheader}
    <div className="list-item-container">
      {/* {icon && (
        <div className="list-item-icon-wrapper">
          <FontAwesomeIcon className="icon" icon={icon} />
        </div>
      )} */}
      <div className="list-item-content">
        {paragraphs &&
          paragraphs.map((paragraph: SingleListParagraph, index: number) =>
            paragraph.hide ? null : (
              <ListParagraph
                key={index}
                icon={paragraph.icon}
                success={paragraph.success}
                danger={paragraph.danger}
                warning={paragraph.warning}
                strong={paragraph.strong}
                text={paragraph.text}
              />
            ),
          )}
        {data}
      </div>
      <div className="list-item-buttons">
        {buttons.map((button: any) =>
          button.hide ? null : (
            <Button
              key={button.text}
              blank={button.blank}
              danger={button.type === 'danger'}
              success={button.type === 'success'}
              secondary={button.type === 'secondary'}
              text={button.text}
              to={button.to}
              click={button.click}
              type="button"
              noShadow
              bottomMargin
            />
          ),
        )}
      </div>
    </div>
  </div>
);

export default ListItem;
