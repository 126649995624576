import { CreateUpdateTimeSheetTemplateReq, TimeSheetEntity, TimeSheetPeriod } from 'app-types';
import { Dispatch } from 'redux';
import ApiService from '../services/api-service';
import { modal, notifications } from '../actions';
import { history } from '../App';
import { parsePickerDate } from '../utils/parse-date';

export const getSingleTimeSheetScheme = (id: string) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_SINGLE_TIME_SHEET_SCHEME_SUCCESS',
    'GET_SINGLE_TIME_SHEET_SCHEME_FAILED',
    null,
    null,
    `time-sheet/template/detail/${id}`,
    'LOADING_SINGLE_TIME_SHEET_SCHEME',
  );
};

export const deleteTimeSheetScheme = (mileageId: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.delete(
    dispatch,
    'DELETE_SINGLE_TIME_SHEET_SCHEME_SUCCESS',
    'DELETE_SINGLE_TIME_SHEET_SCHEME_SUCCESS',
    () => {
      dispatch(modal.hideModal());
      dispatch({ type: 'REFRESH_API_LIST' });
      dispatch(notifications.successNotification('application.time_sheet_scheme_deleted'));
      history.push('/dashboard/time-sheets-schemes/list');
    },
    null,
    `time-sheet/template/${mileageId}`,
    'LOADING_TIME_SHEET',
  );
};

export const addTimeSheetScheme = (
  timeSheet: TimeSheetPeriod,
  isModal?: boolean,
  callback?: (scheme: TimeSheetEntity) => void,
) => async (dispatch: Dispatch<any>) => {
  await ApiService.post(
    dispatch,
    'ADD_TIME_SHEET_SCHEME_SUCCESS',
    'ADD_TIME_SHEET_SCHEME_FAILED',
    (data: TimeSheetEntity) => {
      if (isModal) {
        dispatch(modal.hideModal());
      } else {
        dispatch(notifications.successNotification('application.scheme_added.'));
        history.push('/dashboard/time-sheets-schemes/list');
      }
      if (callback) callback(data);
    },
    null,
    'time-sheet/template/new',
    'LOADING_ADD_TIME_SHEET_SCHEME',
    {
      name: timeSheet.name,
      // because we need full date to store
      start: parsePickerDate(null, timeSheet.start),
      finish: parsePickerDate(null, timeSheet.finish),
    },
  );
};

export const editTimeSheetScheme = (data: TimeSheetEntity, id: string) => async (
  dispatch: Dispatch<any>,
) => {
  await ApiService.patch(
    dispatch,
    'EDIT_TIME_SHEET_SCHEME_SUCCESS',
    'EDIT_TIME_SHEET_SCHEME_FAILED',
    () => {
      dispatch(notifications.successNotification('application.time_sheet_scheme_edited'));
      dispatch(modal.hideModal());
      history.push('/dashboard/time-sheets-schemes/list');
    },
    null,
    `time-sheet/template/${id}`,
    'LOADING',
    {
      name: data.name,
      // because we need full date to store
      start: parsePickerDate(null, data.start),
      finish: parsePickerDate(null, data.finish),
    },
  );
};
