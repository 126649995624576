import React from 'react';
import { isAfter } from 'date-fns';
import { faFileInvoice, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import { InvoiceEntity, InvoiceListRes } from 'app-types';
import { ListItem } from '../../../../components/Common';
import { __ } from '../../../../services/translation';
import { formatDate } from '../../../../utils/format-date';
import { formatPrice } from '../../../../utils/format-price';

import './InvoiceItem.scss';

interface Props {
  item: InvoiceListRes | InvoiceEntity;
}

const InvoiceItem = ({ item }: Props) => {
  const paragraphs = [
    { icon: 'time_sheets', text: formatDate(item.createdAt) },
    { icon: 'pencil', text: item.mark },
    {
      icon: faHourglassEnd,
      text: item.paymentTerm ? formatDate(item.paymentTerm) : '',
      warning: item.paymentTerm && isAfter(new Date(), new Date(item.paymentTerm)),
      hide: !item.paymentTerm,
    },
    { icon: 'costs', text: formatPrice(item.totalVal + item.totalTaxVal) },
  ];

  return (
    <ListItem
      header={item.number}
      icon={faFileInvoice}
      paragraphs={paragraphs}
      buttons={[]}
      noShadow
      className="single-invoice-container"
    />
  );
};

export { InvoiceItem };
