import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { MileageCarRes } from 'app-types';
import { ButtonsContainer, FormContainer } from '../../../components/Layout';
import { Button, Input } from '../../../components/Common';
import { __ } from '../../../services/translation';
import ValidationService from '../../../services/validation-service';
import { addMileageCar, editMileageCar } from '../../../actions/mileage-car';
import { ApplicationState } from '../../../reducers';

interface OwnProps {
  id?: string;
  defaultValues?: MileageCarRes;
}

type Props = OwnProps & InjectedFormProps<MileageCarRes>;

const MileageCarForm = ({ id, handleSubmit }: Props) => {
  const dispatch = useDispatch();

  const save = (data: MileageCarRes) => {
    if (id) {
      dispatch(editMileageCar(data, id));
      return;
    }

    dispatch(addMileageCar(data));
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(save)}>
        <Field
          label="application.registration_number"
          placeholder="application.registration_number_placeholder"
          name="regNumber"
          type="text"
          component={Input}
          required
          validate={[ValidationService.required, ValidationService.max15]}
        />
        <Field
          label="application.car_model"
          placeholder="application.car_model_placeholder"
          name="carModel"
          type="text"
          component={Input}
          validate={ValidationService.max20}
        />
        <Field
          label="application.car_type"
          placeholder="application.car_type_placeholder"
          name="carType"
          type="text"
          component={Input}
          validate={ValidationService.max20}
        />
        <ButtonsContainer marginTop marginLittle>
          <Button
            type="submit"
            primary
            text={id ? 'application.save' : 'application.add'}
            noShadow
          />
        </ButtonsContainer>
      </form>
    </FormContainer>
  );
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
  const { defaultValues } = ownProps;
  return {
    initialValues: {
      regNumber: defaultValues ? defaultValues.regNumber : '',
      carModel: defaultValues ? defaultValues.carModel : '',
      carType: defaultValues ? defaultValues.carType : '',
    },
  };
};

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm<MileageCarRes, any>({
    form: 'mileageCarForm',
    enableReinitialize: false,
    destroyOnUnmount: true,
  })(MileageCarForm),
);
