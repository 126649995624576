import React, { ChangeEvent } from 'react';
import { GetCostSchemeRes } from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '../../../components/Common';
import debounce from '../../../utils/debounce';
import { __ } from '../../../services/translation';
import Suggestion from './Suggestion';
import './CostSchemeAutosuggestion.scss';

import ApiService from '../../../services/api-service';
import RequiredIndicator from '../RequiredIndicator';
import NewNameSuggestion from './NewNameSuggestion';

interface Props {
  allowNewName: boolean;
  allowCreate: boolean;
  onNewNameSelect: (name: string) => void;
  onCreate: (name: string) => void;
  input: {
    onChange: (data: any) => void;
    name: string;
    id: string;
    value: string;
  };
  placeholder: string;
  onOptionSelect: (data: GetCostSchemeRes) => void;
  label: string;
  required?: boolean;
  disabled?: boolean;
}

interface State {
  active: boolean;
  resultCostSchemes: GetCostSchemeRes[];
  loading: boolean;
}

class CostSchemeAutosuggestion extends React.Component<Props, State> {
  debouncedSearch: any;

  constructor(props: Props) {
    super(props);
    this.debouncedSearch = debounce(this.fetchList, 200);

    this.state = {
      active: false,
      resultCostSchemes: [],
      loading: false,
    };
  }

  private fetchList = async (query: string) => {
    const result: { resultCostSchemes: GetCostSchemeRes[] } = {
      resultCostSchemes: [],
    };
    const listCostSchemes: any = await ApiService.callFetch(
      'GET',
      `cost/template/search-data/${query}`,
    );

    result.resultCostSchemes = listCostSchemes.items;
    this.setState({
      ...result,
      loading: false,
    });
  };

  private handleChange = (val: string) => {
    const { input } = this.props;

    input.onChange(val);
    this.setState({ loading: true, active: true });
    this.debouncedSearch(val);
  };

  render() {
    const {
      input,
      label,
      onOptionSelect,
      placeholder,
      required,
      allowNewName,
      allowCreate,
      onCreate,
      onNewNameSelect,
      disabled,
    } = this.props;
    const { resultCostSchemes, loading, active } = this.state;
    const inputLabel = label && (
      <label htmlFor={input.name}>
        {__(label)} {required && <RequiredIndicator />}{' '}
      </label>
    );
    return (
      <div className="input-container">
        {inputLabel}

        <div className="input-sugestion-container">
          <div className="input-group">
            <input
              readOnly={disabled}
              autoComplete="off"
              className="cost-scheme-autosuggestion-input"
              type="text"
              id="cost-scheme-autosuggestion-input"
              value={input.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleChange(e.target.value)}
              onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                if (!disabled) {
                  this.handleChange(e.target.value);
                  this.setState({ active: true });
                }
              }}
              onBlur={() => window.setTimeout(() => this.setState({ active: false }), 100)}
              placeholder={__(placeholder)}
            />

            {!disabled && (
              <FontAwesomeIcon icon={faSearch} className="cost-scheme-autosuggestion-icon" />
            )}
          </div>

          {active && (resultCostSchemes.length || loading || (allowNewName && input.value)) && (
            <ul className="cost-scheme-suggestions">
              {loading && <Spinner overlay />}
              {resultCostSchemes.length > 0 && (
                <>
                  <li className="cost-scheme-suggestions-separator">
                    {__('application.your_schemes')}
                  </li>
                  {resultCostSchemes.map((r: GetCostSchemeRes) => (
                    <Suggestion item={r} onSelect={onOptionSelect} key={r.id} />
                  ))}
                </>
              )}
              {allowNewName && typeof onNewNameSelect === 'function' && input.value && (
                <>
                  <li className="cost-scheme-suggestions-separator">
                    {__('application.use_without_scheme')}
                  </li>
                  <NewNameSuggestion name={input.value} onNewNameSelect={onNewNameSelect} />
                </>
              )}
              {allowCreate && typeof onCreate === 'function' && input.value && (
                <>
                  <li className="cost-scheme-suggestions-separator">
                    {__('application.add_cost_scheme')}
                  </li>
                  <NewNameSuggestion name={input.value} onNewNameSelect={onCreate} />
                </>
              )}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default CostSchemeAutosuggestion;
