import { AddClientRequest, CheaterUser } from 'app-types';

interface GetSingleClient {
  type: 'GET_SINGLE_CLIENT_SUCCESS';
  payload: AddClientRequest;
}

interface LoadingSingleClient {
  type: 'LOADING_SINGLE_CLIENT';
  payload: boolean;
}

interface GetCheatersList {
  type: 'GET_CHEATERS_LIST_SUCCESS';
  payload: CheaterUser[];
}

interface LoadingCheatersList {
  type: 'LOADING_CHEATERS_LIST';
  payload: boolean;
}

export interface ClientState {
  singleClient: any; // TO DO - FIX IT
  loadingSingleClient: boolean;
  cheatersList: CheaterUser[];
  loadingCheatersList: boolean;
}

const initialState: ClientState = {
  singleClient: {
    id: '',
    address: '',
    city: '',
    name: '',
    taxNumber: '',
    paymentTerm: 0,
  },
  loadingSingleClient: true,
  cheatersList: [],
  loadingCheatersList: true,
};

type Action = GetSingleClient | LoadingSingleClient | GetCheatersList | LoadingCheatersList;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_SINGLE_CLIENT_SUCCESS': {
      return { ...state, singleClient: action.payload };
    }
    case 'LOADING_SINGLE_CLIENT': {
      return { ...state, loadingSingleClient: action.payload };
    }

    case 'GET_CHEATERS_LIST_SUCCESS': {
      return { ...state, cheatersList: action.payload };
    }
    case 'LOADING_CHEATERS_LIST': {
      return { ...state, loadingCheatersList: action.payload };
    }
    default:
      return state;
  }
};
