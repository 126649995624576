import React from 'react';
import './ListItemWrapper.scss';

interface Props {
    children: React.ReactNode;
}

const ListItemWrapper = ({ children }: Props) => {
    return <div className='list-item-wrapper'>
        {children}
    </div>
}

export { ListItemWrapper }