import { FieldType, TableSchema } from '../typings';

export const creditNotasForAdmin: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.date',
      field: 'creditNoteDate',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
      alias: 'p',
      oppositeSortDir: true,
    },
    {
      name: 'application.invoice_number',
      field: 'creditNoteNumber',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Text,
      alias: 'p',
    },
    {
      name: 'application.company_name',
      field: 'companyName',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Text,
      alias: 'o',
    },
    {
      name: 'application.email',
      field: 'email',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Email,
      alias: 'o',
    },
    {
      name: 'application.price',
      field: 'amount',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Price,
      alias: 'p',
    },
  ],
};
