import React from 'react';
import { Switch } from '../Switch';
import { __ } from '../../../services/translation';
import './LabeledSwitch.scss';

interface Props {
    checked: boolean;
    label: string;
    onChange: () => void;
}

const LabeledSwitch = ({ checked, label, onChange }: Props) => {
    return <div className="switch-container">
        <span className="switch-label"> {__(label)}</span>
        <Switch onChange={onChange} checked={checked} />
    </div>
}

export { LabeledSwitch }