import React, { FC } from 'react';
import { TimeSheetCalendarItem } from '../../../types/timeSheetCalendar';

import './calendar.scss';
import SingleHour from './SingleHour';

interface Props {
  data: TimeSheetCalendarItem[];
  removeItem: (index: number) => void;
}

const hours = Array.from({ length: 24 }, (_, i) => i);

const TimeCalendar: FC<Props> = ({ data, removeItem }) => {
  const getTimeSheetsForDay = (hour: number) =>
    data.filter((item) => {
      const splitStart = item.start.split(':');
      const splitEnd = item.end.split(':');

      if (
        [Number(splitStart[0]), Number(splitEnd[0])].includes(hour) ||
        (Number(splitEnd[0]) > hour && Number(splitStart[0]) < hour)
      ) {
        return true;
      }

      return false;
    });

  const checkIfShouldRender = (hour: number) => {
    const tiles = getTimeSheetsForDay(hour);

    const laterTiles = data.filter((item) => {
      const splitStart = item.start.split(':');

      const timeCurrent = hour * 60;
      const timeStart = Number(splitStart[0]) * 60 + Number(splitStart[1]);

      return timeStart >= timeCurrent;
    });

    const earlierTiles = data.filter((item) => {
      const splitStart = item.start.split(':');

      const timeCurrent = hour * 60;
      const timeStart = Number(splitStart[0]) * 60 + Number(splitStart[1]);

      return timeStart <= timeCurrent;
    });

    if (tiles.length > 0 || (earlierTiles.length > 0 && laterTiles.length > 0)) {
      return true;
    }

    return false;
  };

  return (
    <div className="time-calendar">
      {hours.map((hour) => (
        <SingleHour
          hour={hour}
          key={hour}
          data={getTimeSheetsForDay(hour)}
          removeItem={removeItem}
          shouldRender={checkIfShouldRender(hour)}
        />
      ))}
    </div>
  );
};

export default TimeCalendar;
