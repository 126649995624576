import { Dispatch } from 'redux';
import ApiService from '../services/api-service';
import { notifications } from '../actions';

export const getContactList = () => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_CONTACT_LIST_SUCCESS',
    'GET_CONTACT_LIST_FAILED',
    null,
    null,
    'contact-list/',
    'LOADING_CONTACT_LIST',
  );
};

export const deleteContact = (id: string) => async (dispatch: Dispatch) => {
  await ApiService.delete(
    dispatch,
    'DELETE_CONTACT_SUCCESS',
    'DELETE_CONTACT_FAILED',
    () => {
      dispatch(notifications.successNotification('application.contact_was_deleted.'));
      dispatch({ type: 'REFRESH_API_TABLE' });
    },
    null,
    `contact-list/${id}`,
    'LOADING_CONTACT_LIST',
  );
};

export const editContact = (contact: string) => async (dispatch: Dispatch) => {
  await ApiService.patch(
    dispatch,
    'EDIT_CONTACT_SUCCESS',
    'EDIT_CONTACT_FAILED',
    () => {
      dispatch(notifications.successNotification('application.contact_was_edited.'));
      dispatch({ type: 'REFRESH_API_TABLE' });
    },
    null,
    `contact-list/contact/${contact}`,
    'LOADING',
    contact,
  );
};
