import React, { ChangeEvent } from 'react';
import { __ } from '../../../services/translation';
import RequiredIndicator from '../RequiredIndicator';
import './TimePickerInput.scss';

interface Props {
  input: any;
  meta: any;
  label: string;
  required?: boolean;
}

const TimePickerInput = (props: Props) => {
  const { label, input, meta, required } = props;

  const inputLabel = label && (
    <label htmlFor={input.name}>
      {__(label)} {required && <RequiredIndicator />}
    </label>
  );
  return (
    <>
      <div className="time-picker input-container">
        <div className="input-wrapper">
          {inputLabel}
          <input
            required={required}
            type="time"
            {...input}
            onChange={(e: ChangeEvent<HTMLInputElement>) => input.onChange(e.target.value)}
            placeholder="00:00"
            value={input.value}
          />
        </div>
        {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
      </div>
    </>
  );
};

export default TimePickerInput;
