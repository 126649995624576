import React from 'react';
import Flatpickr from 'react-flatpickr';
import addDays from 'date-fns/addDays';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { english } from 'flatpickr/dist/l10n/default';
import { useSelector } from 'react-redux';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { Language } from 'app-types';
import { startOfDay } from 'date-fns';
import { __ } from '../../../services/translation';
import RequiredIndicator from '../RequiredIndicator';
import { formatDate } from '../../../utils/format-date';
import { ApplicationState } from '../../../reducers';
import './DateInput.scss';

interface Props {
  input: any;
  multiple?: boolean;
  error?: any;
  meta?: any;
  label?: string;
  max?: boolean;
  value?: any;
  nomargin?: boolean;
  required?: boolean;
  onChange?: (value: string) => void;
  minToday?: boolean;
  minYear?: number;
  minDate?: Date;
  noWeekends?: boolean;
  description?: string;
}

const DateInput = (props: Props) => {
  const {
    nomargin,
    input,
    meta,
    multiple,
    label,
    max,
    required,
    minToday,
    minYear,
    minDate,
    noWeekends,
    description,
  } = props;
  const dateObj = new Date();
  const user = useSelector((state: ApplicationState) => state.user.details);
  const currentLang = useSelector((state: ApplicationState) => state.lang.current);
  const mininumYear = minYear || dateObj.getFullYear() - user!.yearsPeriod;
  // setting default min date as whole previous year
  const minDateValue = minDate
    ? startOfDay(minDate)
    : minToday
    ? formatDate(addDays(new Date(), 1))
    : `01-01-${mininumYear}`;

  const localeByLang = {
    [Language.en]: english,
    [Language.nl]: Dutch,
    [Language.pl]: Polish,
  };

  return (
    <div
      className={`input-container ${max ? 'input-max' : ''} ${
        nomargin ? 'nomargin' : ''
      } input-with-icon`}
    >
      <div className="input-wrapper">
        {label && (
          <label htmlFor={input.name}>
            {__(label)} {required && <RequiredIndicator />}
          </label>
        )}
        <Flatpickr
          key={new Date().toString()}
          required={required}
          placeholder={formatDate(new Date())}
          onClose={(dates) => {
            if (multiple) {
              input.onChange(dates);
            } else input.onChange(dates[0]);
          }}
          value={input.value}
          options={{
            locale: { ...localeByLang[currentLang as Language], firstDayOfWeek: 1 },
            defaultDate: input.value,
            dateFormat: 'd-m-Y',
            disable: noWeekends ? [(date) => date.getDay() === 0 || date.getDay() === 6] : [],
            minDate: minDateValue,
            mode: multiple ? 'multiple' : 'single',
          }}
        />
        <img src="/assets/icons/calendar.svg" className="date-input-icon" />
      </div>
      {meta.error && <small className="text-danger">{meta.error}</small>}
      {description && <small className="text-help">{__(description)}</small>}
    </div>
  );
};

export default DateInput;
