import React, { FC } from 'react';
import { TimeSheetCalendarItem } from '../../../../types/timeSheetCalendar';

import './hour.scss';
import Tile from './Tile';

interface Props {
  hour: number;
  data: TimeSheetCalendarItem[];
  removeItem: (index: number) => void;
  shouldRender: boolean;
}

const minutesForHour = [0, 15, 30, 45];

const SingleHour: FC<Props> = ({ hour, data, removeItem, shouldRender }) => {
  const getTimeSheetForMinutes = (minutes: number) =>
    data
      .filter((item) => {
        const splitStart = item.start.split(':');
        const splitEnd = item.end.split(':');

        const timeCurrent = hour * 60 + minutes;
        const timeStart = Number(splitStart[0]) * 60 + Number(splitStart[1]);
        const timeEnd = Number(splitEnd[0]) * 60 + Number(splitEnd[1]);

        if (timeStart <= timeCurrent && timeEnd > timeCurrent + 15) {
          return true;
        }

        if (timeStart >= timeCurrent && timeCurrent > timeStart - 15) {
          return true;
        }

        if (timeEnd >= timeCurrent && timeCurrent >= timeEnd - 15) {
          return true;
        }

        return false;
      })

      .sort((a, b) => {
        const splitStartA = a.start.split(':');
        const splitStartB = b.start.split(':');

        const timeStartA = Number(splitStartA[0]) * 60 + Number(splitStartA[1]);
        const timeStartB = Number(splitStartB[0]) * 60 + Number(splitStartB[1]);

        if (timeStartA > timeStartB) return 1;
        if (timeStartA < timeStartB) return -1;

        return 0;
      });

  if (!shouldRender) {
    return null;
  }

  return (
    <div className={`time-calenda-hour ${hour % 2 === 0 ? 'second' : ''}`}>
      <p className="time-calenda-hour__hour-indicator">
        {hour < 10 ? `0${hour}:00` : `${hour}:00`}
      </p>
      <div className="time-calenda-minutes-container">
        {minutesForHour.map((minute) => (
          <div className="time-calenda-minutes-container__single-minute">
            {data &&
              getTimeSheetForMinutes(minute).map((item) => (
                <Tile
                  data={item}
                  removeItem={removeItem}
                  key={item.title}
                  minute={minute}
                  hour={hour}
                />
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleHour;
