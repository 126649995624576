import React from 'react';
import './FormContainer.scss';
import clsx from 'clsx';

interface Props {
  children: React.ReactNode;
  lightLabels?: boolean;
  stretch?: boolean;
}

const FormContainer = ({ children, lightLabels, stretch }: Props) => (
  <div className={clsx('form-container', { 'light-labels': lightLabels, stretch })}>{children}</div>
);

export default FormContainer;
