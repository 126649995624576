import React from 'react';
import { Pagination } from '..';

interface Props {
  scheme: any;
  data: any;
  component: React.ComponentClass<any> | React.FunctionComponent<any>;
  initialPage?: number;
  onPageChange?: (page: number) => void;
  additionalComponentProps?: any;
}

const List = ({
  data,
  initialPage,
  onPageChange,
  component,
  scheme,
  additionalComponentProps,
}: Props) => (
  <>
    <div className="list">
      {data.items.map((item: any, index: number) =>
        React.createElement(component, {
          item,
          key: `list-key-${index.toString()}`,
          ...additionalComponentProps,
        }),
      )}
    </div>
    <Pagination
      initialPage={initialPage || 1}
      pageCount={Math.ceil(data.countTotal / scheme.countPerPage)}
      onPageChange={(page: number) => onPageChange && onPageChange(page)}
    />
  </>
);

export default List;
