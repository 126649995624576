import { AccountantConnectorDetails } from 'app-types';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { __ } from '../../../services/translation';
import { InvitationsList, Subheader } from '../../../components/Common';
import ApiService from '../../../services/api-service';
import useHeader from '../../../hooks/useHeader';
import Dashboard from '../../../views/DashboardCommon';
import { Page } from '../../../components/Layout';

export const ManagedClients: FC = () => {
  useHeader('application.managed_users', '/', {
    title: 'application.managed_users',
    text: 'annotations.managed_users_description',
  });
  const [managedUsers, setManagedUsers] = useState<AccountantConnectorDetails[]>();
  const history = useHistory();

  const getMine = async () => {
    await ApiService.callFetch(
      'GET',
      'accountant-connector/my-managed-accepted',
      (users: AccountantConnectorDetails[]) => {
        setManagedUsers(users);
      },
    );
  };

  const managedClientTabs = [
    {
      title: 'application.back',
      onClick: () => {
        history.goBack();
      },
      icon: 'back',
    },
    {
      title: 'application.managed_users_list',
      link: '/dashboard/managed-clients',
      icon: 'list',
    },
  ];

  useEffect(() => {
    getMine();
  }, []);

  return (
    <Page light withNavigation>
      <Dashboard navigation={managedClientTabs}>
        <Subheader text="application.managed_users_list" />
        <Subheader
          text={__('application.total_count', { total: managedUsers ? managedUsers.length : 0 })}
        />
        <InvitationsList
          getMine={getMine}
          managedUsers={managedUsers}
          customNoDataMessage="application.no_clients"
        />
      </Dashboard>
    </Page>
  );
};
