import React, { useMemo } from 'react';
import { OfferHistoryActions } from 'app-types';
import { faFileInvoice, faEdit, faHourglassEnd, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon, ListItem } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import { formatDate, formatDateTime } from '../../../../../utils/format-date';
import { formatPrice } from '../../../../../utils/format-price';
import './OfferListItem.scss';
import { DownloadService } from '../../../../../services/download-service';
import { OfferListRes, OfferStatus } from '../../../../../../../heyzzp-back/types';
import { showModal } from '../../../../../actions/modal';
import OfferHistory from '../../../../../modals/OfferHistory';

interface Props {
  item: OfferListRes;
  id?: string;
  date: string;
}

const OfferListItem = ({ item }: Props) => {
  const dispatch = useDispatch();
  const getIcon = (action: OfferHistoryActions) => {
    switch (action) {
      case OfferHistoryActions.CREATE:
        return 'plus';
      case OfferHistoryActions.EDIT:
        return 'pencil';
      case OfferHistoryActions.ACCEPT:
        return faCheck;
      case OfferHistoryActions.REVOKE_ACCEPT:
        return 'back';
      default:
        return '';
    }
  };

  const paragraphs = useMemo(
    () => [
      { icon: 'time_sheets', text: formatDate(item.createdAt) },
      { icon: faHourglassEnd, text: formatDate(item.validTo) },
      { icon: 'pencil', text: item.mark },
      { icon: 'costs', text: formatPrice(item.totalVal + item.totalTaxVal) },
      {
        icon: getIcon(item.lastAction) as string,
        text: `${formatDateTime(item.lastActionDate)} - ${__(
          `application.offerHistoryActions.${item.lastAction}`,
        )}`,
        hide: !item.lastActionDate,
        success: true,
      },
    ],
    [],
  );

  const showHistoryModal = () => {
    dispatch(showModal(<OfferHistory id={item.id} />));
  };

  const downloadPDF = async () => {
    await DownloadService.download(`offer/pdf/${item.id}`);
  };

  return (
    <div className="offer-list-item-uberwrapper">
      <ListItem
        header={item.client}
        icon={faFileInvoice}
        paragraphs={paragraphs}
        buttons={[
          {
            text: 'application.history',
            click: showHistoryModal,
          },
          {
            text: 'application.offer_to_invoice',
            to: `/dashboard/invoices/offer/${item.id}`,
          },
          {
            text: 'application.details',
            to: `/dashboard/offers/${item.id}`,
          },
          {
            text: 'application.download_pdf',
            click: downloadPDF,
            type: 'secondary',
          },
          {
            text: 'application.edit',
            to: `/dashboard/offers/edit/${item.id}`,
            type: 'secondary',
          },
          {
            text: 'application.send_to_client',
            to: `/dashboard/offers/send/${item.id}`,
            type: 'success',
          },
        ]}
        noShadow
      />
    </div>
  );
};

export default OfferListItem;
