import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { __ } from '../../../services/translation';
import './Subheader.scss';
import { Icon } from '..';

interface Props {
  text?: string | number;
  rawText?: string | number;
  icon?: IconProp | string;
  level?: number;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  noMarginBottom?: boolean;
  halfMarginBottom?: boolean;
  highlight?: boolean;
  children?: React.ReactNode;
}

const Subheader = ({
  text,
  rawText,
  icon,
  noMarginBottom,
  paddingTop,
  paddingBottom,
  halfMarginBottom,
  level,
  highlight,
  children,
}: Props) => {
  const subheaderClass = `subheader ${paddingTop ? 'padding-top' : ''} ${`level-${level}`} ${
    paddingBottom ? 'padding-bottom' : ''
  } ${noMarginBottom ? 'no-margin-bottom' : ''} ${halfMarginBottom ? 'half-margin-bottom' : ''} ${
    highlight ? 'highlight' : ''
  } `;

  const content = (
    <div className="subheader-title">
      {icon && typeof icon === 'string' && <Icon className="subheader-icon" icon={icon} />}
      {icon && typeof icon === 'object' && (
        <FontAwesomeIcon className="subheader-icon" icon={icon} />
      )}
      {rawText}
      {__(text)}
    </div>
  );

  if (level === 2) {
    return (
      <h3 className={subheaderClass}>
        {content} {children}
      </h3>
    );
  }
  if (level === 3) {
    return (
      <h4 className={subheaderClass}>
        {content} {children}
      </h4>
    );
  }
  return (
    <div className={subheaderClass}>
      <h2>{content}</h2>
      {children}
    </div>
  );
};

export default Subheader;
