import { SingleTab } from './tabs';

export enum CustomSearchType {
  MODIFY_URL_ADDRESS,
  ADD_SEARCH_PARAMETER,
}

export interface CustomSearchTab {
  default: string | number | null;
  field: string;
  options: SingleTab[];
  type: CustomSearchType;
}

export interface CustomButton {
  translateKey: string;
  translateParams?: Record<string, string | number | boolean>;
  onClick: () => void;
  isHidden?: boolean;
}
