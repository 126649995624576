import React, { Component } from 'react';
import {
  UserRole,
  ClientUserEntity,
  SomeUserEntity,
  AccountantUserEntity,
  AuthLoginUserResult,
} from 'app-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Spinner } from '../../../components/Common';
import { ApplicationState } from '../../../reducers';

interface Props {
  user: SomeUserEntity | null;
  exact?: boolean;
  path?: string;
  component: any;
  isActiveSub?: boolean;
  loading: boolean;
  managedUser: AuthLoginUserResult | null;
  accountantNeedsUser?: boolean;
  incomeAndCostAccount?: boolean;
}

class RouteSubscribed extends Component<Props> {
  render() {
    const {
      user,
      exact,
      path,
      component,
      loading,
      managedUser,
      accountantNeedsUser,
      incomeAndCostAccount,
    } = this.props;
    const clientOrAccountant = user as ClientUserEntity | AccountantUserEntity;
    let content;
    if (
      !user ||
      !(incomeAndCostAccount
        ? [UserRole.Client, UserRole.Accountant, UserRole.IncomeAndCost]
        : [UserRole.Client, UserRole.Accountant]
      ).includes(user.role) ||
      (user.role === UserRole.Accountant && accountantNeedsUser && !managedUser)
    ) {
      content = <Route render={() => <Redirect to="/" />} />;
    } else if (clientOrAccountant.isBlocked === true) {
      content = <Route render={() => <Redirect to="/blocked" />} />;
    } else if (clientOrAccountant.isActiveSub === false) {
      content = <Route render={() => <Redirect to="/no-subscription" />} />;
    }
    // else if (clientOrAccountant.isActived === false) {
    //   content = <Route render={() => <Redirect to="/inactive" />} />;
    // }
    else {
      content = <Route exact={exact} path={path} component={component} />;
    }
    return <>{loading ? <Spinner overlay /> : content}</>;
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
  loading: state.user.loading,
  managedUser: state.accounting.managedUser,
});

export default connect(mapStateToProps)(RouteSubscribed);
