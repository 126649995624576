import React from 'react';
import { __ } from '../../../services/translation';
import './NarrowParagraph.scss';

interface Props {
  text: string;
}

const NarrowParagraph = ({ text }: Props) => <p className="narrow-paragraph">{__(text)}</p>;

export default NarrowParagraph;
