import { ClientUserEntity, NewCertificateReq } from 'app-types';
import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
} from 'itf_formbuilder_react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkIfMissingData } from '../../../../utils/user';
import { Button, NarrowParagraph, RequiredIndicator, Spinner } from '../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import { ApplicationState } from '../../../../reducers';
import ApiService from '../../../../services/api-service';
import { formOnTranslateString } from '../../../../utils/trans-form';
import UittrekselFieldsMapper from './UittrekselFieldsMapper';
import { modal } from '../../../../actions';
import { EditUserData } from '../../../../modals';

export interface UittrekselData {
  companyName: string;
  kvk: string;
  address: string;
}

const Uittreksel = () => {
  const dispatch = useDispatch();

  useHeader('addons.uittreksel_download', '/dashboard/client/addons');
  const user = useSelector((state: ApplicationState) => state.user.details);

  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  const setData = (data: UittrekselData) => {
    itfForm.api.setValues({
      companyName: data.companyName,
      kvk: data.kvk,
      address: data.address,
    });
  };

  const selectUserData = () => {
    const clientUser = user as ClientUserEntity;
    const first = clientUser.addressStreet;
    const second = [clientUser.addressPostCode, clientUser.addressCity]
      .filter((el) => el)
      .join(' ');
    const userAddress = [first, second].filter((el) => el).join(', ');
    const data: UittrekselData = {
      companyName: clientUser.companyName || '',
      kvk: clientUser.kvk,
      address: userAddress,
    };
    setData(data);
  };

  const handleFormSend = () => {
    if (checkIfMissingData(user as ClientUserEntity)) {
      dispatch(modal.showModal(<EditUserData />));
    } else {
      itfForm.api.send();
    }
  };

  return (
    <>
      <NarrowParagraph text="addons.uittreksel_description_1" />
      <NarrowParagraph text="addons.uittreksel_description_2" />
      <NarrowParagraph text="addons.uittreksel_description_3" />
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="client-form"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm('addons/form/new-uittreksel')}
          sendFetcher={(formId: string, values: NewCertificateReq) =>
            ApiService.sendForm('addons/prepare-uittreksel', values, 'POST')
          }
          onSavedSuccessfully={(data: string) => {
            window.location.replace(data);
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="button"
                className="form-submit-button"
                click={handleFormSend}
                text="application.send"
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={(string: string) => formOnTranslateString(string)}
          onRenderFullField={(
            field: FormFieldToRender,
            errors: any,
            fieldHtmlId: string,
            currentValue: any,
            onChange: ChangeFunction,
            onFocus: FieldAndEventFunction,
            onBlur: FieldAndEventFunction,
          ) => (
            <UittrekselFieldsMapper
              selectUserData={selectUserData}
              setData={setData}
              data={{
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              }}
            />
          )}
          registerAsGlobalForm
        />
      </FormContainer>
    </>
  );
};

export default Uittreksel;
