import React from 'react';
import { useDispatch } from 'react-redux';
import { TimeSheetTemplateDetails } from 'app-types';
import { ListItem } from '../../../../../components/Common';
import { modal, timeSheetSchemes } from '../../../../../actions';
import { EditTimeSheetScheme, Confirmation } from '../../../../../modals';

interface Props {
  item: TimeSheetTemplateDetails;
}

const TimeSheetSchemeListItem = (props: Props) => {
  const dispatch = useDispatch();
  const { item } = props;

  const deleteScheme = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.confirm_time_sheet_scheme_delete"
          confirmCallback={() => dispatch(timeSheetSchemes.deleteTimeSheetScheme(item.id))}
          cancelCallback={() => dispatch(modal.hideModal())}
        />,
      ),
    );
  };

  return (
    <ListItem
      singleRow
      header={item.periods.map((period) => period.name).join(', ')}
      icon="time_sheets"
      buttons={[
        {
          text: 'application.details',
          to: `/dashboard/time-sheets-schemes/${item.id}`,
        },
        {
          text: 'application.edit',
          type: 'secondary',
          click: () => dispatch(modal.showModal(<EditTimeSheetScheme id={item.id} />)),
        },
        {
          text: 'application.use',
          type: 'success',
          to: `/dashboard/time-sheets/add/use-scheme/${item.id}`,
        },
        {
          text: 'application.delete',
          type: 'danger',
          click: deleteScheme,
        },
      ]}
    />
  );
};

export default TimeSheetSchemeListItem;
