import { AccountType } from 'app-types';
import React from 'react';
import { Card } from '../../../../components/Common';
import { __ } from '../../../../services/translation';
import './BasicFeatures.scss';
import SingleFeature from './SingleFeature';

const features = [
  {
    header: 'basic_1',
    count: 21,
    additionalInfo: [2, 4, 5, 6, 7, 8, 9, 10, 11, 16],
  },
  {
    header: 'basic_2',
    count: 4,
    additionalInfo: [1],
  },
  {
    header: 'basic_3',
    count: 16,
    additionalInfo: [1, 2, 5, 6, 7, 10, 11, 12],
  },
  {
    header: 'basic_4',
    count: 8,
    additionalInfo: [1, 2, 5],
  },
  {
    header: 'basic_5',
    count: 2,
    children: [
      {
        header: 'basic_5_1',
        count: 5,
      },
      {
        header: 'basic_5_2',
        count: 5,
      },
    ],
  },
  {
    header: 'basic_6',
    count: 5,
  },
  {
    header: 'basic_7',
    count: 14,
    additionalInfo: [13],
  },
  {
    header: 'basic_8',
    count: 0,
  },
];

const BasicFeatures = () => (
  <div>
    <ul className="features-list">
      {features.map((feature) => (
        <SingleFeature key={feature.header} feature={feature} />
      ))}
    </ul>
  </div>
);

export default BasicFeatures;
