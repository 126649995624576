import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaymentType, AccountType, AppSettingsEntity } from 'app-types';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { payment } from '../../../../actions';
import { Price, Tabs } from '../../../../components/Common';
import { formatSmallPrice } from '../../../../utils/format-price';
import { __ } from '../../../../services/translation';
import PricingContainer from '../PricingContainer';
import '../BuySubscription.scss';
import { SubscriptionType } from '../../../../types/subscription';
import { SingleTab } from '../../../../types/tabs';
import { ApplicationState } from '../../../../reducers';
import BasicFeatures from '../BasicFeatures';

interface State {
  type: SubscriptionType;
}

interface Props {
  newPayment: (paymentType: PaymentType, accountType: AccountType) => void;
  current: string;
  settings: AppSettingsEntity;
}

class Unlimited extends React.Component<Props, State> {
  private pricingContainerData = {
    monthly: {
      priceBig: this.props.settings.professionalMonthlyPrice.toFixed(2).split('.')[0],
      priceSmall: formatSmallPrice(this.props.settings.professionalMonthlyPrice.toFixed(2).toString().split('.')[1]),
      header: 'application.monthly_subscription',
      buttonText: 'application.get_monthly_subscription',
    },
    annual: {
      priceBig: this.props.settings.professionalYearlyPrice.toFixed(2).split('.')[0],
      priceSmall: formatSmallPrice(this.props.settings.professionalYearlyPrice.toFixed(2).split('.')[1]),
      header: 'application.monthly_subscription',
      buttonText: 'application.get_monthly_subscription',
    },
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      type: SubscriptionType.MONTHLY,
    };
  }

  private setType = (type: SubscriptionType) => {
    this.setState({
      type,
    });
  };

  render() {
    const tabs: SingleTab[] = [
      { label: 'application.monthly', value: SubscriptionType.MONTHLY },
      { label: 'application.annual', value: SubscriptionType.ANNUAL },
    ];
    const { type } = this.state;
    const { monthly, annual } = this.pricingContainerData;
    const { current } = this.props;
    return (
      <div className="buy-sub-container">
        <h2 className="buy-sub-type">{__('application.unlimited')}</h2>
        <Tabs tabs={tabs} activeTab={type} chooseTab={this.setType} />

        <div className="price-cont">
          <p>{__('application.current_price')}:</p>

          <div className="buy-sub-price">
            {/* isAnnual ? monthly.priceBig : annual.priceBig */}
            <Price
              big={type === SubscriptionType.ANNUAL ? annual.priceBig : monthly.priceBig}
              small={type === SubscriptionType.ANNUAL ? annual.priceSmall : monthly.priceSmall}
              period={
                type === SubscriptionType.ANNUAL ? __('application.year') : __('application.month')
              }
            />
          </div>

          {/* <p>{isAnnual ? __('7% discount') : null}</p> */}
          <p>
            {type === SubscriptionType.ANNUAL
              ? __('application.fee_will_be_charged_every_year')
              : __('application.fee_will_be_charged_every_month')}
          </p>
        </div>
        <div className="privilege-cont">
          <div>
            <p>
              <FontAwesomeIcon icon={faCheck} className="buy-sub-icon green" />{' '}
              {__('application.unlimited_cost_upload')}{' '}
            </p>
          </div>
        </div>

        {current === 'pl' && <BasicFeatures />}

        <PricingContainer
          paymentType={{
            paymentType:
              type === SubscriptionType.ANNUAL ? PaymentType.yearly : PaymentType.monthly,
            accountType: AccountType.professional,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  current: state.lang.current,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ newPayment: payment.newPayment }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Unlimited);
