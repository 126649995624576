import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { statistics } from '../../../../../actions';
import { __ } from '../../../../../services/translation';
import { Spinner, InfoParagraph, Subheader } from '../../../../../components/Common';
import { formatPrice } from '../../../../../utils/format-price';
import { ApplicationState } from '../../../../../reducers';
import { StatisticPeriod, StatisticsSummaryItem } from '../../../../../types/statistics';
import Chart from '../Chart';
import Summary from '../Summary';

interface Props {
  getWeeklySalaryForCharts: () => void;
  getWeeklyIncome: (year?: number) => void;
  getHourlyIncome: (year?: number) => void;
  getIncomeWithTravel: (year?: number) => void;
  data: any;
  travelIncome: number;
  hourlyIncome: number;
  weeklyIncome: number;
  loadingIncome: boolean;
  period: StatisticPeriod;
}

class Income extends Component<Props> {
  componentDidMount() {
    const {
      getWeeklyIncome,
      getHourlyIncome,
      getIncomeWithTravel,
      getWeeklySalaryForCharts,
    } = this.props;

    const year = this.getCurrentYear();
    getWeeklySalaryForCharts();
    getWeeklyIncome(year);
    getHourlyIncome(year);
    getIncomeWithTravel(year);
  }

  componentDidUpdate(prevProps: Props) {
    const { getWeeklyIncome, getHourlyIncome, getIncomeWithTravel } = this.props;

    const { period } = this.props;
    if (period !== prevProps.period) {
      if (period === StatisticPeriod.THIS_YEAR) {
        const year = this.getCurrentYear();
        getWeeklyIncome(year);
        getHourlyIncome(year);
        getIncomeWithTravel(year);
      } else {
        getWeeklyIncome();
        getHourlyIncome();
        getIncomeWithTravel();
      }
    }
  }

  private getCurrentYear = () => new Date().getFullYear();

  private getPeriodLabel = (period: StatisticPeriod) =>
    period === StatisticPeriod.ALL ? __('application.all') : this.getCurrentYear();

  render() {
    const { period, loadingIncome, hourlyIncome, weeklyIncome, travelIncome, data } = this.props;

    const summary: StatisticsSummaryItem[] = [
      {
        label: 'application.per_hour',
        amount: formatPrice(hourlyIncome),
      },
      {
        label: 'application.per_week',
        amount: formatPrice(weeklyIncome),
      },
      {
        label: 'application.per_week_(travel_time_included)',
        amount: formatPrice(travelIncome),
      },
    ];

    if (loadingIncome) return <Spinner />;
    return (
      <>
        <Subheader text={`${__('application.average_income')} - ${this.getPeriodLabel(period)}`} />

        <Summary data={summary} />

        <Subheader level={2} text="application.income_from_last_6_weeks" />

        <Chart data={data} />
        <InfoParagraph name="application.week_of_the_year" text="&euro;" />
        {data.map((week: any) => (
          <InfoParagraph name={week.week} text={formatPrice(week.value)} />
        ))}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  data: state.statistics.salaryCharts,
  weeklyIncome: state.statistics.weeklyIncome,
  hourlyIncome: state.statistics.hourlyIncome,
  travelIncome: state.statistics.travelIncome,
  loadingIncome: state.statistics.loadingIncome,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getWeeklySalaryForCharts: statistics.getWeeklySalaryForCharts,
      getWeeklyIncome: statistics.getWeeklyIncome,
      getHourlyIncome: statistics.getHourlyIncome,
      getIncomeWithTravel: statistics.getIncomeWithTravel,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Income);
