import { AccountantConnectorDetails } from 'app-types';
import React, { useEffect, useState } from 'react';
import ApiService from '../../../../services/api-service';
import useHeader from '../../../../hooks/useHeader';
import InvitationsList from '../../../../components/Common/InvitationsList';

const PendingInvitationsList = () => {
  useHeader('application.invitations_header', '/dashboard/client/addons');
  const [managedUsers, setManagedUsers] = useState<AccountantConnectorDetails[]>();

  const getMine = async () => {
    await ApiService.callFetch(
      'GET',
      'accountant-connector/my-managed-no-accepted',
      (users: AccountantConnectorDetails[]) => {
        setManagedUsers(users);
      },
    );
  };

  useEffect(() => {
    getMine();
  }, []);

  return <InvitationsList getMine={getMine} managedUsers={managedUsers} />;
};

export default PendingInvitationsList;
