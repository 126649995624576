import React from 'react';
import './ListItemBottomContainer.scss';

interface Props {
    children: React.ReactNode;
}

const ListItemBottomContainer = ({ children }: Props) => {
    return <div className='list-item-bottom-container'>
        { children }
    </div>
}

export { ListItemBottomContainer }