import React from 'react';
import { CostPaymentType, CostRes, TaxRate, TaxType } from 'app-types';

import { OptionalImage, InfoParagraph } from '..';
import Button from '../Button';
import { ButtonsContainer } from '../../Layout';
import { __ } from '../../../services/translation';
import './SingleCostPreview.scss';

interface Props {
  cost: CostRes;
  displayLinkToCost?: boolean;
}

const SingleCostPreview = ({ cost, displayLinkToCost }: Props) => {
  const getCostPaymentTypeLabel = () => {
    switch (cost.paymentType) {
      case CostPaymentType.CASH:
        return __('application.cash');
      case CostPaymentType.INSTALLMENT:
        return __('application.installment');
      case CostPaymentType.PRIVATE_ACCOUNT:
        return __('application.private_account');
      case CostPaymentType.OTHER:
        return cost.otherType;
      default:
        return '-';
    }
  };

  const getCostTaxRate = () => {
    switch (cost.taxRate) {
      case TaxRate['EU/Abroad EU']:
        return __('application.eu_abroad_eu');
      case TaxRate['BTW Gemengd']:
        return __('application.variable_tax_values');
      default:
        return Object.values(TaxRate)[cost.taxRate];
    }
  };

  const getCostTaxType = () => {
    switch (cost.taxType) {
      case TaxType.DEDUCTED:
        return __('application.vat_deducted');
      case TaxType.NOT_DEDUCTED:
        return __('application.vat_not_deducted');

      default:
        return '-';
    }
  };

  return (
    <div className="single-cost-preview">
      <InfoParagraph name="application.name" text={cost.name} />
      <InfoParagraph name="application.cost_value" price={cost.value} />
      {cost.value !== 0 && (
        <InfoParagraph name="application.cost_tax_rate" text={getCostTaxRate()} />
      )}
      {!!cost.customTax && <InfoParagraph name="application.custom_tax" price={cost.customTax} />}
      {cost.taxRate === TaxRate['EU/Abroad EU'] && (
        <InfoParagraph name="application.custom_tax" text={getCostTaxType()} />
      )}
      <InfoParagraph name="application.date" date={cost.date} />
      {cost.category && <InfoParagraph name="application.category" text={cost.category.title} />}
      {typeof cost.paymentType !== 'undefined' && cost.paymentType !== null && (
        <InfoParagraph name="application.cost_mark" text={getCostPaymentTypeLabel()} />
      )}
      {displayLinkToCost && (
        <ButtonsContainer marginTop>
          <Button small to={`/dashboard/costs/${cost.id}`} text="application.see_cost" />
        </ButtonsContainer>
      )}
    </div>
  );
};

export default SingleCostPreview;
