import React from 'react';
import ReactSwitch from 'react-switch';

import './Switch.scss';

interface Props {
    checked: boolean;
    onChange: () => void;
}

const Switch = ({ checked, onChange }: Props) => {
    return <ReactSwitch
        onChange={onChange}
        checked={checked}
        offColor="#cccccc"
        onColor="#faba1a"
        uncheckedIcon={false}
        checkedIcon={false}
        height={25}
        className="switch"
    />
}

export { Switch }