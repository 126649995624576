import React from 'react';
import { TableSchema, serviceSchemaTable } from 'app-tables';
import { ServiceParamsEntity, ServiceSchemaEntity } from 'app-types';
import { InteractiveTableSchema } from '../../utils/table-shcema-utils';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import { ApiList } from '../../components/Common';
import ServiceForInvoiceItem from './ServiceForInvoiceItem';

interface Props {
  callback: (item: ServiceParamsEntity) => void;
}

interface ApiListItemProps {
  item: ServiceSchemaEntity;
}

class ServicesForInvoice extends React.Component<Props> {
  private scheme: TableSchema;

  constructor(props: Props) {
    super(props);
    this.scheme = new InteractiveTableSchema(serviceSchemaTable);
  }

  render() {
    const { callback } = this.props;
    return (
      <ModalContent>
        <ModalHeader>
          <ModalTitle title="application.add_service" />
        </ModalHeader>
        <ApiList
          inModal
          scheme={this.scheme}
          apiEndpointSubUrl="service-schema/list"
          component={({ item }: ApiListItemProps) => (
            <ServiceForInvoiceItem item={item} callback={callback} />
          )}
        />
      </ModalContent>
    );
  }
}

export default ServicesForInvoice;
