import React from 'react';
import { TableSchema, ordersForUserTable } from 'app-tables';
import { useDispatch } from 'react-redux';
import { ApiList, Button } from '../../../../components/Common';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import OrderListItem from './Item';
import useHeader from '../../../../hooks/useHeader';
import { ButtonsContainer } from '../../../../components/Layout';
import ApiService from '../../../../services/api-service';

import { notifications } from '../../../../actions';

const OrderList = () => {
  useHeader('mileage_generator.list');
  const dispatch = useDispatch();
  const scheme: TableSchema = new InteractiveTableSchema(ordersForUserTable);

  // Only for test mode
  // const force = async () => {
  //   await ApiService.callFetch('POST', `user/force-schedule/${new Date(2020, 12, 24).getTime()}`, () => {
  //     dispatch(notifications.successNotification('Ok'));
  //   });
  // };

  return (
    <>
      {/* <ButtonsContainer>
        <Button click={force} text="Wymuś" />
      </ButtonsContainer> */}
      <ApiList apiEndpointSubUrl="order/list" scheme={scheme} component={OrderListItem} />
    </>
  );
};

export default OrderList;
