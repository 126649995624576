import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    ChangeFunction,
    FieldAndEventFunction,
    FormFieldToRender,
    ItfApiForm,
} from 'itf_formbuilder_react';
import { CategoryRes, CostRes, GetCostSchemeRes } from 'app-types';
import ApiService from '../../../services/api-service';
import {
    Spinner,
    Button,
    RequiredIndicator,
    CostSchemeAutosuggestion,
} from '../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../components/Layout';
import { modal } from '../../../actions';
import { flatpickr } from '../../../App';
import { formOnTranslateString } from '../../../utils/trans-form';

import CostFieldsMapper from '../../../views/DashboardClient/Costs/CostFieldsMapper';
import AddCostScheme from '../../../modals/AddCostScheme';
import AddCategory from '../../../modals/AddCategory';
import { SharedFileWithCb } from '../../../types/share';
import { scrollTop } from '../../../utils/scroll-top';

interface Props {
    formUrl: string;
    formSendUrl: string;
    method: string;
    callback?: (data: CostRes) => void;
    file?: SharedFileWithCb;
}

const CostForm = ({ formUrl, formSendUrl, method, callback, file }: Props) => {
    const dispatch = useDispatch();
    const [selectedCategory, selectCategory] = useState<CategoryRes | null>();

    let itfForm: any;

    const setRef = (form: any) => {
        itfForm = form;
    };

    const registerFormCallbacks = () => {
        const currentCategoryId = itfForm.api.getValue('categoryId');
        const currentCategoryName = itfForm.api.getValue('category');
        if (currentCategoryId) {
            selectCategory({
                title: currentCategoryName,
                id: currentCategoryId,
                description: '',
            });
        }
    };

    const onOptionSelect = (option: GetCostSchemeRes) => {
        const newValues = {
            name: option.name,
            taxRate: option.taxRate,
        } as GetCostSchemeRes;
        if (option.valueType === 0) {
            newValues.value = option.value;
        }
        itfForm.api.setValues(newValues);

        console.log('----');
        console.log(itfForm.api.getValue('cost'));
        console.log('----');
    };

    const onNewNameSelect = (name: string) => {
        itfForm.api.setValues({
            name,
        });
    };

    const onCreate = (name: string) => {
        dispatch(modal.showModal(<AddCostScheme name={name} callback={onOptionSelect} />));
    };

    const onCategorySelect = (category: CategoryRes | null) => {
        if ((!category && selectedCategory) || category) {
            if (itfForm) {
                itfForm.api.setValue('category', category ? category.title : null);
                selectCategory(category); // important that this is after setting form value
            }
        }
    };

    const onNewCategorySelect = (name: string) => {
        dispatch(modal.showModal(<AddCategory name={name} callback={onCategorySelect} />));
    };

    return (
        <>
            {/* Form data handled outside form */}
            <FormContainer>
                <ItfApiForm
                  ref={setRef}
                  formId="add-cost"
                  schemaMayBeDynamic
                  schemaCacheEnabled={false}
                  requiredIndicator={<RequiredIndicator />}
                  schemaFetcher={() => ApiService.loadForm(formUrl)}
                  sendFetcher={(formId: string, values: any) => {
                        const fd = new FormData();
                        for (const key in values) {
                                if (key === 'cost' && values.cost) {
                                for (const file of values.cost) {
                                    fd.append('cost', file);
                                }
                            } else if (key === 'category') {
                                if (selectedCategory) fd.append('category', selectedCategory.id);
                            } else fd.append(key, values[key]);
                        }
                        scrollTop();
                        return ApiService.sendForm(formSendUrl, fd, method);
                    }}
                  onSavedSuccessfully={(res: CostRes) => {
                        if (typeof callback === 'function') callback(res);
                    }}
                  loadingInfo={<Spinner halfTransparent overlay />}
                  loadingInfoAboveContent
                  submitButton={
                        <ButtonsContainer>
                            <Button type="submit" text="application.save" primary />
                        </ButtonsContainer>
                    }
                  onRenderFullField={(
                        field: FormFieldToRender,
                        errors: any,
                        fieldHtmlId: string,
                        currentValue: any,
                        onChange: ChangeFunction,
                        onFocus: FieldAndEventFunction,
                        onBlur: FieldAndEventFunction,
                    ) => {
                        if (field.id === 'name') {
                            return (
                                <CostSchemeAutosuggestion
                                  required={field.required}
                                  label={field.title}
                                  input={{
                                        value: currentValue,
                                        name: fieldHtmlId,
                                        id: fieldHtmlId,
                                        onChange,
                                    }}
                                  onOptionSelect={onOptionSelect}
                                  placeholder={field.placeholder}
                                  onNewNameSelect={onNewNameSelect}
                                  allowNewName
                                  onCreate={onCreate}
                                  allowCreate
                                />
                            );
                        } else {
                            return (
                                <CostFieldsMapper
                                  file={file}
                                  data={{
                                        field,
                                        errors,
                                        fieldHtmlId,
                                        currentValue,
                                        onChange,
                                        onFocus,
                                        onBlur,
                                        onCategorySelect,
                                        onNewCategorySelect,
                                    }}
                                />
                            );
                        }
                    }}
                  onTranslateString={formOnTranslateString}
                  thirdPartyComponents={{ flatpickr }}
                  registerAsGlobalForm
                  onFormGloballyRegistered={registerFormCallbacks}
                />
            </FormContainer>
        </>
    );
};

export default CostForm;
