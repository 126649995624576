import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
} from 'itf_formbuilder_react';
import { Redirect, useParams } from 'react-router';
import ReactSelect from 'react-select';
import { UserContact, SendReminderReq } from 'app-types';
import ApiService from '../../../../services/api-service';
import {
  Spinner,
  Button,
  FieldsMapper,
  RequiredIndicator,
  SelectWithSuggestion,
  ContactAutosuggestion,
  Paragraph,
} from '../../../../components/Common';
import { FormContainer, ButtonsContainer } from '../../../../components/Layout';
import { notifications } from '../../../../actions';

import { formOnTranslateString } from '../../../../utils/trans-form';
import useHeader from '../../../../hooks/useHeader';
import { __ } from '../../../../services/translation';
import { history } from '../../../../App';
import { ApplicationState } from '../../../../reducers';

const SendReminder = () => {
  useHeader('application.send_reminder_to_client');

  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  const { invoiceIds, type } = useSelector((state: ApplicationState) => state.reminders);

  const handleSelect = (id: string, val: boolean) => {
    // custom handling to allow either one, not both at the same time
    if (id === 'alwaysForNextInvoice') {
      itfForm.api.setValues({ alwaysForThisClient: false, alwaysForNextInvoice: val });
    }
    if (id === 'alwaysForThisClient') {
      itfForm.api.setValues({ alwaysForThisClient: val, alwaysForNextInvoice: false });
    }
  };

  const { clientId, clientName } = useParams<{ clientId: string; clientName: string }>();
  const dispatch = useDispatch();

  if (type === null || invoiceIds.length === 0) {
    return <Redirect to="/dashboard/reminders" />;
  }

  return (
    <>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="send-invoice"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(`mail/form/send-reminder/${clientId}`)}
          sendFetcher={(formId: string, values: any) => {
            const data: SendReminderReq = {
              ...values,
              invoiceIds,
              clientId,
              type,
              clientName,
            };
            return ApiService.sendForm('mail/send-reminder', data, 'POST');
          }}
          onSavedSuccessfully={() => {
            dispatch(notifications.successNotification('application.reminder_sent'));
            history.push('/dashboard/reminders');
          }}
          loadingInfo={<Spinner transparent />}
          thirdPartyComponents={{ ReactSelect, ReactCreatableSelect: SelectWithSuggestion }}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text="application.send"
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={formOnTranslateString}
          onRenderFullField={(
            field: FormFieldToRender,
            errors: any,
            fieldHtmlId: string,
            currentValue: any,
            onChange: ChangeFunction,
            onFocus: FieldAndEventFunction,
            onBlur: FieldAndEventFunction,
          ) => {
            if (['email', 'additionalEmailAddress'].includes(field.id)) {
              return (
                <ContactAutosuggestion
                  required={field.required}
                  label={field.title}
                  input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange,
                  }}
                  onOptionSelect={(data: UserContact) => onChange(data.email)}
                  placeholder={field.placeholder}
                  meta={{
                    touched: errors.length > 0,
                    error: errors.length
                      ? __(errors[0].messageWithPlaceholders, errors[0].params)
                      : null,
                  }}
                />
              );
            }
            return FieldsMapper(
              field,
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
            );
          }}
        />
      </FormContainer>
    </>
  );
};

export { SendReminder };
