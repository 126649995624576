import { OtherIncomeDetails, TaxRate } from 'app-types';

interface GetSingleOtherIncome {
  type: 'GET_SINGLE_OTHER_INCOME_SUCCESS';
  payload: OtherIncomeDetails;
}

interface LoadingSingleOtherIncome {
  type: 'LOADING_SINGLE_OTHER_INCOME';
  payload: boolean;
}

export interface OtherIncomeState {
  loading: boolean;
  details: OtherIncomeDetails;
}

const initialState: OtherIncomeState = {
  loading: true,
  details: {
    id: '',
    date: new Date(),
    fileName: '',
    name: '',
    value: 0,
    taxRate: TaxRate['21%'],
    sentTo: []
  },
};

type Action = GetSingleOtherIncome | LoadingSingleOtherIncome;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'LOADING_SINGLE_OTHER_INCOME': {
      return { ...state, loading: action.payload };
    }
    case 'GET_SINGLE_OTHER_INCOME_SUCCESS': {
      return { ...state, details: action.payload };
    }
    default:
      return state;
  }
};
