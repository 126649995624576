import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { AccountantUserRegisterRequest, ClientUserRegisterRequest } from 'app-types';
import {
  Logo,
  Button,
  Input,
  WelcomeHeader,
  Spinner,
  LoginParagraph,
} from '../../components/Common';
import { Page, BottomContainer, FormContainer, Content } from '../../components/Layout';
import { modal, auth, header } from '../../actions';
import ValidationService from '../../services/validation-service';
import { ApplicationState } from '../../reducers';
import { __ } from '../../services/translation';

interface Props {
  registerAccountant: (accountantData: AccountantUserRegisterRequest) => void;
  loading: boolean;
  showModal: (content: React.ReactNode) => void;
  setHeader: (header: string) => void;
}
class RegisterAccountant extends Component<
  InjectedFormProps<AccountantUserRegisterRequest> & Props
> {
  componentDidMount() {
    const { setHeader } = this.props;
    setHeader('');
  }

  render() {
    const { handleSubmit, registerAccountant, loading } = this.props;
    return (
      <>
        <Page stretch center padded primary>
          <Logo center big />
          <Content center>
            <WelcomeHeader text="application.register_as_accountant" />
          </Content>
          <FormContainer lightLabels stretch>
            {loading && <Spinner overlay dark />}
            <form
              onSubmit={handleSubmit((accountantData: AccountantUserRegisterRequest) =>
                registerAccountant(accountantData),
              )}
            >
              <div className="form-fields">
                <Field
                  label="application.e-mail"
                  type="email"
                  name="email"
                  component={Input}
                  validate={[ValidationService.required, ValidationService.email]}
                  required
                  placeholder="application.email_placeholder"
                />
                <Field
                  label="application.company_name"
                  type="text"
                  name="companyName"
                  component={Input}
                  validate={[ValidationService.required, ValidationService.max55]}
                  required
                  placeholder="application.company_name_placeholder"
                />
                <Field
                  label="application.kvk"
                  type="text"
                  name="kvk"
                  component={Input}
                  validate={[ValidationService.required]}
                  required
                  placeholder="application.kvk_placeholder"
                />
                <Field
                  type="password"
                  name="pwd"
                  label="application.password"
                  component={Input}
                  validate={[ValidationService.required, ValidationService.passwordLength]}
                  required
                  placeholder="application.password_placeholder"
                />
                <Field
                  type="password"
                  name="confirmPwd"
                  label="application.confirm_password"
                  component={Input}
                  validate={[
                    ValidationService.required,
                    ValidationService.passwordLength,
                    ValidationService.match('pwd'),
                  ]}
                  required
                  placeholder="application.password_placeholder"
                />
                <Field
                  type="checkbox"
                  name="statue"
                  label={
                    <p
                      dangerouslySetInnerHTML={{
                        __html: __('application.i_accept_the_terms_and_conditions', {
                          link: '/assets/algemene_voorwaarden_HeyZZP.pdf',
                          link2: '/privacy',
                        }),
                      }}
                    />
                  }
                  component={Input}
                  validate={[ValidationService.mustAcceptRules]}
                />
              </div>
              <BottomContainer>
                <Button text="application.create_account" primary type="submit" />
                <LoginParagraph />
              </BottomContainer>
            </form>
          </FormContainer>
        </Page>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.user.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      registerAccountant: auth.registerAccountant,
      showModal: modal.showModal,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, any>({
    form: 'accountantRegister',
    enableReinitialize: true,
  })(RegisterAccountant),
);
