import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { CostRes } from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { EditCost, Confirmation } from '../../../../modals';

import { modal, cost, header } from '../../../../actions';
import { ButtonsContainer } from '../../../../components/Layout';
import {
  Button,
  InfoParagraph,
  OptionalImage,
  PDFPreview,
  SingleCostPreview,
  Spinner,
  Subheader,
} from '../../../../components/Common';

import { ApplicationState, RootState } from '../../../../reducers';

import { __ } from '../../../../services/translation';
import { DownloadService } from '../../../../services/download-service';
import ApiService from '../../../../services/api-service';
import { hideModal, showModal } from '../../../../actions/modal';
import { deleteDocument, getDocumentDetails } from '../../../../actions/document';
import { setHeader } from '../../../../actions/header';
import { formatDate } from '../../../../utils/format-date';
import EditDocument from '../../../../modals/EditDocument';

const SingleDocument = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const { loading, details } = useSelector((state: RootState) => state.document);

  const confirmCostRemoval = () => {
    dispatch(
      showModal(
        <Confirmation
          text="application.are_you_sure_you_want_to_delete_this_document?"
          confirmCallback={() => dispatch(deleteDocument(id))}
          cancelCallback={() => dispatch(hideModal())}
        />,
      ),
    );
  };

  const editDocument = () => {
    dispatch(showModal(<EditDocument id={id} defaultValues={details} />));
  };

  const downloadCost = async () => {
    await DownloadService.download(`document/file/one/${id}`);
  };

  useEffect(() => {
    dispatch(getDocumentDetails(id));
    dispatch(setHeader('application.document', '/dashboard/costs-documents'));
  }, []);

  return (
    <>
      {loading ? (
        <Spinner overlay />
      ) : (
        <div>
          <InfoParagraph name="application.name" text={details.name} />
          <InfoParagraph name="application.date" text={formatDate(details.date)} />
          {details.fileName && details.fileName.includes('.pdf') && (
            <PDFPreview url={`${ApiService.url}document/file/one/${details.id}`} />
          )}
          <ButtonsContainer marginTop>
            {details.fileName && (
              <Button
                text="application.download"
                noShadow
                rightMargin
                click={downloadCost}
                bottomMargin
              />
            )}
            <Button
              text="application.edit"
              secondary
              noShadow
              rightMargin
              click={editDocument}
              bottomMargin
            />
            <Button
              text="application.delete"
              danger
              noShadow
              click={confirmCostRemoval}
              bottomMargin
            />
          </ButtonsContainer>
        </div>
      )}
    </>
  );
};

export default SingleDocument;
