import React from 'react';
import { SomeUserEntity, ClientUserEntity } from 'app-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { CompanyLogo } from '../../../Common';
import { menu, auth, user as userActions } from '../../../../actions';
import { ApplicationState } from '../../../../reducers';
import Icon from '../../Icon';
import { __ } from '../../../../services/translation';
import './UserInfo.scss';

interface Props {
  toggled: boolean;
  user: SomeUserEntity | null;
  toggleMenu: () => void;
  getUserDetails: () => void;
}

class UserInfo extends React.Component<Props> {
  render() {
    const { user, toggleMenu } = this.props;
    const label = (user as ClientUserEntity).companyName
      ? (user as ClientUserEntity).companyName
      : user!.email;
    return (
      <Link to="/dashboard/profile" className="user-info-container" onClick={toggleMenu}>
        <CompanyLogo />
        <div>
          <p className="user-name">{label}</p>
          <div className="settings-link">
            <Icon icon="settings_primary" />
            {__('application.settings')}
          </div>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
  toggled: state.menu.toggled,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleMenu: menu.toggleMenu,
      logout: auth.logout,
      getUserDetails: userActions.getUserDetails,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
