import { FieldType, TableSchema } from '../typings';

export const mileageCarTable: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.id',
      field: 'id',
      mobile: false,
      searchable: false,
      sortable: false,
      defaultSort: false,
      type: FieldType.Text,
    },
    {
      name: 'application.regNumber',
      field: 'regNumber',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
    {
      name: 'application.carModel',
      field: 'carModel',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Text,
    },
    {
      name: 'application.carType',
      field: 'carType',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Text,
    },
  ],
};
