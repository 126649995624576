import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../reducers';

import './MenuShadow.scss';

interface Props {
  toggled: boolean;
}

const MenuShadow = ({ toggled }: Props) => <div className={toggled ? 'menu-shadow' : ''} />;

const mapStateToProps = (state: ApplicationState) => ({
  toggled: state.menu.toggled,
});

export default connect(mapStateToProps)(MenuShadow);
