import React, { useEffect, useState } from 'react';
import { TableSchema, costsForUser } from 'app-tables';
import { ApiList, Tabs } from '../../../../components/Common';
import CostItem from '../Item';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import useHeader from '../../../../hooks/useHeader';
import CategoryTiles from '../CategoryTiles';
import { SingleTab } from '../../../../types/tabs';
import { ButtonsContainer } from '../../../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import { UserRole } from 'app-types';
import { getUnpaidCostsCount, markAllCostsAsPaid } from '../../../../actions/cost';

enum CostLayout {
    LIST,
    TILES,
}

const tabs: SingleTab[] = [
    { label: 'application.list', value: CostLayout.LIST },
    { label: 'application.categories', value: CostLayout.TILES },
];

const CostList = () => {
    useHeader('application.cost_list', '/');
    const scheme: TableSchema = new InteractiveTableSchema(costsForUser);
    const dispatch = useDispatch();
    const userDetails = useSelector((state: ApplicationState) => state.user.details!);
    const unpaidCostsCount = useSelector((state: ApplicationState) => state.cost.unpaidCount);
    const [layout, setLayout] = useState<CostLayout>(CostLayout.TILES);


    const handleClickMarkAllInvoicesAsPaid = async () => {
        dispatch(markAllCostsAsPaid());
    };


    useEffect(() => {
        dispatch(getUnpaidCostsCount());
    }, []);


    return (
        <>
            <ButtonsContainer>
                <Tabs auto activeTab={layout} chooseTab={setLayout} tabs={tabs} />
            </ButtonsContainer>
            {layout === CostLayout.LIST && (
                <ApiList
                    apiEndpointSubUrl="cost/list"
                    scheme={scheme}
                    component={CostItem}
                    customButtons={
                        userDetails.role === UserRole.IncomeAndCost
                            ? undefined
                            : [
                                {
                                    translateKey: 'application.mark_all_costs_as_paid',
                                    translateParams: { count: unpaidCostsCount },
                                    onClick: handleClickMarkAllInvoicesAsPaid,
                                    isHidden: !unpaidCostsCount,
                                },
                            ]
                    }
                    padded />
            )}
            {layout === CostLayout.TILES && <CategoryTiles />}
        </>
    );
};

export default CostList;
