import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import './NotificationBadge.scss';

const NotificationBadge = () => {
  const { anyAdministrationUnread, unreadNotifications } = useSelector(
    (state: ApplicationState) => state.userInfo.info,
  );
  if (unreadNotifications === 0) return null;
  return (
    <div className={clsx('notifications-badge', { highlight: anyAdministrationUnread })}>
      {unreadNotifications}
    </div>
  );
};

export default NotificationBadge;
