import React from 'react';
import { useDispatch } from 'react-redux';

import { ClientEntity } from 'app-types';
import { modal } from '../../../../../actions';
import { Button } from '../../../../../components/Common';
import EditClient from '../../../../../modals/EditClient';

interface Props {
  client: ClientEntity;
}

const EditClientButton = ({ client }: Props) => {
  const dispatch = useDispatch();
  const editClient = () => {
    dispatch(modal.showModal(<EditClient id={client.id} defaultValues={client} />));
  };

  return (
    <Button
      text="application.edit"
      secondary
      noShadow
      click={editClient}
      rightMargin
      bottomMargin
    />
  );
};

export default EditClientButton;
