import { PaymentType, AccountType, PaymentUrl, AuthLoginResult } from 'app-types';
import { Dispatch } from 'redux';
import ApiService from '../services/api-service';
import { notifications } from '../actions';

export const stopSubscription = () => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'STOP_SUBSCRIPTION_SUCCESS',
    'STOP_SUBSCRIPTION_FAILED',
    (user: AuthLoginResult) => {
      dispatch({ type: 'FETCH_USER_DETAILS_SUCCESS', payload: user });
      dispatch(notifications.successNotification('application.subscription_canceled'));
    },
    null,
    'payment/stop-subscripiton',
    'LOADING_STOP_SUBSCRIPTION',
  );
};

export const newPayment = (paymentType: PaymentType, accountType: AccountType) => async (
  dispatch: Dispatch,
) => {
  await ApiService.post(
    dispatch,
    'SEND_RESET_PASSWORD_LINK_SUCCESS',
    'SEND_RESET_PASSWORD_LINK_FAILED',
    (data: string) => {
      window.location.replace(data);
    },
    null,
    'payment/prepare',
    'LOADING',
    {
      paymentType,
      accountType,
      redirectPath: 'payment-success',
    },
  );
};

export const downloadPaymentInvoice = (id: string) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'DOWNLOAD_PAYMENT_INVOICE_SUCCESS',
    'DOWNLOAD_PAYMENT_INVOICE_FAILED',
    null,
    null,
    `payment/invoice-pdf/${id}`,
    'LOADING_DOWNLOAD_PAYMENT_INVOICE',
  );
};
