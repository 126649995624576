import React from 'react';
import { CostEntity } from 'app-types';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { modal } from '../../../actions';
import { ListItem } from '../../../components/Common';
import { formatDate } from '../../../utils/format-date';
import { formatPrice } from '../../../utils/format-price';

interface Props {
  item: CostEntity;
  callback: (cost: CostEntity) => void;
}

const CostItemForInvoice = ({ item, callback }: Props) => {
  const dispatch = useDispatch();
  return (
    <>
      <ListItem
        header={item.name}
        icon={faEuroSign}
        paragraphs={[
          { icon: 'calendar', text: formatDate(item.date) },
          { icon: 'costs', text: formatPrice(item.value) },
        ]}
        buttons={[
          {
            text: 'application.add',
            click: () => {
              callback(item);
              dispatch(modal.hideModal());
            },
          },
        ]}
      />
    </>
  );
};

export default CostItemForInvoice;
