import {
  MileageSchemaEntity,
  ValueType,
  TaxRate,
  CostTemplateEntity,
  SeachCostSchemeRes,
  CostSchemaList,
} from 'app-types';

interface GetCostSchemeList {
  type: 'GET_COST_SCHEME_LIST_SUCCESS';
  payload: SeachCostSchemeRes;
}

interface LoadingCostSchemeList {
  type: 'LOADING_COST_SCHEME_LIST';
  payload: boolean;
}

interface GetSingleCostScheme {
  type: 'GET_SINGLE_COST_SCHEME_SUCCESS';
  payload: MileageSchemaEntity;
}

interface LoadingSingleCostScheme {
  type: 'LOADING_SINGLE_COST_SCHEME';
  payload: boolean;
}

export interface CostSchemesState {
  loading: boolean;
  loadingSingle: boolean;
  single: CostTemplateEntity;
  list: CostSchemaList[];
}

const initialState: CostSchemesState = {
  loading: true,
  list: [],
  loadingSingle: true,
  single: {
    id: '',
    name: '',
    value: 0,
    valueType: ValueType.VARIABLE_AMOUNT,
    taxRate: TaxRate['21%'],
  },
};

type Action =
  | GetCostSchemeList
  | LoadingCostSchemeList
  | GetSingleCostScheme
  | LoadingSingleCostScheme;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_COST_SCHEME_LIST_SUCCESS': {
      return { ...state, list: action.payload };
    }
    case 'LOADING_COST_SCHEME_LIST': {
      return { ...state, loading: action.payload };
    }
    case 'GET_SINGLE_COST_SCHEME_SUCCESS': {
      return { ...state, loading: false, single: action.payload };
    }
    case 'LOADING_SINGLE_COST_SCHEME': {
      return { ...state, loadingSingle: action.payload };
    }
    default:
      return state;
  }
};
