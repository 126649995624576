import React, { useState } from 'react';
import { OrderEventType, MileageSchemaEntity, OrderEvent } from 'app-types';
import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
  PublicItfFormBuilderComponentApi,
} from 'itf_formbuilder_react';

import { useDispatch } from 'react-redux';
import { FormContainer, ButtonsContainer } from '../../../components/Layout';
import {
  Button,
  Spinner,
  GoogleMapsRouteButton,
  FieldsMapper,
  RequiredIndicator,
  MileageFieldsMapper,
} from '../../../components/Common';
import ApiService from '../../../services/api-service';
import { hideModal, showModal } from '../../../actions/modal';
import { store } from '../../../App';
import { formOnTranslateString } from '../../../utils/trans-form';
import TimeSheetForInvoiceList from '..';
import { ExtendedOnFormGloballyRegisteredProps } from '../../../types/extendedOnFormGloballyRegisteredProps';

export interface GlobalFormsData {
  [formId: string]: {
    api: PublicItfFormBuilderComponentApi;
    values: {
      [id: string]: any;
    };
    valueChangedClb?: (newValues: any) => any;
  };
}

declare global {
  interface Window {
    globalForms: GlobalFormsData;
  }
}

interface State {
  route: { startPoint: string; finishPoint: string; distance: number; time: number };
  returnRoute: { startPoint: string; finishPoint: string; distance: number; time: number };
  itfForm: any;
  formState: any;
}

interface Props {
  callback: (event: OrderEvent) => void;
  formVals?: any;
}

class MileageForGenerator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      route: { startPoint: '', finishPoint: '', distance: 0, time: 0 },
      returnRoute: { startPoint: '', finishPoint: '', distance: 0, time: 0 },
      itfForm: null,
      formState: null,
    };
  }

  registerFormCallbacks = (api: PublicItfFormBuilderComponentApi) => {
    const { formVals } = this.props;
    this.setState({ formState: window.globalForms['add-mileage-scheme'].values });

    if (formVals) {
      (api as ExtendedOnFormGloballyRegisteredProps).setValues({ ...formVals });
    }

    window.globalForms['add-mileage-scheme'].valueChangedClb = (newValues: MileageSchemaEntity) => {
      this.setState({
        route: {
          startPoint: newValues.startPoint,
          finishPoint: newValues.finishPoint,
          distance: newValues.distance,
          time: newValues.time,
        },
        returnRoute: {
          startPoint: newValues.startPointReturn,
          finishPoint: newValues.finishPointReturn,
          distance: newValues.distanceReturn,
          time: newValues.timeReturn,
        },
        formState: window.globalForms['add-mileage-scheme'].values,
      });
    };
  };

  setRef = (form: any) => {
    this.setState({ itfForm: form });
  };

  render() {
    const { callback } = this.props;
    const { route, returnRoute, itfForm, formState } = this.state;
    return (
      <>
        <FormContainer>
          <ItfApiForm
            ref={this.setRef}
            formId="add-mileage-scheme"
            schemaMayBeDynamic={false}
            schemaCacheEnabled
            requiredIndicator={<RequiredIndicator />}
            schemaFetcher={() => ApiService.loadForm('mileage-schema/form/add-item')}
            sendFetcher={(formId: string, values: MileageSchemaEntity) =>
              ApiService.sendForm('mileage-schema/new', values, 'POST')
            }
            onSavedSuccessfully={(data: MileageSchemaEntity) => {
              callback({ templateId: data.id, type: OrderEventType.mileage });
              store.dispatch(hideModal());
            }}
            loadingInfo={<Spinner transparent />}
            submitButton={
              <ButtonsContainer marginTop>
                <Button type="submit" text="application.add" primary />
              </ButtonsContainer>
            }
            onTranslateString={formOnTranslateString}
            registerAsGlobalForm
            enableLiveValuesOfGlobalForm
            onFormGloballyRegistered={this.registerFormCallbacks}
            onRenderFullField={(
              field: FormFieldToRender,
              errors: any,
              fieldHtmlId: string,
              currentValue: any,
              onChange: ChangeFunction,
              onFocus: FieldAndEventFunction,
              onBlur: FieldAndEventFunction,
            ) => (
              <MileageFieldsMapper
                data={{
                  itfForm,
                  field,
                  errors,
                  fieldHtmlId,
                  currentValue,
                  onChange,
                  onFocus,
                  onBlur,
                  allowOwnAddress: true,
                  noDurationHelp: true,
                  formState,
                  route,
                  returnRoute,
                  closedRelatedModalCallback: (vals: MileageSchemaEntity) => {
                    store.dispatch(
                      showModal(<TimeSheetForInvoiceList callback={callback} formVals={vals} />),
                    );
                  },
                }}
              />
            )}
          />
        </FormContainer>
      </>
    );
  }
}

export default MileageForGenerator;
