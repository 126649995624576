import React from 'react';
import { InvoiceFile } from 'app-types';

import { OptionalImage, InfoParagraph } from '..';

import './SingleFilePreview.scss';

interface Props {
  file: InvoiceFile;
  type: 'offer' | 'invoice';
}

const SingleFilePreview = ({ file, type }: Props) => (
  <div className="single-file-preview">
    <InfoParagraph text={file.name} name="application.name" />
    <InfoParagraph date={file.date} name="application.date" />
    <OptionalImage
      // to fetch correct file - offer stores in offerFiles, invoices in invoiceFiles
      url={`invoice-file/one/${file.id}/${type}`}
      buttonLabel="application.see_file"
      alt={file.name}
    />
  </div>
);

export default SingleFilePreview;
