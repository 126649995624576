import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Alert, InfoParagraph, Input, Spinner, Subheader } from '../../../../components/Common';
import { __ } from '../../../../services/translation';
import { SelectOption } from '../../../../types/select';
import './Summary.scss';
import { isEmptyObject } from '../../../../utils/is-empty-object';
import ApiService from '../../../../services/api-service';

const Statistics = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [years, setYears] = useState<string[]>([]);
  const [yearsOptions, setYearsOptions] = useState<SelectOption[]>([]);

  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth());

  const [summary, setSummary] = useState<any>({});

  const monthOptions: SelectOption[] = useMemo(() => [
    { name: __('months.january'), value: 0 },
    { name: __('months.febuary'), value: 1 },
    { name: __('months.march'), value: 2 },
    { name: __('months.april'), value: 3 },
    { name: __('months.may'), value: 4 },
    { name: __('months.june'), value: 5 },
    { name: __('months.july'), value: 6 },
    { name: __('months.august'), value: 7 },
    { name: __('months.september'), value: 8 },
    { name: __('months.october'), value: 9 },
    { name: __('months.november'), value: 10 },
    { name: __('months.december'), value: 11 },
  ], []);

  const getData = async () => {
    await ApiService.callFetch('GET', 'payment/summary', (data: any) => {
      setSummary(data);
      setLoading(false);
    })
  }

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    const years: string[] = Object.keys(summary).sort(
      (a: string, b: string) => parseInt(b) - parseInt(a),
    );
    setYears(years);
    setYearsOptions(years.map((year) => ({ name: year, value: year })));
  }, [summary]);

  if (!summary || isEmptyObject(summary)) return <Alert type="notice" text="application.no_data" />;

  if (loading) return <Spinner />;

  const stats = summary[currentYear][currentMonth]

  return (
    <>
      <div className="profits">
        <>
          <div className="statistics-year-select">
            <Input
              type="select"
              options={yearsOptions}
              input={{
                value: currentYear,
                onChange: (e: ChangeEvent<any>) => {
                  setCurrentYear(e.currentTarget.value);
                },
              }}
              meta={{}}
            />
            <span className="select-separator">/</span>
            <Input
              type="select"
              options={monthOptions}
              input={{
                value: currentMonth,
                onChange: (e: ChangeEvent<any>) => {
                  setCurrentMonth(e.currentTarget.value);
                },
              }}
              meta={{}}
            />
          </div>
        </>
        <InfoParagraph name="admin.invoiced" price={stats.gross} />
        <InfoParagraph name="admin.invoiced_excl_btw" price={stats.net} />
        <InfoParagraph name="admin.invoiced_btw" price={stats.tax} />
      </div>
    </>
  );
};

export default Statistics;
