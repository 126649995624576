import React from 'react';
import { ClientForm } from '../../../../../components/Common';
import useHeader from '../../../../../hooks/useHeader';

const AddClient = () => {
  useHeader('application.add_client', '/dashboard/clients');
  return (
    <>
      <ClientForm />
    </>
  );
};

export default AddClient;
