import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { getDefaultLanguage } from '../utils/language';
import { MileageCoordinates } from '../types/mileage';
import { i18n } from './translation';

export default class SessionService {
  static clearSessionStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  static clearUserSession() {
    localStorage.removeItem('user');
  }

  static isUserSet() {
    return JSON.parse(localStorage.getItem('user') || 'false');
  }

  static setCurrentLanguage(lang: string) {
    localStorage.setItem('lang', lang);
  }

  static getCurrentLanguage() {
    let language = '';
    try {
      language = localStorage.getItem('lang') || getDefaultLanguage();
    } catch {
      language = getDefaultLanguage();
    }
    i18n.changeLang(language);
    return language;
  }

  static getCurrentCoordinates = () => {
    try {
      return JSON.parse(localStorage.getItem('coordinates') || '[]');
    } catch {
      return [];
    }
  };

  static addCoordinates = (coords: MileageCoordinates) => {
    try {
      const current = SessionService.getCurrentCoordinates();
      current.push(coords);
      localStorage.setItem('coordinates', JSON.stringify(current));
    } catch (err) {
      console.log(err);
    }
  };

  static setTrackingStart = (date: Date) => {
    try {
      localStorage.setItem('trackingStart', JSON.stringify(date));
    } catch (err) {
      localStorage.setItem('trackingStart', JSON.stringify(new Date()));
    }
  };

  static clearTrackingStart = () => {
    localStorage.removeItem('trackingStart');
  };

  static getTrackingStart = (): Date | null => {
    try {
      const date = JSON.parse(localStorage.getItem('trackingStart') || 'null');
      return date ? new Date(date) : null;
    } catch (err) {
      return null;
    }
  };

  static clearCoordinates = () => {
    localStorage.removeItem('trackingStart');
    localStorage.removeItem('coordinates');
  };

  static setToken = async (token: string) => {
    if (!Capacitor.isNativePlatform()) return;
    await Preferences.set({ key: 'heyzzp_token', value: token });
  };

  static getToken = async (): Promise<string> => {
    if (!Capacitor.isNativePlatform()) return '';
    const token = await Preferences.get({ key: 'heyzzp_token' });

    if (!token) return '';
    return token.value || '';
  };
}
