import React from 'react';
import { Page, ButtonsContainer, Container } from '../../../components/Layout';
import { Button, Logo, Price, StayWithUs } from '../../../components/Common';
import { __ } from '../../../services/translation';
import './NoSubscription.scss';
import { isMobileBuild } from '../../../utils/is-mobile';
import useHeader from '../../../hooks/useHeader';

const NoSubscription = () => {
  useHeader('');
  return (
    <>
      <Page stretch center light>
        <Container paddingTop>
          <div className="no-subscription-wrapper">
            <Logo big center dark />
            <h2>
              {isMobileBuild() ? __('no_subscription.header_mobile') : __('no_subscription.header')}
            </h2>

            <p className="data-deletion-indicator">
              {isMobileBuild()
                ? __('no_subscription.paragraph_mobile')
                : __('no_subscription.paragraph')}
            </p>
            {!isMobileBuild() && (
              <ButtonsContainer max>
                <Button
                  big
                  text="application.compare_payment_plans"
                  secondary
                  bottomMargin
                  to="/payment-plans"
                  rightMargin
                />
                <Button big text="application.buy_subscription" to="/buy-subscription" />
              </ButtonsContainer>
            )}
          </div>
        </Container>
      </Page>
    </>
  );
};

export default NoSubscription;
