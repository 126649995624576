import { OrderEventReq, Frequency, OrderDetails } from 'app-types';

interface GetSingleOrder {
  type: 'GET_SINGLE_ORDER_SUCCESS';
  payload: OrderDetails;
}

interface LoadingSingleOrder {
  type: 'LOADING_SINGLE_ORDER';
  payload: boolean;
}

interface AddToGenerator {
  type: 'ADD_TO_GENERATOR';
  payload: OrderEventReq;
}

interface DeleteFromGenerator {
  type: 'DELETE_FROM_GENERATOR';
  payload: number;
}

export interface OrderState {
  loadingSingleOrder: boolean;
  singleOrder: OrderDetails;
}

const initialState: OrderState = {
  loadingSingleOrder: true,
  singleOrder: {
    id: '',
    name: '',
    frequency: Frequency.daily,
    count: 0,
    nextAt: new Date(),
    events: [],
  },
};

type Action = GetSingleOrder | LoadingSingleOrder | AddToGenerator | DeleteFromGenerator;

export default (state = initialState, action: Action): OrderState => {
  switch (action.type) {
    case 'GET_SINGLE_ORDER_SUCCESS': {
      return { ...state, singleOrder: action.payload };
    }
    case 'LOADING_SINGLE_ORDER': {
      return { ...state, loadingSingleOrder: action.payload };
    }
    case 'ADD_TO_GENERATOR': {
      const updatedArray = [...state.singleOrder.events, action.payload];
      return {
        ...state,
        singleOrder: { ...state.singleOrder, events: (updatedArray as unknown) as any },
      };
    }
    case 'DELETE_FROM_GENERATOR': {
      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          events: state.singleOrder.events.filter((e, index) => index !== action.payload),
        },
      };
    }

    default:
      return { ...state };
  }
};
