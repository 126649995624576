import React from 'react';
import { OtherIncomeDetails, TaxRate } from 'app-types';

import { OptionalImage, InfoParagraph } from '..';

import './SingleIncomePreview.scss';
import { __ } from '../../../services/translation';

interface Props {
  income: OtherIncomeDetails;
}

const SingleIncomePreview = ({ income }: Props) => {
  const getIncomeTaxRate = () => {
    if (typeof income.taxRate === 'undefined') return '-';
    switch (income.taxRate) {
      case TaxRate['EU/Abroad EU']:
        return __('application.eu_abroad_eu');
      case TaxRate['BTW Gemengd']:
        return __('application.variable_tax_values');
      default:
        return Object.values(TaxRate)[income.taxRate];
    }
  };

  return (
    <div className="single-income-preview">
      <InfoParagraph name="application.name" text={income.name} />
      <InfoParagraph name="application.income_value" price={income.value} />
      {income.value !== 0 && typeof income.taxRate !== 'undefined' && (
        <InfoParagraph name="application.income_tax_rate" text={getIncomeTaxRate()} />
      )}
      <InfoParagraph name="application.date" date={income.date} />
      {income.fileName && (
        <OptionalImage url={`other-income/file/${income.id}`} alt={income.name} />
      )}
    </div>
  );
};

export default SingleIncomePreview;
