import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { __ } from '../../../services/translation';
import './ButtonRound.scss';

interface Props {
  to: string;
  label: string;
  icon: string;
  half?: boolean;
}

const ButtonRound = ({ to, label, icon, half }: Props) => {
  const getIcon = () => <img src={`/assets/icons/${icon}.svg`} alt={__(label)} />;

  return (
    <div className={clsx('button-round-wrapper', { half })}>
      <Link to={to}>
        <button className="button-round" type="button">
          {getIcon()}
          <div className="plus">
            <img src="/assets/icons/plus_primary_no_border.svg" />
          </div>
        </button>
      </Link>
      <h2 className="button-round-label">{__(label)}</h2>
    </div>
  );
};

export default ButtonRound;
