import React from 'react';
import { __ } from '../../../../services/translation';
import { Icon } from '../..';

interface Props {
  name: string;
  onNewCarSelect: (name: string) => void;
}

const NewCarSuggestion = ({ name, onNewCarSelect }: Props) => (
  <li
    role="button"
    onClick={() => {
      onNewCarSelect(name);
    }}
    className="new-client-suggestion-wrapper"
  >
    <Icon icon="plus" className="add-client-icon" />
    <span className="new-client-name">{name}</span>
  </li>
);

export default NewCarSuggestion;
