import React from 'react';
import { MileageSchemaEntity } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { __ } from '../../services/translation';
import { FormContainer } from '../../components/Layout';
import MileageForm from '../../views/DashboardClient/Mileage/Form';

interface Props {
  mileageId: string;
  mileage: MileageSchemaEntity;
}

const EditMileage = ({ mileageId, mileage }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_mileage" />
    </ModalHeader>
    <FormContainer>
      <MileageForm defaultValues={mileage} id={mileageId} />
    </FormContainer>
  </ModalContent>
);

export default EditMileage;
