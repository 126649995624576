import React, { MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { __ } from '../../../services/translation';
import './TrackingButton.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  text?: string;
  icon?: IconProp;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  start?: boolean;
  stop?: boolean;
  small?: boolean;
  active?: boolean;
  inactive?: boolean;
}

const TrackingButton = ({ text, icon, onClick, start, stop, small, active, inactive }: Props) => (
  <>
    <button
      type="button"
      className={`start-stop-button 
        ${start ? 'start' : ''}  
        ${stop ? 'stop' : ''} 
        ${small ? 'small' : ''} 
        ${active ? 'active' : ''} 
        ${inactive ? 'inactive' : ''}
        `}
      onClick={onClick}
    >
      <div className={`start-stop-inner ${small ? 'small' : ''} `}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {__(text)}
      </div>
    </button>
  </>
);

export default TrackingButton;
