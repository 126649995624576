import React from 'react';
import { OrderEventReq, OrderEvent } from 'app-types';
import { TableSchema, mileageSchemaForUserTable, timeSheetTemplates } from 'app-tables';
import GeneratorTimeSheetListItem from './Item';
import { InteractiveTableSchema } from '../../../utils/table-shcema-utils';
import { ApiList } from '../../../components/Common';

interface Props {
  callback: (event: OrderEvent) => void;
  usedData?: OrderEventReq[];
}

class List extends React.Component<Props> {
  private scheme: TableSchema;

  constructor(props: Props) {
    super(props);
    this.scheme = new InteractiveTableSchema(timeSheetTemplates);
  }

  render() {
    const { usedData, callback } = this.props;
    return (
      <ApiList
        inModal
        apiEndpointSubUrl="time-sheet/template/list"
        scheme={this.scheme}
        component={(props: any) => (
          <GeneratorTimeSheetListItem item={props.item} callback={callback} />
        )}
        filterBy={usedData}
      />
    );
  }
}

export default List;
