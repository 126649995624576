import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, NotificationBadge } from '../../../Common';

import './Notifications.scss';

const Notifications = () => (
  <Link className="header-notifications" to="/dashboard/notifications">
    <Icon icon="bell_thick" className="notifications-icon" />
    <NotificationBadge />
  </Link>
);

export default Notifications;
