import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { offer } from '../../../../../actions';
import OfferForm from '../OfferForm';

const BlankOffer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(offer.setOfferClient(null));
  }, []);
  return (
    <>
      <OfferForm />
    </>
  );
};

export default BlankOffer;
