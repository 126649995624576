import { ItfApiForm } from 'itf_formbuilder_react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AppSettingsEntity } from 'app-types';
import { successNotification } from '../../../actions/notifications';
import { Button, FieldsMapper, RequiredIndicator, Spinner } from '../../../components/Common';
import { ButtonsContainer, Container, FormContainer, Page } from '../../../components/Layout';
import useHeader from '../../../hooks/useHeader';
import ApiService from '../../../services/api-service';
import { formOnTranslateString } from '../../../utils/trans-form';
import Dashboard from '../../DashboardCommon';

const Settings = () => {
  useHeader('application.settings');
  const dispatch = useDispatch();
  return (
    <Page light withNavigation>
      <Dashboard>
        <Container>
          <FormContainer>
            <ItfApiForm
              formId="remove-account"
              schemaMayBeDynamic
              schemaCacheEnabled={false}
              requiredIndicator={<RequiredIndicator />}
              schemaFetcher={() => ApiService.loadForm('settings/form/')}
              sendFetcher={(formId: string, values: AppSettingsEntity) =>
                ApiService.sendForm('settings', values, 'PATCH')
              }
              onSavedSuccessfully={() => {
                dispatch(successNotification('application.settings_saved'));
              }}
              loadingInfo={<Spinner transparent />}
              submitButton={
                <ButtonsContainer>
                  <Button
                    type="submit"
                    className="form-submit-button"
                    text="application.save"
                    primary
                  />
                </ButtonsContainer>
              }
              onTranslateString={(string: string) => formOnTranslateString(string)}
              onRenderFullField={FieldsMapper}
            />
          </FormContainer>
        </Container>
      </Dashboard>
    </Page>
  );
};
export default Settings;
