import { TimeSheetTemplateDetails } from 'app-types';

interface GetSingleTimeSheetTemplate {
  type: 'GET_SINGLE_TIME_SHEET_SCHEME_SUCCESS';
  payload: TimeSheetTemplateDetails; // @TODO add type
}

interface LoadingSingleTimeSheetTemplate {
  type: 'LOADING_SINGLE_TIME_SHEET_SCHEME';
  payload: boolean;
}

export interface TimeSheetTemplateState {
  loadingSingle: boolean;
  single: TimeSheetTemplateDetails;
}

const initialState: TimeSheetTemplateState = {
  loadingSingle: true,
  single: {
    id: '',
    periods: [],
  },
};

type Action = GetSingleTimeSheetTemplate | LoadingSingleTimeSheetTemplate;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_SINGLE_TIME_SHEET_SCHEME_SUCCESS': {
      return { ...state, single: action.payload };
    }
    case 'LOADING_SINGLE_TIME_SHEET_SCHEME': {
      return { ...state, loadingSingle: action.payload };
    }
    default:
      return state;
  }
};
