import React, { ChangeEvent } from 'react';
import {
  ApiErrorCode,
  GetClientKvKRes,
  ClientEntity,
  InvoiceEntity,
  InvoiceFile,
  InvoiceNumeration,
  SomeUserEntity,
  ClientUserEntity,
  CostEntity,
  TaxRate,
  AddInvoiceReq,
  InvoiceCompanyName,
  MileageEntity,
  GetFullInvoiceResponse,
  TimeSheetEntity,
} from 'app-types';
import {
  Field,
  FieldArray,
  reduxForm,
  InjectedFormProps,
  GenericFieldArray,
  formValueSelector,
  change,
  getFormValues,
} from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { addDays, differenceInCalendarDays, isDate, isValid } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FormContainer, ButtonsContainer, FieldsGroup } from '../../../../../components/Layout';
import {
  Spinner,
  Input,
  Button,
  DateInput,
  ClientAutosuggestion,
  InfoButton,
  Icon,
  Paragraph,
} from '../../../../../components/Common';
import { history } from '../../../../../App';
import { invoice, modal, notifications, header, timeSheetSchemes } from '../../../../../actions';
import ValidationService from '../../../../../services/validation-service';
import RenderServices from './RenderServices';
import { __ } from '../../../../../services/translation';
import { ApplicationState } from '../../../../../reducers';
import { scrollTop } from '../../../../../utils/scroll-top';
import { CostForInvoice } from '../../../../../types/cost-for-invoice';
import RenderCosts from './RenderCosts';
import ApiService from '../../../../../services/api-service';
import RenderFiles from './RenderFiles';
import InvoiceNumerationAlert from '../../../../../modals/InvoiceNumerationAlert';
import {
  InvoiceClient,
  LinkMileageToInvoiceModal,
  LinkTimeSheetToInvoiceModal,
  InvoicePreview,
} from '../../../../../modals';
import './InvoiceForm.scss';
import { weekPlaceholder } from '../../../../../utils/week-placeholder';
import { InvoiceFormTaxOptions } from './RenderServices/options';
import MileageItem from './MileageItem';
import TimeSheetItem from './TimeSheetItem';

interface Props extends InjectedFormProps {
  user: SomeUserEntity;
  number: string;
  errorNotification: (error: ApiErrorCode) => void;
  errorNotificationText: (error: string) => void;
  receiveInvoiceData: (values: any) => void;
  showModal: (content: React.ReactNode) => void;
  hideModal: () => void;
  initialData?: AddInvoiceReq;
  edit?: boolean;
  services: any;
  costs: CostForInvoice[];
  issueDate: Date | string;
  paymentTerm: Date | string;
  serviceData: any;
  changeField: (form: string, field: string, value: any) => void;
  file?: any;
  client?: ClientEntity | null;
  invoice?: GetFullInvoiceResponse;
  files?: InvoiceFile[];
  setInvoiceClient: (client: ClientEntity | GetClientKvKRes | null) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
  forms: any;
  secondAccountNumber: string;
  secondCompanyName: string;
  mileages: MileageEntity[];
  timeSheets: TimeSheetEntity[];
}

interface State {
  loadingNextNumber: boolean;
  displayAnnotation: boolean;
}

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

const getPaymentDays = (issueDate: Date, paymentTerm: Date) => {
  const diff = differenceInCalendarDays(paymentTerm, issueDate);
  if ([7, 14, 21, 28, 30, 35, 42].includes(diff)) return diff;
  return -1;
};
class InvoiceForm extends React.Component<Props, State> {
  private paymentDaysOptions = [
    {
      name: `7 ${__('application.days')} (${__('application.week')})`,
      value: 7,
    },
    {
      name: `14 ${__('application.days')} (2 ${__('application.weeks')})`,
      value: 14,
    },
    {
      name: `21 ${__('application.days')} (3 ${__('application.weeks')})`,
      value: 21,
    },
    {
      name: `28 ${__('application.days')} (4 ${__('application.weeks')})`,
      value: 28,
    },
    {
      name: `30 ${__('application.days')}`,
      value: 30,
    },
    {
      name: `35 ${__('application.days')} (5 ${__('application.weeks_secondary')})`,
      value: 35,
    },
    {
      name: `42 ${__('application.days')} (6 ${__('application.weeks_secondary')})`,
      value: 42,
    },
    {
      name: __('application.other_days_count'),
      value: -1,
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      loadingNextNumber: false,
      displayAnnotation: false,
    };
  }

  componentDidMount() {
    const { edit, setHeader, client } = this.props;

    if (!edit) this.getNextInvoiceNumber();
    if (client) this.handleClientSelect(client);
    setHeader(edit ? 'application.edit_invoice' : 'application.add_invoice');
  }

  componentDidUpdate(prevProps: Props) {
    const { issueDate, client } = this.props;
    const oldYear = new Date(prevProps.issueDate).getFullYear();
    const newYear = new Date(issueDate).getFullYear();
    if (!Number.isNaN(oldYear) && !Number.isNaN(newYear) && oldYear && oldYear !== newYear) {
      this.getNextInvoiceNumber();
    }
    if (client !== prevProps.client && typeof client !== 'undefined') {
      this.handleClientSelect(client);
    }
  }

  private showInvoiceNumerationAlert = (year: number) => {
    const { showModal } = this.props;
    showModal(<InvoiceNumerationAlert year={year} />);
  };

  private getNextInvoiceNumber = async () => {
    const { changeField, errorNotificationText, issueDate } = this.props;
    const date = new Date(issueDate);
    const year = isValid(date) ? date.getFullYear() : new Date().getFullYear();
    this.setState({ loadingNextNumber: true });
    await ApiService.callFetch(
      'GET',
      `invoice/next-number/${year}`,
      (number: InvoiceNumeration) => {
        if (number.isDefault) this.showInvoiceNumerationAlert(year);
        changeField('invoiceForm', 'number', number.numberCompiled);
      },
      () => {
        errorNotificationText('error.cant_get_invoice_number');
      },
    );
    this.setState({ loadingNextNumber: false });
  };

  private handleClientSelect = (data: GetClientKvKRes | ClientEntity | null) => {
    const { setInvoiceClient, changeField } = this.props;
    setInvoiceClient(data);
    if (data) {
      changeField(
        'invoiceForm',
        'client.name',
        data.name ||
          `${(data as ClientEntity).firstName} ${(data as ClientEntity).lastName}` ||
          (data as ClientEntity).email,
      );
    } else {
      changeField('invoiceForm', 'client.name', null);
    }
  };

  private handleNewClientModal = (name: string) => {
    const { showModal } = this.props;
    showModal(
      <InvoiceClient
        forForm="invoiceForm"
        client={{ name } as ClientEntity}
        callback={this.handleClientSelect}
      />,
    );
  };

  private handleClientModal = (client: ClientEntity | GetClientKvKRes) => {
    const { showModal } = this.props;
    showModal(
      <InvoiceClient forForm="invoiceForm" client={client} callback={this.handleClientSelect} />,
    );
  };

  private setPaymentTerm = (days: number) => {
    const { changeField, issueDate } = this.props;
    changeField('invoiceForm', 'paymentTerm', addDays(new Date(issueDate), days));
  };

  private setCompanyName = (option: string) => {
    const { changeField } = this.props;
    changeField('invoiceForm', 'companyName', option);
  };

  private handleInvoiceDateChange = (date: any) => {
    if (!isDate(date)) return;
    const { changeField, paymentTerm } = this.props;
    const diff = getPaymentDays(date, new Date(paymentTerm));

    changeField('invoiceForm', 'paymentDays', diff);
  };

  private submitInvoice = (values: any) => {
    const { errorNotificationText } = this.props;
    const { receiveInvoiceData, errorNotification } = this.props;

    if (!values.defaultAccount && !values.secondAccount) {
      errorNotificationText('error.one_of_bank_accounts_must_be_selected');
      return;
    }

    if (values.services && values.services.length > 0) {
      receiveInvoiceData(values);
      history.push('/dashboard/invoices/add/preview');
    } else {
      errorNotification(ApiErrorCode.invoiceWithoutService);
    }
  };

  private checkForm = () => {
    const { invalid, errorNotificationText } = this.props;
    if (invalid) {
      errorNotificationText('error.incorrect_invoice');
      scrollTop();
    }
  };

  private handlePaymentTermChange = (date: any) => {
    if (!isDate(date)) return;
    const { changeField, issueDate } = this.props;
    const diff = getPaymentDays(new Date(issueDate), date);

    changeField('invoiceForm', 'paymentDays', diff);
  };

  private showExampleInvoice = () => {
    const { showModal } = this.props;
    showModal(
      <InvoicePreview url="invoice/annotation-example" header="application.annotation_preview" />,
    );
  };

  private showPreviewInvoice = () => {
    const { showModal, forms, client, errorNotification, errorNotificationText } = this.props;
    const formData = forms.invoiceForm.values;

    if (client === null) {
      return errorNotificationText(__('application.invoice_without_client'));
    }

    if (!formData.services || formData.services.length < 1) {
      return errorNotification(ApiErrorCode.invoiceWithoutService);
    }

    const { street, city, id, paymentDays, owner, ...invoiceDataToParse } = formData;
    if (!client) return null;

    const invoiceDataToSend = {
      ...invoiceDataToParse,
      annotation: formData.annotation || '',
      client,
      costIds: invoiceDataToParse.costs
        ? invoiceDataToParse.costs
            .filter((cost: CostEntity) => cost.id)
            .map((cost: CostEntity) => cost.id)
        : [],
      costs: invoiceDataToParse.costs
        ? invoiceDataToParse.costs
            .filter((cost: CostEntity) => !cost.id)
            .map((cost: CostEntity) => ({ ...cost, date: formData.createdAt }))
        : [],
    };
    showModal(
      <InvoicePreview
        url="invoice/preview"
        header="application.invoice_preview"
        data={invoiceDataToSend}
      />,
    );
  };

  private attachMileageToInvoice = (data: MileageEntity) => {
    const { hideModal, changeField, mileages } = this.props;

    const newData = [...mileages, data];

    changeField('invoiceForm', 'mileages', newData);
    hideModal();
  };

  private handleDetachMileage = (id: string) => {
    const { changeField, mileages } = this.props;

    const newData = mileages.filter((item) => item.id !== id);

    changeField('invoiceForm', 'mileages', newData);
  };

  private handleOpenAddMileageModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { showModal, mileages } = this.props;

    showModal(
      <LinkMileageToInvoiceModal
        onClick={this.attachMileageToInvoice}
        attachedMileageIds={mileages.map((item) => item.id)}
      />,
    );
  };

  private attachTimeSheetToInvoice = (data: TimeSheetEntity) => {
    const { hideModal, changeField, timeSheets } = this.props;

    const newData = [...timeSheets, data];

    changeField('invoiceForm', 'timeSheets', newData);
    hideModal();
  };

  private handleDetachTimeSheet = (id: string) => {
    const { changeField, timeSheets } = this.props;

    const newData = timeSheets.filter((item) => item.id !== id);

    changeField('invoiceForm', 'timeSheets', newData);
  };

  private handleOpenAddTimeSheetModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { showModal, timeSheets } = this.props;

    showModal(
      <LinkTimeSheetToInvoiceModal
        onClick={this.attachTimeSheetToInvoice}
        attachedTimeSheetIds={timeSheets.map((item) => item.id)}
      />,
    );
  };

  render() {
    const {
      handleSubmit,
      services,
      costs,
      files,
      user,
      client,
      showModal,
      changeField,
      secondAccountNumber,
      secondCompanyName,
      invoice,
      invalid,
      timeSheets,
      mileages,
    } = this.props;
    const userMinYear = new Date(user.createdAt).getFullYear() - 1;

    const { loadingNextNumber } = this.state;

    return (
      <div className="invoice-form">
        {loadingNextNumber && <Spinner overlay halfTransparent />}

        <FormContainer>
          <form onSubmit={handleSubmit(this.submitInvoice)} name="add-invoice">
            {/* {!client ? ( */}
            <div className="client-name">
              <Field
                name="client.name"
                component={ClientAutosuggestion}
                label="application.client_name"
                allowNewClient
                validate={ValidationService.required}
                onNewClientSelect={this.handleNewClientModal}
                onOptionSelect={this.handleClientModal}
                required
                disabled={!!client}
                placeholder="John Smith"
              />
              {client && (
                <div className="invoice-client-buttons">
                  <button onClick={() => this.handleClientSelect(null)}>
                    <Icon icon="cross" className="client-remove-button" />
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      showModal(
                        <InvoiceClient
                          forForm="invoiceForm"
                          client={client}
                          callback={this.handleClientSelect}
                        />,
                      )
                    }
                    className="client-edit-button"
                  >
                    <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                  </button>
                </div>
              )}
            </div>
            {/* ) : (
              <ClientInfo client={client} />
            )} */}

            <Field
              name="number"
              type="text"
              component={Input}
              label="application.invoice_number"
              required
              darkBackground
            />
            <Field
              name="mark"
              placeholder={weekPlaceholder({
                withMonth: true,
                withYear: true,
              })}
              type="text"
              component={Input}
              label="application.mark"
              max={50}
              validate={ValidationService.max50}
            />
            <Field
              name="createdAt"
              type="date"
              component={DateInput}
              label="application.date"
              onChange={this.handleInvoiceDateChange}
              validate={ValidationService.required}
              minYear={userMinYear}
              required
            />

            <FieldsGroup>
              <Field
                name="paymentDays"
                type="select"
                component={Input}
                label="application.payment_term_(days)"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  this.setPaymentTerm(Number(e.target.value))
                }
                options={this.paymentDaysOptions}
              />
              <Field
                name="paymentTerm"
                type="date"
                label="&nbsp;"
                component={DateInput}
                validate={ValidationService.required}
                required
                onChange={this.handlePaymentTermChange}
              />
            </FieldsGroup>

            <FieldArrayCustom name="costs" component={RenderCosts} data={costs} />
            <FieldArrayCustom
              name="services"
              component={RenderServices}
              data={services}
              handleTaxChange={(newTax: number, index: number) => {
                const checkIfAnyServiceHaveBtwVerTax = services.find(
                  (service: any, itemIndex: number) =>
                    service.taxRate === InvoiceFormTaxOptions[TaxRate['BTW Verlegd']].value &&
                    itemIndex !== index,
                );
                if (
                  checkIfAnyServiceHaveBtwVerTax ||
                  newTax === InvoiceFormTaxOptions[TaxRate['BTW Verlegd']].value
                ) {
                  changeField('invoiceForm', 'annotation', `BTW verlegd naar ${client?.btw}`);
                } else {
                  changeField('invoiceForm', 'annotation', '');
                }
              }}
            />

            <div className="mileage-container">
              {mileages.length > 0 &&
                mileages.map((item, index) => (
                  <MileageItem item={item} key={index} onClick={this.handleDetachMileage} />
                ))}
              <div className="mileage-button-container">
                <Button
                  secondary
                  leftIcon
                  constant
                  text="application.add_mileage"
                  bottomMargin
                  icon="plus"
                  click={this.handleOpenAddMileageModal}
                />
              </div>
            </div>

            <div className="time-sheet-container">
              {timeSheets.length > 0 &&
                timeSheets.map((item, index) => (
                  <TimeSheetItem item={item} key={index} onClick={this.handleDetachTimeSheet} />
                ))}
              <div className="time-sheet-button-container">
                <Button
                  secondary
                  leftIcon
                  constant
                  text="application.add_time_sheet"
                  bottomMargin
                  icon="plus"
                  click={this.handleOpenAddTimeSheetModal}
                />
              </div>
            </div>

            <FieldArrayCustom name="files" component={RenderFiles} data={files} />
            <div className="annotation-container">
              <div className="annotation-check">
                <Button
                  secondary
                  leftIcon
                  constant
                  icon={this.state.displayAnnotation ? 'cross' : 'plus'}
                  type="button"
                  text="application.invoice_custom_annotation"
                  click={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    this.setState((prevState) => ({
                      displayAnnotation: !prevState.displayAnnotation,
                    }));
                  }}
                />

                <div className="field-help">
                  <InfoButton small click={this.showExampleInvoice} />
                </div>
              </div>
              {this.state.displayAnnotation && (
                <Field
                  name="annotation"
                  type="textarea"
                  component={Input}
                  max={650}
                  validate={ValidationService.max650}
                />
              )}
            </div>

            {secondAccountNumber && (
              <>
                <Paragraph translation="application.payment_can_be_made_on_account" />
                <Field
                  name="defaultAccount"
                  type="checkbox"
                  component={Input}
                  label="application.default_account"
                />
                <Field
                  name="secondAccount"
                  type="checkbox"
                  component={Input}
                  label="application.second_account"
                />
              </>
            )}

            {secondCompanyName && (
              <>
                <Paragraph translation="application.companyName" />
                <Field
                  name="companyName"
                  type="select"
                  component={Input}
                  label="application.which_company"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    this.setCompanyName(e.target.value);
                  }}
                  options={[
                    { name: __('application.first_company_name'), value: InvoiceCompanyName.FIRST },
                    {
                      name: __('application.second_company_name'),
                      value: InvoiceCompanyName.SECOND,
                    },
                  ]}
                />
              </>
            )}

            <ButtonsContainer>
              <Button
                text="application.preview"
                disabled={invalid}
                click={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  this.showPreviewInvoice();
                }}
              />
              <Button type="submit" text="application.next" click={this.checkForm} primary />
            </ButtonsContainer>
          </form>
        </FormContainer>
      </div>
    );
  }
}

const today = new Date();
const selector = formValueSelector('invoiceForm');
const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const file = selector(state, 'file');
  const createdAt = selector(state, 'createdAt');
  const paymentTerm = selector(state, 'paymentTerm');

  return {
    file,
    initialValues: {
      createdAt: createdAt || today,
      paymentTerm: paymentTerm || addDays(today, 7),
      paymentDays: getPaymentDays(
        (ownProps.invoice && new Date(ownProps.invoice.createdAt)) || today,
        (ownProps.invoice && new Date(ownProps.invoice.paymentTerm)) || addDays(today, 7),
      ),
      isCreditNote: false,
      ...ownProps.initialData,
      ...(ownProps.invoice ? ownProps.invoice : {}),
      annotation: ownProps.invoice
        ? ownProps.invoice.annotation || '' // has to be string
        : (state.user.details as ClientUserEntity).invoiceAnnotation || '',
      defaultAccount: [false, true].includes(ownProps?.invoice?.defaultAccount)
        ? ownProps.invoice.defaultAccount
        : true,
      secondAccount: ownProps?.invoice?.secondAccount ? ownProps.invoice.secondAccount : false,
      companyName: ownProps?.invoice?.companyName
        ? ownProps.invoice.companyName
        : InvoiceCompanyName.FIRST,
    },
    issueDate: selector(state, 'createdAt'),
    paymentTerm: selector(state, 'paymentTerm'),
    services: selector(state, 'services') || [],
    costs: selector(state, 'costs') || [],
    files: selector(state, 'files') || [],
    mileages: selector(state, 'mileages') || [],
    timeSheets: selector(state, 'timeSheets') || [],
    user: state.user.details,
    client: state.invoice.client,
    forms: state.form,
    secondAccountNumber: (state.user.details as ClientUserEntity).secondAccountNumber,
    secondCompanyName: (state.user.details as ClientUserEntity).secondCompanyName,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeField: change,
      showModal: modal.showModal,
      receiveInvoiceData: invoice.receiveInvoiceData,
      setInvoiceClient: invoice.setInvoiceClient,
      errorNotification: notifications.errorNotification,
      errorNotificationText: notifications.errorNotificationText,
      setHeader: header.setHeader,
      hideModal: modal.hideModal,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, any>({
    form: 'invoiceForm',
    // important to leave it like that, so the form does not get repopulated
    enableReinitialize: false,
  })(InvoiceForm),
);
