import { TrackingStatus } from 'app-types';

interface SetTrackingInterval {
  type: 'SET_TRACKING_INTERVAL';
  payload: any;
}

interface ClearTrackingInterval {
  type: 'CLEAR_TRACKING_INTERVAL';
}

interface ChangeTrackingStatus {
  type: 'CHANGE_TRACKING_STATUS';
  payload: TrackingStatus;
}

interface SetWorkingTime {
  type: 'SET_WORKING_TIME';
  payload: number;
}
interface IncreaseWorkingTime {
  type: 'INCREASE_WORKING_TIME';
  payload: number;
}

export interface TimeTrackingState {
  loading: boolean;
  status: TrackingStatus;
  currentTime: number;
  interval: any;
  shouldResultsUpdate: boolean;
  finishedTimeSheetData: {
    // @TODO TImeSheedEntity
    start: string;
    finish: string;
    name: string;
    id: string;
  };
}

const initialState: TimeTrackingState = {
  loading: false,
  status: TrackingStatus.Idle,
  currentTime: 0,
  shouldResultsUpdate: false,
  interval: null,
  finishedTimeSheetData: {
    id: '',
    name: '',
    start: '',
    finish: '',
  },
};

type Action =
  | IncreaseWorkingTime
  | SetTrackingInterval
  | ClearTrackingInterval
  | ChangeTrackingStatus
  | SetWorkingTime;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_WORKING_TIME': {
      return { ...state, currentTime: action.payload };
    }

    case 'INCREASE_WORKING_TIME': {
      return { ...state, currentTime: state.currentTime + 1 };
    }

    case 'CHANGE_TRACKING_STATUS': {
      return { ...state, status: action.payload };
    }
    case 'SET_TRACKING_INTERVAL': {
      if (state.interval) clearInterval(state.interval);
      return { ...state, interval: setInterval(action.payload, 1000) };
    }
    case 'CLEAR_TRACKING_INTERVAL': {
      clearInterval(state.interval);
      return { ...state, interval: undefined };
    }
    default:
      return state;
  }
};
