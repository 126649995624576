import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { modal } from '../../actions';
import { Button, Subheader } from '../../components/Common';
import { ButtonsContainer } from '../../components/Layout';
import {} from '../../services/translation';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import './ChooseMonthModal.scss';

interface Props {
  setDates: (startAt: Date, endAt: Date) => void;
}

const ChooseMonthModal = ({ setDates }: Props) => {
  const dispatch = useDispatch();
  const isLeapYear = (year: number) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(0);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const lastYear = new Date().getFullYear() - 1;
  const months = [
    { name: 'months.january', start: new Date(year, 0, 1), finish: new Date(year, 0, 31) },
    {
      name: 'months.febuary',
      start: new Date(year, 1, 1),
      finish: isLeapYear(year) ? new Date(year, 1, 29) : new Date(year, 1, 28),
    },
    { name: 'months.march', start: new Date(year, 2, 1), finish: new Date(year, 2, 31) },
    { name: 'months.april', start: new Date(year, 3, 1), finish: new Date(year, 3, 30) },
    { name: 'months.may', start: new Date(year, 4, 1), finish: new Date(year, 4, 31) },
    { name: 'months.june', start: new Date(year, 5, 1), finish: new Date(year, 5, 30) },
    { name: 'months.july', start: new Date(year, 6, 1), finish: new Date(year, 6, 31) },
    { name: 'months.august', start: new Date(year, 7, 1), finish: new Date(year, 7, 31) },
    { name: 'months.september', start: new Date(year, 8, 1), finish: new Date(year, 8, 30) },
    { name: 'months.october', start: new Date(year, 9, 1), finish: new Date(year, 9, 31) },
    { name: 'months.november', start: new Date(year, 10, 1), finish: new Date(year, 10, 30) },
    { name: 'months.december', start: new Date(year, 11, 1), finish: new Date(year, 11, 31) },
  ];

  const getButtonHighlight = (index: number) => {
    if (index === month) {
      return false;
    }
    return true;
  };

  const getYearHighlight = (val: number) => {
    if (val === year) {
      return false;
    }
    return true;
  };

  const getMonthButtons = () => {
    const monthButtons = months.map((item, index: number) => (
      <Button
        text={item.name}
        click={() => setMonth(index)}
        auto
        secondary={getButtonHighlight(index)}
        className="button-month"
        small
      />
    ));
    let parsedMonths;
    if (year === currentYear) {
      parsedMonths = monthButtons.filter((item, index: number) => index - 1 < currentMonth);
    } else {
      parsedMonths = monthButtons;
    }
    const firstQuarter = parsedMonths.slice(0, 4);
    const secondQuarter = parsedMonths.slice(4, 8);
    const thirdQuarter = parsedMonths.slice(8, 12);
    return (
      <>
        <div className="month-row">{firstQuarter}</div>
        <div className="month-row">{secondQuarter}</div>
        <div className="month-row">{thirdQuarter}</div>
      </>
    );
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.choose_month" />
      </ModalHeader>
      <Subheader level={2} text="application.month" />
      {getMonthButtons()}
      <Subheader level={2} text="application.year" />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          text={lastYear}
          click={() => setYear(lastYear)}
          secondary={getYearHighlight(lastYear)}
          className="choose-month-modal-year-button"
          small
        />
        <Button
          text={currentYear}
          secondary={getYearHighlight(currentYear)}
          click={() => setYear(currentYear)}
          className="choose-month-modal-year-button"
          small
        />
      </div>
      <ButtonsContainer marginTop>
        <Button
          text="application.choose"
          click={() => {
            setDates(months[month].start, months[month].finish);
            dispatch(modal.hideModal());
          }}
          className="choose-quarter-modal-choose-button"
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ChooseMonthModal;
