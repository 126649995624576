const formatThousands = (n: number | string, isHtml = false, toFixed = 2): string => {
    try {
        const converted = Number(n);
        if (Number.isNaN(converted) || (typeof n === 'number' && Number.isNaN(n))) {
            return '-';
        }
        return converted
            .toFixed(toFixed)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)

    } catch (e) {
        return '-';
    }
};

export const formatPrice = (n: number | string, isHtml = false, scale = 2): string =>
    `€ ${formatThousands(n, isHtml, scale)}`;
export const parseDecimal = (n: number, isHtml = false): string => `${formatThousands(n, isHtml)}`;
export const parseDecimalToPrice = (n: string) => parseFloat(n.replace(',', '.'));

export const formatSmallPrice = (n: any) => (n.toString().length === 1 ? `${n}0` : n);
