import { InfoResponse } from 'app-types';

interface GetUserInfo {
  type: 'GET_USER_INFO_SUCCESS';
  payload: InfoResponse;
}

type Action = GetUserInfo;

export interface UserInfoState {
  info: InfoResponse;
}

const initialState: UserInfoState = {
  info: {
    unreadNotifications: 0,
    anyAdministrationUnread: false,
  },
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_USER_INFO_SUCCESS': {
      const info = action.payload;
      return {
        ...state,
        info,
      };
    }
    default:
      return state;
  }
};
