import { GetClientKvKRes, TimeSheetPeriod } from 'app-types';
import {
  addDays,
  addHours,
  differenceInMinutes,
  isBefore,
  setHours,
  setMinutes,
  startOfDecade,
} from 'date-fns';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Input, TimePickerInput, ListItem, TimeCalendar } from '..';
import { ButtonsContainer, FieldsGroup } from '../../Layout';
import { __ } from '../../../services/translation';
import { getTimeFromDate } from '../../../utils/parse-date';
import ClientAutosuggestion from '../ClientAutosuggestion';
import Subheader from '../Subheader';
import { formatDurationHoursMinutes } from '../../../utils/format-duration';

import './Periods.scss';

interface Props {
  updatePeriods: (contacts: TimeSheetPeriod[]) => void;
  errors?: {
    clientName?: string;
    name?: string;
    start?: string;
    end?: string;
    [key: string]: any;
    arrayIndex: number;
  };
  initialPeriods?: TimeSheetPeriod[];
}

interface PeriodErrors {
  clientName: string | null;
  name: string | null;
  start: string | null;
  end: string | null;
}

const initialPeriod: TimeSheetPeriod = {
  clientName: '',
  start: getTimeFromDate(new Date()),
  end: getTimeFromDate(addHours(new Date(), 1)),
  name: '',
};

const Periods = ({ updatePeriods, errors, initialPeriods }: Props) => {
  const [periods, setPeriods] = useState<TimeSheetPeriod[]>(initialPeriods || []);
  const [period, setPeriod] = useState<TimeSheetPeriod>(initialPeriod);
  const [periodErrors, setPeriodErrors] = useState<PeriodErrors>({
    clientName: null,
    start: null,
    end: null,
    name: null,
  });

  useEffect(() => {
    updatePeriods(periods);
  }, [periods]);

  const calculateNextPeriodEnd = (start: string) => {
    let startDate = new Date();
    const startParts = start.split(':');
    const startHours = parseInt(startParts[0]);
    const startMinutes = parseInt(startParts[1]);
    startDate = setMinutes(startDate, startMinutes);
    startDate = setHours(startDate, startHours);

    startDate = addHours(startDate, 1);

    return startDate;
  };

  const validatePeriod = (): boolean => {
    const result = periods.filter((item) => {
      const splitStart = item.start.split(':');
      const splitEnd = item.end.split(':');
      const splitStartOfNewItem = period.start.split(':');
      const splitEndOfNewItem = period.end.split(':');

      const timeStartOfNewItem =
        Number(splitStartOfNewItem[0]) * 60 + Number(splitStartOfNewItem[1]);
      const timeEndOfNewItem = Number(splitEndOfNewItem[0]) * 60 + Number(splitEndOfNewItem[1]);
      const timeStart = Number(splitStart[0]) * 60 + Number(splitStart[1]);
      const timeEnd = Number(splitEnd[0]) * 60 + Number(splitEnd[1]);

      return timeStartOfNewItem < timeEnd && timeEndOfNewItem > timeStart;
    });

    return result.length === 0;
  };

  const addPeriod = () => {
    let isError = false;
    const newErrors: PeriodErrors = {
      name: null,
      clientName: null,
      start: null,
      end: null,
    };

    if (!validatePeriod()) {
      newErrors.start = 'error.invalid_period_range';
      isError = true;
    }

    if (!period.start) {
      newErrors.start = 'error.field_required';
      isError = true;
    }
    if (!period.end) {
      newErrors.end = 'error.field_required';
      isError = true;
    }
    if (!period.name) {
      newErrors.name = 'error.field_required';
      isError = true;
    }
    if (period.name.length > 128) {
      newErrors.name = 'error.max128';
      isError = true;
    }
    if (period.clientName.length > 128) {
      newErrors.clientName = 'error.max128';
      isError = true;
    }

    if (isError) {
      setPeriodErrors(newErrors);
    } else {
      setPeriods((items) => [
        ...items,
        {
          ...period,
        },
      ]);
      setPeriod({
        ...period,
        start: period.end,
        end: getTimeFromDate(calculateNextPeriodEnd(period.end)),
      });
      setPeriodErrors({
        name: null,
        start: null,
        end: null,
        clientName: null,
      });
    }
  };

  const removePeriod = (indexToRemove: number) => {
    setPeriods((items) => items.filter((period, index) => index !== indexToRemove));
  };

  const setValue = (field: string, value: string) => {
    setPeriod((item: TimeSheetPeriod) => ({ ...item, [field]: value }));
  };

  const selectClient = (client: GetClientKvKRes) => {
    setPeriod((item: TimeSheetPeriod) => ({ ...item, clientName: client.name }));
  };

  const calculateBreak = (start: string, end: string) => {
    let startDate = new Date();
    let finishDate = new Date();

    const parts = end.split(':');
    const hours = parseInt(parts[0]);
    const minutes = parseInt(parts[1]);

    finishDate = setMinutes(finishDate, minutes);
    finishDate = setHours(finishDate, hours);

    const startParts = start.split(':');
    const startHours = parseInt(startParts[0]);
    const startMinutes = parseInt(startParts[1]);
    startDate = setMinutes(startDate, startMinutes);
    startDate = setHours(startDate, startHours);

    if (isBefore(finishDate, startDate)) addDays(finishDate, 1);

    const difference = differenceInMinutes(finishDate, startDate);

    return formatDurationHoursMinutes(difference);
  };

  return (
    <>
      <Input
        type="text"
        required
        label="application.timesheet_work_name"
        placeholder="application.timesheet_work_name_placeholder"
        input={{
          value: period.name,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setValue('name', e.target.value);
          },
        }}
        meta={{ error: __(periodErrors.name), touched: periodErrors.name }}
      />
      <FieldsGroup>
        <TimePickerInput
          label="application.start_time"
          required
          input={{
            value: period.start,
            onChange: (value: string) => {
              setValue('start', value);
            },
          }}
          meta={{ error: __(periodErrors.start), touched: periodErrors.start }}
        />
        <TimePickerInput
          label="application.finish_time"
          required
          input={{
            value: period.end,
            onChange: (value: string) => {
              setValue('end', value);
            },
          }}
          meta={{ error: __(periodErrors.end), touched: periodErrors.end }}
        />
      </FieldsGroup>
      <ClientAutosuggestion
        label="application.client_optional"
        input={{
          name: 'clientName',
          id: 'clientName',
          value: period.clientName,
          onChange: (value: string) => setValue('clientName', value),
        }}
        kvkOnly
        onOptionSelect={selectClient}
        placeholder="application.client_autosuggestion"
        meta={{
          touched: periodErrors.clientName,
          error: __(periodErrors.clientName),
        }}
      />
      <ButtonsContainer>
        <Button text="application.add" click={addPeriod} type="button" />
      </ButtonsContainer>
      <Subheader text="application.timesheet_periods" />

      {periods.length === 0 ? (
        <Alert type="notice" text="application.timesheet_no_periods" />
      ) : (
        <TimeCalendar
          data={periods.map((item, index) => ({
            start: item.start,
            end: item.end,
            title: item.name,
            index,
          }))}
          removeItem={removePeriod}
        />
      )}
    </>
  );
};

export default Periods;
