import React from 'react';
import { TableSchema, invoiceForUser, offers } from 'app-tables';
import { ApiList } from '../../../../components/Common';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import OfferListItem from './Item';
import useHeader from '../../../../hooks/useHeader';

const OffersList = () => {
  useHeader('application.offers');

  const scheme: TableSchema = new InteractiveTableSchema(offers);

  return (
    <>
      <ApiList apiEndpointSubUrl="offer/list" scheme={scheme} component={OfferListItem} />
    </>
  );
};

export { OffersList };
