import React, { MouseEvent } from 'react';
import { __ } from '../../../../services/translation';
import './Suggestion.scss';
import { GetCostSchemeRes } from 'app-types';
import { Button } from '../../../../components/Common';
import { useDispatch } from 'react-redux';
import { Confirmation } from '../../../../modals';
import { modal, costScheme } from '../../../../actions';

interface Props {
  item: GetCostSchemeRes;
  onSelect: (data: GetCostSchemeRes) => void;
}

const Suggestion = ({ item, onSelect }: Props) => {
  const dispatch = useDispatch();

  const showConfirmationModal = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.remove_cost_scheme_confirmation"
          textArgs={{ name: item.name }}
          confirmCallback={() => dispatch(costScheme.deleteSingleCostScheme(item.id))}
        />,
      ),
    );
  };

  return (
    <li className="suggestion-wrapper">
      <div className="suggestion-wrapper__container">
        <div
          role="button"
          className="cost-scheme-suggestio-name-container"
          onClick={() => {
            onSelect(item);
          }}
        >
          <span className="cost-scheme-suggestion-name">
            {item.name ||
              `${(item as GetCostSchemeRes).name} ${
                (item as GetCostSchemeRes).valueType === 0 ? (item as GetCostSchemeRes).value : ''
              }`}
          </span>
        </div>
        <Button
          text="application.delete"
          danger
          extraSmall
          click={(e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            showConfirmationModal();
          }}
        />
      </div>
    </li>
  );
};

export default Suggestion;
