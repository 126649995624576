import { __ } from '../services/translation';

interface PropsWeekPlaceholder {
  date?: Date;
  withYear?: boolean;
  withMonth?: boolean;
}

const months = [
  'january',
  'febuary',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const getWeekNumber = (date: any): number => {
  const nDay = (date.getDay() + 6) % 7;
  date.setDate(date.getDate() - nDay + 3);
  const n1stThursday = date.valueOf();
  date.setMonth(0, 1);
  if (date.getDay() !== 4) {
    date.setMonth(0, 1 + ((4 - date.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((n1stThursday - date) / 604800000);
};

export const weekPlaceholder = ({
  date = new Date(),
  withYear,
  withMonth,
}: PropsWeekPlaceholder): string =>
  `${__('application.week')} ${getWeekNumber(date)}${
    withMonth ? `, ${__(`months.${months[date.getMonth()]}`)}` : ''
  } ${withYear ? date.getFullYear() : ''}`;
