import { TaxRate, TaxType } from '../service';
import { CategoryRes } from './category';

export interface CostEntity {
  fileName: string;
  date: Date;
  name: string;
  id: string;
  value: number;
  taxRate: TaxRate;
  invoiceId: string | null;
  categoryId: string | null;
  category?: CategoryRes;
  customTax: number;
  taxType: TaxType;
  sentTo: string[];
  isPaid: boolean;
}

export enum ValueType {
  FIXED_AMOUNT,
  VARIABLE_AMOUNT,
}

export interface CostTemplateEntity {
  id: string;
  name: string;
  taxRate: TaxRate;
  valueType: ValueType;
  value: number;
}
