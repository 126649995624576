import React from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from 'app-types';
import { ApplicationState } from '../../../../../reducers';
import './CompanyData.scss';

const CompanyData = () => {
  const user = useSelector((state: ApplicationState) => state.user.details);

  if (user?.role !== UserRole.Client) return null;

  return <div className="company-data">
    {user.companyName && <p>{user.companyName}</p>}
    {user.kvk && <div>
      <p className="kvk-label">KVK:</p>
      {user.kvk}
    </div>}
  </div>
}


export default CompanyData;
