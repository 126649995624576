import { ItfApiForm } from 'itf_formbuilder_react';
import React, { FC } from 'react';
import { CreatedClientAccountRes } from 'app-types';
import { useDispatch } from 'react-redux';
import ApiService from '../../../../services/api-service';
import { Button, FieldsMapper, RequiredIndicator, Spinner } from '../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import { formOnTranslateString } from '../../../../utils/trans-form';
import { notifications } from '../../../../actions';

interface Props {
  appendRecord: (record: CreatedClientAccountRes) => void;
}

const CreateNewAccountForm: FC<Props> = ({ appendRecord }) => {
  const dispatch = useDispatch();

  return (
    <FormContainer>
      <ItfApiForm
        formId="add-new-client-account"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm('user/form/create-client-account')}
        sendFetcher={(formId: string, values: any) =>
          ApiService.sendForm('user/create-client-account', values, 'POST')
        }
        onSavedSuccessfully={(data: CreatedClientAccountRes) => {
          dispatch(
            notifications.successNotification('application.client_account_has_been_created'),
          );
          appendRecord(data);
        }}
        onRenderFullField={FieldsMapper}
        loadingInfo={<Spinner transparent />}
        submitButton={
          <ButtonsContainer>
            <Button type="submit" text="application.add" noShadow primary />
          </ButtonsContainer>
        }
        onTranslateString={formOnTranslateString}
      />
    </FormContainer>
  );
};

export default CreateNewAccountForm;
