import React, { FC } from 'react';
import { AddConnectorReq } from 'app-types';
import { ItfApiForm } from 'itf_formbuilder_react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ApiService from '../../../../services/api-service';
import {
  Button,
  FieldsMapper,
  RequiredIndicator,
  Spinner,
  Subheader,
} from '../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import { formOnTranslateString } from '../../../../utils/trans-form';
import { notifications } from '../../../../actions';

export const InviteClient: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <Subheader text="application.send_invitation_tp_client_header" />
      <FormContainer>
        <ItfApiForm
          formId="invite-client"
          schemaMayBeDynamic={false}
          schemaCacheEnabled
          requiredIndicator={<RequiredIndicator />}
          loadingInfoAboveContent
          schemaFetcher={() => ApiService.loadForm('accountant-connector/form/add-connector')}
          sendFetcher={(formId: string, values: AddConnectorReq) =>
            ApiService.sendForm('accountant-connector/add-connector', values, 'POST')
          }
          onSavedSuccessfully={() => {
            history.push('/dashboard/invitations/pending-invitations');
            dispatch(notifications.successNotification('application.client_added'));
          }}
          loadingInfo={<Spinner overlay halfTransparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text="application.submit"
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={formOnTranslateString}
          onRenderFullField={FieldsMapper}
        />
      </FormContainer>
    </>
  );
};
