import React, { useState } from 'react';
import clsx from 'clsx';
import { Icon } from '..';
import { formatPrice } from '../../../utils/format-price';
import './ListElementWrapper.scss';

interface Props {
  children: React.ReactNode;
  click?: () => void;
  icon?: string;
  price?: number;
  header?: string;
  defaultMinimized?: boolean;
  invalid?: boolean;
  noMaximizedBorder?: boolean;
  additionalRight?: React.ReactNode;
  className?: string;
}

const ListElementWrapper = ({
  children,
  header,
  icon,
  additionalRight,
  click,
  price,
  defaultMinimized,
  invalid,
  noMaximizedBorder,
  className,
}: Props) => {
  const [minimized, setMinimized] = useState(defaultMinimized);
  return (
    <div
      className={clsx('list-element-wrapper', className, {
        minimized,
        invalid,
        'no-maximized-border': noMaximizedBorder,
      })}
    >
      <header
        onClick={() => {
          setMinimized((min) => !min);
        }}
      >
        <div className="list-element-header-content">
          <h2 className="list-element-header">
            {' '}
            {invalid && <span className="list-element-invalid-indicator">!</span>}
            {header}
          </h2>
          {!!price && <span className="list-element-price">{formatPrice(price)}</span>}
        </div>
        {additionalRight}
        <Icon icon="arrow-down" className={clsx('list-element-state-icon', { minimized })} />
        {icon && click && (
          <button type="button" className="list-element-button" onClick={click}>
            <Icon icon={icon} />
          </button>
        )}
      </header>
      <div className="list-element-content">{children}</div>
    </div>
  );
};

ListElementWrapper.defaultProps = {
  defaultMinimized: false,
};

export default ListElementWrapper;
