import React from 'react';

import { useSelector } from 'react-redux';
import { UserRole } from 'app-types';
import clsx from 'clsx';
import { ApplicationState } from '../../../reducers';
import { SectionHeader } from '../../Common';
import { isMobileBuild } from '../../../utils/is-mobile';
import './Page.scss';

interface Props {
  children: React.ReactNode;
  stretch?: boolean;
  background?: boolean;
  padded?: boolean;
  center?: boolean;
  light?: boolean;
  withNavigation?: boolean;
  className?: string;
  noMenu?: boolean;
  primary?: boolean;
}

const Page = ({
  stretch,
  center,
  background,
  withNavigation,
  padded,
  noMenu,
  children,
  light,
  className,
  primary,
}: Props) => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  const hideTrialIndicator = useSelector(
    (state: ApplicationState) => state.menu.hideTrialIndicator,
  );
  const isTrial = () => {
    if (isMobileBuild()) return false;
    if (hideTrialIndicator) return false;
    if (user && user.role === UserRole.Client) {
      const { trial } = user;
      return trial;
    }
    return false;
  };

  return (
    <main
      className={clsx(className, {
        stretch,
        light,
        center,
        primary,
        background,
        padded,
        'not-logged': !user,
        trial: isTrial(),
        'with-navigation': withNavigation,
        'no-menu': !user || noMenu,
      })}
    >
      <SectionHeader />
      {children}
    </main>
  );
};

export default Page;
