import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { modal } from '../../../../actions';
import { ChangeLanguageModal } from '../../../../modals';
import CONFIG from '../../../../config/app';
import { ApplicationState } from '../../../../reducers';
import './ChangeLanguage.scss';

interface Props {
  showModal: (content: React.ReactNode) => void;
  lang: string;
}

class ChangeLanguage extends React.Component<Props> {
  private pickLanguage = () => {
    const { showModal } = this.props;
    showModal(<ChangeLanguageModal />);
  };

  private getLanguage = () => {
    const { lang } = this.props;
    let currentLang = CONFIG.LANGUAGES.find((language) => language.shortcut === lang);
    if (typeof currentLang === 'undefined') {
      currentLang = CONFIG.LANGUAGES[0];
    }
    return lang;
  };

  render() {
    return (
      <button
        type="button"
        tabIndex={0}
        className="change-language-button"
        onClick={this.pickLanguage}
      >
        <div className="current-language">{this.getLanguage()}</div>
        {/* <FontAwesomeIcon icon={faChevronDown} /> */}
      </button>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  lang: state.lang.current,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ showModal: modal.showModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLanguage);
