import React from 'react';
import { SettlementRequestLogEntity } from 'app-types';
import { __ } from '../../../../../services/translation';
import { ListItem } from '../../../../../components/Common';
import { formatDateTime } from '../../../../../utils/formatDateTime';

interface Props {
  item: SettlementRequestLogEntity;
}

const LogListItem = ({ item }: Props) => (
  <div>
    <ListItem
      header={`${__('application.request_about_settlement_has_been_sent', {
        date: formatDateTime(item.sentDate),
      })}`}
      buttons={[]}
      noShadow
      singleRow
    />
  </div>
);

export default LogListItem;
