import React from 'react';
import { CostTemplateEntity, ValueType } from 'app-types';
import { useDispatch } from 'react-redux';
import { ListItem } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import { modal, costScheme } from '../../../../../actions';
import { Confirmation } from '../../../../../modals';
import EditCostScheme from '../../../../../modals/EditCostScheme';
import { formatPrice } from '../../../../../utils/format-price';

interface Props {
  item: CostTemplateEntity;
}

const CostSchemeItem = ({ item }: Props) => {
  const dispatch = useDispatch();

  const deleteScheme = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.are_you_sure_you_want_to_delete_this_cost_scheme?"
          confirmCallback={() =>
            dispatch(costScheme.deleteSingleCostScheme(item.id, '/dashboard/costs-schemes'))
          }
          cancelCallback={() => dispatch(modal.hideModal())}
        />,
      ),
    );
  };

  return (
    <ListItem
      header={item.name}
      paragraphs={[
        {
          icon: 'costs',
          text:
            item.valueType === ValueType.FIXED_AMOUNT
              ? formatPrice(item.value)
              : __('application.cost_variable_value'),
        },
      ]}
      buttons={[
        { text: 'application.details', to: `/dashboard/costs-schemes/${item.id}` },
        {
          text: 'application.edit',
          type: 'secondary',
          click: () => dispatch(modal.showModal(<EditCostScheme id={item.id} />)),
        },
        {
          type: 'success',
          text: 'application.use',
          to: `/dashboard/costs/add/usescheme/${item.id}`,
        },
        { type: 'danger', text: 'application.delete', click: deleteScheme },
      ]}
    />
  );
};

export default CostSchemeItem;
