import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  reduxForm,
  Field,
  change,
  formValueSelector,
  InjectedFormProps,
  getFormValues,
} from 'redux-form';
import { MileageCarEntity, MileageCarReq, MileageSchemaEntity, RouteReq } from 'app-types';
import { ApplicationState } from '../../../../reducers';
import { orders, modal } from '../../../../actions';
import { Input, Button, CarAutosuggestion } from '../../../../components/Common';
import ValidationService from '../../../../services/validation-service';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import { __ } from '../../../../services/translation';
import RouteInput from '../../../../components/Common/RouteInput';
import { ChangeCar, EditMileageScheme } from '../../../../modals';
import { addMileageScheme, editMileageScheme } from '../../../../actions/mileage-schemes';

interface OwnProps {
  id?: string;
  defaultValues?: MileageSchemaEntity;
}

interface ReduxFormProp extends InjectedFormProps {
  route: RouteReq;
  returnRoute: RouteReq;
  car: MileageCarReq;
  isExtras: boolean;
  isPrivate: boolean;
  name: string;
}

type Props = OwnProps & ReduxFormProp;

const MileageSchemaForm = ({ handleSubmit, route, returnRoute, car, id, name }: Props) => {
  const dispatch = useDispatch();
  const [thisSameReturnRoute, setThisSameReturnRoute] = useState(false);

  const getRawValues = (): any => ({
    name,
    startPoint: route.startPoint,
    finishPoint: route.finishPoint,
    distance: route.distance,
    time: route.time,
    startPointReturn: returnRoute.startPoint,
    finishPointReturn: returnRoute.finishPoint,
    distanceReturn: returnRoute.distance,
    timeReturn: returnRoute.time,
    regNumber: car.regNumber,
    carModel: car.carModel,
    carType: car.carType,
  });

  const save = (data: any) => {
    const { name, route, returnRoute, car } = data;

    const body = {
      name,
      startPoint: route.startPoint,
      finishPoint: route.finishPoint,
      distance: route.distance,
      time: route.time,
      startPointReturn: returnRoute.startPoint,
      finishPointReturn: returnRoute.finishPoint,
      distanceReturn: returnRoute.distance,
      timeReturn: returnRoute.time,
      regNumber: car.regNumber,
      carModel: car.carModel,
      carType: car.carType,
    };

    if (id) {
      dispatch(editMileageScheme(body, id));
      return;
    }

    dispatch(addMileageScheme(body));
  };

  const onNewCarSelected = (val: string) => {
    dispatch(
      modal.showModal(
        <ChangeCar
          regNumber={val}
          currentVals={car}
          callback={(vals) => {
            if (id) {
              return dispatch(
                modal.showModal(
                  <EditMileageScheme
                    mileageId={id}
                    scheme={{
                      ...getRawValues(),
                      regNumber: vals.regNumber,
                      carType: vals.carType,
                      carModel: vals.carModel,
                    }}
                  />,
                ),
              );
            }

            onCarSelected(vals);
            dispatch(modal.hideModal());
          }}
        />,
      ),
    );
  };

  const onCarSelected = (val: MileageCarEntity) => {
    dispatch(change('mileageSchemaForm', 'car', val));
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(save)}>
        <Field
          label="application.name"
          placeholder="application.name"
          name="name"
          type="text"
          component={Input}
          required
          validate={ValidationService.required}
        />
        <div className="route-input-container">
          <div>
            <Field
              name="startPoint"
              component={RouteInput}
              startPoint={route?.startPoint ?? ''}
              finishPoint={route?.finishPoint ?? ''}
              distance={route?.distance ?? 0}
              time={route?.time ?? 0}
              handleChange={(vals: any) => {
                dispatch(change('mileageSchemaForm', 'route', vals));
                if (id) {
                  dispatch(
                    modal.showModal(
                      <EditMileageScheme
                        mileageId={id}
                        scheme={{
                          ...getRawValues(),
                          startPoint: vals.startPoint,
                          finishPoint: vals.finishPoint,
                          distance: vals.distance,
                          time: vals.time,
                        }}
                      />,
                    ),
                  );
                }
              }}
              customCloseModal={!!id}
              allowOwnAddress
            />
          </div>
          {!id && (
            <div>
              <Field
                name="finishPoint"
                component={RouteInput}
                startPoint={returnRoute?.startPoint ?? ''}
                finishPoint={returnRoute?.finishPoint ?? ''}
                distance={returnRoute?.distance ?? 0}
                time={returnRoute?.time ?? 0}
                handleChange={(vals: any) => {
                  dispatch(change('mileageSchemaForm', 'returnRoute', vals));
                }}
                allowOwnAddress
              />
            </div>
          )}
        </div>
        {!id && (
          <div className="field">
            <Input
              type="checkbox"
              label="application.this_same_return_route"
              input={{
                name: 'thisSameReturnRoute',
                id: 'thisSameReturnRoute',
                value: thisSameReturnRoute,
                onChange: (e: any) => {
                  const isSet = Boolean(e.target.checked);
                  setThisSameReturnRoute(isSet);
                  if (isSet) {
                    dispatch(
                      change('mileageSchemaForm', 'returnRoute', {
                        ...route,
                        startPoint: route.finishPoint,
                        finishPoint: route.startPoint,
                      }),
                    );
                  } else {
                    dispatch(
                      change('mileageSchemaForm', 'returnRoute', {
                        startPoint: '',
                        finishPoint: '',
                        distance: 0,
                        time: 0,
                      }),
                    );
                  }
                },
              }}
              meta={{}}
            />
          </div>
        )}
        <Field
          label="application.registration_number"
          placeholder="application.registration_number_placeholder"
          name="car.regNumber"
          type="textarea"
          component={CarAutosuggestion}
          allowNewCar
          onNewCarSelect={onNewCarSelected}
          onOptionSelect={onCarSelected}
          customCloseModal={!!id}
        />
        <ButtonsContainer marginTop marginLittle>
          <Button
            type="submit"
            primary
            text={id ? 'application.save' : 'application.add'}
            noShadow
          />
        </ButtonsContainer>
      </form>
    </FormContainer>
  );
};
const selector = formValueSelector('mileageSchemaForm');
const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
  const { defaultValues } = ownProps;
  const initialValues = {
    name: defaultValues ? defaultValues.name : '',
    route: {
      startPoint: defaultValues ? defaultValues.startPoint : '',
      finishPoint: defaultValues ? defaultValues.finishPoint : '',
      distance: defaultValues ? defaultValues.distance : 0,
      time: defaultValues ? defaultValues.time : 0,
    },
    returnRoute: {
      startPoint: defaultValues ? defaultValues.startPointReturn : '',
      finishPoint: defaultValues ? defaultValues.finishPointReturn : '',
      distance: defaultValues ? defaultValues.distanceReturn : 0,
      time: defaultValues ? defaultValues.timeReturn : 0,
    },
    car: {
      regNumber: defaultValues ? defaultValues.regNumber : '',
      carModel: defaultValues ? defaultValues.carModel : '',
      carType: defaultValues ? defaultValues.carType : '',
    },
  };

  return {
    initialValues,
    car: selector(state, 'car'),
    route: selector(state, 'route'),
    returnRoute: selector(state, 'returnRoute'),
    name: selector(state, 'name'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addOrder: orders.addOrder,
      showModal: modal.showModal,
      editOrder: orders.editOrder,
      changeField: change,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  // @TODO: check why it does not work with enableReinitialize
  reduxForm<Props, any>({
    form: 'mileageSchemaForm',
    enableReinitialize: false,
    destroyOnUnmount: true,
  })(MileageSchemaForm),
);
