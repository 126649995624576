import React, { useCallback, useState } from 'react';
import { LabeledSwitch } from '../LabeledSwitch';
import Spinner from '../Spinner';
import ApiService from '../../../services/api-service';
import './IsPaidSwitch.scss';

interface Props {
    label: string;
    url: string;
    paid: boolean;
    callback?: () =>  void;
}

const IsPaidSwitch = ({ label, url, paid, callback }: Props) => {
    const [isPaid, setPaid] = useState<boolean>(paid);
    const [loading, setLoading] = useState<boolean>(false);

    const onCheck = useCallback(async() => {
        setLoading(true);
        await ApiService.callFetch('PATCH', url, (response: boolean) => {
            setPaid(response);
            if(callback) callback();
        })
        setTimeout(() => setLoading(false), 250);
    }, []);

    return <div className='is-paid-switch-container'>
        {loading && <Spinner overlay halfTransparent small />}
        <LabeledSwitch checked={isPaid} onChange={onCheck} label={label} />
    </div>
}

export { IsPaidSwitch }