import React from 'react';
import { useDispatch } from 'react-redux';
import { UserListForAdmin, CheaterUser, UserBlockRequest } from 'app-types';
import { ItfApiForm } from 'itf_formbuilder_react';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import { Button, Spinner, FieldsMapper } from '../../components/Common';
import { modal, notifications, apiTable } from '../../actions';
import { __ } from '../../services/translation';

import ApiService from '../../services/api-service';
import { formOnTranslateString } from '../../utils/trans-form';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalHeader from '../../components/Common/Modal/ModalHeader';

interface Props {
  user: UserListForAdmin | CheaterUser;
}

const BlockUserModal = ({ user }: Props) => {
  const dispatch = useDispatch();

  return (
    <>
      <ModalContent>
        <ModalHeader>
          <ModalTitle title="application.block_title" />
        </ModalHeader>
        <p>{__('application.block_confirmation')}</p>
        <strong>{user.email}</strong>
        <FormContainer>
          <ItfApiForm
            formId="blockUserForm"
            schemaMayBeDynamic={false}
            schemaCacheEnabled={false}
            loadingInfoAboveContent
            sendDelay={200}
            loadingInfo={<Spinner overlay halfTransparent />}
            schemaFetcher={() => ApiService.loadForm('user/form/block-user')}
            sendFetcher={(formId: string, values: UserBlockRequest) =>
              ApiService.sendForm(`user/block/${user.id}`, values, 'PATCH')
            }
            submitButton={
              <ButtonsContainer>
                <Button click={() => dispatch(modal.hideModal())} small text="application.cancel" />
                <Button type="submit" secondary text="application.confirm" />
              </ButtonsContainer>
            }
            onSavedSuccessfully={() => {
              dispatch(notifications.successNotification('application.successfully_blocked_user'));
              dispatch(apiTable.refreshApiTable());
              dispatch(modal.hideModal());
            }}
            onTranslateString={formOnTranslateString}
            onRenderFullField={FieldsMapper}
          />
        </FormContainer>
      </ModalContent>
    </>
  );
};

export default BlockUserModal;
