import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { SendCollectionReq, UserContact } from 'app-types';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { ButtonsContainer, Container, FormContainer } from '../../components/Layout';
import {
  Button,
  ContactAutosuggestion,
  FieldsMapper,
  RequiredIndicator,
  SelectWithSuggestion,
  Spinner,
} from '../../components/Common';
import ApiService from '../../services/api-service';
import { formOnTranslateString } from '../../utils/trans-form';
import { flatpickr, ReactSelect } from '../../App';
import { __ } from '../../services/translation';
import { modal, notifications } from '../../actions';

interface Props {
  data: {
    startAt: Date;
    endAt: Date;
    withInvoices: boolean;
    withMileages: boolean;
    withTimesheets: boolean;
    withCosts: boolean;
    withOtherIncome: boolean;
    withDocuments: boolean;
  };
}

const SendDocuments = ({ data }: Props) => {
  const dispatch = useDispatch();

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.user_details" />
      </ModalHeader>

      <FormContainer>
        <ItfApiForm
          formId="send-to-accountant"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm('mail/form/send-collection')}
          sendFetcher={(formId: string, values: SendCollectionReq) =>
            ApiService.sendForm('mail/send-collection', { ...values, ...data }, 'POST')
          }
          onSavedSuccessfully={() => {
            dispatch(notifications.successNotification('application.sended'));
            dispatch(modal.hideModal());
          }}
          loadingInfo={<Spinner />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="button"
                text="application.cancel"
                noShadow
                click={() => {
                  dispatch(modal.hideModal());
                }}
              />
              <Button type="submit" text="application.submit" noShadow primary />
            </ButtonsContainer>
          }
          onTranslateString={formOnTranslateString}
          thirdPartyComponents={{
            flatpickr,
            ReactSelect,
            ReactCreatableSelect: SelectWithSuggestion,
          }}
          onRenderFullField={(
            field,
            errors,
            fieldHtmlId,
            currentValue,
            onChange,
            onFocus,
            onBlur,
          ) => {
            if (field.id === 'email') {
              return (
                <ContactAutosuggestion
                  required={field.required}
                  label={field.title}
                  input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange,
                  }}
                  onOptionSelect={(data: UserContact) => onChange(data.email)}
                  placeholder={field.placeholder}
                  meta={{
                    touched: errors.length > 0,
                    error: errors.length
                      ? __(errors[0].messageWithPlaceholders, errors[0].params)
                      : null,
                  }}
                />
              );
            } else {
              return FieldsMapper(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            }
          }}
        />
      </FormContainer>
    </ModalContent>
  );
};

export default SendDocuments;
