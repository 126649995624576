import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import ApiService from '../../../services/api-service';
import { Button } from '..';
import './OptionalImage.scss';
import { DownloadService } from '../../../services/download-service';

interface Props {
  url: string;
  alt: string;
  buttonLabel?: string;
}

const OptionalImage = ({ url, alt, buttonLabel }: Props) => {
  const [imgAvalible, setImgAvalible] = useState(true);
  const imgError = () => {
    setImgAvalible(false);
  };

  const downloadCost = async () => {
    await DownloadService.download(`${url}`);
  };

  if (!imgAvalible) {
    return (
      <div className="optional-image-wrapper">
        <div className="attachment-info">
          <FontAwesomeIcon icon={faFilePdf} size="3x" />
          <Button text={buttonLabel || 'application.see_cost'} click={downloadCost} />
        </div>
      </div>
    );
  }
  return (
    <button className="optional-image-wrapper download" onClick={downloadCost}>
      <img src={`${ApiService.url}${url}`} alt={alt} onError={imgError} />
    </button>
  );
};

export default OptionalImage;
