import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import { __ } from '../../services/translation';

import { Button } from '../../components/Common';
import { ButtonsContainer } from '../../components/Layout';
import { hideModal } from '../../actions/modal';
import { RootState } from '../../reducers';

interface Props {
  year: number;
}

const InvoiceNumerationAlert = ({ year }: Props) => {
  const dispatch = useDispatch();
  const location = useHistory();
  const { managedUser } = useSelector((state: RootState) => state.accounting);
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.invoice_numeration_alert" />
      </ModalHeader>
      <p>
        {__(
          managedUser
            ? 'application.invoice_numeration_alert_description_client'
            : 'application.invoice_numeration_alert_description',
          { year },
        )}
      </p>
      <ButtonsContainer>
        <Button text="application.cancel" click={() => dispatch(hideModal())} />
        <Button
          secondary
          text="application.settings"
          click={() => {
            location.push(
              managedUser
                ? '/dashboard/client-data/invoice-number'
                : '/dashboard/profile/edit/invoice-number',
            );
            dispatch(hideModal());
          }}
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default InvoiceNumerationAlert;
