import React from 'react';
import { Language, UsersByLanguage } from 'app-types';
import { InfoParagraph, Subheader } from '../../../../../components/Common';

interface Props {
  byLanguage: UsersByLanguage;
}

const ByLanguage = ({ byLanguage }: Props) => (
  <>
    <Subheader highlight text="admin.active_users_by_language" />
    {Object.keys(byLanguage).map((lang) => (
      <InfoParagraph key={lang} name={`application.${lang}`} text={byLanguage[lang as Language]} />
    ))}
  </>
);

export { ByLanguage };
