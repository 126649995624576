import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import AddBlank from './AddOffer/AddBlank';
import { OffersList } from './List';
import { RouteClientAccountant } from '../../../components/Routes';

import Dashboard from '../../DashboardCommon';
import Edit from './AddOffer/Preview/Edit';
import EditOffer from './EditOffer';
import SingleOffer from './SingleOffer';
import SendOffer from './Send';
import OfferPreview from './AddOffer/Preview';

const offerTabs = [
  {
    title: 'application.invoices',
    link: '/dashboard/invoices',
    icon: 'back',
  },
  {
    title: 'application.add_offer',
    link: '/dashboard/offers/add',
    icon: 'plus',
  },
  {
    title: 'application.offers',
    link: '/dashboard/offers/list',
    icon: 'list',
  },
];

const Offers = () => (
  <>
    <Page light withNavigation>
      <Dashboard navigation={offerTabs}>
        <Switch>
          <Route
            exact
            path="/dashboard/offers"
            render={() => <Redirect to="/dashboard/offers/list" />}
          />
          <RouteClientAccountant exact path="/dashboard/offers/list" component={OffersList} />
          <RouteClientAccountant
            needsFullData
            exact
            path="/dashboard/offers/add"
            component={AddBlank}
          />
          <RouteClientAccountant
            needsFullData
            exact
            path="/dashboard/offers/add/preview"
            component={OfferPreview}
          />
          <RouteClientAccountant
            needsFullData
            exact
            path="/dashboard/offers/add/preview/edit"
            component={Edit}
          />
          <RouteClientAccountant
            needsFullData
            exact
            path="/dashboard/offers/edit/:id"
            component={EditOffer}
          />
          <RouteClientAccountant
            needsFullData
            exact
            path="/dashboard/offers/:id"
            component={SingleOffer}
          />
          <RouteClientAccountant
            needsFullData
            exact
            path="/dashboard/offers/send/:id"
            component={SendOffer}
          />
        </Switch>
      </Dashboard>
    </Page>
  </>
);

export default Offers;
