import React from 'react';
import { MileageCarRes } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { MileageCarForm } from '../../components/Common';
import { FormContainer } from '../../components/Layout';

interface Props {
  id: string;
  car: MileageCarRes;
}
const EditMileageCar = ({ id, car }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_car" />
    </ModalHeader>
    <FormContainer>
      <MileageCarForm id={id} defaultValues={car} />
    </FormContainer>
  </ModalContent>
);

export default EditMileageCar;
