import { FieldType, TableSchema } from '../typings';

const statusToText: {
  [key: number]: string;
} = {
  0: 'payment_status.open',
  1: 'payment_status.canceled',
  2: 'payment_status.pending',
  3: 'payment_status.authorized',
  4: 'payment_status.expired',
  5: 'payment_status.failed',
  6: 'payment_status.paid',
};

const typeToText: {
  [key: number]: string;
} = {
  0: 'application.business_one_person',
  1: 'application.business_vof',
};

export const certificateForAdmin: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.date',
      field: 'date',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
    },
    {
      name: 'application.business_type',
      field: 'businessType',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      customValue: value => typeToText[value.businessType],
      searchableValues: typeToText,
      translate: true,
    },
    {
      name: 'application.company_name',
      field: 'companyName',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'application.last_name',
      field: 'lastName',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'application.rsin',
      field: 'rsin',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'application.bsn',
      field: 'bsn',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'application.payment_status',
      field: 'paymentStatus',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      customValue: value => statusToText[value.paymentStatus],
      searchableValues: statusToText,
      translate: true,
    },
    {
      name: 'application.language',
      field: 'language',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
  ],
};
