import { FieldType, TableSchema } from '../typings';
const statusToText: {
  [key: number]: string;
} = {
  0: 'payment_status.open',
  1: 'payment_status.canceled',
  2: 'payment_status.pending',
  3: 'payment_status.authorized',
  4: 'payment_status.expired',
  5: 'payment_status.failed',
  6: 'payment_status.paid',
};

export const paymentsForAdmin: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.date',
      field: 'date',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
      alias: 'p',
      oppositeSortDir: true,
    },
    {
      name: 'application.invoice_number',
      field: 'invoiceNumber',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Text,
      alias: 'p',
    },
    {
      name: 'application.company_name',
      field: 'companyName',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Text,
      alias: 'o',
    },
    {
      name: 'application.email',
      field: 'email',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Email,
      alias: 'o',
    },
    {
      name: 'application.price',
      field: 'amount',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Price,
      alias: 'p',
    },
    {
      name: 'application.is_payment_manually_approved',
      field: 'isManuallyApproved',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Boolean,
      alias: 'p',
    },
    {
      name: 'application.payment_status',
      field: 'status',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      customValue: value => statusToText[value.status],
      searchableValues: statusToText,
      translate: true,
      alias: 'p',
    },
  ],
};
