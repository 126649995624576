import React from 'react';
import { CategoryRes } from 'app-types';

import './Suggestion.scss';

interface Props {
  item: CategoryRes;
  onSelect: (data: CategoryRes) => void;
}

const Suggestion = ({ item, onSelect }: Props) => (
  <li
    role="button"
    onClick={() => {
      onSelect(item);
    }}
    className="suggestion-wrapper"
  >
    <div>
      <span className="category-suggestion-name">{item.title}</span>
    </div>
  </li>
);

export default Suggestion;
