import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserLabel, UserLabelReq } from 'app-types';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { ButtonsContainer } from '../../components/Layout';
import { Button, Spinner } from '../../components/Common';
import ApiService from '../../services/api-service';
import { apiTable, modal, notifications } from '../../actions';

interface Props {
  userId: string;
}

const SelectLabel = ({ userId }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const save = async (label: UserLabel) => {
    const req: UserLabelReq = {
      label,
    };
    await ApiService.callFetch(
      'PATCH',
      `user/label/${userId}`,
      () => {
        dispatch(notifications.successNotification('application.successfully_updated_user_label'));
        dispatch(modal.hideModal());
        dispatch(apiTable.refreshApiTable());
      },
      null,
      req,
    );
    setLoading(false);
  };
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.user_label" />
      </ModalHeader>
      {loading && <Spinner halfTransparent overlay />}
      <ButtonsContainer center>
        <Button text="application.user_heyzzp" click={() => save(UserLabel.HEYZZP)} />
        <Button text="application.user_wak" click={() => save(UserLabel.WAK)} />
        <Button text="application.user_internal" click={() => save(UserLabel.INTERNAL)} />
      </ButtonsContainer>
      <ButtonsContainer marginTop>
        <Button small text="application.cancel" click={() => dispatch(modal.hideModal())} />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default SelectLabel;
