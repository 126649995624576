import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CostEntity, TaxRate } from 'app-types';
import { Field, getFormMeta } from 'redux-form';
import { modal } from '../../../../../../actions';
import {
  Input,
  Subheader,
  FileInput,
  SingleCostPreview,
  ListElementWrapper,
  ListElementsWrapper,
} from '../../../../../../components/Common';
import ValidationService from '../../../../../../services/validation-service';
import { __ } from '../../../../../../services/translation';
import { ApplicationState } from '../../../../../../reducers';

interface Props {
  fields: any;
  meta: any;
  isDirty: boolean[];
  data: any;
  showModal: (content: React.ReactNode) => void;
  hideModal: () => void;
}

class RenderCosts extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  private isCostValid = (cost: any, index: number) => {
    const { isDirty } = this.props;
    if (!isDirty[index]) return true;
    if (cost.id) return true;
    if (!cost.name) return false;
    if (cost.file || cost.value) return true;
    return true;
  };

  render() {
    const { fields, data } = this.props;

    return (
      <>
        {/* <AddCostButton /> */}
        <ListElementsWrapper empty={fields.length === 0}>
          <Subheader text="application.costs" />
          {fields.map(
            (cost: any, index: any) =>
              data[index] && (
                <li
                  key={data[index].id || data[index].uuid}
                  id={`cost-${data[index].id || data[index].uuid}`}
                >
                  {data[index].id && (
                    <ListElementWrapper
                      header={`${data[index].name}`}
                      price={data[index].value}
                      icon="cross"
                      click={() => fields.remove(index)}
                    >
                      <SingleCostPreview cost={data[index]} />
                      <Field
                        name={`${cost}.id`}
                        component={Input}
                        type="hidden"
                        label="application.id"
                        validate={ValidationService.required}
                        required
                      />
                    </ListElementWrapper>
                  )}
                  {!data[index].id && (
                    <ListElementWrapper
                      header={data[index].name ? data[index].name : __('application.new_cost')}
                      price={data[index].value}
                      icon="cross"
                      click={() => fields.remove(index)}
                      invalid={!this.isCostValid(data[index], index)}
                    >
                      <Field
                        name={`${cost}.file`}
                        component={FileInput}
                        accept=".jpg,.jpeg,.png,.pdf"
                        validate={ValidationService.costOrFile('value', index)}
                      />
                      <Field
                        name={`${cost}.name`}
                        component={Input}
                        label="application.name"
                        validate={ValidationService.required}
                        required
                        placeholder="application.cost_placeholder"
                      />
                      <Field
                        name={`${cost}.value`}
                        component={Input}
                        label="application.value"
                        validate={ValidationService.costOrFile('file', index)}
                        min={0}
                        type="cost"
                        placeholder="application.value_placeholder"
                      />
                      <Field
                        name={`${cost}.taxRate`}
                        component={Input}
                        label="application.cost_tax_rate"
                        validate={ValidationService.required}
                        options={[
                          {
                            name: '21%',
                            value: TaxRate['21%'],
                          },
                          {
                            name: '9%',
                            value: TaxRate['9%'],
                          },
                          {
                            name: '0%',
                            value: TaxRate['0%'],
                          },
                          {
                            name: 'BTW Verlegd',
                            value: TaxRate['BTW Verlegd'],
                          },
                          {
                            name: 'BTW vrijgesteld',
                            value: TaxRate['BTW vrijgesteld'],
                          },
                        ]}
                        type="select"
                      />
                    </ListElementWrapper>
                  )}
                </li>
              ),
          )}
        </ListElementsWrapper>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      showModal: modal.showModal,
      hideModal: modal.hideModal,
    },
    dispatch,
  );

const anyTouched = (cost: any) => cost.name || cost.value || cost.date || cost.taxRate;

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const meta: any = getFormMeta('invoiceForm')(state);
  return {
    isDirty: ownProps.fields.map(
      (cost: CostEntity, index: number) =>
        meta.costs && meta.costs[index] && anyTouched(meta.costs[index]),
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderCosts);
