import React from 'react';
import { TableSchema, timeSheetTemplates } from 'app-tables';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { ApiList } from '../../../../components/Common';
import TimeSheetSchemeListItem from './TimeSheetSchemeListItem';
import useHeader from '../../../../hooks/useHeader';

const TimeSheetList = () => {
  useHeader('application.time_sheet_schemes', '/dashboard/time-sheets');
  const scheme: TableSchema = new InteractiveTableSchema(timeSheetTemplates);

  return (
    <>
      <ApiList
        apiEndpointSubUrl="time-sheet/template/list"
        scheme={scheme}
        component={TimeSheetSchemeListItem}
        padded
        noSearch
      />
    </>
  );
};
export default TimeSheetList;
