import React from 'react';
import { useDispatch } from 'react-redux';
import { GetClientKvKRes } from 'app-types';
import { __ } from '../../../../services/translation';
import { Icon } from '../..';

import './NewClientSuggestion.scss';

interface Props {
  name: string;
  onNewClientSelect: (name: string) => void;
}

const NewClientSuggestion = ({ name, onNewClientSelect }: Props) => (
  <li
    role="button"
    onClick={() => {
      onNewClientSelect(name);
    }}
    className="new-client-suggestion-wrapper"
  >
    <Icon icon="plus" className="add-client-icon" />
    <span className="new-client-name">{name}</span>
  </li>
);

export default NewClientSuggestion;
