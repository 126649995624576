import React from 'react';
import { TableSchema, clientsForUser } from 'app-tables';
import { InteractiveTableSchema } from '../../../../../utils/table-shcema-utils';
import { ApiList } from '../../../../../components/Common';

import ClientItem from '../Item';
import useHeader from '../../../../../hooks/useHeader';

interface Props {
  toInvoice?: boolean;
}

const ClientList = ({ toInvoice }: Props) => {
  const scheme: TableSchema = new InteractiveTableSchema(clientsForUser);

  const backButton = toInvoice ? '/dashboard/invoices/list' : '/';
  useHeader('application.client_list', backButton, {
    title: 'application.client_list',
    text: 'annotations.clients',
  });
  return (
    <>
      <ApiList apiEndpointSubUrl="client/list" scheme={scheme} component={ClientItem} />
    </>
  );
};

export default ClientList;
