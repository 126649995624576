import React from 'react';
import { useDispatch } from 'react-redux';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { __ } from '../../services/translation';
import { ButtonsContainer } from '../../components/Layout';
import { Button } from '../../components/Common';
import { modal } from '../../actions';

interface Props {
  callback: () => void;
}

const GPSMobileInfo = ({ callback }: Props) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(modal.hideModal());
    callback();
  };
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.gps_mobile_info" />
      </ModalHeader>
      <p>{__('application.gps_mobile_description')}</p>
      <ButtonsContainer marginTop>
        <Button primary text="application.ok" click={handleClick} />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default GPSMobileInfo;
