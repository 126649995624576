import React from 'react';
import { faFileInvoice, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { isAfter } from 'date-fns';
import { ApplicationState } from 'src/reducers';
import { InvoiceListRes, UserRole } from 'app-types';
import { Button, ListItem, ListItemBottomContainer, ListItemWrapper, IsPaidSwitch } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import { formatDate } from '../../../../../utils/format-date';
import { formatPrice } from '../../../../../utils/format-price';
import { DownloadService } from '../../../../../services/download-service';
import { SentStatus } from '../../../../../components/Common/SentStatus';
import { getUnpaidInvoicesCount } from '../../../../../actions/invoice';
import './InvoiceListItem.scss';

interface Props {
    item: InvoiceListRes;
    id?: string;
    date: string;
}

const InvoiceListItem = ({ item }: Props) => {
    const dispatch = useDispatch();
    const userDetails = useSelector((state: ApplicationState) => state.user.details!);

    const updatePaidCount = () => {
        dispatch(getUnpaidInvoicesCount());
    }

    const creditnotaButton = () =>
        !item.isCreditNote ? (
            <Button
                small
                to={`/dashboard/invoices/creditnota/${item.id}`}
                text={__('application.creditnote')}
                noShadow
            />
        ) : null;

    const isExpired = () => {
        return !item.isPaid && isAfter(new Date(), new Date(item.paymentTerm));
    };

    const paragraphs = [
        { icon: 'time_sheets', text: formatDate(item.createdAt) },
        { icon: 'pencil', text: item.mark },
        { icon: 'costs', text: formatPrice(item.totalVal + item.totalTaxVal) },
        {
            icon: faHourglassEnd,
            text: `${__('application.payment_term_exceeded')}`,
            warning: true,
            hide: !isExpired(),
        },
        { icon: 'invoices', text: `${__('application.creditnote')}`, hide: !item.isCreditNote },
    ];

    const downloadPDF = async () => {
        await DownloadService.download(`invoice/pdf/${item.id}`);
    };

    return (
        <ListItemWrapper>
            <ListItem
                header={item.number}
                subheader={item.client}
                icon={faFileInvoice}
                paragraphs={paragraphs}
                headerRight={(item.sentTo || []).length > 0 ? <SentStatus /> : null}
                buttons={[
                    {
                        text: 'application.details',
                        to: `/dashboard/invoices/${item.id}`,
                    },
                    {
                        text: 'application.download_pdf',
                        click: downloadPDF,
                        type: 'secondary',
                    },
                    {
                        text: 'application.edit',
                        to: `/dashboard/invoices/edit/${item.id}`,
                        type: 'secondary',
                    },
                    {
                        text: 'application.send_to_client',
                        to: `/dashboard/invoices/send/${item.id}`,
                        type: 'success',
                    },
                ]}
                noShadow
            />
            {userDetails.role !== UserRole.IncomeAndCost && (
                <ListItemBottomContainer>
                    <IsPaidSwitch url={`invoice/trigger-payment-mark/${item.id}`} paid={item.isPaid} callback={updatePaidCount} label='application.is_paid?' />
                    {creditnotaButton()}
                </ListItemBottomContainer>
            )}
        </ListItemWrapper>
    );
};

export { InvoiceListItem };