import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { SomeUserEntity, UserRole } from 'app-types';
import { menu } from '../../../actions';
import ChangeLanguage from './ChangeLanguage';
import TrialIndicator from './TrialIndicator';
import { CompanyLogo, Logo, HamburgerButton, Icon } from '../../Common';
import { ApplicationState } from '../../../reducers';
import TrackingPreview from './TrackingPreview';
import LocationPreview from './LocationPreview';
import './TopHeader.scss';
import Notifications from './Notifications';
import { __ } from '../../../services/translation';

interface Props extends RouteComponentProps {
  toggleMenu: () => void;
  user: SomeUserEntity | null;
  language: string;
  toggled?: boolean;
  trial?: boolean;
  hideTrialIndicator: boolean;
  animate?: boolean;
}

class TopHeader extends Component<Props> {
  private trialindicator = () => {
    const { user, hideTrialIndicator } = this.props;
    return user && user.role === UserRole.Client && user.trial && !hideTrialIndicator ? (
      <TrialIndicator />
    ) : null;
  };

  render() {
    const { user, animate } = this.props;
    return !user ? (
      <header className="main-header-unauthorized">
        <ChangeLanguage />
      </header>
    ) : (
      <>
        <header className="main-header">
          {/* {this.hamburgerOrBackButton()} */}
          <HamburgerButton />
          <div className="header-logo-cont">
            <Logo animate={animate} />
          </div>
          <div className="header-inner-wrapper-end">
            {user.role === UserRole.Client && user.isActiveSub === true && !user.isBlocked && (
              <TrackingPreview />
            )}
            {user.role === UserRole.Client && user.isActiveSub === true && !user.isBlocked && (
              <LocationPreview />
            )}
            <div className="header-user-info">
              <CompanyLogo desktop small />
              <Link to="/dashboard/profile" className="settings-link">
                <Icon icon="settings" />
              </Link>
            </div>
            {/* <LogoutButton /> */}
            <ChangeLanguage />
            <a className="help-link" target="_blank" href="https://heyzzp.nl/help" rel="noreferrer">
              <Icon icon="help" />
            </a>
            {[UserRole.Client, UserRole.Accountant].includes(user.role) && <Notifications />}
          </div>
        </header>
        {this.trialindicator()}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  toggled: state.menu.toggled,
  user: state.user.details,
  hideTrialIndicator: state.menu.hideTrialIndicator,
  language: state.lang.current,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ toggleMenu: menu.toggleMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopHeader));
