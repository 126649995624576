import React from 'react';
import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
} from 'itf_formbuilder_react';
import { RouteComponentProps } from 'react-router';
import ReactSelect from 'react-select';
import { useDispatch } from 'react-redux';
import { UserContact } from 'app-types';
import ApiService from '../../../../services/api-service';
import {
  Spinner,
  Button,
  FieldsMapper,
  RequiredIndicator,
  SelectWithSuggestion,
  Subheader,
  ContactAutosuggestion,
  Paragraph,
} from '../../../../components/Common';
import { FormContainer, ButtonsContainer } from '../../../../components/Layout';
import { notifications } from '../../../../actions';
import { formOnTranslateString } from '../../../../utils/trans-form';
import useHeader from '../../../../hooks/useHeader';
import { __ } from '../../../../services/translation';

interface RouteProps {
  year: string;
}
type Props = RouteComponentProps<RouteProps>;

const SendMileage = ({ match }: Props) => {
  useHeader('application.send_mileage');
  const dispatch = useDispatch();
  return (
    <>
      <Subheader level={1} text={match.params.year} />
      <FormContainer>
        <ItfApiForm
          formId="send-mileage"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(`mail/form/send/${match.params.year}/mileage`)}
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm(`mail/send-summary-mileage/${match.params.year}`, values, 'POST')
          }
          onSavedSuccessfully={() => {
            dispatch(notifications.successNotification('application.mileage_sent'));
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button type="submit" text="application.submit" primary />
            </ButtonsContainer>
          }
          thirdPartyComponents={{ ReactSelect, ReactCreatableSelect: SelectWithSuggestion }}
          onTranslateString={formOnTranslateString}
          onRenderFullField={(
            field: FormFieldToRender,
            errors: any,
            fieldHtmlId: string,
            currentValue: any,
            onChange: ChangeFunction,
            onFocus: FieldAndEventFunction,
            onBlur: FieldAndEventFunction,
          ) => {
            if (field.id === 'email') {
              return (
                <ContactAutosuggestion
                  required={field.required}
                  label={field.title}
                  input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange,
                  }}
                  onOptionSelect={(data: UserContact) => onChange(data.email)}
                  placeholder={field.placeholder}
                  meta={{
                    touched: errors.length > 0,
                    error: errors.length
                      ? __(errors[0].messageWithPlaceholders, errors[0].params)
                      : null,
                  }}
                />
              );
            } else if (field.id === 'title') {
              return (
                <>
                  <Paragraph strong translation="application.mileage_message_description" />
                  {FieldsMapper(
                    field,
                    errors,
                    fieldHtmlId,
                    currentValue,
                    onChange,
                    onFocus,
                    onBlur,
                  )}
                </>
              );
            } else {
              return FieldsMapper(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            }
          }}
        />
      </FormContainer>
    </>
  );
};

export default SendMileage;
