import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ClientListForAdmin from './ClientListForAdmin';
import ChangePassword from './ChangePassword';
import NewUsers from './NewUsers';
import CheatersList from './CheatersList';
import AllPayments from './AllPayments';
import Notifications from './Notifications';
import Certificates from './Certificates';
import InvoiceNumber from './InvoiceNumber';
import Stats from './Stats';
import AccountantsListForAdmin from './AccountantsListForAdmin';
import Settings from './Settings';

const DashboardAdmin = () => (
  <Switch>
    <Route exact path="/admin" render={() => <Redirect to="/admin/client-list" />} />
    <Route exact path="/admin/client-list" component={ClientListForAdmin} />
    <Route exact path="/admin/accountant-list" component={AccountantsListForAdmin} />
    <Route path="/admin/payments/" component={AllPayments} />
    <Route exact path="/admin/change-password" component={ChangePassword} />
    <Route exact path="/admin/cheaters" component={CheatersList} />
    <Route path="/admin/notifications" component={Notifications} />
    <Route path="/admin/certificate-requests" component={Certificates} />
    <Route exact path="/admin/edition-request" component={NewUsers} />
    <Route exact path="/admin/invoice-number" component={InvoiceNumber} />
    <Route path="/admin/stats" component={Stats} />
    <Route path="/admin/settings" component={Settings} />
  </Switch>
);

export default DashboardAdmin;
