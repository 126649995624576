import React, { useEffect } from 'react';
import { change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { ClientEntity, ClientType } from 'app-types';
import { getSingleClient } from '../../actions/client';
import { modal } from '../../actions';
import { ApplicationState } from '../../reducers';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { Spinner, Button, ClientDetails, Icon } from '../../components/Common';
import './SingleClientPreview.scss';

interface Props {
  id: string;
  forForm?: 'invoiceForm' | 'offerForm';
  noUse?: boolean;
}

const SingleClientPreview = ({ id, noUse, forForm }: Props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: ApplicationState) => state.client.loadingSingleClient);
  const client: ClientEntity = useSelector((state: ApplicationState) => state.client.singleClient);

  useEffect(() => {
    dispatch(getSingleClient(id));
  }, []);

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.client" />
        <div className="client-type-icon">
          {client.type === ClientType.COMPANY ? (
            <Icon icon="company_client" />
          ) : (
            <Icon icon="private_client" />
          )}
        </div>
      </ModalHeader>
      {loading && <Spinner overlay />}
      <ClientDetails client={client} />
      {!noUse && forForm && (
        <Button
          text="application.use"
          success
          click={() => {
            dispatch(modal.hideModal());
            dispatch(change(forForm, 'client.name', `${client.name}`));
            dispatch(change(forForm, 'client.street', `${client.address}`));
            dispatch(change(forForm, 'client.city', `${client.city}`));
            dispatch(change(forForm, 'client.kvk', `${client.kvk}`));
            dispatch(change(forForm, 'client.btw', `${client.kvk}`));
          }}
        />
      )}
    </ModalContent>
  );
};

export default SingleClientPreview;
