import React from 'react';
import { useDispatch } from 'react-redux';
import { AccountantConnectorDetails, AccountantConnectorStatus } from 'app-types';
import { Button, Subheader, Spinner, Alert } from '..';
import { ButtonsContainer } from '../../Layout';

import { __ } from '../../../services/translation';
import ApiService from '../../../services/api-service';
import { notifications, modal } from '../../../actions';
import { Confirmation } from '../../../modals';
import './List.scss';

interface Props {
  managedUsers: AccountantConnectorDetails[] | undefined;
  getMine: () => void;
  subHeader?: string;
  customNoDataMessage?: string;
}

const InvitationsList = ({ getMine, managedUsers, subHeader, customNoDataMessage }: Props) => {
  const dispatch = useDispatch();

  const remove = async (connectorId: string) => {
    await ApiService.callFetch('DELETE', `accountant-connector/${connectorId}`, () => {
      dispatch(notifications.successNotification('application.managed_user_removed'));
      dispatch(modal.hideModal());
      getMine();
    });
  };

  const accept = async (connectorId: string) => {
    await ApiService.callFetch('PATCH', `accountant-connector/accept/${connectorId}`, () => {
      dispatch(notifications.successNotification('application.managed_user_accepted'));
      dispatch(modal.hideModal());
      getMine();
    });
  };

  const confirmRemove = (id: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          confirmCallback={() => remove(id)}
          text="application.confirm_managed_user_remove"
        />,
      ),
    );
  };

  const confirmAccept = (id: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          confirmCallback={() => accept(id)}
          text="application.confirm_managed_user_approve"
        />,
      ),
    );
  };

  if (!managedUsers) return <Spinner />;

  return (
    <div className="managed-users-list">
      {subHeader && <Subheader text={subHeader} />}
      {managedUsers.length === 0 && (
        <Alert type="notice" text={customNoDataMessage ?? 'application.no_invitations'} />
      )}
      <ul>
        {managedUsers.map((child) => (
          <li>
            <div>
              <strong>{child.name}</strong>
            </div>
            <span>{child.email}</span>
            <div className="accountant-connector-status">
              {child.status === AccountantConnectorStatus.ACTIVE
                ? __('application.connector_approved')
                : __('application.connector_pending')}
            </div>
            <ButtonsContainer>
              {child.status === AccountantConnectorStatus.WAITING_FOR_ACCOUNTANT_CONFIRMATION && (
                <Button
                  small
                  text="application.accept"
                  success
                  click={() => confirmAccept(child.id)}
                />
              )}
              <Button
                small
                text="application.delete"
                danger
                click={() => confirmRemove(child.id)}
              />
            </ButtonsContainer>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InvitationsList;
