import { FieldType, TableSchema } from '../typings';

export const accountantsListForAdmin: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.email',
      field: 'email',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
      alias: 'u',
    },
    {
      name: 'application.createdAt',
      field: 'createdAt',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Date,
      alias: 'u',
    },
    {
      name: 'application.servedUsersCount',
      field: 'servedUsersCount',
      mobile: true,
      searchable: false,
      sortable: false,
      defaultSort: false,
      type: FieldType.Text,
    },
  ],
};
