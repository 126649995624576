import clsx from 'clsx';
import React from 'react';
import { InvoiceListRes } from '../../../../../../../heyzzp-back/types';
import { InvoiceItem } from '../../InvoiceItem';
import './SelectableInvoiceItem.scss';

interface Props {
  onSelect: (item: InvoiceListRes) => void;
  isSelected: boolean;
  item: InvoiceListRes;
}

const SelectableInvoiceItem = ({ onSelect, isSelected, item }: Props) => (
  <div
    role="button"
    className={clsx('selectable-invoice-item', { selected: isSelected })}
    onClick={() => onSelect(item)}
  >
    <InvoiceItem item={item} />
  </div>
);

export { SelectableInvoiceItem };
