import { mileageForUserTable } from 'app-tables';
import React, { FC } from 'react';
import { MileageEntity } from 'app-types';
import { InteractiveTableSchema } from '../../utils/table-shcema-utils';
import { ApiList } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import SingleItem from './SingleItem';

interface Props {
  onClick: (data: MileageEntity) => void;
  attachedMileageIds: string[];
}

const LinkMileageToInvoiceModal: FC<Props> = ({ onClick, attachedMileageIds }) => {
  const scheme = new InteractiveTableSchema(mileageForUserTable);

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.mileage_list" />
      </ModalHeader>
      <ApiList
        apiEndpointSubUrl={`mileage/list/attach-to-invoice/${JSON.stringify(attachedMileageIds)}`}
        scheme={scheme}
        component={({ item }: { item: MileageEntity }) => (
          <SingleItem item={item} onClick={onClick} />
        )}
        padded
      />
    </ModalContent>
  );
};

export default LinkMileageToInvoiceModal;
