export interface PendingInvitationsState {
  count: number;
}

const initialState: PendingInvitationsState = {
  count: 0,
};

interface GetPendingInvitationsCount {
  type: 'GET_PENDING_INVITATIONS_COUNT';
  payload: number;
}

type Action = GetPendingInvitationsCount;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_PENDING_INVITATIONS_COUNT':
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
};
