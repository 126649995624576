import React, { useEffect } from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { useDispatch } from 'react-redux';
import { ButtonsContainer, FormContainer } from '../../../components/Layout';
import { Button, FieldsMapper, RequiredIndicator, Spinner } from '../../../components/Common';
import { formOnTranslateString } from '../../../utils/trans-form';
import { history } from '../../../App';
import { apiList, modal, notifications } from '../../../actions';
import ApiService from '../../../services/api-service';
import { __ } from '../../../services/translation';
import { CategoryReq, CategoryRes } from '../../../../../heyzzp-back/types';
import CategoryAutosuggestion from '../CategoryAutosuggestion';

interface Props {
  formUrl: string;
  formSendUrl: string;
  method: string;
  callback?: (data: any) => void;
  buttonText?: string;
  name?: string;
}

const CategoryForm = ({ formUrl, formSendUrl, method, callback, buttonText, name }: Props) => {
  const dispatch = useDispatch();

  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  return (
    <>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="cost-scheme-form"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(formUrl)}
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm(formSendUrl, values, method)
          }
          onSavedSuccessfully={(data: CategoryRes) => {
            if (callback) {
              dispatch(notifications.successNotification('application.category_edited'));
              dispatch(modal.hideModal());
              callback(data);
            } else {
              dispatch(notifications.successNotification('application.category_created'));
              history.push('/dashboard/category');
            }
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text={buttonText || 'application.save'}
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={(string: string) => formOnTranslateString(string)}
          onRenderFullField={FieldsMapper}
        />
      </FormContainer>
    </>
  );
};

export default CategoryForm;
