import React from 'react';
import { TableSchema, requestSettlementLog } from 'app-tables';
import { ApiList, Subheader } from '../../../../components/Common';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import LogListItem from './Item';
import useHeader from '../../../../hooks/useHeader';
import './LogList.scss';

const LogList = () => {
  useHeader('application.invoices');
  const scheme: TableSchema = new InteractiveTableSchema(requestSettlementLog);

  return (
    <div className="settlement-logs">
      <Subheader text="application.settlement_log_header" />
      <ApiList
        apiEndpointSubUrl="settlement-request/list"
        scheme={scheme}
        component={LogListItem}
        noSearch
      />
    </div>
  );
};

export default LogList;
