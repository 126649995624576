import React, { useState } from 'react';
import { Alert, Tabs } from '../../../../components/Common';
import { Page, Container } from '../../../../components/Layout';
import IncomeStats from './Income';
import MileageStats from './Mileage';
import { SingleTab } from '../../../../types/tabs';
import { StatisticsTab, StatisticPeriod } from '../../../../types/statistics';
import useHeader from '../../../../hooks/useHeader';

const StatisticsOther = () => {
  useHeader('application.statistics');
  const [activeTab, setActiveTab] = useState<StatisticsTab>(StatisticsTab.INCOME);
  const [activePeriod, setActivePeriod] = useState<StatisticPeriod>(StatisticPeriod.THIS_YEAR);
  const tabs: SingleTab[] = [
    {
      label: 'application.income',
      value: StatisticsTab.INCOME,
    },
    {
      label: 'application.mileage',
      value: StatisticsTab.MILEAGE,
    },
  ];

  const secondaryTabs: SingleTab[] = [
    {
      label: 'application.this_year',
      value: StatisticPeriod.THIS_YEAR,
    },
    {
      label: 'application.all_time',
      value: StatisticPeriod.ALL,
    },
  ];

  return (
    <>
      <Tabs activeTab={activeTab} chooseTab={setActiveTab} tabs={tabs} />
      <Tabs activeTab={activePeriod} chooseTab={setActivePeriod} tabs={secondaryTabs} />
      <div className="statistics-container">
        {activeTab === StatisticsTab.INCOME ? (
          <IncomeStats period={activePeriod} />
        ) : (
          <MileageStats period={activePeriod} />
        )}
      </div>
    </>
  );
};

export default StatisticsOther;
