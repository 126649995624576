import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserRole } from 'app-types';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import AddCost from './Add/AddCost';
import CostList from './List';
import SingleCost from './SingleCost';
import AddCostFromScheme from './Add/AddFromScheme';
import { ApplicationState } from '../../../reducers';

const Costs = () => {

  const user = useSelector((state: ApplicationState) => state.user.details);

  const costTabs = [
    {
      title: 'application.cost_list',
      link: '/dashboard/costs/list',
      icon: 'costs',
    },
    {
      title: 'application.add_cost',
      link: '/dashboard/costs/add',
      icon: 'plus',
    },
    {
      title: 'application.cost_schemes',
      link: '/dashboard/costs-schemes/list',
      icon: 'list',
    },
    {
      title: 'application.categories',
      link: '/dashboard/categories/list',
      icon: 'list',
      indicator: true,
    },
    {
      title: 'application.cost_documents',
      link: '/dashboard/costs-documents/list',
      icon: 'list',
      indicator: true,
      inactive: user?.role === UserRole.IncomeAndCost,
    },
  ];

  return <>
    <Page light withNavigation>
      <Dashboard navigation={costTabs}>
        <Switch>
          <Route
            exact
            path="/dashboard/costs"
            render={() => <Redirect to="/dashboard/costs/list" />}
          />
          <Route exact path="/dashboard/costs/add/usescheme/:id" component={AddCostFromScheme} />
          <Route exact path="/dashboard/costs/add" component={AddCost} />
          <Route exact path="/dashboard/costs/list" component={CostList} />
          <Route exact path="/dashboard/costs/:id" component={SingleCost} />
        </Switch>
      </Dashboard>
    </Page>
  </>
}

export default Costs;
