import { Language } from 'app-types';

const dateObj = new Date();
const yearBefore = dateObj.getFullYear() - 1;
const currentYear = dateObj.getFullYear();
const day = dateObj.getDate();
const month = dateObj.getMonth() + 1;

export default {
  LANGUAGES: [
    {
      name: 'English',
      flag: '/assets/uk.png',
      shortcut: Language.en,
    },
    {
      name: 'Polski',
      flag: '/assets/poland.png',
      shortcut: Language.pl,
    },
    {
      name: 'Nederlands',
      flag: '/assets/nl.png',
      shortcut: Language.nl,
    },
  ],
  DEFAULT_LANG: Language.pl,
  MIN_DATE: `${yearBefore}-${month}-${day}`,
  MAX_DATE: `${currentYear}-${month}-${day}`,
  DATE_FORMAT: 'dd-MM-yyyy',
  FULL_DATE_FORMAT: 'EEEE, dd MMMM yyyy',
  TIME_FORMAT: 'HH:mm',
  DATE_TIME_FORMAT: 'dd-MM-yyyy HH:mm',
  YEARS_PERIOD_AVAILABLE: 2,
};
