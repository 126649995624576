import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { TableResponse } from 'app-tables';
import { useDispatch } from 'react-redux';
import {
    Alert,
    Button,
    InfoButton,
    NarrowParagraph,
    Spinner,
} from '../../../../components/Common';

import useHeader from '../../../../hooks/useHeader';
import { InvoiceListRes, ReminderType } from 'app-types';
import { SelectableInvoiceItem } from './SelectableInvoiceItem';
import ApiService from '../../../../services/api-service';
import { ButtonsContainer } from '../../../../components/Layout';
import { setReminderData } from '../../../../actions/reminders';
import { history } from '../../../../App';

const RemindersForClient = () => {
    useHeader('application.client_unpaid_invoices');
    const [selected, setSelected] = useState<string[]>([]);
    const [invoices, setInvoices] = useState<InvoiceListRes[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { clientId, clientKvk } = useParams<{ clientId: string; clientKvk: string }>();

    const dispatch = useDispatch();

    const toggleSelect = (item: InvoiceListRes) => {
        setSelected((prevSelected) => {
            if (prevSelected.includes(item.id)) {
                return prevSelected.filter((selectedId) => selectedId !== item.id);
            }
            return [...prevSelected, item.id];
        });
    };

    const getData = async () => {
        await ApiService.callFetch(
            'GET',
            `invoice/unpaid-by-client/${clientId}${clientKvk ? `/${clientKvk}` : ''}`,
            (data: TableResponse<InvoiceListRes>) => {
                setInvoices(data.items);
            },
        );
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    if (loading) return <Spinner />;
    if (invoices.length === 0) return <Alert text="application.no_data" type="notice" />;

    const prepareReminder = (type: ReminderType) => {
        dispatch(setReminderData(selected, type));
        history.push(`/dashboard/reminders/${clientId}/prepare${clientKvk ? `/${clientKvk}` : ''}`);
    };

    return (
        <>
            <NarrowParagraph text="application.unpaid_invoices_description" />
            {invoices.map((invoice) => (
                <SelectableInvoiceItem
                  key={invoice.id}
                  item={invoice}
                  isSelected={selected.includes(invoice.id)}
                  onSelect={toggleSelect}
                />
            ))}
            <ButtonsContainer>
                <Button
                  text="application.first_reminder"
                  disabled={selected.length === 0}
                  click={() => prepareReminder(ReminderType.FIRST)}
                />
                <Button
                  text="application.second_reminder"
                  disabled={selected.length === 0}
                  click={() => prepareReminder(ReminderType.SECOND)}
                />
                <Button
                  text="application.last_reminder"
                  disabled={selected.length === 0}
                  click={() => prepareReminder(ReminderType.LAST)}
                />
                <InfoButton text="application.last_reminder_info" header="application.last_reminder" />
            </ButtonsContainer>
        </>
    );
};

export { RemindersForClient };
