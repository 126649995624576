import React from 'react';
import './ListElementsWrapper.scss';
import clsx from 'clsx';

interface Props {
  children: React.ReactNode;
  empty: boolean;
}

const ListElementsWrapper = ({ children, empty }: Props) => (
  <ul className={clsx('list-elements-wrapper', { empty })}>{children}</ul>
);

export default ListElementsWrapper;
