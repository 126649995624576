import { CostRes, DocumentRes, TaxRate } from 'app-types';

interface LoadingSingleDocument {
  type: 'LOADING_SINGLE_DOCUMENT';
  payload: boolean;
}
interface GetSingleDocumentDetails {
  type: 'GET_SINGLE_DOCUMENT_DETAILS_SUCCESS';
  payload: DocumentRes;
}

export interface DocumentState {
  loading: boolean;
  details: DocumentRes;
}

const initialState: DocumentState = {
  loading: true,
  details: {
    id: '',
    date: new Date(),
    fileName: null,
    name: '',
    sent: false,
  },
};

type Action = LoadingSingleDocument | GetSingleDocumentDetails;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'LOADING_SINGLE_DOCUMENT': {
      return { ...state, loading: action.payload };
    }
    case 'GET_SINGLE_DOCUMENT_DETAILS_SUCCESS': {
      return { ...state, details: action.payload };
    }

    default:
      return state;
  }
};
