import React from 'react';
import './Tabs.scss';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { __ } from '../../../services/translation';
import { SingleTab } from '../../../types/tabs';
import { ApplicationState } from '../../../reducers';

interface Props {
  tabs: SingleTab[] | null;
  activeTab?: string | null | number;
  auto?: boolean;
  start?: boolean;
  chooseTab?: (type: any) => void;
}

const Tabs = ({ activeTab, chooseTab, tabs, auto, start }: Props) => {
  const user = useSelector((state: ApplicationState) => state.user.details);

  if (!user) return null;
  if (tabs === null) {
    return null;
  }

  return (
    <div className={`tabs-container ${auto ? 'auto' : ''} ${start ? 'start' : ''}`}>
      {chooseTab
        ? tabs
            .filter((t) => !t.restrict || t.restrict.includes(user.role))
            .map((tab: SingleTab) => (
              <button
                type="button"
                tabIndex={0}
                className={`tabs-option ${activeTab === tab.value ? 'active' : ''}`}
                onClick={() => {
                  chooseTab(tab.value);
                }}
              >
                {__(tab.label)}
              </button>
            ))
        : tabs
            .filter((t) => !t.restrict || t.restrict.includes(user.role))
            .map((tab: SingleTab) => (
              <NavLink
                exact
                tabIndex={0}
                activeClassName="active"
                className="tabs-option"
                to={tab.to || ''}
              >
                {__(tab.label)}
              </NavLink>
            ))}
    </div>
  );
};

export default Tabs;
