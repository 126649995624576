import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import { Button, Spinner } from '../../components/Common';
import { ButtonsContainer } from '../../components/Layout';
import ApiService from '../../services/api-service';
import { DownloadService } from '../../services/download-service';

interface Props {
  data?: any;
  url: string;
  header: string;
}

const InvoicePreview = ({ data, url, header }: Props) => {
  const [numPages, setNumPages] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState<Blob>();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const getInvoicePreview = async () => {
    const res = await ApiService.sendForFile('POST', url, data ?? {});
    const base64 = res.data;
    setFile(DownloadService.base64ToBlob(base64, 'pdf'));
    setLoading(false);
  };

  useEffect(() => {
    getInvoicePreview();
  }, []);

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title={header} />
      </ModalHeader>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess} className="preview-container">
            <Page pageNumber={pageNumber} />
          </Document>
          {numPages && numPages > 1 && (
            <ButtonsContainer>
              <Button
                faIcon={faChevronLeft}
                leftIcon
                disabled={pageNumber === 1}
                click={() => setPageNumber((num) => num - 1)}
              />
              <Button
                faIcon={faChevronRight}
                leftIcon
                disabled={pageNumber === numPages}
                click={() => setPageNumber((num) => num + 1)}
              />
            </ButtonsContainer>
          )}
        </div>
      )}
    </ModalContent>
  );
};

export default InvoicePreview;
