import React from 'react';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { UpdatePassRequest } from 'app-types';
import { FormContainer, ButtonsContainer, Page } from '../../../components/Layout';
import { Spinner, Button, FieldsMapper, RequiredIndicator } from '../../../components/Common';
import ApiService from '../../../services/api-service';
import { notifications } from '../../../actions';
import { formOnTranslateString } from '../../../utils/trans-form';
import useHeader from '../../../hooks/useHeader';
import Dashboard from '../../DashboardCommon';

const ChangePassword = () => {
  const dispatch = useDispatch();
  useHeader('application.change_password');
  return (
    <Page light>
      <Dashboard>
        <FormContainer>
          <ItfApiForm
            formId="edit-client-data"
            schemaMayBeDynamic={false}
            schemaCacheEnabled
            requiredIndicator={<RequiredIndicator />}
            schemaFetcher={() => ApiService.loadForm('user/form/change-pass')}
            sendFetcher={(formId: string, values: UpdatePassRequest) =>
              ApiService.sendForm('user/pass', values, 'PATCH')
            }
            onSavedSuccessfully={() => {
              dispatch(
                notifications.successNotification('application.you_password_has_been_changed.'),
              );
            }}
            loadingInfo={<Spinner transparent />}
            submitButton={
              <ButtonsContainer>
                <Button
                  type="submit"
                  className="form-submit-button"
                  text="application.save"
                  noShadow
                  primary
                />
              </ButtonsContainer>
            }
            onTranslateString={formOnTranslateString}
            onRenderFullField={FieldsMapper}
          />
        </FormContainer>
      </Dashboard>
    </Page>
  );
};

export default ChangePassword;
