import React from 'react';
import { OrderEventType, OrderEvent, MileageSchemaEntity } from 'app-types';
import { useDispatch } from 'react-redux';
import { faCar, faMapPin, faUndo, faClock } from '@fortawesome/free-solid-svg-icons';
import { modal } from '../../../../actions';
import { formatDistance } from '../../../../utils/format-distance';
import { ListItem } from '../../../../components/Common';

import { __ } from '../../../../services/translation';
import { formatDuration } from '../../../../utils/format-duration';
import MileageSubheader from '../../../../components/Common/MileageSubheader';

interface Props {
  item: MileageSchemaEntity;
  callback: (event: OrderEvent) => void;
}

const Item = ({ item, callback }: Props) => {
  const dispatch = useDispatch();

  const paragraphs = [
    { icon: faMapPin, text: `${item.startPoint}`, success: true },
    { icon: faMapPin, text: `${item.finishPoint}`, danger: true },
    { icon: faCar, text: formatDistance(item.distance) },
    {
      icon: 'time_sheets',
      text: typeof item.time !== 'undefined' ? formatDuration(item.time) : item.time,
    },
  ];

  return (
    <ListItem
      header={item.name}
      paragraphs={paragraphs}
      buttons={[
        {
          text: 'application.use',
          type: 'success',
          click: () => {
            callback({ templateId: item.id, type: OrderEventType.mileage });
            dispatch(modal.hideModal());
          },
        },
      ]}
    />
  );
};

export default Item;
