import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DocumentRes } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { history } from '../../App';
import { notifications, modal, apiList } from '../../actions';
import CostForm from '../../components/Common/CostForm';
import DocumentForm from '../../views/DashboardClient/CostDocument/Form';

interface Props {
  defaultValues: DocumentRes;
  id: string;
  successNotification: (text: string) => void;
  hideModal: () => void;
  refreshApiList: () => void;
}

const EditDocument = ({
  id,
  successNotification,
  hideModal,
  refreshApiList,
  defaultValues,
}: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_document" />
    </ModalHeader>
    {/* Form data handled outside form */}
    <DocumentForm
      id={id}
      defaultValues={defaultValues}
      callback={() => {
        successNotification('application.document_was_edited');
        refreshApiList();
        hideModal();
        history.push('/dashboard/costs-documents/list');
      }}
    />
  </ModalContent>
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
      refreshApiList: apiList.refreshApiList,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(EditDocument);
