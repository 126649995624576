import React from 'react';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import ApiService from '../../../../../services/api-service';
import { notifications } from '../../../../../actions';
import { Spinner, Button, FieldsMapper, RequiredIndicator } from '../../../../../components/Common';
import { FormContainer, ButtonsContainer } from '../../../../../components/Layout';
import { history } from '../../../../../App';
import { formOnTranslateString } from '../../../../../utils/trans-form';
import useHeader from '../../../../../hooks/useHeader';

const ContactAdd = () => {
  const dispatch = useDispatch();
  useHeader('application.add_contact');
  return (
    <>
      <FormContainer>
        <ItfApiForm
          formId="add-contact-id"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm('contact-list/form/add-item')}
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm('contact-list/new', values, 'POST')
          }
          onSavedSuccessfully={() => {
            dispatch(notifications.successNotification('application.contact_was_added.'));
            dispatch({ type: 'REFRESH_API_TABLE' });
            history.push('/dashboard/clients-and-contacts/contacts/list');
          }}
          onRenderFullField={FieldsMapper}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button type="submit" text="application.add" noShadow primary />
            </ButtonsContainer>
          }
          onTranslateString={formOnTranslateString}
        />
      </FormContainer>
    </>
  );
};

export default ContactAdd;
