import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SaveClientReq } from 'app-types';

import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { ClientForm } from '../../components/Common';
import { notifications, modal, apiList, client } from '../../actions';
import { __ } from '../../services/translation';

interface Props {
  id: string;
  defaultValues: SaveClientReq;
  successNotification: (text: string) => void;
  refreshApiList: () => void;
  hideModal: () => void;
  getSingleClient: (id: string) => void;
}

const EditClient = ({ id, defaultValues, refreshApiList }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_client" />
    </ModalHeader>
    <ClientForm
      isModal
      id={id}
      defaultValues={defaultValues}
      callback={() => {
        refreshApiList();
      }}
    />
  </ModalContent>
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
      refreshApiList: apiList.refreshApiList,
      getSingleClient: client.getSingleClient,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(EditClient);
