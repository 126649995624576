import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ClientUserEntity } from 'app-types';
import { ButtonsContainer } from '../../components/Layout';
import { Button, Spinner, InfoParagraph } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { modal } from '../../actions';
import ApiService from '../../services/api-service';

interface Props {
  id: string;
  accountant?: boolean;
}

const UserDetails = ({ id, accountant }: Props) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<ClientUserEntity | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getUser = async () => {
    setLoading(true);
    await ApiService.callFetch('GET', `user/one/${id}`, (response: ClientUserEntity) => {
      setUser(response);
    });
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.user_details" />
      </ModalHeader>
      {loading && <Spinner overlay />}
      {user && (
        <>
          <InfoParagraph name="application.user_created_at" date={user.createdAt} />
          <InfoParagraph name="application.last_login" date={user.lastLoginDt} />
          <InfoParagraph name="application.active_to" date={user.subscribedTo} />
          <InfoParagraph name="application.last_sent_email_date" date={user.lastSentEmailDt} />
          <InfoParagraph name="application.emails_sent_today" text={user.emailsSent} />
          <InfoParagraph name="application.individual_emails_limit" text={user.emailsLimitOverride} />
          <InfoParagraph name="application.subscription_active?" boolean={user.subscription} />
          <InfoParagraph name="application.confirmed" boolean={user.isConfirmed} />
          <InfoParagraph name="application.blocked" boolean={user.isBlocked} />
          <InfoParagraph name="application.block_reason" text={user.blockReason} />
          <InfoParagraph name="application.company_name" text={user.companyName} />
          {!accountant && (
            <>
              <InfoParagraph name="application.street" text={user.addressStreet} />
              <InfoParagraph name="application.post_code" text={user.addressPostCode} />
              <InfoParagraph name="application.city" text={user.addressCity} />
              <InfoParagraph name="application.phone" text={user.phone} />
              <InfoParagraph name="application.website" text={user.urlAddress} />
              <InfoParagraph name="application.bank_account_number" text={user.accountNumber} />
            </>
          )}
          <InfoParagraph name="application.kvk" text={user.kvk} />
          {!accountant && <InfoParagraph name="application.btw" text={user.nip} />}
          <InfoParagraph name="application.user_allows_emails" boolean={user.allowEmails} />
          <InfoParagraph name="application.user_allows_pushes" boolean={user.allowPushes} />
        </>
      )}
      <ButtonsContainer marginTop>
        <Button
          className="button-modal"
          click={() => dispatch(modal.hideModal())}
          text="application.ok"
          primary
          noShadow
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default UserDetails;
