import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import useHeader from '../../../hooks/useHeader';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import System from './System';
import Administration from './Administration';

const Notifications = () => {
  useHeader('application.notifications', '/');

  const notificationTabs = [
    {
      title: 'application.notifications_system',
      link: '/dashboard/notifications/system',
    },
    {
      title: 'application.notifications_administration',
      link: '/dashboard/notifications/administration',
    },
  ];

  return (
    <Page light>
      <Dashboard navigation={notificationTabs}>
        <Switch>
          <Route
            exact
            path="/dashboard/notifications"
            render={() => <Redirect to="/dashboard/notifications/administration" />}
          />
          <Route exact path="/dashboard/notifications/system" component={System} />
          <Route exact path="/dashboard/notifications/administration" component={Administration} />
        </Switch>
      </Dashboard>
    </Page>
  );
};

export default Notifications;
