import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { MileageEntity, InvoiceFile, CostRes, OfferRes } from 'app-types';
import { offer as offerActions, modal, header } from '../../../../actions';
import { ButtonsContainer } from '../../../../components/Layout';
import {
  Spinner,
  Button,
  InfoParagraph,
  Subheader,
  TaxRateTable,
  SingleFilePreview,
  Alert,
} from '../../../../components/Common';
import { Confirmation } from '../../../../modals';
import { ApplicationState } from '../../../../reducers';
import { DownloadService } from '../../../../services/download-service';
import './SingleOffer.scss';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  getSingleOffer: (mileageId: string) => void;
  loading: boolean;
  mileage: MileageEntity;
  showModal: (content: React.ReactNode) => void;
  deleteSingleOffer: (id: string) => void;
  hideModal: () => void;
  offer: OfferRes | null;
  setHeader: (header: string) => void;
}

class SingleOffer extends Component<Props> {
  componentDidMount() {
    const { getSingleOffer, match, setHeader } = this.props;
    getSingleOffer(match.params.id);
    setHeader('application.offer');
  }

  private deleteOffer = () => {
    const { showModal, deleteSingleOffer, hideModal, match } = this.props;
    showModal(
      <Confirmation
        text="application.confirm_offer_delete"
        confirmCallback={() => deleteSingleOffer(match.params.id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  render() {
    const { loading, match, offer } = this.props;
    const downloadPDF = async () => {
      await DownloadService.download(`offer/pdf/${match.params.id}`);
    };

    if (!offer) return <Alert type="error" text="applicaation.no_data" />;

    return (
      <div className="single-offer-container">
        {loading && !offer ? (
          <Spinner overlay />
        ) : (
          <div className="single-offer">
            <InfoParagraph name="application.mark" text={offer.mark} />
            <InfoParagraph name="application.date" date={offer.createdAt} />
            <InfoParagraph name="application.offer_valid_to" date={offer.validTo} />
            {offer.annotation && (
              <InfoParagraph
                breakLine
                text={offer.annotation}
                name="application.offer_annotation"
              />
            )}
            <Subheader highlight level={1} text="application.client" paddingTop />
            <InfoParagraph name="application.name" text={offer.client.name} />
            <InfoParagraph name="application.address_street" text={offer.client.address} />
            <InfoParagraph name="application.city" text={offer.client.city} />
            <InfoParagraph name="application.tin" text={offer.client.btw} />

            {offer.files.length > 0 && (
              <>
                <Subheader highlight level={1} text="application.files" paddingTop />
                {offer.files.map((file: InvoiceFile) => (
                  <SingleFilePreview type="offer" file={file} key={file.id} />
                ))}
              </>
            )}
            <Subheader highlight level={1} text="application.services" paddingTop />
            <TaxRateTable data={offer.services} showSum showNames details />
            <ButtonsContainer marginTop>
              <Button
                text="application.offer_to_invoice"
                primary
                noShadow
                to={`/dashboard/invoices/offer/${match.params.id}`}
                rightMargin
                bottomMargin
              />
              <Button
                text="application.edit"
                primary
                noShadow
                to={`/dashboard/offers/edit/${match.params.id}`}
                rightMargin
                bottomMargin
              />
              <Button
                text="application.delete"
                danger
                noShadow
                click={this.deleteOffer}
                rightMargin
                bottomMargin
              />
              <Button
                text="application.send_to_client"
                to={`/dashboard/offers/send/${match.params.id}`}
                noShadow
                secondary
                bottomMargin
              />
            </ButtonsContainer>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSingleOffer: offerActions.getSingleOffer,
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      deleteSingleOffer: offerActions.deleteSingleOffer,
      setHeader: header.setHeader,
    },
    dispatch,
  );

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.offer.loadingSingleOffer,
  offer: state.offer.singleOffer,
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleOffer);
