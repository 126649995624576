import React, { Component } from 'react';
import { faPlus, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import AddMileageScheme from './AddMileageScheme';
import MileageSchemeList from './MileageSchemeList';
import SingleMileageScheme from './SingleMileageScheme';

const invoiceTabs = [
  {
    title: 'application.mileage_statistics',
    link: '/dashboard/mileages',
    icon: 'back',
  },
  {
    title: 'application.mileage_scheme_list',
    link: '/dashboard/mileage-schemes/list',
    icon: 'list',
  },
  {
    title: 'application.add_mileage_scheme',
    link: '/dashboard/mileage-schemes/add',
    icon: 'plus',
  },
];

class MileageSchemes extends Component<{}> {
  render() {
    return (
      <>
        <Page light withNavigation>
          <Dashboard navigation={invoiceTabs}>
            <Switch>
              <Route
                exact
                path="/dashboard/mileage-schemes"
                render={() => <Redirect to="/dashboard/mileage-schemes/list" />}
              />
              <Route exact path="/dashboard/mileage-schemes/list" component={MileageSchemeList} />
              <Route exact path="/dashboard/mileage-schemes/add" component={AddMileageScheme} />
              <Route exact path="/dashboard/mileage-schemes/:id" component={SingleMileageScheme} />
            </Switch>
          </Dashboard>
        </Page>
      </>
    );
  }
}

export default MileageSchemes;
