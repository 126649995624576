import SessionService from '../services/session-service';
import { i18n } from '../services/translation';

interface ChangeLang {
  type: 'CHANGE_LANGUAGE';
  payload: string;
}

interface ChangeServerLang {
  type: 'CHANGE_SERVER_LANG_SUCCESS';
  payload: string;
}
interface LoadingChangeServerLang {
  type: 'LOADING_CHANGE_SERVER_LANG';
  payload: boolean;
}

export interface LangState {
  current: string;
  loadingLang: boolean;
}

const initialState: LangState = {
  current: SessionService.getCurrentLanguage(),
  loadingLang: false,
};

type Action = ChangeLang | ChangeServerLang | LoadingChangeServerLang;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'CHANGE_LANGUAGE': {
      i18n.changeLang(action.payload);
      return { ...state, current: action.payload };
    }
    case 'CHANGE_SERVER_LANG_SUCCESS': {
      i18n.changeLang(action.payload);
      return { ...state, current: action.payload };
    }
    case 'LOADING_CHANGE_SERVER_LANG': {
      return { ...state, langLoading: action.payload };
    }
    default:
      return state;
  }
};
