import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { SomeUserEntity, UserRole } from 'app-types';
import { Redirect } from 'react-router';
import { Page } from '../../../components/Layout';
import DashboardHome from './DashboardHome';
import CompanyData from './DashboardHome/CompanyData';
import { auth, modal } from '../../../actions';
import { ApplicationState } from '../../../reducers';
import './Home.scss';
import { PromoImage } from '../../../components/Common';
import { AccountingState } from '../../../reducers/accountaing-reducer';
import { SelectManagedUser } from '../../../modals';

interface Props {
  user: SomeUserEntity | null;
  accounting: AccountingState;
  logout: () => void;
  showModal: (content: React.ReactNode, isClosable: boolean) => void;
}

class Home extends Component<Props> {
  render() {
    const { user, accounting, showModal } = this.props;
    if (!user) return <Redirect to="/" />;

    if (user.role === UserRole.Accountant && !accounting.managedUser) {
      showModal(<SelectManagedUser />, true);
    }
    return (
      <>
        <Page stretch className="home">
          <DashboardHome />
          <PromoImage />
          <CompanyData />
        </Page>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
  accounting: state.accounting,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { logout: auth.logout, showModal: modal.showModal, hideModal: modal.hideModal },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
