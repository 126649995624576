import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { MileageSchemeRes } from 'app-types';
import { NarrowParagraph } from '../../../../../components/Common';
import ApiService from '../../../../../services/api-service';
import MileageForm from '../../Form';

interface Prams {
  id: string;
}

const AddMileageWithScheme = () => {
  const { id }: Prams = useParams();
  const [scheme, setScheme] = useState<MileageSchemeRes | null>();
  const dispatch = useDispatch();

  const fetchScheme = () => {
    ApiService.get(
      dispatch,
      '',
      '',
      (data: MileageSchemeRes) => {
        setScheme(data);
      },
      undefined,
      `mileage-schema/detail/${id}`,
      'LOADING',
    );
  };

  useEffect(() => {
    fetchScheme();
  }, []);

  return (
    <>
      <NarrowParagraph text="mileage.create_description" />
      {scheme && <MileageForm defaultValues={scheme} />}
    </>
  );
};

export default AddMileageWithScheme;
