import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { OtherIncomeReq } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ApiService from '../../services/api-service';
import { Spinner, Button, FieldsMapper, RequiredIndicator } from '../../components/Common';
import { history, flatpickr } from '../../App';
import { notifications, modal, apiList } from '../../actions';
import { FormContainer, ButtonsContainer } from '../../components/Layout';
import { formOnTranslateString } from '../../utils/trans-form';

interface Props {
  id: string;
  successNotification: (text: string) => void;
  hideModal: () => void;
  refreshApiList: () => void;
}

const EditOtherIncome = ({ id, successNotification, hideModal, refreshApiList }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_other_income" />
    </ModalHeader>
    <FormContainer>
      <ItfApiForm
        formId="edit-other-income"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        useFormDataInsteadOfPlainObject
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm(`other-income/form/update-item/${id}`)}
        sendFetcher={(formId: string, values: OtherIncomeReq) =>
          ApiService.sendForm(`other-income/${id}`, values, 'PATCH')
        }
        onSavedSuccessfully={() => {
          successNotification('application.other_income_was_edited');
          refreshApiList();
          hideModal();
          history.push('/dashboard/other-income/list');
        }}
        loadingInfo={<Spinner transparent />}
        onRenderFullField={FieldsMapper}
        submitButton={
          <ButtonsContainer>
            <Button type="submit" className="form-submit-button" text="application.save" primary />
          </ButtonsContainer>
        }
        onTranslateString={formOnTranslateString}
        thirdPartyComponents={{ flatpickr }}
      />
    </FormContainer>
  </ModalContent>
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
      refreshApiList: apiList.refreshApiList,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(EditOtherIncome);
