import { FieldType, TableSchema } from '../typings';
export const cheatersForAdmin: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.cmpany_name',
      field: 'companyName',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
  ],
};
