import React from 'react';
import { TableSchema, mileageSchemaForUserTable } from 'app-tables';
import { ApiList } from '../../../../components/Common';
import MileageSchemeItem from './Items/Item';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import useHeader from '../../../../hooks/useHeader';

interface Props {
  toOrder?: boolean;
}

const MileageList = ({ toOrder }: Props) => {
  useHeader(
    'application.mileage_schemes',
    toOrder ? '/dashboard/generator/add' : '/dashboard/mileages',
    {
      title: 'application.mileage_schemes',
      text: 'annotations.mileage_scheme',
    },
  );
  const scheme: TableSchema = new InteractiveTableSchema(mileageSchemaForUserTable);

  return (
    <>
      <ApiList
        apiEndpointSubUrl="mileage-schema/list"
        scheme={scheme}
        component={MileageSchemeItem}
        padded
      />
    </>
  );
};

export default MileageList;
