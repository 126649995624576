import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { ReminderReq, ReminderType } from '../../../../../../heyzzp-back/types';
import { Button } from '../../../../components/Common';
import { ButtonsContainer } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import { ApplicationState } from '../../../../reducers';
import { ReminderPreview } from './ReminderPreview';
import './PrepareReminder.scss';
import { DownloadService } from '../../../../services/download-service';

const PrepareReminder = () => {
  const { clientId, clientName } = useParams<{ clientId: string; clientName: string }>();
  const { invoiceIds, type } = useSelector((state: ApplicationState) => state.reminders);

  const getHeader = () => {
    switch (type) {
      case ReminderType.FIRST:
        return 'application.first_reminder';
      case ReminderType.SECOND:
        return 'application.second_reminder';
      case ReminderType.LAST:
        return 'application.last_reminder';
      default:
        return 'application.reminder';
    }
  };

  useHeader(getHeader());

  const downloadPDF = async () => {
    if (type === null) return;
    const data: ReminderReq = {
      clientId,
      invoiceIds,
      type,
      clientName,
    };
    await DownloadService.download('invoice/reminder/pdf', data);
  };

  if (type === null || invoiceIds.length === 0) {
    return <Redirect to="/dashbboard/reminders" />;
  }
  return (
    <div className="prepare-reminder-container">
      <ReminderPreview clientId={clientId} type={type} invoiceIds={invoiceIds} />
      <ButtonsContainer>
        <Button text="application.send" to={`/dashboard/reminders/${clientId}/send`} />
        <Button text="application.download_pdf" secondary click={downloadPDF} />
      </ButtonsContainer>
    </div>
  );
};

export { PrepareReminder };
