import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ItfApiForm, PublicItfFormBuilderComponentApi } from 'itf_formbuilder_react';
import { MileageSchemaEntity } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ApiService from '../../services/api-service';
import {
  Spinner,
  Button,
  FieldsMapper,
  RequiredIndicator,
  MileageFieldsMapper,
} from '../../components/Common';
import { history, flatpickr } from '../../App';
import { modal, notifications, apiList } from '../../actions';
import { formOnTranslateString } from '../../utils/trans-form';
import { FormContainer, ButtonsContainer } from '../../components/Layout';
import { ExtendedOnFormGloballyRegisteredProps } from '../../types/extendedOnFormGloballyRegisteredProps';
import MileageSchemaForm from '../../views/DashboardClient/MileageSchemes/Form';

interface Props {
  scheme: MileageSchemaEntity;
  mileageId: string;
}

const EditMileageScheme = ({ mileageId, scheme }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_mileage_scheme" />
    </ModalHeader>
    <MileageSchemaForm id={mileageId} defaultValues={scheme} />
  </ModalContent>
);

export default EditMileageScheme;
