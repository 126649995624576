export enum ApiErrorCode {
  InvalidLoginData,
  InsufficientPrivileges,
  UserLocked,
  OtherError,

  NoSuchUser,
  InvalidMail, // walidacja podczas rejestracji
  UserAlreadyExists, // walidacja podczas rejestracji - email zajęty

  InvalidOldPass, // walidacja podczas zmiany hasła
  InvalidPass, // Walidacja podczas usuwania konta

  InvalidNip,
  CompanyAddressCannotBeEmpty,
  CompanyNameCannotBeEmpty,
  PhoneNoCannotBeEmpty,
  PassCannotBeEmptyOrIsTooShort,

  NameCannotBeEmpty,
  StockholderShouldHaveAtLeastOneShare,
  AddressNotComplete, // There is now address, city, country or zip code

  ContactPersonNameIsRequired,
  ThisPointOfSaleIsAlreadyRegistered,

  EmailAlreadySent,

  RequiredDocumentsNotUploaded,

  InvalidPrice,
  InvalidProductCode,
  ThisProductCodeIsAlreadyInUse,
  NoSuchObjectWithThisId,
  FormValidationError,
  VideoIsProcessingYouCannotWatchNow,
  NoAccessToTheVideo,
  PreviousVideoNotFinished,
  VoucherInvalidOrUsed,
  EmptyKvkResponse,
  CannotReadGpsCoords,
  NotUniuqueParama,
  SearchLimit,
  UpgradeAccountToAddMoreCosts,
  TimeSheetUnvalidated,
  ForebiddenInvoiceNumber,
  InvalidLang,
  NoMileageForThisYear,
  NoMileageStarted,
  NoMileageFinished,
  InvalidCoordinate,
  NoTrackingStarted,
  NoPaymentFound,
  ExistTimeSheetInThisTime,
  NoWorkFinished,
  NoWorkStarted,
  InvalidId,
  InvalidAccountTypeName,
  TooMuchUploadedFiles,
  InvalideService,
  invoiceWithoutService,
  ForebiddenForTrialAccount,
  ForebiddenFileFormat,
  UserNotConfirmedByAdmin,
  NoDataForThisKVK,
  NoLogo,
  FileIsTooLarge,
  NoInvoiceForThisPayment,
  InvalidEvent,
  MustChooseOneCategory,
  InvalidKvk,
  MileagestUnvalidated,
  InvalidaDates,
  OnlyMasterCanChangeStartNumber,
  InvalidaMaster,
  MissingFile,
  NonConfirmedEmail,
  InvalidActiveData,
  ContactAlreadyExists,
  InvalidMileageType,
  InvalidCost,
  NoMileagesForThisYear,
  NoTimesheetsForThisYear,
  MustBeAtLeastOneDayDifference,
  InvalidToken,
  UserAlreadyHasVof,
  YouCantInviteMoreUsers,
  AskVofCreatorToInvite,
  YouAlreadyInvitedThisUser,
  YouCantInviteYourself,
  YouCantRemoveBecauseOfOrder,
  ClientExists,
  NoPermission,
  CantStartOnWeekend,
  IncompleteUserData,
  YouHaveNoInvoices,
  ErrorWhileGeneratingCertificate,
  ErrorWhilePreparingPayment,
  YouAlreadyHaveActiveSubscription,
  KvkNumberIsRequired,
  AccountantDoesNotExist,
  AccountantAlreadyHasAccess,
  CantMixFileTypes,
  OnlyOneFileInThisType,
  Max10FilesAllowed,
  AtLeastOnePeriodRequired,
  UpgradeAccountToAddMoreOtherIncome,
  OfferWithoutService,
  OfferAlreadyAccepted,
  OfferIsInvalid,
  InvalidInvoices,
  CantRemoveMileageInInvoice,
  CantRemoveTimesheetInInvoice,
  CantRemoveCostInInvoice,
  ClientIsNoLongerExist,
  InvalidCaptcha,
  YouNeedToWait,
  EmailsLimitReached
}
