import { format } from 'date-fns';
import { pl, nl, enUS } from 'date-fns/locale';
import CONFIG from '../config/app';
import { store } from '../App';

const locales: any = { pl, nl, enUS };

export const formatDate = (date: Date | string | number, full = false, overrideFormat?: string) => {
  const newDate = typeof date === 'string' ? new Date(date) : date;
  const dateFormat = overrideFormat || (full ? CONFIG.FULL_DATE_FORMAT : CONFIG.DATE_FORMAT);
  const lang = store.getState().lang.current;
  try {
    return format(newDate, dateFormat, {
      locale: locales[lang],
    });
  } catch (e) {
    return '_';
  }
};

export const formatDateTime = (date: Date | string | number) => {
  const newDate = typeof date === 'string' ? new Date(date) : date;
  const lang = store.getState().lang.current;
  try {
    return format(newDate, CONFIG.DATE_TIME_FORMAT, {
      locale: locales[lang],
    });
  } catch (e) {
    return '_';
  }
};
