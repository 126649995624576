import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { TaxRate, ValueType } from 'app-types';
import { costScheme, modal } from '../../../../actions';
import { ButtonsContainer } from '../../../../components/Layout';
import { InfoParagraph, Button, Spinner } from '../../../../components/Common';
import { Confirmation } from '../../../../modals';
import { ApplicationState } from '../../../../reducers';
import EditCostScheme from '../../../../modals/EditCostScheme';
import { __ } from '../../../../services/translation';

interface RouteProps {
  id: string;
}

const SingleCostScheme = () => {
  const dispatch = useDispatch();
  const { id }: RouteProps = useParams();
  const { loading, single } = useSelector((state: ApplicationState) => state.costScheme);

  const confirmSchemeDeletion = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.are_you_sure_you_want_to_delete_this_cost_scheme"
          confirmCallback={() =>
            dispatch(costScheme.deleteSingleCostScheme(id, '/dashboard/costs-schemes'))
          }
          cancelCallback={() => dispatch(modal.hideModal())}
        />,
      ),
    );
  };

  const costSchemeEdition = () => {
    dispatch(modal.showModal(<EditCostScheme id={id} />));
  };

  useEffect(() => {
    dispatch(costScheme.getSingleCostScheme(id));
  }, []);

  if (loading) return <Spinner />;

  return (
    <>
      <InfoParagraph name="application.cost_scheme_name" text={single.name} />
      <InfoParagraph
        name="application.cost_tax_rate"
        text={`${Object.values(TaxRate)[single.taxRate]}`}
      />
      {single.valueType === ValueType.VARIABLE_AMOUNT && (
        <InfoParagraph
          name="application.cost_amount_type"
          translate="application.cost_variable_value"
        />
      )}

      {single.valueType === ValueType.FIXED_AMOUNT && (
        <>
          <InfoParagraph name="application.cost_value" price={single.value} />
          <InfoParagraph
            name="application.cost_amount_type"
            translate="application.cost_fixed_value"
          />
        </>
      )}

      <ButtonsContainer marginTop>
        <Button text="application.edit" secondary noShadow click={costSchemeEdition} />
        <Button text="application.delete" danger noShadow click={confirmSchemeDeletion} />
      </ButtonsContainer>
    </>
  );
};

export default SingleCostScheme;
