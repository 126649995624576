import React, { ChangeEvent, FC, useEffect } from 'react';
import { GetClientKvKRes, ClientType, SaveClientReq } from 'app-types';
import { connect, useDispatch } from 'react-redux';
import { change, Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'src/reducers';
import { useHistory } from 'react-router';
import { AddressAutosuggestionResponse } from '../../../types/autosuggestion';
import { FormContainer } from '../../Layout';
import { ClientAutosuggestion, Button, AddressAutosuggestion, Input } from '../../Common';

import ApiService from '../../../services/api-service';
import { __ } from '../../../services/translation';

import { notifications, modal } from '../../../actions';
import ValidationService from '../../../services/validation-service';

interface OwnProps {
    type: number;
    isModal?: boolean;
    callback?: (data: SaveClientReq) => void;
    showModal: (content: React.ReactNode) => void;
    changeField: (form: string, field: string, value: any) => void;
    submit: (data: SaveClientReq) => void;
    id: string;
    defaultValues: { [key: string]: string | boolean | number };
}

type Props = InjectedFormProps<SaveClientReq, OwnProps> & OwnProps;

const ClientForm: FC<Props> = ({
    changeField,
    handleSubmit,
    type,
    isModal,
    callback,
    defaultValues = { type: ClientType.COMPANY },
    id,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (defaultValues) {
            Object.keys(defaultValues).forEach((key) => {
                changeField('clientForm', key, defaultValues[key]);
            });
        }
    }, []);

    const clientTypeOptions = [
        {
            name: `${__('application.client_company')}`,
            value: 0,
        },
        {
            name: `${__('application.client_private')}`,
            value: 1,
        },
    ];

    const handleAddressSelect = (sugestion: AddressAutosuggestionResponse) => {
        changeField('clientForm', 'address', sugestion.first);
        if (sugestion.second) changeField('clientForm', 'city', sugestion.second);
        if (sugestion.third) changeField('clientForm', 'country', sugestion.third);
    };

    const setClientType = (value: number) => {
        changeField('clientForm', 'type', value);
    };

    const handleClientModal = (client: GetClientKvKRes) => {
        changeField('clientForm', 'name', client.name);
        changeField('clientForm', 'kvk', client.kvk);
        changeField('clientForm', 'city', client.city);
        changeField('clientForm', 'country', client.country);
        changeField('clientForm', 'address', client.address);
    };

    const submit = async (data: SaveClientReq) => {
        const success = (type: 'add' | 'edit') => {
            if (isModal) {
                dispatch(modal.hideModal());
                if (callback) callback(data);
            } else {
                history.push('/dashboard/clients-and-contacts/clients/list');
            }
            if (type === 'add') dispatch(notifications.successNotification('application.client_added.'));
            if (type === 'edit') dispatch(notifications.successNotification('application.client_edited'));
        };

        if (id) {
            ApiService.patch(
                dispatch,
                '',
                '',
                () => {
                    success('edit');
                },
                null,
                `client/${id}`,
                '',
                data,
            );
        } else {
            ApiService.post(
                dispatch,
                '',
                '',
                () => {
                    success('add');
                },
                null,
                'client/save-client',
                '',
                data,
            );
        }
    };

    return (
        <>
            <FormContainer>
                <form onSubmit={handleSubmit(submit)}>
                    <Field
                        name="type"
                        type="select"
                        component={Input}
                        label="application.client_type"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setClientType(Number(e.target.value))}
                        options={clientTypeOptions}
                    />
                    {Number(type) === clientTypeOptions[0].value && (
                        <Field
                            name="name"
                            component={ClientAutosuggestion}
                            label="application.company_name"
                            allowNewClient
                            validate={[ValidationService.required, ValidationService.max64]}
                            onOptionSelect={handleClientModal}
                            required
                            placeholder="application.company_name_placeholder"
                        />
                    )}
                    {Number(type) === clientTypeOptions[1].value && (
                        <>
                            <Field
                                name="firstName"
                                type="text"
                                component={Input}
                                label="application.first_name"
                                placeholder="John"
                                required
                                validate={[ValidationService.required, ValidationService.max64]}
                            />
                            <Field
                                name="lastName"
                                type="text"
                                component={Input}
                                validate={ValidationService.max64}
                                label="application.last_name"
                                placeholder="application.last_name_placeholder"
                            />
                        </>
                    )}
                    <Field
                        name="email"
                        type="text"
                        component={Input}
                        label="application.email_for_invoice"
                        placeholder="application.email_placeholder"
                        validate={[ValidationService.max64, ValidationService.email]}
                    />
                    <Field
                        name="address"
                        component={AddressAutosuggestion}
                        label="application.address_street"
                        onSugestionSelect={handleAddressSelect}
                        splitted
                        validate={ValidationService.max64}
                        placeholder="application.address_placeholder"
                    />
                    <Field
                        name="city"
                        type="text"
                        component={Input}
                        label="application.city_and_post_code"
                        validate={ValidationService.max64}
                        placeholder="application.city_and_post_code_placeholder"
                    />
                    <Field
                        name="country"
                        type="text"
                        component={Input}
                        label="application.country"
                        validate={ValidationService.max64}
                        placeholder="application.country_placeholder"
                    />
                    <Field
                        name="kvk"
                        type="text"
                        component={Input}
                        label="application.kvk"
                        validate={ValidationService.max32}
                        placeholder="application.kvk_placeholder"
                    />
                    <Field
                        name="btw"
                        type="text"
                        component={Input}
                        label="application.btw"
                        placeholder="NL123456789B01"
                        validate={ValidationService.max32}
                    />
                    <Field
                        name="phone"
                        type="text"
                        component={Input}
                        label="application.phone"
                        placeholder="0612345678"
                        validate={ValidationService.max16}
                    />
                    <Field name="iban" type="text" component={Input} label="application.iban" validate={[ValidationService.max32, ValidationService.iban]} />
                    <Field name="bic" type="text" component={Input} label="application.bic" validate={[ValidationService.max32, ValidationService.bic]} />
                    {Number(type) === clientTypeOptions[0].value && (
                        <>
                            <Field
                                name="firstName"
                                type="text"
                                component={Input}
                                label="application.first_name"
                                placeholder="John"
                                validate={ValidationService.max64}
                            />
                            <Field
                                name="lastName"
                                type="text"
                                component={Input}
                                label="application.last_name"
                                placeholder="application.last_name_placeholder"
                                validate={ValidationService.max64}
                            />
                        </>
                    )}
                    <Field name="note" type="text" component={Input} label="application.note" />
                    <Button type="submit" text="application.next" primary />
                </form>
            </FormContainer>
        </>
    );
};

const selector = formValueSelector('clientForm');

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
    type: selector(state, 'type'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            changeField: change,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm<SaveClientReq, OwnProps>({
        form: 'clientForm',
        enableReinitialize: false,
    })(ClientForm),
);
