import React from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { UpdateInvoiceAnnotationRequest } from 'app-types';
import { useDispatch } from 'react-redux';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import {
  Spinner,
  Button,
  Subheader,
  FieldsMapper,
  RequiredIndicator,
} from '../../../../components/Common';
import ApiService from '../../../../services/api-service';
import { formOnTranslateString } from '../../../../utils/trans-form';
import { notifications, user } from '../../../../actions';

const InvoiceAnnotationForm = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Subheader text="application.invoice_annotation_profile" paddingTop />

      <FormContainer>
        <ItfApiForm
          formId="invoice-annotation"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm('user/form/update-invoice-annotation')}
          sendFetcher={(formId: string, values: UpdateInvoiceAnnotationRequest) =>
            ApiService.sendForm('user/update-invoice-annotation', values, 'PATCH')
          }
          onSavedSuccessfully={() => {
            dispatch(notifications.successNotification('application.invoice_annotation_saved'));
            dispatch(user.getUserDetails());
          }}
          loadingInfoAboveContent
          loadingInfo={<Spinner halfTransparent />}
          submitButton={
            <ButtonsContainer>
              <Button type="submit" primary text="application.save" />
            </ButtonsContainer>
          }
          onTranslateString={formOnTranslateString}
          onRenderFullField={FieldsMapper}
        />
        {/* </div> */}
      </FormContainer>
    </>
  );
};

export default InvoiceAnnotationForm;
