import { Client } from '../../src/client/client.entity';
import { ClientEntity } from '../client';
import { CostEntity, NewCost } from '../cost';
import { InvoiceFile } from '../invoice';
import { NewServiceForInvoice, ServiceEntity } from '../service';
import { InvoiceUser } from '../user';

export interface OfferEntity {
  id: string;
  createdAt: Date;
  client: ClientEntity;
  issuer: InvoiceUser;
  mark: string;
  totalVal: number;
  totalTaxVal: number;
  validTo: Date;
  status: OfferStatus;
  statusChangeDt: Date;
  lastActionDate: Date;
  lastAction: OfferHistoryActions;
}

export interface OfferRes {
  id: string;
  createdAt: Date;
  client: ClientEntity;
  issuer: InvoiceUser;
  mark: string;
  validTo: Date;
  annotation: string;
  services: ServiceEntity[];
  files: InvoiceFile[];
  status: OfferStatus;
  statusChangeDt: Date;
  lastActionDate: Date;
  lastAction: OfferHistoryActions;
}

export interface OfferListRes {
  id: string;
  createdAt: Date;
  validTo: Date;
  mark: string;
  totalVal: number;
  totalTaxVal: number;
  client: string;
  status: OfferStatus;
  statusChangeDt: Date;
  lastActionDate: Date;
  lastAction: OfferHistoryActions;
}

export interface OfferReq {
  // number: string;
  createdAt: Date;
  client: ClientEntity;
  // issuer: InvoiceUser;
  mark: string;
  validTo: Date;
  // totalVal: number;
  // totalTaxVal: number;
  annotation: string;
  services: NewServiceForInvoice[];
  files?: File[];
}

export interface OfferReqWithCosts {
  createdAt: Date;
  client: ClientEntity;
  mark: string;
  validTo: Date;
  services: NewServiceForInvoice[];
  costIds: string[];
  files?: File[];
}

export interface OfferFormData {
  id?: string;
  createdAt: Date;
  client: { name: string }; // rest is taken from redux
  mark: string;
  validTo: Date;
  services: NewServiceForInvoice[];
  annotation: string;
  files?: File[];
}

export enum OfferStatus {
  NEW,
  ACCEPTED,
}

export enum OfferHistoryActions {
  CREATE,
  REJECT,
  EDIT,
  ACCEPT,
  REVOKE_ACCEPT,
}

export interface OfferHistory {
  date: Date;
  action: OfferHistoryActions;
}

export interface OfferHistoryRes {
  id: string;
  date: Date;
  action: OfferHistoryActions;
}
