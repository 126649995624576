import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ButtonsContainer } from '../../components/Layout';
import { Button } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal } from '../../actions';

interface Props {
  text: string;
  header: string;
  hideModal: () => void;
}

const Description = ({ text, header, hideModal }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title={header} />
    </ModalHeader>
    <p className="description-paragraph">{__(text)}</p>

    <ButtonsContainer marginTop>
      <Button className="button-modal" click={hideModal} text="application.ok" primary noShadow />
    </ButtonsContainer>
  </ModalContent>
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ hideModal: modal.hideModal }, dispatch);

export default connect(null, mapDispatchToProps)(Description);
