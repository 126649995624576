import { emit } from 'process';
import { ServiceType } from './service';

export enum TaxRate {
  '21%',
  '9%',
  '0%',
  'BTW Verlegd',
  'BTW vrijgesteld',
  'BTW Gemengd',
  'EU/Abroad EU',
}

export enum TaxType {
  DEDUCTED,
  NOT_DEDUCTED,
}

export enum DiscountType {
  PERCENT,
  VALUE,
}

export enum CostPaymentType {
  NONE,
  INSTALLMENT,
  CASH,
  PRIVATE_ACCOUNT,
  OTHER,
}

export interface ServiceParamsEntity {
  id: string;
  name: string;
  price: number;
  net: boolean;
  taxRate: TaxRate;
  discount: number;
  discountType: DiscountType;
  description: string;
  type: ServiceType;
}

export interface ServiceEntity extends ServiceParamsEntity {
  quantity: number;
  position: number;
}

export type ServiceSchemaEntity = ServiceParamsEntity;

export type NewServiceForInvoice = { position?: number } & Omit<
  ServiceEntity,
  'id' | 'position'
>;
