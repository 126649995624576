import React from 'react';
import { Dispatch } from 'redux';

export const showModal = (content: React.ReactNode, isClosable = true, isFullscreen = false) => (
  dispatch: Dispatch,
) => {
  dispatch({ type: 'PREPARE_MODAL', payload: { content, isClosable, isFullscreen } });
  setTimeout(() => {
    dispatch({ type: 'SHOW_MODAL', payload: { content, isClosable, isFullscreen } });
  }, 50);
};

export const hideModal = () => (dispatch: Dispatch) => {
  dispatch({ type: 'HIDE_MODAL' });
  setTimeout(() => {
    dispatch({ type: 'UNPREPARE_MODAL' });
  }, 150);
};
