import {
  SimpleKvkResponse,
  SomeUserEntity,
  UserBlockBecausePaymentRequest,
  UserRole,
} from 'app-types';
import { SplashScreen } from '@capacitor/splash-screen';
import { Dispatch } from 'redux';
import ApiService from '../services/api-service';
import { modal, notifications, apiList, apiTable } from '.';
import { history } from '../App';
import { refreshApiTable } from './api-table';
import { getCheatersList } from './client';
import { getPendingInvitationsCount } from './pendingInvitations';
import { getUserInfo } from './info';
import { AnalitycsService } from '../services/analitycs-service';

export const getUserDetails = () => async (dispatch: Dispatch<any>) => {
  await ApiService.get(
    dispatch,
    'FETCH_USER_DETAILS_SUCCESS',
    'FETCH_USER_DETAILS_FAILED',
    async (res: SomeUserEntity) => {
      if (res.role === UserRole.Accountant) {
        dispatch(getPendingInvitationsCount());
      }
      await dispatch(getUserInfo());

      // delay to avoid flickering
      setTimeout(() => {
        SplashScreen.hide();
        AnalitycsService.setUser(res);
      }, 50);
    },
    () => {
      setTimeout(() => {
        SplashScreen.hide();
      }, 50);
    },
    'user/details',
    'LOADING_FETCH_USER_DETAILS',
  );
};

export const validateKvk = (kvk: string) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'VALIDATE_KVK_SUCCESS',
    'VALIDATE_KVK_FAILED',
    null,
    null,
    `user/prepare-add-user-form-for-kvk/${kvk}`,
    'LOADING_VALIDATE_KVK',
  );
};

export const getUserForAdmin = (id: string) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'FETCH_USER_DETAILS_FOR_ADMIN_SUCCESS',
    'FETCH_USER_DETAILS_FOR_ADMIN_FAILED',
    null,
    null,
    `user/one/${id}`,
    'LOADING_USER_FOR_ADMIN',
  );
};

export const deleteUser = (id: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.delete(
    dispatch,
    'DELETE_USER_FOR_ADMIN_SUCCESS',
    'DELETE_USER_FOR_ADMIN_FAILURE',
    () => {
      dispatch(notifications.successNotification('application.user_blocked.'));
      dispatch(modal.hideModal());
      window.location.reload();
    },
    null,
    `user/${id}`,
    'DELETE_USER_FOR_ADMIN',
  );
};

export const toggleAccountType = (id: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.patch(
    dispatch,
    '',
    '',
    () => {
      dispatch(notifications.successNotification('application.account_type_changed'));
      dispatch(modal.hideModal());
      dispatch(apiTable.refreshApiTable());
    },
    null,
    `user/toggle-account-type/${id}`,
    '',
  );
};

export const unblockUser = (id: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.patch(
    dispatch,
    'UNLBLOCK_USER_FOR_ADMIN_SUCCESS',
    'UNBLOCK_USER_FOR_ADMIN_FAILURE',
    () => {
      dispatch(notifications.successNotification('application.successfully_unblocked_user'));
      dispatch(modal.hideModal());
      dispatch(apiTable.refreshApiTable());
    },
    null,
    `user/unblock/${id}`,
    'LOADING_USER_FOR_ADMIN',
  );
};

export const markAsCheater = (id: string, isCheater: boolean) => async (
  dispatch: Dispatch<any>,
) => {
  await ApiService.patch(
    dispatch,
    'MARK_CHEATER_USER_FOR_ADMIN_SUCCESS',
    'MARK_CHEATER_USER_FOR_ADMIN_FAILURE',
    () => {
      if (isCheater) {
        dispatch(notifications.successNotification('application.successfully_marked_as_cheater'));
      } else {
        dispatch(
          notifications.successNotification('application.successfully_marked_as_not_cheater'),
        );
        dispatch(getCheatersList());
      }
      dispatch(modal.hideModal());
      dispatch(apiTable.refreshApiTable());
    },
    null,
    `user/mark-as-cheater/${id}`,
    'LOADING_USER_FOR_ADMIN',
    { isCheater },
  );
};

export const deleteFromCheaterList = (userId: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.get(
    dispatch,
    'DELETE_USER_FROM_CHEATER_LIST_SUCCESS',
    'DELETE_USER_FROM_CHEATER_LIST_FAILED',
    () => {
      dispatch(notifications.successNotification('application.deleted.'));
      dispatch(modal.hideModal());
    },
    null,
    `user/no-cheater/${userId}`,
  );
};

export const getUserPayments = (userId: string) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_USER_PAYMENTS_SUCCESS',
    'GET_USER_PAYMENTS_FAILED',
    null,
    null,
    `payment/list/${userId}`,
    'LOADING_USER_PAYMENTS',
  );
};

export const extendSubscription = (userId: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.get(
    dispatch,
    'EXTEND_SUBSCRIPTION_SUCCESS',
    'EXTEND_SUBSCRIPTION_FAILED',
    () => {
      dispatch(notifications.successNotification('application.subscription_extended'));
      dispatch(modal.hideModal());
      dispatch(refreshApiTable());
    },
    null,
    `payment/give-free-for-month/${userId}`,
    'LOADING_EXTEND_SUBSCRIPTION',
  );
};

export const receiveKvkFormData = (form: any) => ({
  type: 'RECEIVE_KVK_FORM_DATA',
  payload: form,
});

export const confirmDataChange = (id: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.patch(
    dispatch,
    'CONFIRM_USER_DATA_CHANGE_SUCCESS',
    'CONFRIM_USER_DATA_CHANGE_FAILED',
    () => {
      dispatch(notifications.successNotification('application.user_data_was_edited'));
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
      dispatch(apiTable.refreshApiTable());
    },
    null,
    `user/confirm-change-data/${id}`,
    'LOADING_USER_CONFIRMATION',
    {},
  );
};

export const receiveKvk = (values: SimpleKvkResponse) => {
  history.push(`/inactive/data/${values.kvk}`);
  return {
    type: 'RECEIVE_KVK_NUMBER',
    payload: values.kvk,
  };
};

export const blockUserBecausePayment = (id: string, data: UserBlockBecausePaymentRequest) => async (
  dispatch: Dispatch<any>,
) => {
  await ApiService.patch(
    dispatch,
    'BLOCK_USER_FOR_ADMIN_SUCCESS',
    'BLOCK_USER_FOR_ADMIN_FAILURE',
    () => {
      dispatch(notifications.successNotification('application.user_blocked'));
      dispatch(modal.hideModal());
      dispatch(apiTable.refreshApiTable());
    },
    null,
    `user/block-because-payment/${id}`,
    'LOADING_USER_FOR_ADMIN',
    data,
  );
};

export const generatePaymentToUnblock = () => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GENERATE_PAYMENT_TO_UNBLOCK_SUCCESS',
    'GENERATE_PAYMENT_TO_UNBLOCK_FAILURE',
    (data: any) => {
      // dispatch(notifications.successNotification('application.user_blocked'));
      // dispatch(modal.hideModal());
      // dispatch(apiTable.refreshApiTable());
    },
    null,
    'payment/generate-payment-to-unblock',
    'LOADING_GENERATE_PAYMENT_TO_UNBLOCK',
  );
};
