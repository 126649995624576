import { AdminOverallSummary, UserLabel } from 'app-types';
import React, { useEffect, useState } from 'react';
import { Alert, InfoParagraph, Spinner, Subheader } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';
import ApiService from '../../../../services/api-service';
import { ByLanguage } from './ByLanguage';

import './Overall.scss';

const Overall = () => {
  useHeader('admin.stats_overall');
  const [stats, setStats] = useState<AdminOverallSummary | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const getStats = async () => {
    await ApiService.callFetch('GET', 'dashboard/overall-summary', (data: AdminOverallSummary) => {
      setStats(data);
    });
    setLoading(false);
  };

  useEffect(() => {
    getStats();
  }, []);

  if (loading) return <Spinner />;
  if (!stats) return <Alert simple type="error" text="application.error" />;
  return (
    <div className="overall-stats">
      <InfoParagraph name="admin.total_users" text={stats.total} />
      <InfoParagraph name="admin.active_users" text={stats.active} />
      <InfoParagraph name="admin.logged_last_week" text={stats.loggedDuringLastWeek} />
      <InfoParagraph name="admin.joined_last_week" text={stats.joinedDuringLastWeek} />
      <InfoParagraph name="admin.payments_total" price={stats.paymentsTotal} />
      <ByLanguage byLanguage={stats.byLanguage} />
      <Subheader highlight text="admin.subscriptions" />
      {stats.subscriptions[UserLabel.WAK] && (
        <div className="overall-stats-for-label">
          <Subheader level={2} text="application.user_wak" />
          <InfoParagraph
            name="admin.monthly_basic"
            text={stats.subscriptions[UserLabel.WAK].monthlyBasic}
          />
          <InfoParagraph
            name="admin.monthly_premium"
            text={stats.subscriptions[UserLabel.WAK].monthlyPremium}
          />
          <InfoParagraph
            name="admin.yearly_basic"
            text={stats.subscriptions[UserLabel.WAK].yearlyBasic}
          />
          <InfoParagraph
            name="admin.yearly_premium"
            text={stats.subscriptions[UserLabel.WAK].yearlyPremium}
          />
          {/* Number of active, paid subscriptions */}
          <InfoParagraph
            name="admin.total_paid_subscriptions"
            text={stats.subscriptions[UserLabel.WAK].totalPaidSubscriptions}
          />
        </div>
      )}
      {stats.subscriptions[UserLabel.INTERNAL] && (
        <div className="overall-stats-for-label">
          <Subheader level={2} text="application.user_internal" />
          <InfoParagraph
            name="admin.monthly_basic"
            text={stats.subscriptions[UserLabel.INTERNAL].monthlyBasic}
          />
          <InfoParagraph
            name="admin.monthly_premium"
            text={stats.subscriptions[UserLabel.INTERNAL].monthlyPremium}
          />
          <InfoParagraph
            name="admin.yearly_basic"
            text={stats.subscriptions[UserLabel.INTERNAL].yearlyBasic}
          />
          <InfoParagraph
            name="admin.yearly_premium"
            text={stats.subscriptions[UserLabel.INTERNAL].yearlyPremium}
          />
          {/* Number of active, paid subscriptions */}
          <InfoParagraph
            name="admin.total_paid_subscriptions"
            text={stats.subscriptions[UserLabel.INTERNAL].totalPaidSubscriptions}
          />
        </div>
      )}
      {stats.subscriptions[UserLabel.HEYZZP] && (
        <div className="overall-stats-for-label">
          <Subheader level={2} text="application.user_heyzzp" />
          <InfoParagraph
            name="admin.monthly_basic"
            text={stats.subscriptions[UserLabel.HEYZZP].monthlyBasic}
          />
          <InfoParagraph
            name="admin.monthly_premium"
            text={stats.subscriptions[UserLabel.HEYZZP].monthlyPremium}
          />
          <InfoParagraph
            name="admin.yearly_basic"
            text={stats.subscriptions[UserLabel.HEYZZP].yearlyBasic}
          />
          <InfoParagraph
            name="admin.yearly_premium"
            text={stats.subscriptions[UserLabel.HEYZZP].yearlyPremium}
          />
          {/* Number of active, paid subscriptions */}
          <InfoParagraph
            name="admin.total_paid_subscriptions"
            text={stats.subscriptions[UserLabel.HEYZZP].totalPaidSubscriptions}
          />
        </div>
      )}
    </div>
  );
};

export default Overall;
