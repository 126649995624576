import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AccountantConnectorDetails, AccountantConnectorStatus } from 'app-types';
import { Button, Subheader, Spinner, Alert } from '../../../../components/Common';
import { ButtonsContainer } from '../../../../components/Layout';
import './List.scss';

import { __ } from '../../../../services/translation';
import ApiService from '../../../../services/api-service';
import { notifications, modal } from '../../../../actions';
import { Confirmation } from '../../../../modals';

interface Props {
  accountants: AccountantConnectorDetails[] | undefined;
  getAccountants: () => void;
}

const List = ({ accountants, getAccountants }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getAccountants();
  }, []);

  const remove = async (connectorId: string) => {
    await ApiService.callFetch('DELETE', `accountant-connector/${connectorId}`, () => {
      dispatch(notifications.successNotification('application.accountant_removed'));
      dispatch(modal.hideModal());
      getAccountants();
    });
  };

  const accept = async (connectorId: string) => {
    await ApiService.callFetch('PATCH', `accountant-connector/accept/${connectorId}`, () => {
      dispatch(notifications.successNotification('application.accountant_approved'));
      dispatch(modal.hideModal());
      getAccountants();
    });
  };

  const confirmRemove = (vofId: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          confirmCallback={() => remove(vofId)}
          text="application.confirm_accountant_remove"
        />,
      ),
    );
  };

  const confirmAccept = (id: string) => {
    dispatch(
      modal.showModal(
        <Confirmation
          confirmCallback={() => accept(id)}
          text="application.confirm_accountant_approve"
        />,
      ),
    );
  };

  if (!accountants) return <Spinner />;

  return (
    <div className="accountant-list">
      <Subheader text="application.accountant_list" />
      {accountants.length === 0 && <Alert type="notice" text="application.no_accountants" />}
      <ul>
        {accountants.map((child) => (
          <li>
            <div>
              <strong>{child.name}</strong>
            </div>
            <span>{child.email}</span>
            <div className="accountant-connector-status">
              {child.status === AccountantConnectorStatus.ACTIVE
                ? __('application.connector_approved')
                : __('application.connector_pending')}
            </div>
            <ButtonsContainer>
              {child.status === AccountantConnectorStatus.WAITING_FOR_CLIENT_CONFIRMATION && (
                <Button
                  small
                  text="application.accept"
                  success
                  click={() => confirmAccept(child.id)}
                />
              )}
              <Button
                small
                text="application.delete"
                danger
                click={() => confirmRemove(child.id)}
              />
            </ButtonsContainer>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default List;
