import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { timeSheet, header } from '../../../../actions';
import { Spinner, Alert } from '../../../../components/Common';
import SingleTimeSheetSummary from '../SingleTimeSheetSummary';
import { ApplicationState } from '../../../../reducers';

interface Props {
  getTimeSheetYears: () => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
  loading: boolean;
  years: number[];
}

class YearSummary extends Component<Props> {
  componentDidMount() {
    const { getTimeSheetYears, setHeader } = this.props;
    setHeader('application.annual_summary', '/dashboard/time-sheets/list', {
      title: 'application.annual_summary',
      text: 'annotations.time_sheets_download',
    });
    getTimeSheetYears();
  }

  render() {
    const { years, loading } = this.props;
    return (
      <>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {years.length === 0 && <Alert simple type="error" text="application.no_data." />}
            {years.map((year: number) => (
              <SingleTimeSheetSummary key={year} year={year} />
            ))}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  years: state.timeSheet.timeSheetYears,
  loading: state.timeSheet.loadingTimeSheetYears,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getTimeSheetYears: timeSheet.getTimeSheetYears, setHeader: header.setHeader },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(YearSummary);
