interface ReceiveStartTime {
  type: 'RECEIVE_START_TIME';
  payload: string;
}
interface ReceiveFinishTime {
  type: 'RECEIVE_FINISH_TIME';
  payload: string;
}

interface ReceiveStartDate {
  type: 'RECEIVE_START_DATE';
  payload: string;
}

interface ReceiveFinishDate {
  type: 'RECEIVE_FINISH_DATE';
  payload: string;
}

interface ReceiveTimeSheetName {
  type: 'RECEIVE_TIME_SHEET_NAME';
  payload: string;
}

interface ClearTime {
  type: 'CLEAR_TIME';
}

export interface TimeState {
  startTime: string;
  finishTime: string;
  startDate: string;
  finishDate: string;
  receivedTimeSheetName: string;
}

const initialState: TimeState = {
  startTime: '00:00',
  finishTime: '00:00',
  startDate: '',
  finishDate: '',

  receivedTimeSheetName: '',
};

type Action =
  | ReceiveFinishDate
  | ReceiveStartTime
  | ReceiveStartDate
  | ReceiveFinishTime
  | ReceiveTimeSheetName
  | ClearTime;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'RECEIVE_START_TIME': {
      return { ...state, startTime: action.payload };
    }
    case 'RECEIVE_START_DATE': {
      return { ...state, startDate: action.payload };
    }
    case 'RECEIVE_FINISH_DATE': {
      const parsedDate = new Date(`${action.payload} ${state.finishTime}`);
      return { ...state, finishDate: action.payload, parsedFinishDate: parsedDate };
    }
    case 'RECEIVE_FINISH_TIME': {
      const parsedDate = new Date(`${state.finishDate} ${action.payload}`);
      return { ...state, finishTime: action.payload, parsedFinishDate: parsedDate };
    }
    case 'RECEIVE_TIME_SHEET_NAME': {
      return { ...state, receivedTimeSheetName: action.payload };
    }
    case 'CLEAR_TIME': {
      return { ...initialState };
    }

    default:
      return state;
  }
};
