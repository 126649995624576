import React from 'react';
import { useParams } from 'react-router';
import useHeader from '../../../../../hooks/useHeader';
import TimeSheetForm from '../../../../../components/Common/TimeSheetForm';
import { NarrowParagraph } from '../../../../../components/Common';

// eslint-disable-next-line react/prefer-stateless-function
const AddTimeSheet = () => {
  const { id } = useParams<{ id: string }>();
  useHeader('application.add_time_sheet');
  return (
    <>
      <NarrowParagraph text="timesheet.create_description" />
      <TimeSheetForm formUrl={`time-sheet/form-from-template/${id}`} withPeriods />
    </>
  );
};

export default AddTimeSheet;
