import { CreatedClientAccountRes } from 'app-types';
import React, { FC } from 'react';
import { formatDate } from '../../../../utils/format-date';
import { ListItem, Subheader } from '../../../../components/Common';

interface Props {
  records: CreatedClientAccountRes[];
}

export const List: FC<Props> = ({ records }) => (
  <div>
    <Subheader text="application.created_accounts" />
    {records &&
      records.length > 0 &&
      records.map((item) => (
        <ListItem
          key={Math.random()}
          paragraphs={[
            {
              icon: 'time_sheets',
              text: formatDate(item.date),
            },
            {
              icon: 'contacts',
              text: item.email,
            },
          ]}
          buttons={[]}
        />
      ))}
  </div>
);
