import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { NarrowParagraph } from '../../../../../components/Common';
import { header } from '../../../../../actions';
import { __ } from '../../../../../services/translation';
import MileageForm from '../../Form';
import { AnalitycsService } from '../../../../../services/analitycs-service';
import { Screens } from '../../../../../types/analitycs';

interface Props {
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class AddMileage extends React.Component<Props> {
  componentDidMount() {
    const { setHeader } = this.props;
    AnalitycsService.setScreen(Screens.MILEAGE_FORM);

    setHeader('application.add_mileage', '/dashboard/mileages/add', {
      title: 'application.add_mileage',
      text: 'mileage.disclaimer',
    });
  }

  render() {
    return (
      <>
        <NarrowParagraph text="mileage.create_description" />
        <MileageForm />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setHeader: header.setHeader }, dispatch);

export default connect(null, mapDispatchToProps)(AddMileage);
