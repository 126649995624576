import React from 'react';
import { useDispatch } from 'react-redux';
import { CheaterUser, UserListForAdmin } from 'app-types';
import { Button } from '../../../components/Common';
import { modal, user } from '../../../actions';
import { Confirmation, SendNotification } from '../../../modals';
import BlockUserModal from '../../../modals/BlockUserModal';

interface Props {
  client: CheaterUser | UserListForAdmin;
}

const CommonButtons = ({ client }: Props) => {
  const dispatch = useDispatch();

  const confirmUserBlock = (client: UserListForAdmin | CheaterUser) => {
    dispatch(modal.showModal(<BlockUserModal user={client} />));
  };

  const confirmUserUnblock = (client: UserListForAdmin | CheaterUser) => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.unblock_confirmation"
          confirmCallback={() => {
            dispatch(user.unblockUser(client.id));
          }}
        />,
      ),
    );
  };

  const sendNotification = (client: UserListForAdmin | CheaterUser) => {
    dispatch(modal.showModal(<SendNotification user={client} />));
  };

  return (
    <>
      <Button
        small
        primary
        text="application.send_notification"
        click={() => sendNotification(client)}
      />
      {client.isBlocked ? (
        <Button small primary text="application.unblock" click={() => confirmUserUnblock(client)} />
      ) : (
        <Button small danger text="application.block" click={() => confirmUserBlock(client)} />
      )}
    </>
  );
};

export default CommonButtons;
