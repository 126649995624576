import { TableResponse } from 'app-tables';
import { ContactListEntity } from 'app-types';

interface GetContactList {
  type: 'GET_CONTACT_LIST_SUCCESS';
  payload: TableResponse<ContactListEntity>;
}

interface LoadingContactList {
  type: 'LOADING_CONTACT_LIST';
  payload: boolean;
}

export interface ContactsState extends TableResponse<ContactListEntity> {
  loading: boolean;
}

const initialState: ContactsState = {
  loading: true,
  items: [],
  countTotal: 0,
};

type Action = GetContactList | LoadingContactList;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_CONTACT_LIST_SUCCESS': {
      return { ...state, contacts: action.payload };
    }
    case 'LOADING_CONTACT_LIST': {
      return { ...state, loading: action.payload };
    }
    default:
      return state;
  }
};
