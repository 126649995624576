import React from 'react';
import { TableSchema, notificationsTableSchema } from 'app-tables';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { ApiList } from '../../../../components/Common';

import NotificationItem from '../Item';

const Administration = () => {
  const scheme: TableSchema = new InteractiveTableSchema(notificationsTableSchema);

  return (
    <ApiList
      noSearch
      apiEndpointSubUrl="notification/mine/administration"
      scheme={scheme}
      component={NotificationItem}
    />
  );
};

export default Administration;
