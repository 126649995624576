import React from 'react';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../components/Common';
import { buildMapsLink } from '../../../utils/google-maps-link';

interface Props {
  startPoint: string;
  finishPoint: string;
  startPointReturn?: string;
  finishPointReturn?: string;
  returnWay?: boolean;
  both?: boolean;
  previewRoute?: boolean;
}

const GoogleMapsButton = ({
  startPoint,
  finishPoint,
  returnWay,
  both,
  previewRoute,
  startPointReturn,
  finishPointReturn,
}: Props) => {
  const getText = () => {
    if (both || previewRoute) return 'mileage.show_google_maps_route';
    if (returnWay) return 'mileage.show_google_maps_route_return';
    return 'mileage.show_google_maps_route_one_way';
  };

  const getAddress = () => {
    if (both) {
      let arr = [startPoint, finishPoint];
      if (startPointReturn && startPointReturn !== finishPoint) arr = [...arr, startPointReturn];
      if (finishPointReturn) arr = [...arr, finishPointReturn];
      return arr;
    }
    if (returnWay) {
      let arr: string[] = [];
      if (startPointReturn) arr = [...arr, startPointReturn];
      if (finishPointReturn) arr = [...arr, finishPointReturn];
      return arr;
    }
    return [startPoint, finishPoint];
  };
  return (
    <>
      <Button
        outline
        to={buildMapsLink(getAddress())}
        leftIcon
        faIcon={faMapMarkerAlt}
        text={getText()}
        className="google-maps-button"
        noShadow
        blank
        disabled={!startPoint || !finishPoint}
      />
    </>
  );
};

export default GoogleMapsButton;
