import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import Overall from './Overall';
import Payments from './Payments';
import Users from './Users';

const statsTabs = [
  {
    title: 'admin.stats_overall',
    link: '/admin/stats/overall',
  },
  {
    title: 'admin.stats_users',
    link: '/admin/stats/users',
  },
  {
    title: 'admin.payments',
    link: '/admin/stats/payments',
  },
];

const Stats = () => (
  <>
    <Page light withNavigation>
      <Dashboard navigation={statsTabs}>
        <Switch>
          <Route exact path="/admin/stats" render={() => <Redirect to="/admin/stats/overall" />} />
          <Route exact path="/admin/stats/overall" component={Overall} />
          <Route exact path="/admin/stats/users" component={Users} />
          <Route exact path="/admin/stats/payments" component={Payments} />
        </Switch>
      </Dashboard>
    </Page>
  </>
);

export default Stats;
