import React from 'react';
import { UserContact } from 'app-types';
import { __ } from '../../../../services/translation';
import './Suggestion.scss';

interface Props {
  item: UserContact;
  onSelect: (data: UserContact) => void;
}

const Suggestion = ({ item, onSelect }: Props) => (
  <li
    role="button"
    onClick={() => {
      onSelect(item);
    }}
    className="contact-suggestion-wrapper"
  >
    <div>
      <span className="contact-suggestion-name">{item.email}</span>
      <div className="contact-suggestion-details">
        {item.name || `${item.firstName} ${item.lastName}`}
      </div>
    </div>
  </li>
);

export default Suggestion;
