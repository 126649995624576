import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  faCheck,
  faHourglassHalf,
  faTimes,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Alert, Button } from '../../components/Common';
import { Page, ButtonsContainer, BottomContainer, Container } from '../../components/Layout';
import { __ } from '../../services/translation';
import useHeader from '../../hooks/useHeader';

import ApiService from '../../services/api-service';

import { modal } from '../../actions';
import { OfferAccepted } from '../../modals';

import './AcceptOffer.scss';
import OfferHistory from '../../modals/OfferHistory';
import { showModal } from '../../actions/modal';

const AcceptOffer = () => {
  useHeader('application.offer');
  const [isSuccess, setIsSuccess] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { offerId, accessToken } = useParams<{ offerId: string; accessToken: string }>();

  const generateInvoice = async () => {
    await ApiService.callFetch(
      'PATCH',
      `offer/accept/${offerId}/${accessToken}`,
      () => {
        dispatch(modal.showModal(<OfferAccepted />));
        setIsSuccess(true);
      },
      () => {
        setIsSuccess(false);
      },
    );
    setLoading(false);
  };

  useEffect(() => {
    generateInvoice();
  }, []);

  const getIconForStatus = () => {
    if (loading) return faHourglassHalf;
    if (isSuccess) return faCheck;
    if (!isSuccess) return faTimes;
    return faTimesCircle;
  };

  const getClassForStatus = () => {
    if (loading) return 'loading';
    if (isSuccess) return 'success';
    if (!isSuccess) return 'error';
    return 'error';
  };

  const getStatusType = () => {
    if (loading) return 'notice';
    if (isSuccess) return 'success';
    if (!isSuccess) return 'error';
    return 'error';
  };

  const getStatusText = () => {
    if (loading) return 'application.loading';
    if (isSuccess) return 'application.offer_accepted';
    if (!isSuccess) return 'application.error';
    return 'application.error';
  };

  const showHistoryModal = () => {
    dispatch(showModal(<OfferHistory id={offerId} />));
  };

  return (
    <>
      <Page withNavigation light>
        <Container>
          <div className="offer-status-container">
            <div className={clsx('offer-processing', getClassForStatus())}>
              <FontAwesomeIcon
                icon={getIconForStatus()}
                className={clsx('accept-status', getClassForStatus())}
              />
            </div>
          </div>
          <div className="accept-offer-paragraph-container">
            <Alert text={getStatusText()} type={getStatusType()} center />
          </div>
          <BottomContainer>
            <Button text="application.history" click={showHistoryModal} />
            <Button text="application.go_back_to_home_page" to="/" />
          </BottomContainer>
        </Container>
      </Page>
    </>
  );
};

export default AcceptOffer;
