import React from 'react';
import { Page } from '../../../components/Layout';
import useHeader from '../../../hooks/useHeader';
import Dashboard from '../../DashboardCommon';
import SetInvoiceNumber from '../../DashboardCommon/SetInvoiceNumber';

const InvoiceNumber = () => {
  useHeader('application.set_invoice_number');
  return (
    <Page light>
      <Dashboard>
        <SetInvoiceNumber />
      </Dashboard>
    </Page>
  );
};

export default InvoiceNumber;
