import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { SomeUserEntity } from 'app-types';
import { lang as langActions, modal } from '../../../actions';
import { Button } from '../../../components/Common';
import { ApplicationState } from '../../../reducers';
import './Language.scss';

interface Props {
  name: string;
  flag: string;
  shortcut: string;
  user: SomeUserEntity | null;
  lang: string;
  hideModal: () => void;
  notLogged?: boolean;
  changeLanguage: (lang: string) => void;
}

const Language = ({ user, name, lang, shortcut, flag, hideModal, changeLanguage }: Props) => {
  const changeOrHide = () => {
    if (lang === shortcut) {
      return hideModal();
    } else {
      changeLanguage(shortcut);
      hideModal();
    }
  };
  return <Button type="button" primary text={name} click={changeOrHide} leftIcon img={flag} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  lang: state.lang.current,
  user: state.user.details,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideModal: modal.hideModal,
      changeLanguage: langActions.changeLanguage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Language);
