import React from 'react';
import { useDispatch } from 'react-redux';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { Button, NarrowParagraph } from '../../components/Common';
import { ButtonsContainer } from '../../components/Layout';
import { modal } from '../../actions';
import { history } from '../../App';

const EditUserData = () => {
  const dispatch = useDispatch();

  const goToData = () => {
    history.push('/inactive');
    dispatch(modal.hideModal());
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.personal_data" />
      </ModalHeader>
      <NarrowParagraph text="application.missing_data" />
      <ButtonsContainer marginTop>
        <Button click={goToData} text="application.fill_data" />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default EditUserData;
