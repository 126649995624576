import React, { ChangeEvent } from 'react';
import { ChangeFunction, FieldAndEventFunction, FormFieldToRender } from 'itf_formbuilder_react';
import Input from '../Input';
import { __ } from '../../../services/translation';
import { AddressAutosuggestion, Captcha } from '..';
import FileInput from '../FileInput';
import DateInput from '../DateInput';
import { parseInputNumber } from '../../../utils/parse-input-number';
import { parseDateInput } from '../../../utils/parse-date-input';
import { formOnTranslateString } from '../../../utils/trans-form';
import InfoButton from '../InfoButton';
import { parseDurationInput } from '../../../utils/parse-duration-input';
import Subheader from '../Subheader';
import './FieldsMapper.scss';

const formatValue = (value: string | number | boolean, type: string, multiple?: boolean) => {
    switch (type) {
        case 'number':
            return parseInputNumber(value);
        case 'time':
            return parseInputNumber(value);
        case 'distance':
            return parseInputNumber(value);
        case 'cost':
            return parseInputNumber(value);
        case 'percent':
            return parseInputNumber(value);
        case 'dateTime':
            return parseDateInput(value, multiple);
        case 'date':
            return parseDateInput(value, multiple);
        case 'duration':
            return parseDurationInput(value);
        default:
            return value;
    }
};

const getSelectOptions = (field: any) => {
    if (!field.options.possibleValues) return [];
    return field.options.possibleValues.map(
        (val: string | { name: string; value: string | number }, index: number) => {
            if (typeof val === 'string') {
                return {
                    name: __(val),
                    value: index,
                };
            } else {
                return {
                    name: __(val.name),
                    value: val.value,
                };
            }
        },
    );
};

const getType = (type: string) => {
    if (type === 'staticSelect') return 'select';
    return type;
};

const FieldsMapper = (
    field: FormFieldToRender,
    errors: any,
    fieldHtmlId: string,
    currentValue: any,
    onChange: ChangeFunction,
    onFocus: FieldAndEventFunction,
    onBlur: FieldAndEventFunction,
    readonly?: boolean,
    data?: {
        noHelp?: boolean;
    },
    customDescriptionInModal?: () => void,
) => {
    if (field.id === 'captchaCode' || fieldHtmlId === 'captchaCode') {
        return <Captcha onChange={onChange} />
    }
    if (['groupStart', 'groupEnd', 'variablePlaceholder'].includes(field.type)) return <></>;

    else if (field.type === 'header') {
        return <Subheader text={field.title} />;
    } else if (field.id === 'startPoint') {
        return (
            <AddressAutosuggestion
                required={field.required}
                label={field.title}
                readonly={readonly}
                input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange,
                }}
                placeholder={field.placeholder}
                meta={{
                    touched: errors.length > 0,
                    error: errors.length
                        ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
                        : null,
                }}
            />
        );
    } else if (field.id === 'finishPoint') {
        return (
            <AddressAutosuggestion
                required={field.required}
                label={field.title}
                readonly={readonly}
                input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange,
                }}
                placeholder={field.placeholder}
                meta={{
                    touched: errors.length > 0,
                    error: errors.length
                        ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
                        : null,
                }}
            />
        );
    } else if (field.type === 'dateTime' || field.type === 'date') {
        return (
            <DateInput
                label={field.title}
                required={field.required}
                description={field.descr}
                input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    placeholder: field.placeholder,
                    value: currentValue,
                    onChange: (value: any) => {
                        onChange(formatValue(value, field.options.type || field.type, field.options.multiple));
                    },
                    onFocus,
                    onBlur,
                }}
                multiple={field.options.multiple}
                meta={{
                    touched: errors.length > 0,
                    error: errors.length
                        ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
                        : null,
                }}
            />
        );
    } else if (field.type === 'fileDragDrop') {
        return (
            <FileInput
                accept={field.options.accept}
                hasImage={field.options.previewUrl}
                label={field.title}
                input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    value: currentValue,
                    onChange,
                    onFocus,
                    onBlur,
                }}
                meta={{
                    touched: errors.length > 0,
                    error: errors.length
                        ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
                        : null,
                }}
            />
        );
    } else if (['checkbox'].includes(field.type)) {
        return (
            <div className="field">
                <Input
                    type={field.options.type || field.type}
                    required={field.required}
                    label={field.title}
                    readonly={readonly}
                    input={{
                        name: fieldHtmlId,
                        id: fieldHtmlId,
                        placeholder: field.placeholder,
                        value: currentValue,
                        onChange: (e: ChangeEvent<HTMLInputElement>) =>
                            onChange(formatValue(e.target.checked, 'checkbox')),
                        onFocus,
                        onBlur,
                    }}
                    step={field.options.step}
                    placeholder={field.placeholder}
                    meta={{
                        touched: errors.length > 0,
                        error: errors.length
                            ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
                            : null,
                    }}
                />
                {field.descr && !(data && data.noHelp) && (
                    <div className="field-help">
                        <InfoButton small text={field.descr} header={field.title} />
                    </div>
                )}
                {customDescriptionInModal && (
                    <div className="field-help">
                        <InfoButton small click={customDescriptionInModal} />
                    </div>
                )}
            </div>
        );
    } else if (['duration'].includes(field.id)) {
        return (
            <div className="field">
                <Input
                    type={field.options.type || field.type}
                    required={field.required}
                    label={field.title}
                    readonly={readonly}
                    input={{
                        name: fieldHtmlId,
                        id: fieldHtmlId,
                        placeholder: field.placeholder,
                        value: currentValue,
                        onChange: (e: ChangeEvent<HTMLInputElement>) =>
                            onChange(formatValue(e.target.value, 'text')),
                        onFocus,
                        onBlur: (e: ChangeEvent<HTMLInputElement>) =>
                            onChange(formatValue(e.target.value, 'duration')),
                    }}
                    step={field.options.step}
                    placeholder={field.placeholder}
                    meta={{
                        touched: errors.length > 0,
                        error: errors.length
                            ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
                            : null,
                    }}
                />
                {field.descr && !(data && data.noHelp) && (
                    <div className="field-help">
                        <InfoButton small text={field.descr} header={field.title} />
                    </div>
                )}
                {customDescriptionInModal && (
                    <div className="field-help">
                        <InfoButton small click={customDescriptionInModal} />
                    </div>
                )}
            </div>
        );
    } else if (!['fileDragDrop'].includes(field.type)) {
        console.log('GOT INPUT', fieldHtmlId, currentValue);
        return (
            <div className="field">
                <Input
                    type={getType(field.options.type || field.type)}
                    required={field.required}
                    options={getSelectOptions(field)}
                    label={field.title}
                    input={{
                        name: fieldHtmlId,
                        id: fieldHtmlId,
                        placeholder: field.placeholder,
                        value: currentValue,
                        onChange: (e: ChangeEvent<HTMLInputElement>) =>
                            onChange(formatValue(e.target.value, field.options.type || field.type)),
                        onFocus,
                        onBlur,
                    }}
                    step={field.options.step}
                    placeholder={field.placeholder}
                    meta={{
                        touched: errors.length > 0,
                        error: errors.length
                            ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
                            : null,
                    }}
                />
                {field.descr && !(data && data.noHelp) && (
                    <div className="field-help">
                        <InfoButton small text={field.descr} header={field.title} />
                    </div>
                )}
                {customDescriptionInModal && (
                    <div className="field-help">
                        <InfoButton small click={customDescriptionInModal} />
                    </div>
                )}
            </div>
        );
    }
    return <>field not supported</>;
};

export default FieldsMapper;
