import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { AddressAutosuggestionResponse } from '../../../types/autosuggestion';
import Results from './Results';

export interface AutosuggestionValue {
  first: string;
  second?: string;
  third?: string;
}

interface Props {
  input: {
    name: string;
    id: string;
    value: string;
    onChange: (val: any) => void;
  };
  label: string;
  onSugestionSelect?: (data: AddressAutosuggestionResponse) => void;
  defaultValue?: AutosuggestionValue;
  required?: boolean;
  readonly?: boolean;
  placeholder?: string;
  splitted?: boolean;
  meta: { error: any; touched: any };
}

const AddressAutosuggestion = ({
  splitted,
  meta,
  defaultValue,
  onSugestionSelect,
  input,
  placeholder,
  label,
  required,
  readonly,
}: Props) => {
  const [value, setValue] = useState<AutosuggestionValue>({
    first: '',
    second: '',
    third: '',
  });

  useEffect(() => {
    if (defaultValue) {
      setValue({
        first: defaultValue.first,
      });
      input.onChange(defaultValue);
    }
  }, []);

  const handleSelect = async (address: string, placeId: string) => {
    const result = await geocodeByPlaceId(placeId);
    if (result) {
      const street = result[0].address_components.find((part) => part.types.includes('route'));
      const streetNumber = result[0].address_components.find((part) =>
        part.types.includes('street_number'),
      );
      const postalCode = result[0].address_components.find((part) =>
        part.types.includes('postal_code'),
      );
      const country = result[0].address_components.find((part) => part.types.includes('country'));
      const city = result[0].address_components.find((part) => part.types.includes('locality'));

      const firstParts = [];
      if (street) firstParts.push(street.long_name);
      if (streetNumber) firstParts.push(streetNumber.long_name);
      const secondParts = [];
      if (postalCode) secondParts.push(postalCode.long_name);
      if (city) secondParts.push(city.long_name);

      const first = firstParts.join(' ');
      const second = secondParts.join(' ');
      const third = country ? country.long_name : '';
      const whole = [first, second, third].filter((el) => el).join(', ');

      if (splitted) {
        if (typeof onSugestionSelect === 'function') {
          onSugestionSelect({
            first,
            second,
            third,
          });
        }
        input.onChange(first);
      } else {
        setValue({
          first: whole,
        });
        input.onChange(whole);
      }
    } else {
      input.onChange(address);
    }
  };
  const handleChange = (address: string) => {
    input.onChange(address);
    setValue({
      first: address,
    });
  };

  return (
    <>
      <PlacesAutocomplete value={input.value} onChange={handleChange} onSelect={handleSelect}>
        {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
          <Results
            placeholder={placeholder}
            meta={meta}
            input={input}
            label={label}
            required={required}
            readonly={readonly}
            getInputProps={getInputProps}
            getSuggestionItemProps={getSuggestionItemProps}
            suggestions={suggestions}
            loading={loading}
          />
        )}
      </PlacesAutocomplete>
    </>
  );
};

export default AddressAutosuggestion;
