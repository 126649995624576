import React from 'react';
import { faChessBoard, faHistory } from '@fortawesome/free-solid-svg-icons';
import { TimeSheetEntity } from 'app-types';
import { differenceInMinutes } from 'date-fns';
import { ListItem } from '../../../components/Common';
import { formatDate } from '../../../utils/format-date';
import { formatDuration } from '../../../utils/format-duration';
import { __ } from '../../../services/translation';
import { formatTime } from '../../../utils/format-time';

interface Props {
  item: TimeSheetEntity;
  onClick: (data: TimeSheetEntity) => void;
}

const SingleItem = ({ item, onClick }: Props) => (
  <>
    <ListItem
      header={item.name}
      buttons={[
        {
          text: 'application.attach',
          type: 'secondary',
          click: () => onClick(item),
        },
      ]}
      paragraphs={[
        {
          icon: 'time_sheets',
          text: (
            <span>
              <strong>
                {formatDuration(
                  differenceInMinutes(new Date(item.finish), new Date(item.start)),
                  true,
                )}
              </strong>
            </span>
          ),
        },
        {
          icon: faHistory,
          text: (
            <span>
              <strong>{formatDate(item.start)}</strong> {formatTime(item.start)}
            </span>
          ),
        },
        {
          icon: faChessBoard,
          text: (
            <span>
              <strong>{formatDate(item.finish)}</strong> {formatTime(item.finish)}
            </span>
          ),
        },
      ]}
    />
  </>
);

export default SingleItem;
