import React from 'react';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { TimeSheetSchemeForm } from '../../components/Common';
import { FormContainer } from '../../components/Layout';

interface Props {
  id: string;
}
const EditTimeSheetScheme = ({ id }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_time_sheet_scheme" />
    </ModalHeader>
    <FormContainer>
      <TimeSheetSchemeForm
        formUrl={`time-sheet/template/form/update-item/${id}`}
        formSendUrl={`time-sheet/template/${id}`}
        method="PATCH"
        edit
      />
    </FormContainer>
  </ModalContent>
);

export default EditTimeSheetScheme;
