import React from 'react';
import { UpdateActivated, UpdateActivatedReload, UpdateAvailable } from 'react-workbox';
import { __ } from '../../../services/translation';
import './UpdateInfoBar.scss';

const onUpdateClick = async () => {
  const registration = await navigator.serviceWorker.getRegistration();
  if (registration && registration.waiting) {
    registration.waiting.postMessage('skipWaiting');
  }
  setTimeout(() => window.location.reload(true));
};

const UpdateInfoBar = () => (
  <>
    <UpdateAvailable>
      <div className="update-info-bar" onClick={onUpdateClick} role="button" tabIndex={0}>
        {__('application.app_update_info')}
      </div>
    </UpdateAvailable>
    <UpdateActivated>
      <div className="update-info-bar" onClick={onUpdateClick} role="button" tabIndex={0}>
        {__('application.app_updated')}
      </div>
    </UpdateActivated>
    <UpdateActivatedReload />
  </>
);

export default UpdateInfoBar;
