import React from 'react';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { ButtonsContainer } from '../../components/Layout';
import { Button } from '../../components/Common';
import { __ } from '../../services/translation';
import './SelectServiceType.scss';

interface Props {
  selectService: (services: any[]) => void;
  newService: (services: any[]) => void;
}

const SelectServiceType = ({ selectService, newService }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.choose" />
    </ModalHeader>
    <p className="select-service-info">{__('application.service_template_info')}</p>
    <ButtonsContainer max>
      <Button text="application.existing_service" click={selectService} />
      <Button secondary text="application.new_service" click={newService} />
    </ButtonsContainer>
  </ModalContent>
);

export default SelectServiceType;
