import React from 'react';
import { ClientUserEntity } from 'app-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Page, Content, Container } from '../../components/Layout';
import { Button, Logo, WelcomeHeader } from '../../components/Common';
import { ApplicationState } from '../../reducers';
import './Blocked.scss';
import { __ } from '../../services/translation';
import { generatePaymentToUnblock } from '../../actions/user';

const Blocked = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.user.details);
    if (!user) return <Redirect to="/" />;

    const generatePayment = () => {
        dispatch(generatePaymentToUnblock());
    };

    return (
        <>
            <Page center className="blocked-page">
                <Container paddingTop>
                    <Logo big center dark />
                    <Content center>
                        <WelcomeHeader text="application.blocked_account" />
                    </Content>
                    <h2>{__('application.block_reason')}</h2>
                    <p>
                        {(user as ClientUserEntity).amountToPay !== null
                            ? __('application.block_reason_payment', {
                                description: (user as ClientUserEntity).blockReason,
                            })
                            : (user as ClientUserEntity).blockReason}
                    </p>
                    <div className="get-payment-link-button-container">
                        <div>
                            {(user as ClientUserEntity).amountToPay !== null && (
                                <Button text="application.generate_payment" click={generatePayment} />
                            )}
                        </div>
                    </div>
                    {/* For flexbox to work properly */}
                    <span />
                </Container>
            </Page>
        </>
    );
};

export default Blocked;
