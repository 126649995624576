export const receiveStartTime = (hour: string, min: string) => ({
  type: 'RECEIVE_START_TIME',
  payload: `${hour}:${min}`,
});

export const receiveFinishTime = (hour: string, min: string) => ({
  type: 'RECEIVE_FINISH_TIME',
  payload: `${hour}:${min}`,
});

export const receiveStartDate = (date: string) => ({
  type: 'RECEIVE_START_DATE',
  payload: date,
});

export const receiveFinishDate = (date: string) => ({
  type: 'RECEIVE_FINISH_DATE',
  payload: date,
});

export const receiveTimerFinishDate = (date: string) => ({
  type: 'RECEIVE_TIMER_FINISH_DATE',
  payload: date,
});

export const clearTime = () => ({
  type: 'CLEAR_TIME',
});
