import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { ContactRequest, ContactListEntity } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ApiService from '../../services/api-service';
import { Spinner, Button, FieldsMapper, RequiredIndicator } from '../../components/Common';
import { notifications, modal, apiTable } from '../../actions';
import { formOnTranslateString } from '../../utils/trans-form';
import { FormContainer, ButtonsContainer } from '../../components/Layout';

interface Props {
  contact: ContactListEntity;
  successNotification: (text: string) => void;
  refreshApiTable: () => void;
  hideModal: () => void;
}

const EditContactName = ({ contact, successNotification, hideModal, refreshApiTable }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_contact" />
    </ModalHeader>
    <FormContainer>
      <ItfApiForm
        formId="edit-contact-name"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm(`contact-list/form/update-item/${contact.id}`)}
        sendFetcher={(formId: string, values: ContactRequest) =>
          ApiService.sendForm(`contact-list/${contact.id}`, values, 'PATCH')
        }
        onSavedSuccessfully={() => {
          successNotification('application.contact_was_edited.');
          hideModal();
          refreshApiTable();
        }}
        loadingInfo={<Spinner halfTransparent overlay />}
        loadingInfoAboveContent
        submitButton={
          <ButtonsContainer>
            <Button type="submit" className="form-submit-button" text="application.save" primary />
          </ButtonsContainer>
        }
        onTranslateString={formOnTranslateString}
        onRenderFullField={FieldsMapper}
      />
    </FormContainer>
  </ModalContent>
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
      refreshApiTable: apiTable.refreshApiTable,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(EditContactName);
