import React from 'react';
import './FieldsGroup.scss';

interface Props {
  children: React.ReactNode;
}

const FieldsGroup = ({ children }: Props) => <div className="fields-group">{children}</div>;

export default FieldsGroup;
