import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { TaxRate, ServiceEntity, ServiceType, DiscountType } from 'app-types';
import { Field, change, getFormMeta } from 'redux-form';
import { modal } from '../../../../../../actions';
import {
  Input,
  Button,
  Subheader,
  ListElementWrapper,
  ListElementsWrapper,
  TaxRateTable,
} from '../../../../../../components/Common';
import ValidationService from '../../../../../../services/validation-service';
import { __ } from '../../../../../../services/translation';
import { parseInputNumber } from '../../../../../../utils/parse-input-number';
import { FieldsGroup } from '../../../../../../components/Layout';
import { ApplicationState } from '../../../../../../reducers';
import { formatDuration } from '../../../../../../utils/format-duration';
import ChooseServiceFill from '../../../../../../modals/ChooseServiceFill';
import { percentFromTaxRate } from '../../../../../../utils/tax-rate-percent';
import './RenderServices.scss';

interface Props {
  fields: any;
  meta: any;
  data: any;
  isDirty: boolean[];
  showModal: (content: React.ReactNode) => void;
  changeField: (formId: string, fieldId: string, value: any) => void;
}

class RenderServices extends React.Component<Props, {}> {
  componentDidMount() {
    const { data, fields } = this.props;
    if (fields.length === 0) {
      fields.push({
        quantity: 1,
        type: ServiceType.UNIT,
        taxRate: Number(TaxRate['21%']),
        net: true,
        price: 0,
        discountType: DiscountType.PERCENT,
        discount: 0,
      });
    }
  }

  componentDidUpdate() {
    const { fields } = this.props;

    if (fields.length === 0) {
      fields.push({
        quantity: 1,
        type: ServiceType.UNIT,
        taxRate: Number(TaxRate['21%']),
        net: true,
        price: 0,
        discountType: DiscountType.PERCENT,
        discount: 0,
      });
    }
  }

  private isServiceCorrect = (service: ServiceEntity, index: number) => {
    const { isDirty } = this.props;
    if (!isDirty[index]) return true;
    if (!service.name) return false;
    if (!service.quantity && service.quantity === 0) return false;
    if (!service.price && service.price !== 0) return false;
    if (
      typeof service.discount === 'undefined' ||
      service.discount === null ||
      service.discount < 0
    ) {
      return false;
    }
    return true;
  };

  private getQuantity = (service: ServiceEntity) => {
    if (typeof service.quantity !== 'number') return '-';
    if (service.type === ServiceType.UNIT) {
      return service.quantity || 0;
    } else if (service.type === ServiceType.TIME) {
      // to get minutes
      return formatDuration(service.quantity || 0, true);
    }
  };

  private getServicePrice = (service: ServiceEntity) => {
    const { quantity, taxRate, discount, discountType } = service;
    let sum = 0;
    if (typeof quantity !== 'number') return 0;
    const correctQuantity = quantity || 0;
    if (service.type === ServiceType.UNIT) {
      sum = correctQuantity * service.price;
    } else if (service.type === ServiceType.TIME) {
      // to get minutes
      sum = (quantity / 60) * service.price;
    }

    if (service.net) sum += (percentFromTaxRate(taxRate) / 100) * sum;

    if (discountType === DiscountType.PERCENT) sum *= 1 - discount / 100;
    else sum -= service.quantity >= 0 ? discount : -discount;

    return sum;
  };

  render() {
    const { data, fields, showModal } = this.props;
    return (
      <>
        <ListElementsWrapper empty={fields.length === 0}>
          <Subheader text="application.offer_lines" />
          {fields.map(
            (service: any, index: any) =>
              data[index] && (
                <li key={service} id={`service-${index}`}>
                  <ListElementWrapper
                    className="service-element-wrapper"
                    header={`${this.getQuantity(data[index])} x ${data[index].name ||
                      __('application.new_service_product')}`}
                    price={this.getServicePrice(data[index])}
                    invalid={!this.isServiceCorrect(data[index], index)}
                    icon="cross"
                    click={fields.length > 1 ? () => fields.remove(index) : undefined}
                    defaultMinimized={false}
                    noMaximizedBorder
                    additionalRight={
                      !data[index].name && (
                        <Button
                          // extraSmall
                          className="fill-service-button"
                          type="button"
                          leftIcon
                          icon="plus-no-margin"
                          click={(e: MouseEvent) => {
                            e.stopPropagation();
                            showModal(
                              <ChooseServiceFill formName="offerForm" index={index} noCosts />,
                            );
                          }}
                        />
                      )
                    }
                  >
                    <Field
                      name={`${service}.name`}
                      component={Input}
                      label="application.service_name"
                      validate={[ValidationService.required, ValidationService.max80]}
                      required
                      placeholder="application.service_placeholder"
                    />

                    <FieldsGroup>
                      <div className="field-with-select">
                        {data[index].type === ServiceType.TIME && (
                          <Field
                            name={`${service}.quantity`}
                            type="duration"
                            component={Input}
                            validate={[ValidationService.required]}
                            label="application.quantity"
                            placeholder="application.work_duration_placeholder"
                            // parse={parseDurationInput}
                            key={`${service}.quantity-${index.toString()}`}
                            required
                          />
                        )}
                        {data[index].type === ServiceType.UNIT && (
                          <Field
                            name={`${service}.quantity`}
                            type="number"
                            step="0.01"
                            component={Input}
                            validate={[ValidationService.required]}
                            min={-99999999}
                            label="application.quantity"
                            placeholder="1"
                            max={99999999}
                            parse={parseInputNumber}
                            key={`${service}.quantity-${index.toString()}`}
                            required
                          />
                        )}
                        <Field
                          name={`${service}.type`}
                          type="select"
                          selectOnlyArrow
                          component={Input}
                          options={[
                            {
                              value: ServiceType.UNIT,
                              name: __('application.invoice_service_unit'),
                            },
                            {
                              value: ServiceType.TIME,
                              name: __('application.invoice_service_time'),
                            },
                          ]}
                          parse={parseInputNumber}
                          validate={ValidationService.required}
                          key={`${service}.type-${index.toString()}`}
                        />
                      </div>
                      <div className="field-with-select">
                        <Field
                          name={`${service}.price`}
                          type="cost"
                          step="0.0001"
                          min={-99999999}
                          component={Input}
                          label={
                            data[index].type === ServiceType.UNIT
                              ? 'application.price'
                              : 'application.price'
                          }
                          placeholder="10,00"
                          validate={[ValidationService.required]}
                          parse={parseInputNumber}
                          required
                        />
                        {data[index].taxRate === TaxRate['0%'] ||
                        data[index].taxRate === TaxRate['BTW Verlegd'] ||
                        data[index].taxRate === TaxRate['BTW vrijgesteld'] ? null : (
                          <Field
                            name={`${service}.net`}
                            type="select"
                            selectOnlyArrow
                            component={Input}
                            options={[
                              { value: true, name: __('application.net_for_user') },
                              { value: false, name: __('application.gross_for_user') },
                            ]}
                            parse={(val: string) => val === 'true'}
                            validate={ValidationService.requiredWithAllowedFalse}
                          />
                        )}
                      </div>

                      <Field
                        name={`${service}.taxRate`}
                        type="select"
                        component={Input}
                        label="application.btw_value"
                        options={[
                          { value: TaxRate['21%'], name: '21%', key: 'taxRate11' },
                          { value: TaxRate['9%'], name: '9%', key: 'taxRate12' },
                          { value: TaxRate['0%'], name: '0%', key: 'taxRate13' },
                          { value: TaxRate['BTW Verlegd'], name: 'BTW Verlegd' },
                          { value: TaxRate['BTW vrijgesteld'], name: 'BTW vrijgesteld' },
                        ]}
                        parse={parseInputNumber}
                        validate={ValidationService.required}
                        key={`${service}.taxRate-${index.toString()}`}
                        required
                      />
                      <div className="field-with-select">
                        {data[index].discountType === DiscountType.PERCENT ? (
                          <Field
                            name={`${service}.discount`}
                            type="percent"
                            component={Input}
                            validate={[ValidationService.max100, ValidationService.required]}
                            label="application.discount"
                            parse={parseInputNumber}
                            placeholder="10"
                            required
                          />
                        ) : (
                          <Field
                            name={`${service}.discount`}
                            type="cost"
                            component={Input}
                            validate={[ValidationService.required]}
                            label="application.discount"
                            parse={parseInputNumber}
                            placeholder="10"
                            required
                          />
                        )}
                        <Field
                          name={`${service}.discountType`}
                          type="select"
                          component={Input}
                          selectOnlyArrow
                          options={[
                            {
                              value: DiscountType.PERCENT,
                              name: __('application.discount_type_percent'),
                            },
                            {
                              value: DiscountType.VALUE,
                              name: __('application.discount_type_value'),
                            },
                          ]}
                          parse={parseInputNumber}
                          key={`${service}.discountType-${index.toString()}`}
                        />
                      </div>
                      <Input
                        label="application.total"
                        type="cost"
                        min={-99999999}
                        input={{
                          value: Math.round(this.getServicePrice(data[index]) * 100) / 100,
                        }}
                        meta={{}}
                      />
                    </FieldsGroup>

                    <Field
                      name={`${service}.description`}
                      component={Input}
                      type="textarea"
                      label="application.service_description"
                      placeholder="application.service_description_placeholder"
                      validate={ValidationService.max500}
                    />
                  </ListElementWrapper>
                </li>
              ),
          )}
          <div className="invoice-new-line-button-container">
            <Button
              leftIcon
              constant
              small
              transparent
              className="invoice-new-line-button"
              icon="plus"
              type="button"
              text="application.add_new_invoice_line"
              click={() => {
                fields.push({
                  price: 0,
                  quantity: 1,
                  type: ServiceType.UNIT,
                  discount: 0,
                  discountType: DiscountType.PERCENT,
                  taxRate: TaxRate['21%'],
                  net: true,
                });
              }}
            />
          </div>
          {data && data.length > 0 ? <TaxRateTable overall data={data} /> : null}
        </ListElementsWrapper>
      </>
    );
  }
}

const anyTouched = (service: any) =>
  service.name || service.quantity || service.quantity || service.price || service.discount;

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const meta: any = getFormMeta('offerForm')(state);
  return {
    isDirty: ownProps.fields.map(
      (service: ServiceEntity, index: number) =>
        meta.services && meta.services[index] && anyTouched(meta.services[index]),
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: modal.showModal,
      changeField: change,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RenderServices);
