import React, { useEffect, useState } from 'react';
import { faCheck, faCross, faHourglassHalf, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaymentStatus } from 'app-types';
import { useParams } from 'react-router';
import { Alert, Button, Card } from '../../components/Common';
import { Page, ButtonsContainer, BottomContainer, Container } from '../../components/Layout';
import { __ } from '../../services/translation';
import useHeader from '../../hooks/useHeader';
import './PaymentStatus.scss';
import ApiService from '../../services/api-service';

const UserPaymentStatus = () => {
  useHeader('application.payment_status');
  const { paymentId } = useParams<{ paymentId: string }>();
  const [status, setStatus] = useState<PaymentStatus>();
  const [loading, setLoading] = useState<boolean>(true);

  const getStatus = async () => {
    setLoading(true);
    await ApiService.callFetch('GET', `payment/status/${paymentId}`, (status: PaymentStatus) => {
      setStatus(status);
    });
    setLoading(false);
  };

  const getStatusText = () => {
    switch (status) {
      case PaymentStatus.AUTHORIZED:
        return 'payment_status.authorized';
      case PaymentStatus.CANCELED:
        return 'payment_status.canceled';
      case PaymentStatus.EXPIRED:
        return 'payment_status.expired';
      case PaymentStatus.FAILED:
        return 'payment_status.failed';
      case PaymentStatus.OPEN:
        return 'payment_status.open';
      case PaymentStatus.PAID:
        return 'payment_status.paid';
      case PaymentStatus.PENDING:
        return 'payment_status.pending';
      default:
        return 'payment_status.open';
    }
  };

  const getStatusType = () => {
    switch (status) {
      case PaymentStatus.AUTHORIZED:
        return 'notice';
      case PaymentStatus.CANCELED:
        return 'error';
      case PaymentStatus.EXPIRED:
        return 'error';
      case PaymentStatus.FAILED:
        return 'error';
      case PaymentStatus.OPEN:
        return 'error';
      case PaymentStatus.PAID:
        return 'success';
      case PaymentStatus.PENDING:
        return 'notice';
      default:
        return 'notice';
    }
  };

  const getIconForStatus = () => {
    switch (status) {
      case PaymentStatus.AUTHORIZED:
        return faHourglassHalf;
      case PaymentStatus.PAID:
        return faCheck;
      case PaymentStatus.PENDING:
        return faHourglassHalf;
      default:
        return faTimes;
    }
  };

  useEffect(() => {
    getStatus();
    const interval = setInterval(getStatus, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Page withNavigation light>
        <Container paddingTop>
          <Card
            body={
              <>
                <div className="payment-processing-container">
                  <div className="payment-processing">
                    <FontAwesomeIcon icon={getIconForStatus()} className="payment-icon-success" />
                  </div>
                </div>
                <div className="payment-processing-paragraph-container">
                  <Alert text={getStatusText()} type={getStatusType()} center />
                </div>
              </>
            }
          />

          <ButtonsContainer marginTop>
            <Button text="application.go_back_to_home_page" to="/" />
          </ButtonsContainer>
        </Container>
      </Page>
    </>
  );
};

export default UserPaymentStatus;
