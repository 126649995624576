import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DiscountType, ServiceEntity, SaveServiceSchemaReq } from 'app-types';
import ReactSelect from 'react-select';
import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
} from 'itf_formbuilder_react';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ApiService from '../../services/api-service';
import {
  Spinner,
  Button,
  FieldsMapper,
  RequiredIndicator,
  SelectWithSuggestion,
  Input,
} from '../../components/Common';
import { history, flatpickr } from '../../App';
import { notifications, modal } from '../../actions';
import { FormContainer, ButtonsContainer } from '../../components/Layout';
import { formOnTranslateString } from '../../utils/trans-form';
import { __ } from '../../services/translation';

interface Props {
  successNotification: (text: string) => void;
  callback: (service: ServiceEntity) => void;
  hideModal: () => void;
}

const AddService = ({ successNotification, hideModal, callback }: Props) => {
  const [type, setType] = useState<DiscountType>(DiscountType.PERCENT);

  const registerFormCallbacks = () => {
    window.globalForms['add-service'].valueChangedClb = (newValues: ServiceEntity) => {
      setType(Number(newValues.discountType));
    };
  };
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.new_service" />
      </ModalHeader>
      <FormContainer>
        <ItfApiForm
          formId="add-service"
          schemaMayBeDynamic={false}
          schemaCacheEnabled
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm('service-schema/form/add-item')}
          sendFetcher={(formId: string, values: SaveServiceSchemaReq) =>
            ApiService.sendForm('service-schema/new', values, 'POST')
          }
          onSavedSuccessfully={(service: ServiceEntity) => {
            successNotification('application.service_was_added');
            callback(service);
            hideModal();
          }}
          loadingInfo={<Spinner transparent />}
          onRenderFullField={(
            field: FormFieldToRender,
            errors: any,
            fieldHtmlId: string,
            currentValue: any,
            onChange: ChangeFunction,
            onFocus: FieldAndEventFunction,
            onBlur: FieldAndEventFunction,
          ) => {
            if (field.id === 'discount') {
              return (
                <Input
                  required={field.required}
                  label={field.title}
                  input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange: (e: ChangeEvent<HTMLInputElement>) => {
                      onChange(e.target.value);
                    },
                  }}
                  maxValue={type === DiscountType.PERCENT ? 100 : 100000}
                  type={type === DiscountType.PERCENT ? 'percent' : 'cost'}
                  placeholder={field.placeholder}
                  meta={{
                    touched: errors.length > 0,
                    error: errors.length
                      ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
                      : null,
                  }}
                />
              );
            } else {
              return FieldsMapper(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              );
            }
          }}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text="application.save"
                success
              />
            </ButtonsContainer>
          }
          onTranslateString={formOnTranslateString}
          thirdPartyComponents={{
            flatpickr,
            ReactSelect,
            ReactCreatableSelect: SelectWithSuggestion,
          }}
          registerAsGlobalForm
          onFormGloballyRegistered={registerFormCallbacks}
        />
      </FormContainer>
    </ModalContent>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(AddService);
