import React, { Component } from 'react';
import { TableSchema, changeDataTable } from 'app-tables';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ClientUserEntity } from 'app-types';
import { InteractiveTableSchema } from '../../../utils/table-shcema-utils';
import { ApiTable } from '../../../components/Common';
import { Confirmation, UserDetails } from '../../../modals';
import { modal, user, header } from '../../../actions';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';

interface Props {
  showModal: (content: React.ReactNode) => void;
  confirmDataChange: (id: string) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class NewUsers extends Component<Props> {
  private scheme: TableSchema;

  constructor(props: Props) {
    super(props);
    this.scheme = new InteractiveTableSchema(changeDataTable)
      .removeFields('ownerId')
      .addFieldAtTheEnd({
        name: 'application.actions',
        field: 'actions',
        mobile: true,
        buttons: [
          { name: 'application.confirm', type: 'success', click: this.confirmActivation },
          { name: 'application.current_data', type: 'primary', click: this.showCurrentData },
        ],
      });
  }

  componentDidMount() {
    const { setHeader } = this.props;
    setHeader('application.update_requests');
  }

  private confirmActivation = (user: ClientUserEntity) => {
    const { showModal, confirmDataChange } = this.props;
    showModal(
      <Confirmation
        confirmCallback={() => confirmDataChange(user.id)}
        text="application.do_you_want_to_confirm_update_request?"
      />,
    );
  };

  private showCurrentData = (user: any) => {
    const { showModal } = this.props;
    showModal(<UserDetails id={user.ownerId} />);
  };

  render() {
    return (
      <Page light>
        <Dashboard>
          <ApiTable apiEndpointSubUrl="user/change-data-list" scheme={this.scheme} />
        </Dashboard>
      </Page>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      confirmDataChange: user.confirmDataChange,
      showModal: modal.showModal,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(NewUsers);
