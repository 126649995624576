import { AuthLoginUserResult } from '..';
import { UserEntity } from '../user';

export enum AccountantConnectorStatus {
  ACTIVE,
  WAITING_FOR_CLIENT_CONFIRMATION,
  WAITING_FOR_ACCOUNTANT_CONFIRMATION,
}

export interface AccountantConnectorEntity {
  id: string;
  accountant: UserEntity;
  managedUser: UserEntity;
  status: AccountantConnectorStatus;
  createdAt: Date;
  incoming: string;
  expenses: string;
  documents: string;
}

export interface AccountantInvitationEntity {
  id: string;
  lastInvitationDate: Date;
  invitationSentCount: number;
  email: string;
}

export interface AccountantConnectorDetails {
  id: string;
  name: string;
  email: string;
  status: AccountantConnectorStatus;
  createdAt: Date;
  userCreatedAt: Date;
}

export interface AddConnectorReq {
  email: string;
}

export interface SendInvitationToCreateAccount {
  email: string;
}

export interface DigitaleBrievenbus {
  expenses: string | null;
  documents: string | null;
  incoming: string | null;
  digitalBrievenbusFrom: Date;
}

export interface FullDataRes extends AuthLoginUserResult {
  digitaleBrievenbus: DigitaleBrievenbus;
}
