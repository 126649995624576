import { ChangeFunction, FieldAndEventFunction, FormFieldToRender } from 'itf_formbuilder_react';
import React from 'react';
import { CategoryRes } from '../../../../../../heyzzp-back/types';
import { FieldsMapper, FileInput } from '../../../../components/Common';
import { __ } from '../../../../services/translation';
import { formOnTranslateString } from '../../../../utils/trans-form';
import { SharedFileWithCb } from '../../../../types/share';

interface Props {
  file?: SharedFileWithCb;
  data: {
    field: FormFieldToRender;
    errors: any;
    fieldHtmlId: string;
    currentValue: any;
    onChange: ChangeFunction;
    onFocus: FieldAndEventFunction;
    onBlur: FieldAndEventFunction;
    onCategorySelect?: (category: CategoryRes | null) => void;
    onNewCategorySelect?: (val: string) => void;
  };
}

const OtherIncomeFieldsMapper = ({
  file,
  data: { field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur },
}: Props) => {
  if (field.type === 'fileDragDrop') {
    return (
      <FileInput
        accept={field.options.accept}
        hasImage={field.options.previewUrl}
        label={field.title}
        input={{
          name: fieldHtmlId,
          id: fieldHtmlId,
          value: currentValue,
          onChange,
          onFocus,
          onBlur,
        }}
        meta={{
          touched: errors.length > 0,
          error: errors.length
            ? formOnTranslateString(errors[0].messageWithPlaceholders, errors[0].params)
            : null,
        }}
        sharedFile={file}
      />
    );
  } else return FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur);
};

export default OtherIncomeFieldsMapper;
