import React, { ChangeEvent, useEffect, useState } from 'react';
import { AccountantConnectorDetails, FullDataRes } from 'app-types';
import { useDispatch } from 'react-redux';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import ApiService from '../../services/api-service';
import { Alert, Button, Input, Spinner, Subheader } from '../../components/Common';
import { ButtonsContainer } from '../../components/Layout';
import { accounting, modal } from '../../actions';
import './SelectManagedUser.scss';
import { history } from '../../App';

const SelectManagedUser = () => {
  const [managedUsers, setManagedUsers] = useState<AccountantConnectorDetails[]>();
  const [search, setSearch] = useState<string>();
  const dispatch = useDispatch();

  const getMine = async () => {
    await ApiService.callFetch(
      'GET',
      'accountant-connector/my-managed-accepted',
      (users: AccountantConnectorDetails[]) => {
        setManagedUsers(users);
      },
    );
  };

  const selectManagedUser = async (connector: AccountantConnectorDetails) => {
    await ApiService.callFetch(
      'GET',
      `accountant-connector/full-data/${connector.id}`,
      (fullData: FullDataRes) => {
        dispatch(accounting.selectManagedUser(fullData));
        history.push('/');
      },
    );

    dispatch(modal.hideModal());
  };

  useEffect(() => {
    getMine();
  }, []);

  if (!managedUsers) return <Spinner />;

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.select_managed_user" />
      </ModalHeader>
      <p className="managed-users-description">{__('application.select_managed_user_info')}</p>
      <Subheader text="application.managed_users" />
      {managedUsers.length === 0 && <Alert type="notice" text="application.no_managed_users" />}
      {managedUsers.length > 0 && (
        <Input
          type="text"
          onChange={(e) => setSearch(e)}
          placeholder="application.search"
          input={{
            value: search,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
            },
          }}
          meta={{}}
        />
      )}
      <ul>
        {managedUsers
          .filter((user) => !search || user.name.toLowerCase().includes(search.toLowerCase()))
          .map((user) => (
            <li key={user.id} className="single-user">
              <div>
                <div>
                  <strong>{user.name}</strong>
                </div>
                <div>
                  <span>{user.email}</span>
                </div>
              </div>
              <ButtonsContainer>
                <Button small text="application.choose" click={() => selectManagedUser(user)} />
              </ButtonsContainer>
            </li>
          ))}
      </ul>
    </ModalContent>
  );
};

export default SelectManagedUser;
