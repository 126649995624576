import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { AuthLoginRequest } from 'app-types';
import { Link } from 'react-router-dom';
import { isMobileBuild } from '../../utils/is-mobile';
import ValidationService from '../../services/validation-service';
import { auth, header } from '../../actions';
import { Page, Content, BottomContainer, FormContainer, ButtonsContainer } from '../../components/Layout';
import { Logo, Button, Input, WelcomeHeader, Spinner } from '../../components/Common';
import { __ } from '../../services/translation';
import { ApplicationState } from '../../reducers';
import './Login.scss';

interface Props {
    login: (userData: AuthLoginRequest) => void;
    loading: boolean;
    showActivationMessage: boolean;
    setHeader: (header: string) => void;
}

class Login extends Component<InjectedFormProps<AuthLoginRequest> & Props> {
    componentDidMount() {
        const { setHeader } = this.props;
        setHeader('');
    }

    render() {
        const { handleSubmit, login, loading, showActivationMessage } = this.props;
        const url =
            typeof process.env.REACT_APP_PUBLIC_URL === 'string'
                ? `${process.env.REACT_APP_PUBLIC_URL.trim()}/`
                : 'http://localhost:3000/';
        return (
            <>
                <Page stretch padded center primary>
                    <Logo animate={loading} big />
                    <Content center>
                        <WelcomeHeader text="application.sign_in" />
                    </Content>
                    {showActivationMessage && (
                        <div className="activation-info">{__('application.activation_info')}</div>
                    )}
                    <FormContainer lightLabels stretch>
                        <form
                            onSubmit={handleSubmit((values: AuthLoginRequest) => {
                                login(values);
                            })}
                        >
                            {loading && <Spinner overlay dark />}
                            <div className="form-fields">
                                <Field
                                    label="application.e-mail"
                                    type="email"
                                    name="email"
                                    component={Input}
                                    validate={[ValidationService.required, ValidationService.email]}
                                    required
                                    placeholder="application.email_placeholder"
                                />
                                <Field
                                    type="password"
                                    name="pwd"
                                    label="application.password"
                                    component={Input}
                                    validate={[ValidationService.required, ValidationService.passwordLength]}
                                    required
                                    placeholder="application.password_placeholder"
                                />
                            </div>
                            <BottomContainer>
                                <Button type="submit" primary text="application.login_to_account" />
                                {!isMobileBuild() && (
                                    <>

                                        <div className="box-separate">
                                            <Link to="/restore-pass" className="login-footer-link">
                                                {__('application.forgot_password?')}
                                            </Link>
                                            <Link to="/send-activation-link" className="login-footer-link">
                                                {__('application.no_activation_link')}
                                            </Link>
                                        </div>
                                        <p className='login-footer-question'>
                                            {__("application.don't_have_an_account?")}

                                        </p>

                                        <Button small full primary to="/registration" text='application.sign_up' />

                                        <Button small full primary to="/register-accountant" text='application.register_as_accountant' />



                                    </>
                                )}

                            </BottomContainer>
                        </form>
                    </FormContainer>
                </Page>
            </>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.user.loading,
    showActivationMessage: state.user.showActivationMessage,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ login: auth.login, setHeader: header.setHeader }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm<any, any>({
        form: 'loginForm',
        enableReinitialize: true,
    })(Login),
);
