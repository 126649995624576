import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { history } from '../../App';
import { notifications, modal, apiList } from '../../actions';
import CostForm from '../../components/Common/CostForm';

interface Props {
  id: string;
  successNotification: (text: string) => void;
  hideModal: () => void;
  refreshApiList: () => void;
}

const EditCost = ({ id, successNotification, hideModal, refreshApiList }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_cost" />
    </ModalHeader>
    {/* Form data handled outside form */}
    <CostForm
      method="PATCH"
      formUrl={`cost/form/update-item/${id}`}
      formSendUrl={`cost/one/${id}`}
      callback={() => {
        successNotification('application.cost_was_edited.');
        refreshApiList();
        hideModal();
        history.push('/dashboard/costs/list');
      }}
    />
  </ModalContent>
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
      refreshApiList: apiList.refreshApiList,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(EditCost);
