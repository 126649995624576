import { Dispatch } from 'redux';
import ApiService from '../services/api-service';
import { modal, notifications } from '../actions';
import { refreshApiList } from './api-list';

export const deleteCost = (id: string) => async (dispatch: Dispatch<any>) => {
    await ApiService.delete(
        dispatch,
        'DELETE_COST_SUCCESS',
        'DELETE_COST_FAILED',
        () => {
            dispatch(modal.hideModal());
            dispatch(notifications.successNotification('application.cost_was_deleted.'));
            dispatch({ type: 'REFRESH_API_TABLE' });
            dispatch({ type: 'REFRESH_API_LIST' });
        },
        null,
        `cost/one/${id}`,
        'LOADING_DELETE_COST',
    );
};

export const getCostDetails = (id: string) => async (dispatch: Dispatch) => {
    await ApiService.get(
        dispatch,
        'GET_SINGLE_COST_DETAILS_SUCCESS',
        'GET_SINGLE_COST_DETAILS_FAILED',
        null,
        null,
        `cost/detail/${id}`,
        'LOADING_SINGLE_COST',
    );
};

export const getUnpaidCostsCount = (categoryId?: string) => async (dispatch: Dispatch) => {
    await ApiService.get(
        dispatch,
        'SET_COSTS_UNPAID_COUNT_SUCCESS',
        'SET_COSTS_UNPAID_COUNT_FAILED',
        null,
        null,
        `cost/get-not-paid-count/${categoryId || ''}`,
        'LOADING_COSTS_UNPAID_COUNT',
    );
};

export const getUnpaidInCategoryCostsCount = (categoryId?: string) => async (dispatch: Dispatch) => {
    await ApiService.get(
        dispatch,
        'SET_COSTS_UNPAID_COUNT_SUCCESS',
        'SET_COSTS_UNPAID_COUNT_FAILED',
        null,
        null,
        `cost/get-not-paid-count-in-category/${categoryId || ''}`,
        'LOADING_COSTS_UNPAID_COUNT',
    );
};

export const setUnpaidCostsCount = (count: number) => ({
    type: 'SET_COSTS_UNPAID_COUNT_SUCCESS',
    payload: count
})

export const markAllCostsAsPaid = () => async (dispatch: Dispatch) => {
    await ApiService.patch(
        dispatch,
        'MARK_ALL_COSTS_AS_PAID_SUCCESS',
        'MARK_ALL_COSTS_AS_PAID_FAILED',
        () => {
            dispatch(notifications.successNotification('application.all_costs_marked_as_paid'));
            dispatch(setUnpaidCostsCount(0));
            dispatch(refreshApiList());
        },
        null,
        'cost/mark-all-as-paid/',
        'LOADING_MARK_ALL_COSTS_AS_PAID',
        {},
    );
};

export const markAllCostsAsPaidInCategory = (categoryId?: string) => async (dispatch: Dispatch) => {
    await ApiService.patch(
        dispatch,
        'MARK_ALL_COSTS_AS_PAID_SUCCESS',
        'MARK_ALL_COSTS_AS_PAID_FAILED',
        () => {
            dispatch(notifications.successNotification('application.all_costs_marked_as_paid'));
            dispatch(setUnpaidCostsCount(0));
            dispatch(refreshApiList());
        },
        null,
        `cost/mark-all-as-paid-in-category/${categoryId || ''}`,
        'LOADING_MARK_ALL_COSTS_AS_PAID',
        {},
    );
};