interface ToggleMenu {
  type: 'TOGGLE_MENU';
}

interface HideTrialIndicator {
  type: 'HIDE_TRIAL_INDICATOR';
  payload: boolean;
}

export interface MenuState {
  toggled: boolean;
  hideTrialIndicator: boolean;
}

const initialState: MenuState = {
  toggled: false,
  hideTrialIndicator: false,
};

type Action = ToggleMenu | HideTrialIndicator;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return { ...state, toggled: !state.toggled };
    case 'HIDE_TRIAL_INDICATOR':
      return { ...state, hideTrialIndicator: true };
    default:
      return state;
  }
};
