import React, { Component } from 'react';
import { InjectedFormProps } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ClientEntity, GetFullInvoiceResponse } from 'app-types';
import { invoice } from '../../../../actions';
import { Spinner } from '../../../../components/Common';
import { ApplicationState } from '../../../../reducers';
import InvoiceForm from '../AddInvoice/InvoiceForm';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  getSingleInvoice: (id: string) => void;
  setInvoiceClient: (client: ClientEntity) => void;
  loadingSingleInvoice: boolean;
  singleInvoice: GetFullInvoiceResponse;
}

class EditInvoice extends Component<InjectedFormProps<{}, Props> & Props> {
  componentDidMount() {
    const { getSingleInvoice, match } = this.props;
    getSingleInvoice(match.params.id);
  }

  componentDidUpdate(prevProps: Props) {
    const { singleInvoice, setInvoiceClient } = this.props;
    if (singleInvoice) {
      setInvoiceClient(singleInvoice.client);
    }
  }

  render() {
    const { loadingSingleInvoice, singleInvoice } = this.props;

    return <>{loadingSingleInvoice ? <Spinner /> : <InvoiceForm invoice={singleInvoice} edit />}</>;
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  singleInvoice: state.invoice.singleInvoice,
  loadingSingleInvoice: state.invoice.loadingSingleInvoice,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSingleInvoice: invoice.getSingleInvoice,
      setInvoiceClient: invoice.setInvoiceClient,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(EditInvoice);
