import React, { ChangeEvent } from 'react';
import {
  GetClientKvKRes,
  GetCompanyKvKRes,
  SearchedCompanyKvKRes,
  SimpleKvkResponse,
} from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '../../../components/Common';
import debounce from '../../../utils/debounce';
import { __ } from '../../../services/translation';
import Suggestion from './Suggestion';
import './CompanyAutosuggestion.scss';

import ApiService from '../../../services/api-service';
import RequiredIndicator from '../RequiredIndicator';

interface Props {
  input: {
    onChange: (data: any) => void;
    name: string;
    id: string;
    value: string;
  };
  placeholder: string;
  onOptionSelect: (data: GetCompanyKvKRes) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  meta: { error: any; touched: any };
}

interface State {
  active: boolean;
  resultsKvk: SimpleKvkResponse[];
  loading: boolean;
}

class CompanyAutosuggestion extends React.Component<Props, State> {
  debouncedSearch: any;

  constructor(props: Props) {
    super(props);
    this.debouncedSearch = debounce(this.fetchList, 200);

    this.state = {
      active: false,
      resultsKvk: [],
      loading: false,
    };
  }

  private fetchList = async (query: string) => {
    const result: { resultsKvk: SimpleKvkResponse[] } = {
      resultsKvk: [],
    };
    const listKvk: SearchedCompanyKvKRes = await ApiService.callFetch(
      'GET',
      `user/search-company-kvk/${query}`,
    );
    result.resultsKvk = listKvk.items;
    this.setState({
      ...result,
      loading: false,
    });
  };

  private handleChange = (val: string) => {
    const { input } = this.props;

    input.onChange(val);
    this.setState({ loading: true, active: true });
    this.debouncedSearch(val);
  };

  private handleOptionSelect = (option: SimpleKvkResponse) => {
    const { onOptionSelect } = this.props;
    ApiService.callFetch('GET', `kvk/company/${option.kvk}`, (data: GetCompanyKvKRes) => {
      onOptionSelect(data);
    });
  };

  render() {
    const { input, label, onOptionSelect, placeholder, required, meta, disabled } = this.props;
    const { resultsKvk, loading, active } = this.state;
    const inputLabel = label && (
      <label htmlFor={input.name}>
        {__(label)} {required && <RequiredIndicator />}{' '}
      </label>
    );
    return (
      <div className="input-container">
        {inputLabel}

        <div className="input-sugestion-container">
          <div className="input-group">
            <input
              readOnly={disabled}
              autoComplete="off"
              className="company-autosuggestion-input"
              type="text"
              id="company-autosuggestion-input"
              value={input.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleChange(e.target.value)}
              onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                if (!disabled) {
                  this.handleChange(e.target.value);
                  this.setState({ active: true });
                }
              }}
              onBlur={() => window.setTimeout(() => this.setState({ active: false }), 100)}
              placeholder={__(placeholder)}
            />

            {!disabled && (
              <FontAwesomeIcon icon={faSearch} className="company-autosuggestion-icon" />
            )}
          </div>

          {active && (resultsKvk.length || loading) && (
            <ul className="company-suggestions">
              {loading && <Spinner overlay />}
              {resultsKvk.length > 0 && (
                <>
                  <li className="company-suggestions-separator">{__('application.sugestions')}</li>
                  {resultsKvk.map((r: SimpleKvkResponse) => (
                    <Suggestion item={r} onSelect={this.handleOptionSelect} key={r.kvk} />
                  ))}
                </>
              )}
            </ul>
          )}
          {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
        </div>
      </div>
    );
  }
}

export default CompanyAutosuggestion;
