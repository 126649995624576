import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SomeUserEntity, UserRole } from 'app-types';
import { ApplicationState } from '../../../reducers';

interface Props {
  user: SomeUserEntity | null;
  exact?: boolean;
  path?: string;
  component: any;
}

const RouteUnauthorized = ({ user, exact, path, component }: Props) => {
  if (user && user.role === UserRole.Admin) {
    return <Route render={() => <Redirect to="/admin" />} />;
  }
  if (user && user.role === UserRole.Client) {
    return <Route render={() => <Redirect to="/dashboard" />} />;
  }
  if (user && user.role === UserRole.Accountant) {
    return <Route render={() => <Redirect to="/dashboard" />} />;
  }
  return <Route exact={exact} path={path} component={component} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
});

export default connect(mapStateToProps)(RouteUnauthorized);
