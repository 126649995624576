import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ModalState } from '../../../reducers/modal-reducer';
import { ApplicationState } from '../../../reducers';
import { modal as modalActions } from '../../../actions';

import './Modal.scss';

interface Props {
  modal: ModalState;
  hideModal: () => void;
}

class Modal extends React.Component<Props> {
  render() {
    const { modal } = this.props;
    if (!modal.isVisible && !modal.isPrepared) {
      return null;
    }
    return (
      <>
        <div className={`modal-overlay ${modal.isVisible ? 'visible' : ''}`} />
        <div
          className={`modal ${modal.isVisible ? 'visible' : ''} ${
            modal.isFullscreen ? 'full-screen' : ''
          }`}
          id="modal"
        >
          <div className="modal-body">
            {/* {!modal.isVisible && <Spinner overlay />} */}
            <div className="modal-wrapper">{modal.content}</div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  modal: state.modal,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ hideModal: modalActions.hideModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
