import React from 'react';
import './Section.scss';

interface Props {
  children: React.ReactNode;
  notStyled?: boolean;
  noMargin?: boolean;
  id?: string;
  noPadding?: boolean;
  list?: boolean;
}

const Section = ({ noMargin, notStyled, noPadding, list, id, children }: Props) => (
  <section
    className={`single-section ${noMargin ? 'no-margin' : ''}  ${notStyled ? 'not-styled' : ''} ${
      noPadding ? 'no-padding' : ''
    } ${list ? 'list' : ''} `}
    id={id}
  >
    {children}
  </section>
);

export default Section;
