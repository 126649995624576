import { MileageEntity, TrackingStatus } from 'app-types';
import { Dispatch } from 'redux';
import ApiService from '../services/api-service';
import { modal, notifications, user, apiList } from '../actions';
import { history } from '../App';

import { __ } from '../services/translation';
import { MileageCoordinates } from '../types/mileage';

export const getMileageList = () => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_MILEAGE_LIST_SUCCESS',
    'GET_MILEAGE_LIST_FAILED',
    null,
    null,
    'mileage/list',
    'LOADING_MILEAGE_LIST',
  );
};

export const getMileageYears = () => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_MILEAGE_YEARS_SUCCESS',
    'GET_MILEAGE_YEARS_FAILED',
    null,
    null,
    'mileage/years',
    'LOADING_MILEAGE_YEARS',
  );
};

export const getSingleMileage = (mileageId: string) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_SINGLE_MILEAGE_SUCCESS',
    'GET_SINGLE_MILEAGE_FAILED',
    null,
    null,
    `mileage/detail/${mileageId}`,
    'LOADING_SINGLE_MILEAGE',
  );
};

export const deleteSingleMileage = (mileageId: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.delete(
    dispatch,
    'DELETE_SINGLE_MILEAGE_SUCCESS',
    'DELETE_SINGLE_MILEAGE_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(apiList.refreshApiList());
      dispatch(notifications.successNotification('application.mileage_deleted'));
      history.push('/dashboard/mileages/list');
    },
    null,
    `mileage/${mileageId}`,
    'LOADING_SINGLE_MILEAGE',
  );
};

export const storeCoordinates = (lat: number, lon: number) => ({
  type: 'STORE_COORDINATES',
  payload: {
    lat,
    lon,
  },
});

export const clearCoordinates = () => ({
  type: 'CLEAR_COORDINATES',
});

export const triggerMileageModal = () => async (dispatch: Dispatch<any>) => {
  await ApiService.patch(
    dispatch,
    'TRIGGER_MILEAGE_MODAL_SUCCESS',
    'TRIGGER_MILEAGE_MODAL_FAILED',
    () => {
      dispatch(user.getUserDetails());
    },
    null,
    'user/trigger-mileage-info',
    'LOADING_TRIGGER_MILEAGE_MODAL',
    {},
  );
};

export const setTrackingState = (state: TrackingStatus) => ({
  type: 'SET_TRACKING_STATE',
  payload: state,
});

export const startTracking = (handler: any, date: Date) => async (dispatch: Dispatch) => {
  dispatch({ type: 'START_GPS_TRACKING', payload: { handler, date } });
};

export const continueTracking = (
  handler: any,
  coordinates: MileageCoordinates[],
  trackingStart: Date,
) => async (dispatch: Dispatch) => {
  dispatch({ type: 'CONTINUE_GPS_TRACKING', payload: { handler, coordinates, trackingStart } });
};

export const stopTracking = (date: Date) => async (dispatch: Dispatch) => {
  dispatch({ type: 'STOP_GPS_TRACKING', payload: date });
  history.push('/dashboard/mileages/add/geolocationresults');
};

export const setGpsWatcher = (watcherId: string | null) => ({ type: 'SET_GPS_WATCHER', payload: watcherId})

export const cancelTracking = () => ({ type: 'CANCEL_GPS_TRACKING' });
