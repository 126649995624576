import React from 'react';
import { HeaderConfig } from '../types/header';

interface SetHeader {
  type: 'SET_HEADER';
  payload: HeaderConfig;
}

type Action = SetHeader;

export interface HeaderState {
  title: string;
  back: string;
  additionalTitlePart: React.ReactNode;
  info: {
    title?: string;
    text?: string;
    click?: () => void;
  } | null;
}

const initialState: HeaderState = {
  title: '',
  back: '',
  info: null,
  additionalTitlePart: null,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_HEADER': {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};
