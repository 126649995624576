import { FieldType, TableSchema } from '../typings';

export const otherIncomeForUser: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.name',
      field: 'name',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
    {
      name: 'application.date',
      field: 'date',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
    },
    {
      name: 'application.income_value',
      field: 'value',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Price,
    },
    {
      name: 'application.tax_rate',
      field: 'taxRate',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Text,
    },
  ],
};
