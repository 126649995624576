import React from 'react';
import ApiService from '../../../../services/api-service';
import { ListItem } from '../../../../components/Common';
import { __ } from '../../../../services/translation';
import { DownloadService } from '../../../../services/download-service';

interface Props {
  year: number;
}

const SingleMileageSummary = ({ year }: Props) => {
  const downloadCSV = async () => {
    await DownloadService.download(`mileage/summary-csv/${year}`);
  };
  const downloadPDF = async () => {
    await DownloadService.download(`mileage/summary-pdf/${year}`);
  };
  return (
    <ListItem
      header={`${__('application.mileage')} - ${year}`}
      paragraphs={[]}
      singleRow
      buttons={[
        {
          text: 'application.download_pdf',
          click: downloadPDF,
        },
        {
          text: 'application.download_csv',
          click: downloadCSV,
        },
        {
          text: 'application.send_to_client',
          to: `/dashboard/mileages/send/${year}`,
        },
      ]}
    />
  );
};

export default SingleMileageSummary;
