import { __ } from '../services/translation';

const getDuration = (duration: number) => {
  const isNegative = duration < 0;
  const toConvert = Math.abs(duration);

  const hours = Math.floor(toConvert / 60)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor(toConvert % 60)
    .toString()
    .padStart(2, '0');
  return { hours, minutes, isNegative };
};

export const formatDuration = (duration: number, noUnit = false) => {
  if (typeof duration === 'undefined') return '-';
  if (Number.isNaN(duration)) return '-';

  const { hours, minutes, isNegative } = getDuration(duration);
  if (noUnit) return `${isNegative ? '-' : ''}${hours}:${minutes}`;
  return `${isNegative ? '-' : ''}${hours}:${minutes} ${__('application.minutes')}`;
};

export const formatDurationHoursMinutes = (duration: number) => {
  if (typeof duration === 'undefined') return '-';
  if (Number.isNaN(duration)) return '-';
  const { hours, minutes, isNegative } = getDuration(duration);

  return `${isNegative ? '-' : ''}${hours}h ${minutes}m`;
};

export const durationToMinutes = (duration: string) => {
  if (typeof duration !== 'string') return 0;
  const isNegative = duration.includes('-');
  const toConvert = duration.replace('-', '');
  const parts = toConvert.split(':');
  const hours = parseInt(parts[0]);
  const minutes = parseInt(parts[1]);
  const total = minutes + hours * 60;
  return isNegative ? -1 * total : total;
};

export const durationToHours = (duration: string) => {
  if (typeof duration !== 'string') return 0;
  const isNegative = duration.includes('-');
  const toConvert = duration.replace('-', '');
  const parts = toConvert.split(':');
  const hours = parseInt(parts[0]);
  const minutes = parseInt(parts[1]);
  const total = minutes / 60 + hours;
  return isNegative ? -1 * total : total;
};
