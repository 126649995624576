import React from 'react';
import { useDispatch } from 'react-redux';
import { Confirmation } from '../../../../modals';
import { modal, notifications } from '../../../../actions';
import { Button } from '../../../../components/Common';
import { ButtonsContainer } from '../../../../components/Layout';
import ApiService from '../../../../services/api-service';
import LogList from '../LogList';
import './SettlementSection.scss';
import { hideModal } from '../../../../actions/modal';

const SettlementSection = () => {
  const dispatch = useDispatch();

  const sendForSettlement = async () => {
    await ApiService.callFetch('POST', 'settlement-request/send-for-settlement', () => {
      dispatch(notifications.successNotification('application.request_about_settlement_sent'));
      dispatch({ type: 'REFRESH_API_TABLE' });
      dispatch({ type: 'REFRESH_API_LIST' });
      dispatch(hideModal());
    });
  };

  const showConfirmationModal = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.send_for_settlement_confirmation"
          confirmCallback={() => sendForSettlement()}
        />,
      ),
    );
  };

  return (
    <div className="settlement-section">
      <ButtonsContainer>
        <Button
          click={showConfirmationModal}
          type="submit"
          className="form-submit-button"
          text="application.send_for_settlement"
          small
        />
      </ButtonsContainer>
      <LogList />
    </div>
  );
};

export { SettlementSection };
