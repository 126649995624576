import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { MileageEntity } from 'app-types';
import { ListItem } from '../../../components/Common';
import { formatDate } from '../../../utils/format-date';
import { formatDistance } from '../../../utils/format-distance';
import { formatDuration } from '../../../utils/format-duration';
import { __ } from '../../../services/translation';
import { ApplicationState } from '../../../reducers';

interface Props {
  item: MileageEntity;
  onClick: (data: MileageEntity) => void;
}

const SingleItem = ({ item, onClick }: Props) => {
  const userDetails = useSelector((state: ApplicationState) => state.user.details);
  const getExtraInfo = () => {
    if (item.extraPrivateDistance) {
      return [
        {
          icon: faInfoCircle,
          text: `${__('mileage.with_private_ride')}:`,
          strong: true,
          success: true,
        },
        {
          icon: 'mileages',
          text: formatDistance(item.extraPrivateDistance),
        },
        {
          hide: !item.time,
          icon: 'time_sheets',
          text:
            typeof item.time !== 'undefined'
              ? formatDuration(item.extraPrivateTime)
              : item.extraPrivateTime,
        },
      ];
    }
    return [];
  };
  return (
    <>
      <ListItem
        header={formatDate(item.date)}
        buttons={[
          {
            text: 'application.attach',
            type: 'secondary',
            click: () => onClick(item),
          },
        ]}
        paragraphs={[
          {
            icon: 'pin_start',
            text: item.startPoint,
          },
          {
            icon: 'pin_stop',
            text: item.finishPoint,
          },
          {
            icon: 'mileages',
            text: formatDistance(item.distance),
          },
          {
            hide: !item.time,
            icon: 'time_sheets',
            text: typeof item.time !== 'undefined' ? formatDuration(item.time) : item.time,
          },
          ...getExtraInfo(),
        ]}
      />
    </>
  );
};

export default SingleItem;
