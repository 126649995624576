import { OrderReq, OrderEventType } from 'app-types';

import { Dispatch } from 'redux';
import ApiService from '../services/api-service';
import { modal, notifications } from '../actions';
import { history, store } from '../App';

export const getSingleOrder = (id: string) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_SINGLE_ORDER_SUCCESS',
    'GET_SINGLE_ORDER_FAILED',
    null,
    null,
    `order/detail/${id}`,
    'LOADING_SINGLE_ORDER',
  );
};

export const deleteSingleOrder = (id: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.delete(
    dispatch,
    'DELETE_SINGLE_ORDER_SUCCESS',
    'DELETE_SINGLE_ORDER_FAILED',
    () => {
      dispatch(notifications.successNotification('mileage_generator.deleted'));
      dispatch(modal.hideModal());
      history.push('/dashboard/generator/list');
    },
    null,
    `order/${id}`,
    'LOADING_SINGLE_ORDER',
  );
};

export const addOrder = (values: OrderReq) => async (dispatch: Dispatch) => {
  await ApiService.post(
    dispatch,
    'ADD_STANDING_ORDER_SUCCESS',
    'ADD_STANDING_ORDED_FAILED',
    () => {
      dispatch(notifications.successNotification('application.order_was_created'));
      history.push('/dashboard/generator/list');
    },
    null,
    'order/new',
    'LOADING_ADD_STANDING_ORDER',
    values,
  );
};

export const editOrder = (values: OrderReq, id: string) => async (dispatch: Dispatch) => {
  await ApiService.patch(
    dispatch,
    'EDIT_STANDING_ORDER_SUCCESS',
    'EDIT_STANDING_ORDED_FAILED',
    () => {
      dispatch(notifications.successNotification('application.order_was_edited'));
      history.push('/dashboard/generator/list');
    },
    null,
    `order/${id}`,
    'LOADING_EDIT_STANDING_ORDER',
    values,
  );
};
