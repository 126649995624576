export interface MileageParamsEntity {
  startPoint: string;
  finishPoint: string;
  distance: number;
  time: number;
  type: MileageType;
  id: string;
}

export interface MileageEntity extends MileageParamsEntity {
  date: Date;
  description: string;
  regNumber: string;
  carType: string;
  carModel: string;
  extraPrivateDistance: number;
  extraPrivateTime: number;
}

export interface MileageToCreate extends MileageParamsEntity {
  date?: Date | Date[];
  description: string;
  regNumber: string;
  carType: string;
  carModel: string;
  extraPrivateDistance: number;
  extraPrivateTime: number;
  isPrivate: boolean;
  startPointReturn?: string;
  finishPointReturn?: string;
  distanceReturn?: number;
  timeReturn?: number;
}

export interface MileageSchemaEntity extends MileageParamsEntity {
  name: string;
  startPointReturn: string;
  finishPointReturn: string;
  distanceReturn: number;
  timeReturn: number;
  regNumber: string;
  carModel: string;
  carType: string;
}

export interface MileageSettingsEntity {
  regNumber: string;
  carModel: string;
  carType: string;
}
export interface MileageCarEntity {
  regNumber: string;
  carModel: string;
  carType: string;
}

export enum MileageType {
  private,
  company,
}
