import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { TrackingStatus, ApiErrorCode } from 'app-types';
import { ApplicationState } from '../../../../reducers';
import { Button } from '../../../Common';

import './LocationPreview.scss';
import SessionService from '../../../../services/session-service';
import { mileage, notifications } from '../../../../actions';

const LocationPreview = () => {
  const dispatch = useDispatch();
  const { trackingStatus } = useSelector((state: ApplicationState) => state.mileage);

  const getLocation = () =>
    new Promise((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
          };
          resolve(location);
        },
        (err) => reject(err),
      );
    });

  const trackLocal = () => {
    getLocation()
      .then((location: any) => {
        dispatch(mileage.storeCoordinates(location.lat, location.long));
      })
      .catch(() => {
        dispatch(notifications.errorNotification(ApiErrorCode.CannotReadGpsCoords));
      });
  };

  useEffect(() => {
    const trackingStart = SessionService.getTrackingStart();
    if (trackingStart) {
      const coords = SessionService.getCurrentCoordinates();
      dispatch(mileage.continueTracking(trackLocal, coords, trackingStart));
    }
  }, []);

  if (trackingStatus !== TrackingStatus.Working) return null;

  return (
    <Button
      secondary
      faIcon={faLocationArrow}
      leftIcon
      to="/dashboard/mileages/add/geolocation"
      small
      className="location-preview"
    />
  );
};

export default LocationPreview;
