import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ButtonsContainer } from '../../components/Layout';
import { Button } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal } from '../../actions';

interface Props {
  text: string;
  textArgs?: { [key: string]: string | number };
  confirmCallback: () => void;
  hideModal: () => void;
  children?: React.ReactNode;
  cancelCallback?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}

const Confirmation = ({
  text,
  textArgs,
  confirmCallback,
  hideModal,
  children,
  cancelCallback,
  confirmLabel,
  cancelLabel,
}: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.confirmation" />
    </ModalHeader>
    <p className="confirmation-paragraph">{__(text, textArgs)}</p>
    {children}
    <ButtonsContainer>
      <Button
        className="button-modal"
        click={cancelCallback || hideModal}
        text={cancelLabel || 'application.no'}
        secondary
        noShadow
      />
      <Button
        className="button-modal"
        click={confirmCallback}
        text={confirmLabel || 'application.yes'}
        primary
        noShadow
      />
    </ButtonsContainer>
  </ModalContent>
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ hideModal: modal.hideModal }, dispatch);

export default connect(null, mapDispatchToProps)(Confirmation);
