import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import AddCategory from './Add';
import CategoriesList from './List';
import SingleCategory from './Single';

const invoiceTabs = [
  {
    title: 'application.costs',
    link: '/dashboard/costs',
    icon: 'back',
  },
  {
    title: 'application.categories_list',
    link: '/dashboard/categories/list',
    icon: 'list',
  },
  {
    title: 'application.add_category',
    link: '/dashboard/categories/add',
    icon: 'plus',
  },
];

class CostCategories extends Component<{}> {
  render() {
    return (
      <>
        <Page light withNavigation>
          <Dashboard navigation={invoiceTabs}>
            <Switch>
              <Route
                exact
                path="/dashboard/categories"
                render={() => <Redirect to="/dashboard/categories/list" />}
              />
              <Route exact path="/dashboard/categories/add" component={AddCategory} />
              <Route exact path="/dashboard/categories/list" component={CategoriesList} />
              <Route exact path="/dashboard/categories/:id" component={SingleCategory} />
            </Switch>
          </Dashboard>
        </Page>
      </>
    );
  }
}

export default CostCategories;
