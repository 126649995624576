export enum DigitalBrivenbusInterval {
  DAY,
  WEEK,
  QUARTER,
  NONE,
}

export interface DigitalBrivenbusEntity {
  interval: DigitalBrivenbusInterval;
}
