import React from 'react';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { UserContact } from 'app-types';
import ApiService from '../../../services/api-service';
import { notifications, modal } from '../../../actions';
import ChooseMonthModal from '../../../modals/ChooseMonthModal';
import ChooseQuarterModal from '../../../modals/ChooseQuarterModal';
import {
  Spinner,
  Button,
  SelectWithSuggestion,
  RequiredIndicator,
  DateInput,
  FieldsMapper,
  ContactAutosuggestion,
} from '../../../components/Common';
import { __ } from '../../../services/translation';
import { FormContainer, Page, ButtonsContainer, Container } from '../../../components/Layout';
import { flatpickr, ReactSelect } from '../../../App';
import { formOnTranslateString } from '../../../utils/trans-form';
import './SendToAccountant.scss';
import useHeader from '../../../hooks/useHeader';
import { openInNewTab } from '../../../utils/openInNewTab';
import { SendDocuments } from '../../../modals';
import { DownloadService } from '../../../services/download-service';

const SendToAccountant = () => {
  useHeader('application.send_documents_set');
  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };
  const dispatch = useDispatch();

  const setDates = (startAt: Date, endAt: Date) => {
    itfForm.api.setValues({ startAt, endAt });
  };

  return (
    <>
      <Page light>
        <Container paddingTop>
          <FormContainer>
            <ItfApiForm
              ref={setRef}
              formId="prepare-collection"
              schemaMayBeDynamic
              schemaCacheEnabled={false}
              requiredIndicator={<RequiredIndicator />}
              schemaFetcher={() => ApiService.loadForm('dashboard/form/download-collection')}
              sendFetcher={(formId: string, values: any) =>
                ApiService.sendForm('dashboard/download-collection', values, 'POST')
              }
              onSavedSuccessfully={(url: string) => {
                DownloadService.download(url);
              }}
              loadingInfo={<Spinner />}
              submitButton={
                <ButtonsContainer>
                  <Button type="submit" text="application.download" noShadow primary />
                  <Button
                    type="button"
                    text="application.submit"
                    click={() =>
                      dispatch(
                        modal.showModal(
                          <SendDocuments
                            data={{
                              startAt: new Date(itfForm.api.getValue('startAt')),
                              endAt: new Date(itfForm.api.getValue('endAt')),
                              withInvoices: itfForm.api.getValue('withInvoices'),
                              withMileages: itfForm.api.getValue('withMileages'),
                              withTimesheets: itfForm.api.getValue('withTimesheets'),
                              withCosts: itfForm.api.getValue('withCosts'),
                              withOtherIncome: itfForm.api.getValue('withOtherIncome'),
                              withDocuments: itfForm.api.getValue('withDocuments'),
                            }}
                          />,
                        ),
                      )
                    }
                    noShadow
                    primary
                  />
                </ButtonsContainer>
              }
              onTranslateString={formOnTranslateString}
              thirdPartyComponents={{
                flatpickr,
                ReactSelect,
                ReactCreatableSelect: SelectWithSuggestion,
              }}
              onRenderFullField={(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                onChange,
                onFocus,
                onBlur,
              ) => {
                if (field.id === 'startAt') {
                  return (
                    <>
                      <ButtonsContainer marginLittle inline>
                        <Button
                          text="application.choose_quarter"
                          click={() =>
                            dispatch(modal.showModal(<ChooseQuarterModal setDates={setDates} />))
                          }
                          type="button"
                        />

                        <Button
                          text="application.choose_month"
                          type="button"
                          click={() =>
                            dispatch(modal.showModal(<ChooseMonthModal setDates={setDates} />))
                          }
                        />
                      </ButtonsContainer>
                      {FieldsMapper(
                        field,
                        errors,
                        fieldHtmlId,
                        currentValue,
                        onChange,
                        onFocus,
                        onBlur,
                      )}
                    </>
                  );
                } else if (field.id === 'email') {
                  return (
                    <ContactAutosuggestion
                      required={field.required}
                      label={field.title}
                      input={{
                        value: currentValue,
                        name: fieldHtmlId,
                        id: fieldHtmlId,
                        onChange,
                      }}
                      onOptionSelect={(data: UserContact) => onChange(data.email)}
                      placeholder={field.placeholder}
                      meta={{
                        touched: errors.length > 0,
                        error: errors.length
                          ? __(errors[0].messageWithPlaceholders, errors[0].params)
                          : null,
                      }}
                    />
                  );
                } else {
                  return FieldsMapper(
                    field,
                    errors,
                    fieldHtmlId,
                    currentValue,
                    onChange,
                    onFocus,
                    onBlur,
                  );
                }
              }}
            />
          </FormContainer>
        </Container>
      </Page>
    </>
  );
};

export default SendToAccountant;
