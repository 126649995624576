import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { __ } from '../../../services/translation';

import './NavItems.scss';
import { NavigationItem } from '../../../types/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { showModal } from '../../../actions/modal';
import PremiumAccess from '../../../modals/PremiumAccess';

interface Props {
  items: NavigationItem[];
}

const NavItems = ({ items }: Props) => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  const params: any = useParams();
  const dispatch = useDispatch();

  const getNavItemUrl = (item: NavigationItem): string => {
    const splitUrl = (item.link as string).split('/');
    let newUrl = '';
    splitUrl.forEach((urlPart, index) => {
      const slash = index === 0 ? '' : '/';
      if (urlPart.startsWith('%{') && urlPart.endsWith('}')) {
        newUrl += `${slash}${params[urlPart.slice(0, -1).substring(2)]}`;
        return;
      }
      newUrl += `${slash}${urlPart}`;
    });
    return newUrl;
  };

  const showInfoModal = () => {
    dispatch(showModal(<PremiumAccess />));
  };

  if (!user) return null;
  const getIcon = (item: NavigationItem) => {
    if (typeof item.icon === 'string') {
      return (
        <img
          className="dashboard-navigation-icon"
          src={`/assets/icons/${item.icon}.svg`}
          alt={__(item.title)}
        />
      );
    }
    return item.icon;
  };
  return (
    <div className="dashboard-navigation-container">
      {items
        .filter((item) => !item.inactive)
        .filter((item) => !item.restrict || (item.restrict && item.restrict.includes(user.role)))
        .map((item: NavigationItem) => (
          <>
            {item.link && (
              <NavLink
                exact={item.exact}
                to={getNavItemUrl(item)}
                key={getNavItemUrl(item)}
                className={`dashboard-navigation-item ${item.highlight ? 'hightlight' : ''}`}
                activeClassName="active"
              >
                {getIcon(item)}
                <div className="item-title">{__(item.title)}</div>
                {item.indicator && <span className="indicator" />}
              </NavLink>
            )}
            {item.onClick && (
              <a
                key={Math.random()}
                className={`dashboard-navigation-item ${item.highlight ? 'hightlight' : ''}`}
                onClick={item.onClick}
              >
                {getIcon(item)}
                <div className="item-title">{__(item.title)}</div>
                {item.indicator && <span className="indicator" />}
              </a>
            )}
          </>
        ))}
    </div>
  );
};

export default NavItems;
