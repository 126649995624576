import React, { useState } from 'react';
import { __ } from '../../../services/translation';
import './ToggleInput.scss';

interface Props {
  input: {
    value: boolean;
    onChange: (val: boolean) => void;
    id: string;
  };
  firstButtonLabel: string;
  secondButtonLabel: string;
  translateLabels?: boolean;
  textArgs?: {
    [key: string]: string | number;
  };
}

const ToggleInput = ({
  input,
  firstButtonLabel,
  secondButtonLabel,
  translateLabels,
  textArgs,
}: Props) => {
  const [localState, setLocalState] = useState(input.value);

  const handleChangeValue = (value: boolean) => {
    setLocalState(value);
    input.onChange(value);
  };

  const getLabel = (label: string) => (translateLabels ? __(label, textArgs) : label);

  return (
    <div className="toggle-input">
      <button
        onClick={(e) => {
          e.preventDefault();
          handleChangeValue(false);
        }}
        className={`toggle-input__button ${localState ? '' : 'toggle-input__button--active'}`}
      >
        {getLabel(firstButtonLabel)}
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          handleChangeValue(true);
        }}
        className={`toggle-input__button ${localState ? 'toggle-input__button--active' : ''}`}
      >
        {getLabel(secondButtonLabel)}
      </button>
    </div>
  );
};

export default ToggleInput;
