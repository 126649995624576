import React from 'react';
import { connect } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import { bindActionCreators, Dispatch } from 'redux';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { AppSettingsEntity, ClientUserEntity, SomeUserEntity, UserRole } from 'app-types';
import { modal, user as userActions, info, auth } from '../../../actions';
import { ApplicationState } from '../../../reducers';
import EditUserData from '../../../modals/EditUserData';
import { checkIfMissingData } from '../../../utils/user';
import { i18n } from '../../../services/translation';
import ApiService from '../../../services/api-service';
import { AppConservationMode, AppOutdated } from '../../../modals';
import { Spinner } from '../../Common';
import './AppWrapper.scss';

interface Props {
  children: React.ReactNode;
  user: SomeUserEntity | null;
  initializeMobile: () => void;
  getUserDetails: () => void;
  loading: boolean;
  hideTrialIndicator?: boolean;
  getUserInfo: () => void;
  showModal: (content: React.ReactNode, isClosable: boolean, isFullScreen?: boolean) => void;
}

interface State {
  interval: NodeJS.Timeout | undefined;
  loadingMobileInfo: boolean;
  loaded: boolean;
}

class AppWrapper extends React.Component<Props, State> {
  state = {
    interval: undefined,
    loadingMobileInfo: false,
    loaded: false,
  };

  async componentDidMount() {
    const { getUserDetails } = this.props;

    if (Capacitor.isNativePlatform()) {
      // user details and info are fetched in initMobile()
      await this.initMobile();
    } else {
      await getUserDetails();
      this.setState({ loaded: true });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { user, showModal, getUserInfo } = this.props;
    const { interval } = this.state;
    const client = user as ClientUserEntity;

    if (client && client.isActived && client.role === UserRole.Client) {
      if (checkIfMissingData(client)) {
        showModal(<EditUserData />, true);
      }

      if (!interval) {
        const interval = setInterval(() => {
          getUserInfo();
        }, 10000);
        this.setState({
          interval,
        });
      }
    } else if (interval) {
      clearInterval(interval);
      this.setState({
        interval: undefined,
      });
    }
  }

  async initMobile() {
    const { initializeMobile } = this.props;
    await initializeMobile();
    await this.fetchAppSettings();
    this.setState({ loaded: true });
  }

  async fetchAppSettings() {
    const { showModal } = this.props;
    try {
      await ApiService.callFetch('GET', 'settings', async (res: AppSettingsEntity) => {
        const device = await Device.getInfo();
        const info = await App.getInfo();

        // There is a need to prevent displaying update info for new versions (because of checking process in stores)
        if (
          (device.operatingSystem === 'android' &&
            info.version !== res.androidVersion &&
            info.version !== res.androidReviewVersion) ||
          (device.operatingSystem === 'ios' &&
            info.version !== res.iosVersion &&
            info.version !== res.iosReviewVersion)
        ) {
          showModal(<AppOutdated settings={res} system={device.operatingSystem} />, false, true);
        }

        if (device.operatingSystem === 'android' && res.androidLock) {
          showModal(<AppConservationMode />, false, true);
        } else if (device.operatingSystem === 'ios' && res.iosLock) {
          showModal(<AppConservationMode />, false, true);
        }

        this.setState({
          loadingMobileInfo: false,
        });
      });
    } catch (e) {
      this.setState({
        loadingMobileInfo: false,
      });
    }
  }

  render() {
    const { children } = this.props;
    const { loadingMobileInfo, loaded } = this.state;
    if (!loaded) return <Spinner />;
    if (loadingMobileInfo) return <Spinner />;
    if (!i18n.loaded) return null;
    return <div className="app-wrapper">{children}</div>;
  }
}
const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
  loading: state.user.loading,
  hideTrialIndicator: state.menu.hideTrialIndicator,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      initializeMobile: auth.initializeMobile,
      getUserDetails: userActions.getUserDetails,
      showModal: modal.showModal,
      getUserInfo: info.getUserInfo,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
