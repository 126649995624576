import { timeScheetsForUserTable } from 'app-tables';
import React, { FC } from 'react';
import { TimeSheetEntity } from 'app-types';
import { InteractiveTableSchema } from '../../utils/table-shcema-utils';
import { ApiList } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import SingleItem from './SingleItem';

interface Props {
  onClick: (data: TimeSheetEntity) => void;
  attachedTimeSheetIds: string[];
}

const LinkTimeSheetToInvoiceModal: FC<Props> = ({ onClick, attachedTimeSheetIds }) => {
  const scheme = new InteractiveTableSchema(timeScheetsForUserTable);

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.time_sheet_list" />
      </ModalHeader>
      <ApiList
        apiEndpointSubUrl={`time-sheet/list/attach-to-invoice/${JSON.stringify(
          attachedTimeSheetIds,
        )}`}
        scheme={scheme}
        component={({ item }: { item: TimeSheetEntity }) => (
          <SingleItem item={item} onClick={onClick} />
        )}
        padded
      />
    </ModalContent>
  );
};

export default LinkTimeSheetToInvoiceModal;
