import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Page, ButtonsContainer, BottomContainer } from '../../components/Layout';
import { Button } from '../../components/Common';
import './PaymentFailure.scss';
import useHeader from '../../hooks/useHeader';

const PaymentFailure = () => {
  useHeader('application.payment_failed');
  return (
    <>
      <Page withNavigation light>
        <div className="payment-failure-container">
          <div className="payment-wrapper-failure">
            <FontAwesomeIcon icon={faTimes} className="payment-icon-failure" />
          </div>
        </div>
        <BottomContainer>
          <ButtonsContainer marginTop max>
            <Button text="application.go_back_to_home_page" to="/" />
          </ButtonsContainer>
        </BottomContainer>
      </Page>
    </>
  );
};

export default PaymentFailure;
