import { FieldType, TableSchema } from '../typings';

export const userNotificationsTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.date',
      field: 'date',
      mobile: true,
      sortable: true,
      oppositeSortDir: true,
      defaultSort: true,
      type: FieldType.Date,
    },
    {
      name: 'application.title',
      field: 'title',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Text,
    },
    {
      name: 'application.read',
      field: 'read',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Boolean,
    },
  ],
};
