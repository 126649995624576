import React, { useEffect, useState } from 'react';
import { Alert, Spinner } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';
import ApiService from '../../../../services/api-service';
import { CategoryRes } from '../../../../../../heyzzp-back/types';
import Item from './Item';

const CategoryList = () => {
  useHeader('application.categories', '/dashboard/costs');
  const [categories, setCategories] = useState<CategoryRes[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const getCategories = async () => {
    await ApiService.callFetch('GET', 'category', (data: CategoryRes[]) => {
      setCategories(data);
    });
    setLoading(false);
  };

  useEffect(() => {
    getCategories();
  }, []);

  if (loading) return <Spinner />;

  if (!categories || categories.length === 0) {
    return <Alert type="notice" text="application.no_data" />;
  }

  return (
    <>
      {categories.map((cat) => (
        <Item item={cat} key={cat.id} callback={getCategories} />
      ))}
    </>
  );
};

export default CategoryList;
