import { Dispatch } from 'redux';
import ApiService from '../services/api-service';
import { modal, notifications } from '../actions';

import { history } from '../App';

export const deleteOtherIncome = (id: string) => async (dispatch: Dispatch<any>) => {
  await ApiService.delete(
    dispatch,
    'DELETE_OTHER_INCOME_SUCCESS',
    'DELETE_OTHER_INCOME_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(notifications.successNotification('application.other_income_was_deleted'));
      dispatch({ type: 'REFRESH_API_TABLE' });
      dispatch({ type: 'REFRESH_API_LIST' });
      history.push('/dashboard/other-income/list');
    },
    null,
    `other-income/${id}`,
    'LOADING_DELETE_OTHER_INCOME',
  );
};

export const getOtherIncomeDetails = (id: string) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_SINGLE_OTHER_INCOME_SUCCESS',
    'GET_SINGLE_OTHER_INCOME_FAILED',
    null,
    null,
    `other-income/${id}`,
    'LOADING_SINGLE_OTHER_INCOME',
  );
};
