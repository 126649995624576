import React, { useEffect, useState } from 'react';
import { GetFullInvoiceResponse, InvoiceToRepeat } from 'app-types';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../../../../components/Common';
import InvoiceForm from '../../AddInvoice/InvoiceForm';
import ApiService from '../../../../../services/api-service';
import { history } from '../../../../../App';
import { invoice } from '../../../../../actions';

const AddFromLast = () => {
  const dispatch = useDispatch();
  const [lastInvoice, setLastInvoice] = useState<InvoiceToRepeat | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const getLastInvoice = async () => {
    setLoading(true);
    await ApiService.callFetch(
      'GET',
      'invoice/last',
      (data: InvoiceToRepeat) => {
        setLastInvoice(data);
        dispatch(invoice.setInvoiceClient(data.client));
      },
      () => {
        history.push('/dashboard/invoices');
      },
    );
    setLoading(false);
  };

  useEffect(() => {
    getLastInvoice();
  }, []);

  return <>{loading ? <Spinner /> : <InvoiceForm invoice={lastInvoice} />}</>;
};

export default AddFromLast;
