import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useDispatch, useSelector } from 'react-redux';
import weekSelectPlugin from 'flatpickr/dist/plugins/weekSelect/weekSelect';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { english } from 'flatpickr/dist/l10n/default';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { Language } from 'app-types';
import { addDays, getWeek, startOfWeek } from 'date-fns';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { formatDate } from '../../utils/format-date';
import { ApplicationState } from '../../reducers';
import { ButtonsContainer } from '../../components/Layout';
import AddServiceButton from '../../views/DashboardClient/Invoices/AddInvoice/AddServiceButton';

interface Props {
  index: number;
}

const SelectInvoiceWeek = ({ index }: Props) => {
  const [dates, setDates] = useState<Date[]>();
  const [selectedWeek, setSelectedWeek] = useState<number>();
  const dateObj = new Date();
  const user = useSelector((state: ApplicationState) => state.user.details!);
  const currentLang = useSelector((state: ApplicationState) => state.lang.current);
  const mininumYear = dateObj.getFullYear() - user.yearsPeriod;
  const minDate = `01-01-${mininumYear}`;

  const localeByLang = {
    [Language.en]: english,
    [Language.nl]: Dutch,
    [Language.pl]: Polish,
  };

  const getDatesByWeekNumber = (date: Date): Date[] => {
    const firstDate = startOfWeek(date, { weekStartsOn: 1 });
    const dates = [];

    for (let i = 0; i < 7; i++) {
      dates.push(addDays(firstDate, i));
    }

    return dates;
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.choose" />
      </ModalHeader>
      <p className="select-service-info">{__('application.select_invoice_week')}</p>
      <Flatpickr
        key={new Date().toString()}
        required
        placeholder={formatDate(new Date())}
        onClose={(newDates: Date[], current, self, data) => {
          if (!dates || dates.length === 0) {
            setDates(getDatesByWeekNumber(newDates[0]));
            setSelectedWeek(getWeek(newDates[0], { weekStartsOn: 1 }));
          } else setDates(newDates);
        }}
        value={dates}
        options={{
          dateFormat: 'd-m-Y',
          locale: { ...localeByLang[currentLang as Language], firstDayOfWeek: 1 },
          minDate,
          weekNumbers: true,
          mode: 'multiple',
          plugins: !dates || dates.length === 0 ? [new (weekSelectPlugin as any)({})] : [],
          disable: selectedWeek
            ? [(date: Date) => getWeek(date, { weekStartsOn: 1 }) !== selectedWeek]
            : [],
        }}
      />
      <ButtonsContainer marginTop>
        <AddServiceButton index={index} dates={dates} />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default SelectInvoiceWeek;
