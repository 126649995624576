import React from 'react';
import { OrderDetails } from 'app-types';
import { faStickyNote, faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { ListItem } from '../../../../components/Common';
import { __ } from '../../../../services/translation';
import { formatDate } from '../../../../utils/format-date';
import { modal, orders } from '../../../../actions';
import { Confirmation } from '../../../../modals';

interface Props {
  item: OrderDetails;
}

const OrderItem = ({ item }: Props) => {
  const dispatch = useDispatch();

  const deleteOrder = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="mileage_generator.delete_confirmation"
          confirmCallback={() => dispatch(orders.deleteSingleOrder(item.id))}
          cancelCallback={() => dispatch(modal.hideModal())}
        />,
      ),
    );
  };

  return (
    <ListItem
      header={item.name}
      icon={faStickyNote}
      paragraphs={[
        {
          icon: 'calendar',
          text: __(`application.frequency_${item.frequency}`),
        },
        {
          icon: 'time_sheets',
          text: formatDate(item.nextAt),
        },
      ]}
      buttons={[
        { text: 'application.details', to: `/dashboard/generator/${item.id}` },
        { text: 'application.edit', type: 'secondary', to: `/dashboard/generator/edit/${item.id}` },
        { text: 'application.delete', type: 'danger', click: deleteOrder },
      ]}
    />
  );
};

export default OrderItem;
