import React from 'react';
import { DiscountType, TaxRate } from 'app-types';
import { formatPrice } from '../../../utils/format-price';

interface Props {
  quantity: number | string;
  discount: number;
  discountType: DiscountType;
  taxRate?: TaxRate;
  noBtw?: number;
  btw?: number;
  sum?: number;
  name?: string;
}

const TaxRateTableRow = (props: Props) => {
  const { noBtw, quantity, btw, discount, taxRate, sum, name, discountType } = props;

  return (
    <tr>
      {name ? (
        <td className="tax-rate-row name">
          <strong>{name}</strong>
        </td>
      ) : null}
      <td className="tax-rate-row">{formatPrice(noBtw || 0)}</td>
      <td className="tax-rate-row">{quantity}</td>
      <td className="tax-rate-row">{formatPrice(btw || 0)}</td>
      {discountType === DiscountType.PERCENT && <td className="tax-rate-row">{discount} %</td>}
      {discountType === DiscountType.VALUE && (
        <td className="tax-rate-row">{formatPrice(discount)}</td>
      )}
      <td className="tax-rate-row">{taxRate} %</td>
      <td className="tax-rate-row">{formatPrice(sum || 0)}</td>
    </tr>
  );
};

export default TaxRateTableRow;
