import { UserLabel } from '../../types';
import { FieldType, TableSchema } from '../typings';

const statusToText: {
  [key: number]: string;
} = {
  [UserLabel.WAK]: 'application.user_wak',
  [UserLabel.INTERNAL]: 'application.user_internal',
  [UserLabel.HEYZZP]: 'application.user_heyzzp',
};

export const clientForAdminTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.email',
      field: 'email',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Email,
    },
    {
      name: 'application.company_name',
      field: 'companyName',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
    {
      name: 'application.user_label',
      field: 'label',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: false,
      type: FieldType.Text,
      customValue: value => statusToText[value.label],
      searchableValues: statusToText,
      translate: true,
    },
    {
      name: 'application.created_at',
      field: 'createdAt',
      mobile: true,
      sortable: true,
      oppositeSortDir: true,
      type: FieldType.Date,
    },
    {
      name: 'application.subscribed_to',
      field: 'subscribedTo',
      mobile: true,
      sortable: true,
      oppositeSortDir: true,
      type: FieldType.Date,
    },
    {
      name: 'application.is_enabled?',
      field: 'isEnabled',
      sortable: true,
      type: FieldType.Boolean,
    },
    {
      name: 'application.blocked',
      field: 'isBlocked',
      sortable: true,
      type: FieldType.Boolean,
    },
  ],
};
