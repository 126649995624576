import React from 'react';
import { Page } from '../../../../components/Layout';
import Dashboard from '../../../DashboardCommon';

import useHeader from '../../../../hooks/useHeader';
import { __ } from '../../../../services/translation';
import Addon from '../Addon';
import './List.scss';
import { isMobileBuild } from '../../../../utils/is-mobile';

const addons = [
  {
    title: 'addons.certificate',
    description: 'addons.certificate_description',
    external: true,
    link: 'https://certyfikat.nl',
  },
  {
    title: 'addons.certificate_non_arrears',
    description: 'addons.certificate_non_arrears_description',
    link: '/dashboard/addons/certificate',
    disabled: isMobileBuild(),
  },
  {
    title: 'addons.uittreksel_download',
    description: 'addons.uittreksel_description_short',
    link: '/dashboard/addons/uittreksel',
    disabled: isMobileBuild(),
  },
  {
    title: 'addons.addons_4_title',
    description: 'addons.addons_4_description',
    external: true,
    link:
      'https://www.belastingdienst.nl/wps/wcm/connect/nl/ondernemers/content/hulpmiddel-checken-of-ik-ondernemer-ben-voor-de-inkomstenbelasting?utm_campaign=zzpstarters&utm_medium=cpc&utm_source=google&utm_content=ondernemerscheck',
  },
  {
    title: 'addons.addons_5_title',
    description: 'addons.addons_5_description',
    external: true,
    link: 'https://www.berekenhet.nl/ondernemen/netto-inkomen-zzp-er.html',
  },
  {
    title: 'addons.addons_6_title',
    description: 'addons.addons_6_description',
    external: true,
    link: 'https://ec.europa.eu/taxation_customs/vies/vatResponse.html?locale=pl',
  },
  {
    title: 'addons.addons_7_title',
    description: 'addons.addons_7_description',
    external: true,
    link:
      'https://www.belastingdienst.nl/wps/wcm/connect/nl/ondernemers/content/ik-heb-een-investering-gedaan-heb-ik-recht-op-de-kleinschaligheidsinvesteringsaftrek-kia',
  },
];

const List = () => {
  useHeader('application.addons');
  return (
    <>
      <div className="addons">
        {addons
          .filter((addon) => !addon.disabled)
          .map((addon) => (
            <Addon key={addon.title} addon={addon} />
          ))}
      </div>
    </>
  );
};

export default List;
