import React from 'react';
import { __ } from '../../../../services/translation';
import './ModalTitle.scss';

interface Props {
  title?: string;
  rawTitle?: string;
}

const ModalTitle = ({ title, rawTitle }: Props) => (
  <h2 className="modal-title">
    {__(title)}
    {rawTitle}
  </h2>
);

export default ModalTitle;
