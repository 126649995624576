import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import useHeader from '../../../hooks/useHeader';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import ClientList from './Clients/List';
import AddClient from './Clients/Add';
import SingleClient from './Clients/SingleClient';
import ContactsList from './Contacts/List';
import ContactAdd from './Contacts/Add';
import SentInvoicesHistory from './Clients/SentInvoicesHistory';

const clientsAndContactsTabs = [
  {
    title: 'application.clients',
    link: '/dashboard/clients-and-contacts/clients/list',
    icon: 'clients',
  },
  {
    title: 'application.add_client',
    link: '/dashboard/clients-and-contacts/clients/add',
    icon: 'plus',
  },
  {
    title: 'application.contact_list',
    link: '/dashboard/clients-and-contacts/contacts',
    icon: 'contacts',
  },
];

const contactTabs = [
  {
    title: 'application.back',
    link: '/dashboard/clients-and-contacts',
    icon: 'back',
    exact: true,
  },
  {
    title: 'application.contacts',
    link: '/dashboard/clients-and-contacts/contacts/list',
    icon: 'contacts',
  },
  {
    title: 'application.add_contact',
    link: '/dashboard/clients-and-contacts/contacts/add',
    icon: 'plus',
  },
];

const clientDetails = [
  {
    title: 'application.back',
    link: '/dashboard/clients-and-contacts/clients/list',
    icon: 'back',
    exact: true,
  },
  {
    title: 'application.info',
    link: '/dashboard/clients-and-contacts/clients/%{id}',
    icon: 'user',
    exact: true,
  },
  {
    title: 'application.sent_invoices_history',
    link: '/dashboard/clients-and-contacts/clients/%{id}/sent-invoices-history',
    icon: 'invoices',
    exact: true,
  },
];

const ClientsAndContacts = () => {
  useHeader('application.clients_and_contacts');

  const route = (
    <Switch>
      <Route
        exact
        path="/dashboard/clients-and-contacts"
        render={() => <Redirect to="/dashboard/clients-and-contacts/clients/list" />}
      />
      <Route
        exact
        path="/dashboard/clients-and-contacts/clients/list"
        render={() => <ClientList />}
      />

      <Route exact path="/dashboard/clients-and-contacts/clients/add" component={AddClient} />
      <Route exact path="/dashboard/clients-and-contacts/clients/:id" component={SingleClient} />
      <Route
        exact
        path="/dashboard/clients-and-contacts/clients/:id/sent-invoices-history"
        component={SentInvoicesHistory}
      />
      <Route
        exact
        path="/dashboard/clients-and-contacts/contacts"
        render={() => <Redirect to="/dashboard/clients-and-contacts/contacts/list" />}
      />
      <Route exact path="/dashboard/clients-and-contacts/contacts/list" component={ContactsList} />
      <Route exact path="/dashboard/clients-and-contacts/contacts/add" component={ContactAdd} />
    </Switch>
  );

  return (
    <Page light withNavigation>
      <Switch>
        <Route
          path="/dashboard/clients-and-contacts/clients/list"
          render={() => <Dashboard navigation={clientsAndContactsTabs}>{route}</Dashboard>}
        />
        <Route
          path="/dashboard/clients-and-contacts/clients/add"
          render={() => <Dashboard navigation={clientsAndContactsTabs}>{route}</Dashboard>}
        />
        <Route
          path="/dashboard/clients-and-contacts/clients/:id"
          render={() => <Dashboard navigation={clientDetails}>{route}</Dashboard>}
        />
        <Route
          path="/dashboard/clients-and-contacts/contacts"
          render={() => <Dashboard navigation={contactTabs}>{route}</Dashboard>}
        />
        <Route render={() => <Dashboard navigation={clientsAndContactsTabs}>{route}</Dashboard>} />
      </Switch>
    </Page>
  );
};

export default ClientsAndContacts;
