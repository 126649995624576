import React from 'react';
import GeneratorForm from '../Form';
import useHeader from '../../../../hooks/useHeader';
import './AddForm.scss';

const AddOrder = () => {
  useHeader('application.add_order');
  return (
    <>
      <GeneratorForm />
    </>
  );
};

export default AddOrder;
