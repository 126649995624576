import React from 'react';
import { CostSchemeForm } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';

const AddCostScheme = () => {
  useHeader('application.add_cost_scheme', '/dashboard/costs-schemes/list');
  return (
    <>
      <CostSchemeForm
        formSendUrl="cost/template/new"
        formUrl="cost/template/form/add-item"
        method="POST"
      />
    </>
  );
};

export default AddCostScheme;
