import React from 'react';
import { CategoryRes } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { FormContainer } from '../../components/Layout';
import CategoryForm from '../../components/Common/CategoryForm';

interface Props {
  name: string;
  callback: (cost: CategoryRes) => void;
}

const AddCategory = ({ callback, name }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.add_category" />
    </ModalHeader>
    <FormContainer>
      <CategoryForm
        name={name}
        formUrl={`category/form/with-name/${name}`}
        formSendUrl="category"
        method="POST"
        callback={callback}
      />
    </FormContainer>
  </ModalContent>
);

export default AddCategory;
