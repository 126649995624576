import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { UserDataReq } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ApiService from '../../services/api-service';
import { Spinner, Button, FieldsMapper, RequiredIndicator } from '../../components/Common';
import { apiTable, modal, notifications } from '../../actions';
import { formOnTranslateString } from '../../utils/trans-form';
import { FormContainer, ButtonsContainer } from '../../components/Layout';

interface Props {
  id: string;
  successNotification: (text: string) => void;
  hideModal: () => void;
  refreshApiTable: () => void;
}

const EditUserDataForAdmin = ({ id, successNotification, refreshApiTable, hideModal }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_user" />
    </ModalHeader>
    <FormContainer>
      <ItfApiForm
        formId={`edit-user-for-admin-${id}`}
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm(`user/form/update-user-data/${id}`)}
        sendFetcher={(formId: string, values: UserDataReq) =>
          ApiService.sendForm(`user/client/${id}`, values, 'PATCH')
        }
        onSavedSuccessfully={() => {
          successNotification('application.client_data_was_edited.');
          hideModal();
          refreshApiTable();
        }}
        loadingInfo={<Spinner transparent />}
        submitButton={
          <ButtonsContainer>
            <Button type="submit" className="form-submit-button" text="application.save" primary />
          </ButtonsContainer>
        }
        onTranslateString={formOnTranslateString}
        onRenderFullField={FieldsMapper}
      />
    </FormContainer>
  </ModalContent>
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
      refreshApiTable: apiTable.refreshApiTable,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(EditUserDataForAdmin);
