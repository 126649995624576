import { format } from 'date-fns';
import CONFIG from '../config/app';

export const formatTime = (time: Date | string) => {
  const newTime = typeof time === 'string' ? new Date(time) : time;
  try {
    return format(newTime, CONFIG.TIME_FORMAT);
  } catch (e) {
    return '_';
  }
};
