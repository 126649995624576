import React from 'react';
import { TableSchema, timeScheetsForUserTable } from 'app-tables';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { ApiList } from '../../../../components/Common';
import TimeSheetItem from './Item';
import useHeader from '../../../../hooks/useHeader';

const TimeSheet = () => {
  useHeader('application.time_sheet_list', '/', {
    title: 'application.time_sheet_list',
    text: 'annotations.time_sheets',
  });
  const scheme: TableSchema = new InteractiveTableSchema(timeScheetsForUserTable);
  return (
    <>
      <ApiList
        apiEndpointSubUrl="time-sheet/list"
        scheme={scheme}
        component={TimeSheetItem}
        padded
      />
    </>
  );
};
export default TimeSheet;
