import React from 'react';
import { TimeSheetTemplateDetails } from 'app-types';
import { Spinner, InfoParagraph, Subheader, Card } from '../../../../../components/Common';
import { Content } from '../../../../../components/Layout';

import ApiService from '../../../../../services/api-service';
import './TimeSheetItem.scss';

interface Props {
  id: string;
}

interface State {
  timesheet: TimeSheetTemplateDetails | null;
  loading: boolean;
}

class TimeSheetItem extends React.Component<Props, State> {
  state: State = {
    timesheet: null,
    loading: true,
  };

  componentDidMount() {
    const { id } = this.props;
    this.getTimeSheet(id);
  }

  private getTimeSheet = async (id: string) => {
    const timesheet = await ApiService.callFetch('GET', `time-sheet/template/detail/${id}`);
    this.setState({
      loading: false,
      timesheet,
    });
  };

  render() {
    const { timesheet, loading } = this.state;

    if (loading || !timesheet) {
      return <Spinner />;
    } else {
      return (
        <div className="generator-event-timesheet">
          <Content spread>
            <Subheader icon="time_sheets" level={2} text="application.time_sheet" />
          </Content>
          {timesheet.periods.map((period) => (
            <Card
              compact
              header={<InfoParagraph name="application.name" text={period.name} />}
              body={
                <>
                  <InfoParagraph name="application.start_time" text={period.start} />
                  <InfoParagraph name="application.start_time" text={period.end} />
                  <InfoParagraph name="application.client" text={period.clientName} />
                </>
              }
            />
          ))}
        </div>
      );
    }
  }
}

export default TimeSheetItem;
