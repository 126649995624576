import React, { useState } from 'react';
import { TableSchema, costsForUser, userDocuments } from 'app-tables';
import { ApiList, Tabs } from '../../../../components/Common';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import useHeader from '../../../../hooks/useHeader';
import DocumentItem from '../Item';

const DocumentsList = () => {
  useHeader('application.documents_list', '/');
  const scheme: TableSchema = new InteractiveTableSchema(userDocuments);

  return (
    <>
      <ApiList apiEndpointSubUrl="document/list" scheme={scheme} component={DocumentItem} padded />
    </>
  );
};

export default DocumentsList;
