import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'src/reducers';
import './Captcha.scss';

interface Props {
    onChange: (val: string | null) => void;
}

const siteKey = "6LeAFyspAAAAALUZQWtaPL3RhRQ5VlopUJum8bii";

const Captcha = ({ onChange }: Props) => {
    const lang = useSelector((state: ApplicationState) => state.lang.current);
    const handleLoaded = () => {
        (window as any).grecaptcha.ready(() => {
            (window as any).grecaptcha
                .execute(siteKey, { action: "homepage" })
                .then((token: string) => {
                    onChange(token);
                })
        })
    }

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}&hl=${lang.toLowerCase()}`
        script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
    }, [])

    return (
        <div className='captcha-container'>
            <div
                className="g-recaptcha"
                data-sitekey={siteKey}
                data-size="invisible"
                data-badge="inline"
            ></div>
        </div>
    )
}

export { Captcha }