import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import {
  AuthLoginUserResult,
  ChangeStartNumberReq,
  InvoiceNumeration,
  SomeUserEntity,
  UserVofStatus,
  UserVofStatusResponse,
} from 'app-types';
import ApiService from '../../../services/api-service';
import { notifications, modal } from '../../../actions';
import {
  Spinner,
  Button,
  Subheader,
  FieldsMapper,
  RequiredIndicator,
  Alert,
  Input,
  NarrowParagraph,
} from '../../../components/Common';
import { FormContainer, ButtonsContainer } from '../../../components/Layout';
import { formOnTranslateString } from '../../../utils/trans-form';
import { store } from '../../../App';
import { Confirmation } from '../../../modals';
import { ApplicationState } from '../../../reducers';
import { __ } from '../../../services/translation';

import './SetInvoiceNumber.scss';

interface Props {
  user: SomeUserEntity | null;
  managedUser: AuthLoginUserResult | null;
  errorNotificationText: (text: string) => void;
  hideModal: () => void;
  showModal: (content: React.ReactNode) => void;
}

interface State {
  nextNumber: string;
  number: string;
  prefix: string;
  selectedYear: number;
  loading: boolean;
  vofStatus: UserVofStatus;
  itfForm: any;
}

interface NumberForm {
  number: string;
  prefix: string;
  multipart: boolean;
}

class SetInvoiceNumber extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      nextNumber: '-',
      number: '001',
      prefix: '',
      selectedYear: new Date().getFullYear(),
      loading: false,
      vofStatus: UserVofStatus.CHILD,
      itfForm: null,
    };
  }

  componentDidMount() {
    const { selectedYear } = this.state;
    this.getNextInvoiceNumber(selectedYear);
    this.getVofStatus();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { selectedYear } = this.state;
    if (selectedYear && prevState.selectedYear !== selectedYear) {
      this.getNextInvoiceNumber(selectedYear);
    }
  }

  registerFormCallbacks = () => {
    window.globalForms['set-invoice-number'].valueChangedClb = (newValues: NumberForm) => {
      this.setState({
        prefix: newValues.multipart ? newValues.prefix : '',
        number: newValues.number,
      });
    };
  };

  private getVofStatus = async () => {
    await ApiService.callFetch('GET', 'vof/status', (data: UserVofStatusResponse) => {
      this.setState({
        vofStatus: data.status,
      });
    });
  };

  private getNextInvoiceNumber = async (year: number) => {
    const { errorNotificationText } = this.props;
    this.setState({ loading: true });
    await ApiService.callFetch(
      'GET',
      `invoice/next-number/${year}`,
      (newNumber: InvoiceNumeration) => {
        const nextNumberPadded = newNumber.nextNumber
          .toString()
          .padStart(newNumber.defaultNumber.length, '0');
        this.setState({
          nextNumber: newNumber.nextNumber ? newNumber.numberCompiled : '',
          prefix: newNumber.prefix,
          number: nextNumberPadded,
          loading: false,
        });
      },
      () => {
        errorNotificationText('error.cant_get_invoice_number');
        this.setState({ loading: false });
      },
    );
  };

  private setRef = (form: any) => {
    this.setState({
      itfForm: form,
    });
  };

  private generateNumbers = () => {
    const { prefix, number } = this.state;
    const { length } = number;

    const numbers = [0, 1, 2].map((num) => {
      const parsed = parseInt(number);
      const nextNumberPadded = (Number.isNaN(parsed) ? '-' : parsed + num)
        .toString()
        .padStart(length, '0');
      return `${prefix}${nextNumberPadded}`;
    });
    return numbers;
  };

  private getYearsForDate = (createdAt: Date) => {
    const options = [];
    const year = new Date(createdAt).getFullYear() - 1;
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i >= year; i--) {
      options.push({ name: i, value: i });
    }
    return options;
  };

  private getYearSelectionForCurrentUser = (user: SomeUserEntity) => {
    const { createdAt } = user;
    return this.getYearsForDate(createdAt);
  };

  private getYearSelectionForManagedUser = (managedUser: AuthLoginUserResult) => {
    const { createdAt } = managedUser;
    return this.getYearsForDate(createdAt);
  };

  private changeYear = (ev: ChangeEvent<HTMLInputElement>) => {
    const { itfForm } = this.state;
    const year = parseInt(ev.target.value);
    this.setState(
      {
        selectedYear: year,
      },
      () => {
        if (itfForm) itfForm.api.resetAll();
      },
    );
  };

  render() {
    const { showModal, hideModal, user, managedUser } = this.props;
    const { nextNumber, selectedYear, loading, vofStatus, itfForm } = this.state;

    return (
      <div className="invoice-number-container">
        {!nextNumber && <Alert simple type="error" text="application.invoice_unset_alert" />}
        <Input
          label="application.year"
          type="select"
          options={
            managedUser
              ? this.getYearSelectionForManagedUser(managedUser)
              : this.getYearSelectionForCurrentUser(user!)
          }
          input={{ value: selectedYear, onChange: this.changeYear }}
          meta={{}}
        />
        <Subheader level={2} text="application.next_invoice_numbers" paddingTop />
        <p>{this.generateNumbers().join(', ')}</p>
        <Subheader level={2} text="application.edit" paddingTop />
        <FormContainer>
          {loading ? (
            <Spinner />
          ) : vofStatus === UserVofStatus.CHILD ? (
            <NarrowParagraph text="application.vof_cant_change_numeration" />
          ) : (
            <ItfApiForm
              ref={this.setRef}
              formId="set-invoice-number"
              schemaMayBeDynamic
              schemaCacheEnabled={false}
              requiredIndicator={<RequiredIndicator />}
              schemaFetcher={() =>
                ApiService.loadForm(`invoice/form/change-start-number/${selectedYear}`)
              }
              sendFetcher={(formId: string, values: ChangeStartNumberReq) =>
                ApiService.sendForm(`invoice/change-start-number/${selectedYear}`, values, 'PATCH')
              }
              onSavedSuccessfully={() => {
                store.dispatch(notifications.successNotification('application.invoice_number_set'));
                this.getNextInvoiceNumber(selectedYear);
              }}
              loadingInfoAboveContent
              loadingInfo={<Spinner halfTransparent overlay />}
              submitButton={
                <ButtonsContainer>
                  <Button
                    type="button"
                    click={() => {
                      showModal(
                        <Confirmation
                          text="application.override_invoice_settings_confirmation"
                          confirmCallback={() => {
                            itfForm.api.send();
                            hideModal();
                          }}
                        />,
                      );
                    }}
                    className="form-submit-button"
                    text="application.save"
                    primary
                  />
                </ButtonsContainer>
              }
              onTranslateString={formOnTranslateString}
              onRenderFullField={FieldsMapper}
              registerAsGlobalForm
              enableLiveValuesOfGlobalForm
              onFormGloballyRegistered={this.registerFormCallbacks}
            />
          )}
        </FormContainer>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
  managedUser: state.accounting.managedUser,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      errorNotificationText: notifications.errorNotificationText,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SetInvoiceNumber);
