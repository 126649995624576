import React from 'react';
import { useParams } from 'react-router';
import { NarrowParagraph } from '../../../../../../components/Common';
import useHeader from '../../../../../../hooks/useHeader';
import TimeSheetForm from '../../../../../../components/Common/TimeSheetForm';

const TimeSheetResults = () => {
  useHeader('application.add_time_sheet');

  const { start, finish } = useParams<{ start: string; finish: string }>();

  return (
    <>
      <NarrowParagraph text="timesheet.create_description" />
      <TimeSheetForm formUrl={`time-sheet/form-from-time/${start}/${finish}`} />
    </>
  );
};

export default TimeSheetResults;
