import React from 'react';
import { useDispatch } from 'react-redux';
import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
} from 'itf_formbuilder_react';
import { RouteComponentProps, useParams } from 'react-router';
import ReactSelect from 'react-select';
import { UserContact } from 'app-types';
import ApiService from '../../../../services/api-service';
import {
  Spinner,
  Button,
  FieldsMapper,
  RequiredIndicator,
  SelectWithSuggestion,
  ContactAutosuggestion,
  Paragraph,
} from '../../../../components/Common';
import { FormContainer, ButtonsContainer } from '../../../../components/Layout';
import { notifications } from '../../../../actions';

import { formOnTranslateString } from '../../../../utils/trans-form';
import useHeader from '../../../../hooks/useHeader';
import { __ } from '../../../../services/translation';
import { history } from '../../../../App';

const SendInvoice = () => {
  useHeader('application.send_invoice');
  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  const handleSelect = (id: string, val: boolean) => {
    // custom handling to allow either one, not both at the same time
    if (id === 'alwaysForNextInvoice') {
      itfForm.api.setValues({ alwaysForThisClient: false, alwaysForNextInvoice: val });
    }
    if (id === 'alwaysForThisClient') {
      itfForm.api.setValues({ alwaysForThisClient: val, alwaysForNextInvoice: false });
    }
  };

  const { id: invoiceId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  return (
    <>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="send-invoice"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm(`mail/form/send-invoice/${invoiceId}`)}
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm(`mail/send-invoice/${invoiceId}`, values, 'POST')
          }
          onSavedSuccessfully={() => {
            dispatch(notifications.successNotification('application.invoice_sent.'));
            history.push('/dashboard/invoices/list');
          }}
          loadingInfo={<Spinner transparent />}
          thirdPartyComponents={{ ReactSelect, ReactCreatableSelect: SelectWithSuggestion }}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text="application.send"
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={formOnTranslateString}
          onRenderFullField={(
            field: FormFieldToRender,
            errors: any,
            fieldHtmlId: string,
            currentValue: any,
            onChange: ChangeFunction,
            onFocus: FieldAndEventFunction,
            onBlur: FieldAndEventFunction,
          ) => {
            if (['email', 'additionalEmailAddress'].includes(field.id)) {
              return (
                <ContactAutosuggestion
                  required={field.required}
                  label={field.title}
                  input={{
                    value: currentValue,
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    onChange,
                  }}
                  onOptionSelect={(data: UserContact) => onChange(data.email)}
                  placeholder={field.placeholder}
                  meta={{
                    touched: errors.length > 0,
                    error: errors.length
                      ? __(errors[0].messageWithPlaceholders, errors[0].params)
                      : null,
                  }}
                />
              );
            } else if (field.id === 'invoiceMailTitle') {
              return (
                <>
                  <Paragraph strong translation="application.invoice_message_description" />
                  {FieldsMapper(
                    field,
                    errors,
                    fieldHtmlId,
                    currentValue,
                    onChange,
                    onFocus,
                    onBlur,
                  )}
                </>
              );
            } else if (['alwaysForNextInvoice', 'alwaysForThisClient'].includes(field.id)) {
              return FieldsMapper(
                field,
                errors,
                fieldHtmlId,
                currentValue,
                // custom onChange handling
                (val: boolean) => {
                  handleSelect(field.id, val);
                },
                onFocus,
                onBlur,
              );
            }
            return FieldsMapper(
              field,
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
            );
          }}
        />
      </FormContainer>
    </>
  );
};

export default SendInvoice;
