import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AccountType, ChangeSubscriptionReq, PaymentType, UserSubscriptionRes } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import {
  Spinner,
  InfoParagraph,
  DateInput,
  Input,
  Button,
  Subheader,
} from '../../components/Common';
import ApiService from '../../services/api-service';
import { parseDateInput } from '../../utils/parse-date-input';
import { __ } from '../../services/translation';
import { ButtonsContainer } from '../../components/Layout';
import { modal, notifications } from '../../actions';
import './ManageSubscription.scss';
import Confirmation from '../Confirmation';

interface Props {
  id: string;
}

const ManageSubscription = ({ id }: Props) => {
  const dispatch = useDispatch();
  const [subInfo, setSubInfo] = useState<UserSubscriptionRes>();
  const [loading, setLoading] = useState<boolean>(true);
  const [date, setDate] = useState<Date>();
  const [type, setType] = useState<AccountType>();

  const getSubscriptionInfo = async () => {
    await ApiService.callFetch('GET', `user/subscription/${id}`, (data: UserSubscriptionRes) => {
      setSubInfo(data);
      setDate(data.dateTo);
      setType(data.accountType);
    });
    setLoading(false);
  };

  const cancelSubscription = async () => {
    await ApiService.callFetch('DELETE', `user/subscription/${id}`, () => {
      dispatch(notifications.successNotification('application.subscription_canceled'));
      dispatch(modal.showModal(<ManageSubscription id={id} />));
    });
    setLoading(false);
  };

  const confirmSubscriptionCancel = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.confirm_subscription_cancel"
          confirmCallback={cancelSubscription}
          cancelCallback={() => {
            dispatch(modal.showModal(<ManageSubscription id={id} />));
          }}
        />,
      ),
    );
  };

  const saveSubscription = async () => {
    setLoading(true);
    if (typeof date === 'undefined' || typeof type === 'undefined') return;
    const req: ChangeSubscriptionReq = {
      dateTo: date.toISOString(),
      type,
    };

    await ApiService.callFetch(
      'PATCH',
      `user/subscription/${id}`,
      (data: UserSubscriptionRes) => {
        getSubscriptionInfo();
      },
      null,
      req,
    );
    setLoading(false);
  };

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.user_subscription_manage" />
      </ModalHeader>
      {loading && <Spinner overlay halfTransparent />}
      {subInfo && (
        <>
          <InfoParagraph name="application.subscription_enabled" boolean={subInfo.active} />
          {subInfo.active && (
            <ButtonsContainer>
              <Button
                text="application.cancel_subscription"
                danger
                click={confirmSubscriptionCancel}
              />
            </ButtonsContainer>
          )}
          <InfoParagraph name="application.active_to" date={subInfo.dateTo} />
          <InfoParagraph
            name="application.account_type"
            translate={AccountType[subInfo.accountType]}
          />
          {subInfo.paymentType !== null && (
            <InfoParagraph
              name="application.payment_type"
              translate={PaymentType[subInfo.paymentType]}
            />
          )}
        </>
      )}
      <div className="manage-subscription">
        <Subheader text="application.change" />
        <DateInput
          label="application.active_to"
          required
          input={{
            value: date,
            onChange: (value: Date) => {
              setDate(parseDateInput(value));
            },
          }}
          meta={{
            error: null,
            touched: null,
          }}
        />
        <Input
          input={{
            value: type,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              const val = Number(e.target.value);
              if ([AccountType.basic, AccountType.professional].includes(val)) {
                setType(val);
              }
            },
          }}
          label="application.account_type"
          type="select"
          options={[
            {
              name: __('application.basic'),
              value: AccountType.basic,
            },
            {
              name: __('application.professional'),
              value: AccountType.professional,
            },
          ]}
          meta={{
            error: null,
            touched: null,
          }}
        />
        <ButtonsContainer>
          <Button primary text="application.save" click={saveSubscription} />
        </ButtonsContainer>
      </div>
      <ButtonsContainer marginTop>
        <Button text="application.close" click={() => dispatch(modal.hideModal())} />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ManageSubscription;
