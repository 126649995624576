import { ClientUserEntity, NewCertificateReq, PaymentUrl } from 'app-types';
import { ItfApiForm } from 'itf_formbuilder_react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/reducers';
import { EditUserData } from '../../../../modals';
import { modal, notifications } from '../../../../actions';
import { Button, FieldsMapper, RequiredIndicator, Spinner } from '../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import ApiService from '../../../../services/api-service';
import { formOnTranslateString } from '../../../../utils/trans-form';
import { checkIfMissingData } from '../../../../utils/user';

const CertificateNonArrears = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.user.details);
  useHeader('addons.certificate_non_arrears', '/dashboard/client/addons');

  // needed to send form programatically
  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };

  const handleFormSend = () => {
    if (checkIfMissingData(user as ClientUserEntity)) {
      dispatch(modal.showModal(<EditUserData />));
    } else {
      itfForm.api.send();
    }
  };

  return (
    <FormContainer>
      <ItfApiForm
        ref={setRef}
        formId="client-form"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm('addons/form/new-certificate/')}
        sendFetcher={(formId: string, values: NewCertificateReq) => {
          if (checkIfMissingData(user as ClientUserEntity)) {
            dispatch(modal.showModal(<EditUserData />));
          } else {
            return ApiService.sendForm('addons/prepare-certificate', values, 'POST');
          }
        }}
        onSavedSuccessfully={(data: string) => {
          window.location.replace(data);
        }}
        loadingInfo={<Spinner transparent />}
        submitButton={
          <ButtonsContainer>
            <Button
              type="button"
              click={handleFormSend}
              className="form-submit-button"
              text="application.send"
              primary
            />
          </ButtonsContainer>
        }
        onTranslateString={(string: string) => formOnTranslateString(string)}
        onRenderFullField={FieldsMapper}
        registerAsGlobalForm
      />
    </FormContainer>
  );
};

export default CertificateNonArrears;
