import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Redirect } from 'react-router';
import { NarrowParagraph, Spinner } from '../../../../../../components/Common';
import ApiService from '../../../../../../services/api-service';
import { notifications, header } from '../../../../../../actions';
import { ApplicationState } from '../../../../../../reducers';
import { MileageToCreate, TrackReqParams } from '../../../../../../../../heyzzp-back/types/mileage';
import MileageForm from '../../../Form';
import { MileageCoordinates } from '../../../../../../types/mileage';

interface State {
  initialMileage: MileageToCreate | null;
}

interface Props {
  coords: MileageCoordinates[];
  startAt: Date;
  finishAt: Date;
  successNotification: (text: string) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class GeolocationResults extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      initialMileage: null,
    };
  }

  componentDidMount() {
    const { setHeader } = this.props;
    setHeader('application.add_geolocated_mileage', '', {
      title: 'application.add_geolocated_mileage',
      text: 'mileage.disclaimer',
    });
    this.getInitialData();
  }

  private async getInitialData() {
    const { coords, startAt, finishAt } = this.props;

    const data: TrackReqParams = {
      startAt,
      finishAt,
      coords,
    };

    await ApiService.callFetch(
      'POST',
      'mileage/finish',
      (mileage: MileageToCreate) => {
        this.setState({
          initialMileage: mileage,
        });
      },
      null,
      data,
    );
  }

  render() {
    const { coords, successNotification, startAt, finishAt } = this.props;
    if (!coords || coords.length === 0) {
      return <Redirect to="/dashboard/mileages/add/geolocation" />;
    }

    // const fakeCoords = [
    //   {
    //     lat: 50.2739216,
    //     lon: 18.7664594,
    //   },
    //   {
    //     lat: 50.294774,
    //     lon: 18.68207,
    //   },
    //   {
    //     lat: 50.279448,
    //     lon: 18.725703,
    //   },
    //   {
    //     lat: 50.2762752,
    //     lon: 18.914978,
    //   },
    // ];

    // https://www.google.com/maps/dir/Raciborska+4,+44-100+Gliwice,+Polska/Brainhub+Sp.+z+o.+o.,+G%C3%B3rnych+Wa%C5%82%C3%B3w,+Gliwice/@50.2930497,18.6624754,18.57z/data=!4m14!4m13!1m5!1m1!1s0x471130f84a8fdd01:0x241c7b717fe71b6e!2m2!1d18.6643861!2d50.2943961!1m5!1m1!1s0x471130f8704630dd:0x218a5911c68a79bf!2m2!1d18.6628695!2d50.2932521!3e0
    // 270m
    // const fakeCoords = [
    //   {
    //     lat: 50.294339326013635,
    //     lon: 18.664382822465665,
    //   },
    //   {
    //     lat: 50.29403131951436,
    //     lon: 18.664890327266942,
    //   },
    //   {
    //     lat: 50.29372979543099,
    //     lon: 18.664453873137845,
    //   },
    //   { lat: 50.29337315157529, lon: 18.663849942424328 },
    //   { lat: 50.292935638125684, lon: 18.6630684496531 },
    //   { lat: 50.29308370592816, lon: 18.662877745469494 },
    //   { lat: 50.29339985569585, lon: 18.66264126055035 },
    // ];

    // const fakeCoords = [
    //   { lon: 18.663167, lat: 50.301655 },
    //   { lon: 18.659517, lat: 50.303995 },
    //   { lon: 18.659764, lat: 50.304256 },
    //   { lon: 18.720449, lat: 50.27946 },
    //   { lon: 18.728776, lat: 50.270857 },
    //   { lon: 18.720226, lat: 50.262575 },
    //   { lon: 18.717885, lat: 50.258787 },
    //   { lon: 18.722513, lat: 50.261273 },
    //   { lon: 18.723979, lat: 50.261623 },
    //   { lon: 18.991015, lat: 50.25171 },
    //   { lon: 18.995492, lat: 50.250784 },
    //   { lon: 18.994544, lat: 50.239767 },
    //   { lon: 18.98926, lat: 50.223343 },
    //   { lon: 18.990237, lat: 50.223329 },
    //   { lon: 18.989622, lat: 50.22118 },
    //   { lon: 18.989977, lat: 50.215036 },
    //   { lon: 19.002292, lat: 50.215694 },
    //   { lon: 19.003687, lat: 50.215152 },
    // ];

    const { initialMileage } = this.state;

    if (initialMileage === null) return <Spinner />;

    return (
      <>
        <NarrowParagraph text="mileage.create_description" />
        <MileageForm
          defaultValues={{
            date: [initialMileage.date],
            startPoint: initialMileage.startPoint,
            finishPoint: initialMileage.finishPoint,
            distance: initialMileage.distance,
            time: initialMileage.time,
          }}
        />
      </>
    );
  }
}

const today = new Date();

const mapStateToProps = (state: ApplicationState) => ({
  coords: state.mileage.coordinates,
  startAt: state.mileage.geolocationStartDate,
  finishAt: state.mileage.geolocationFinishDate,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GeolocationResults);
