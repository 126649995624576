import React from 'react';
import { MileageSchemaEntity } from 'app-types';
import { faRoad } from '@fortawesome/free-solid-svg-icons';
import { Spinner, InfoParagraph, Subheader } from '../../../../../components/Common';
import ReturnData from '../../../MileageSchemes/SingleMileageScheme/ReturnData';
import { Content } from '../../../../../components/Layout';
import ApiService from '../../../../../services/api-service';
import './MileageItem.scss';

interface Props {
  id: string;
}

interface State {
  loading: boolean;
  mileage: MileageSchemaEntity | null;
}

class MileageItem extends React.Component<Props, State> {
  state: State = {
    mileage: null,
    loading: true,
  };

  componentDidMount() {
    const { id } = this.props;
    this.getMileage(id);
  }

  private getMileage = async (id: string) => {
    const mileage = await ApiService.callFetch('GET', `mileage-schema/detail/${id}`);
    this.setState({
      loading: false,
      mileage,
    });
  };

  private returnData = (shouldReturn?: boolean, returnTime?: number, returnDistance?: number) => {
    if (
      shouldReturn &&
      typeof returnTime !== 'undefined' &&
      typeof returnDistance !== 'undefined'
    ) {
      return <ReturnData returnTime={returnTime} returnDistance={returnDistance} />;
    }
    return null;
  };

  render() {
    const { mileage, loading } = this.state;

    if (loading || !mileage) {
      return <Spinner />;
    } else {
      return (
        <div className="generator-event-mileage">
          <Content spread>
            <Subheader icon="mileages" level={2} text="application.mileage" />
          </Content>
          <InfoParagraph name="application.name" text={mileage.name} />
          <InfoParagraph name="application.start_point" text={mileage.startPoint} />
          <InfoParagraph name="application.finish_point" text={mileage.finishPoint} />
          <InfoParagraph name="application.distance" distance={mileage.distance} />
          {mileage.time && <InfoParagraph name="application.time" duration={mileage.time} />}
        </div>
      );
    }
  }
}

export default MileageItem;
