import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TrackingStatus, UserRole } from 'app-types';
import { ApplicationState } from '../../../../reducers';
import { secondsToTime } from '../../../../utils/seconds-to-time';
import { Button } from '../../../Common';
import { timeTracking } from '../../../../actions';
import ApiService from '../../../../services/api-service';
import './TrackingPreview.scss';

const TrackingPreview = () => {
  const user = useSelector((state: ApplicationState) => state.user.details);
  const dispatch = useDispatch();

  const tick = () => {
    dispatch(timeTracking.increaseWorkingTime());
  };
  const startTimer = () => {
    dispatch(timeTracking.clearTrackingInterval());
    dispatch(timeTracking.setTrackingInterval(tick));
  };

  const checkCurrentWorkTime = async () => {
    await ApiService.callFetch('GET', 'time-sheet/work/time', (time: number) => {
      if (time > 0) {
        dispatch(timeTracking.changeTrackingStatus(TrackingStatus.Working));
        dispatch(timeTracking.setWorkingTime(time));
        startTimer();
      }
    });
  };

  const checkVisibility = () => {
    if (user) checkCurrentWorkTime();
  };

  useEffect(() => {
    if (user) checkCurrentWorkTime();
    document.addEventListener('visibilitychange', checkVisibility);

    return () => {
      document.removeEventListener('visibilitychange', checkVisibility);
    };
  }, []);
  const { currentTime, status } = useSelector((state: ApplicationState) => state.timeTracking);

  if (!currentTime || status !== TrackingStatus.Working) return null;
  return (
    <Button
      secondary
      icon="time_sheets"
      leftIcon
      to="/dashboard/time-sheets/add/start-stop"
      small
      className="tracking-preview"
    >
      <span className="tracking-seconds">{secondsToTime(currentTime)}</span>
    </Button>
  );
};

export default TrackingPreview;
