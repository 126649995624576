import { StatisticsSummary, WeeklyChartData, YearlyChartData } from 'app-types';

interface GetWeeklySalaryForCharts {
  type: 'GET_WEEKLY_SALARY_FOR_CHARTS_SUCCESS';
  payload: WeeklyChartData[];
}

interface LoadingWeeklySalaryForCharts {
  type: 'LOADING_WEEKLY_SALARY_FOR_CHARTS';
  payload: boolean;
}

interface GetMileageForCharts {
  type: 'GET_MILEAGE_FOR_CHARTS_SUCCESS';
  payload: WeeklyChartData[];
}

interface LoadingMileageForCharts {
  type: 'LOADING_MILEAGE_FOR_CHARTS';
  payload: boolean;
}

interface GetHourlyIncome {
  type: 'GET_HOURLY_INCOME_SUCCESS';
  payload: number;
}

interface LoadingHourlyIncome {
  type: 'LOADING_HOURLY_INCOME';
  payload: boolean;
}

interface GetWeeklyIncome {
  type: 'GET_WEEKLY_INCOME_SUCCESS';
  payload: number;
}

interface LoadingWeeklyIncome {
  type: 'LOADING_WEEKLY_INCOME';
  payload: boolean;
}

interface GetWeeklyMileage {
  type: 'GET_WEEKLY_MILEAGE_SUCCESS';
  payload: number;
}

interface LoadingWeeklyMileage {
  type: 'LOADING_WEEKLY_MILEAGE';
  payload: boolean;
}

interface GetFullDistance {
  type: 'GET_FULL_DISTANCE_SUCCESS';
  payload: number;
}

interface LoadingFullDistance {
  type: 'LOADING_FULL_DISTANCE';
  payload: boolean;
}

interface GetMileageFinancialResults {
  type: 'GET_MILEAGE_FINANCIAL_RESULTS_SUCCESS';
  payload: YearlyChartData[];
}

interface LoadingMileageFinancialResults {
  type: 'LOADING_MILEAGE_FINANCIAL_RESULTS';
  payload: boolean;
}

interface GetTravelIncome {
  type: 'GET_INCOME_WITH_TRAVEL_SUCCESS';
  payload: number;
}

interface LoadingtTravelIncome {
  type: 'LOADING_INCOME_WITH_TRAVEL';
  payload: boolean;
}

interface LoadingStatistics {
  type: 'LOADING_STATISTICS';
  payload: boolean;
}

interface GetStatistics {
  type: 'GET_STATISTICS_SUCCESS';
  payload: StatisticsSummary;
}

interface LoadingIncomeStatistics {
  type: 'LOADING_INCOME_STATISTICS';
  payload: boolean;
}

interface LoadingMileageStatistics {
  type: 'LOADING_MILEAGE_STATISTICS';
  payload: boolean;
}

export interface StatisticsState {
  loadingSalaryCharts: boolean;
  loadingMileageCharts: boolean;
  loadingHourlyIncome: boolean;
  loadingWeeklyIncome: boolean;
  loadingWeeklyMileage: boolean;
  loadingFullDistance: boolean;
  loadingTravelIncome: boolean;
  loadingMileageFinancialResult: boolean;
  loadingIncome: boolean;
  loadingMileage: boolean;
  salaryCharts: WeeklyChartData[];
  mileageCharts: WeeklyChartData[];
  hourlyIncome: number;
  weeklyIncome: number;
  weeklyMileage: number;
  fullDistance: number;
  travelIncome: number;
  mileageFinancialResult: YearlyChartData[];
  summary: StatisticsSummary;
  loading: boolean;
}

const initialState: StatisticsState = {
  loadingSalaryCharts: true,
  salaryCharts: [],
  loadingMileageCharts: true,
  mileageCharts: [],
  hourlyIncome: 0,
  loadingHourlyIncome: true,
  weeklyIncome: 0,
  loadingWeeklyIncome: true,
  weeklyMileage: 0,
  loadingWeeklyMileage: true,
  fullDistance: 0,
  loadingFullDistance: true,
  mileageFinancialResult: [],
  loadingMileageFinancialResult: true,
  travelIncome: 0,
  loadingTravelIncome: true,
  loadingIncome: true,
  loadingMileage: true,
  summary: {},
  loading: true,
};

type Action =
  | GetWeeklySalaryForCharts
  | LoadingWeeklySalaryForCharts
  | GetMileageForCharts
  | LoadingMileageForCharts
  | GetHourlyIncome
  | LoadingHourlyIncome
  | GetWeeklyIncome
  | LoadingWeeklyIncome
  | GetWeeklyMileage
  | LoadingWeeklyMileage
  | GetFullDistance
  | LoadingFullDistance
  | GetMileageFinancialResults
  | LoadingMileageFinancialResults
  | GetTravelIncome
  | LoadingtTravelIncome
  | LoadingStatistics
  | LoadingIncomeStatistics
  | GetStatistics
  | LoadingMileageStatistics;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_WEEKLY_SALARY_FOR_CHARTS_SUCCESS': {
      const salary = action.payload.reverse();
      return { ...state, salaryCharts: salary };
    }
    case 'LOADING_WEEKLY_SALARY_FOR_CHARTS': {
      return { ...state, loadingSalaryCharts: action.payload };
    }
    case 'GET_MILEAGE_FOR_CHARTS_SUCCESS': {
      const mileage = action.payload.reverse();
      return { ...state, mileageCharts: mileage };
    }
    case 'LOADING_MILEAGE_FOR_CHARTS': {
      return { ...state, loadingMileageCharts: action.payload };
    }
    case 'GET_HOURLY_INCOME_SUCCESS': {
      return { ...state, hourlyIncome: action.payload };
    }
    case 'LOADING_HOURLY_INCOME': {
      return { ...state, loadingHourlyIncome: action.payload };
    }
    case 'GET_WEEKLY_INCOME_SUCCESS': {
      return { ...state, weeklyIncome: action.payload };
    }
    case 'LOADING_WEEKLY_INCOME': {
      return { ...state, loadingWeeklyIncome: action.payload };
    }
    case 'GET_WEEKLY_MILEAGE_SUCCESS': {
      return { ...state, weeklyMileage: action.payload };
    }
    case 'LOADING_WEEKLY_MILEAGE': {
      return { ...state, loadingWeeklyMileage: action.payload };
    }
    case 'GET_FULL_DISTANCE_SUCCESS': {
      return { ...state, fullDistance: action.payload };
    }
    case 'LOADING_FULL_DISTANCE': {
      return { ...state, loadingFullDistance: action.payload };
    }
    case 'GET_MILEAGE_FINANCIAL_RESULTS_SUCCESS': {
      return { ...state, mileageFinancialResult: action.payload };
    }
    case 'LOADING_MILEAGE_FINANCIAL_RESULTS': {
      return { ...state, loadingMileageFinancialResult: action.payload };
    }
    case 'GET_INCOME_WITH_TRAVEL_SUCCESS': {
      return { ...state, travelIncome: action.payload };
    }
    case 'LOADING_INCOME_WITH_TRAVEL': {
      return { ...state, loadingtravelIncome: action.payload };
    }
    case 'LOADING_INCOME_STATISTICS': {
      return { ...state, loadingIncome: action.payload };
    }
    case 'LOADING_MILEAGE_STATISTICS': {
      return { ...state, loadingMileage: action.payload };
    }
    case 'GET_STATISTICS_SUCCESS': {
      return { ...state, summary: action.payload };
    }
    case 'LOADING_STATISTICS': {
      return { ...state, loading: action.payload };
    }
    default:
      return state;
  }
};
