import React, { ChangeEvent, useState } from 'react';
import { GetClientKvKRes } from 'app-types';
import { ChangeFunction, FieldAndEventFunction, FormFieldToRender } from 'itf_formbuilder_react';
import { ClientAutosuggestion, FieldsMapper, Input } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import { UittrekselData } from '..';

interface Props {
  selectUserData: () => void;
  setData: (data: UittrekselData) => void;
  data: {
    field: FormFieldToRender;
    errors: any;
    fieldHtmlId: string;
    currentValue: any;
    onChange: ChangeFunction;
    onFocus: FieldAndEventFunction;
    onBlur: FieldAndEventFunction;
    readonly?: boolean;
  };
}

const UittrekselFieldsMapper = ({ selectUserData, setData, data }: Props) => {
  const { field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur } = data;
  const [useMyCompany, setUseMyCompany] = useState(true);

  const selectOption = (client: GetClientKvKRes) => {
    const first = client.address;
    const second = client.city;
    const userAddress = [first, second].filter((el) => el).join(', ');
    const data: UittrekselData = {
      companyName: client.name || '',
      kvk: client.kvk,
      address: userAddress,
    };
    setData(data);
  };

  if (field.id === 'companyName') {
    return (
      <>
        <div className="field">
          <Input
            type="checkbox"
            label="application.use_my_company"
            input={{
              name: 'useMyCompany',
              id: 'useMyCompany',
              placeholder: field.placeholder,
              value: useMyCompany,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                const isSet = Boolean(e.target.checked);
                setUseMyCompany(isSet);
                if (isSet) {
                  selectUserData();
                } else {
                  setData({
                    companyName: '',
                    kvk: '',
                    address: '',
                  });
                }
              },
              onFocus,
              onBlur,
            }}
            meta={{}}
          />
        </div>
        {useMyCompany &&
          FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur)}
        {!useMyCompany && (
          <ClientAutosuggestion
            required={field.required}
            label={field.title}
            input={{
              value: currentValue,
              name: fieldHtmlId,
              id: fieldHtmlId,
              onChange,
            }}
            onOptionSelect={selectOption}
            placeholder={field.placeholder}
            meta={{
              touched: errors.length > 0,
              error: errors.length ? __(errors[0].messageWithPlaceholders, errors[0].params) : null,
            }}
          />
        )}
      </>
    );
  } else return FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur);
};

export default UittrekselFieldsMapper;
