import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { menu } from '../../../actions';
import { ApplicationState } from '../../../reducers';
import './HamburgerButton.scss';

const HamburgerButton = () => {
  const dispatch = useDispatch();
  const { toggled } = useSelector((state: ApplicationState) => state.menu);
  const toggleMenu = () => {
    dispatch(menu.toggleMenu());
  };
  return (
    <button
      className={`menu-toggle  ${toggled && 'toggled'}`}
      title="Menu"
      type="button"
      onClick={toggleMenu}
      tabIndex={0}
    >
      <span className="line line-1" />
      <span className="line line-2" />
      <span className="line line-3" />
    </button>
  );
};

export default HamburgerButton;
