import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { TableSchema, contactsForUser } from 'app-tables';
import { ContactListEntity } from 'app-types';
import { contacts, modal, header } from '../../../../../actions';
import { InteractiveTableSchema } from '../../../../../utils/table-shcema-utils';
import { Confirmation, EditContactName } from '../../../../../modals';
import { ApiTable } from '../../../../../components/Common';
import { ApplicationState } from '../../../../../reducers';

interface Props {
  showModal: (content: React.ReactNode) => void;
  hideModal: () => void;
  deleteContact: (clientId: string) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class ContactsList extends Component<Props> {
  private scheme: TableSchema;

  constructor(props: Props) {
    super(props);
    this.scheme = new InteractiveTableSchema(contactsForUser).addFieldAtTheEnd({
      name: 'application.actions',
      field: 'editButton',
      mobile: true,
      buttons: [
        { name: 'application.edit', type: 'secondary', click: this.editContact },

        { name: 'application.delete', type: 'danger', click: this.confirmContactDelete },
      ],
    });
  }

  componentDidMount() {
    const { setHeader } = this.props;
    setHeader('application.contact_list', '/', {
      title: 'application.contact_list',
      text: 'annotations.contacts',
    });
  }

  private deleteContact = (contactId: string) => {
    const { hideModal, deleteContact } = this.props;
    deleteContact(contactId);
    hideModal();
  };

  private confirmContactDelete = (contact: ContactListEntity) => {
    const { showModal, hideModal } = this.props;
    showModal(
      <Confirmation
        text="application.are_you_sure_you_want_to_delete_this_contact?"
        confirmCallback={() => this.deleteContact(contact.id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  private editContact = (contact: ContactListEntity) => {
    const { showModal } = this.props;
    showModal(<EditContactName contact={contact} />);
  };

  render() {
    return (
      <>
        <ApiTable apiEndpointSubUrl="contact-list/list" scheme={this.scheme} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteContact: contacts.deleteContact,
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(ContactsList);
