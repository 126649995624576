import { ClientEntity, ClientType } from 'app-types';
import React from 'react';
import { InfoParagraph, Subheader } from '..';
import './ClientDetails.scss';

interface Props {
  client: ClientEntity;
}

const ClientDetails = ({ client }: Props) => (
  <>
    <div className="client-section">
      {client.type === ClientType.COMPANY && (
        <InfoParagraph name="application.name" text={client.name} />
      )}
      <InfoParagraph name="application.address_street" text={client.address} />
      <InfoParagraph name="application.city_and_post_code" text={client.city} />
      <InfoParagraph name="application.country" text={client.country} />
    </div>

    <div className="client-section">
      {client.type === ClientType.COMPANY && (
        <>
          <InfoParagraph name="application.kvk" text={client.kvk} />
          <InfoParagraph name="application.btw" text={client.btw} />
        </>
      )}
      <InfoParagraph name="application.iban" text={client.iban} />
      <InfoParagraph name="application.bic" text={client.bic} />
    </div>
    <div className="client-section">
      <Subheader level={3} text="application.contact_data" />
      <div className="client-name">
        <div className="first-name">
          <InfoParagraph name="application.first_name" text={client.firstName} />
        </div>
        <div className="last-name">
          <InfoParagraph name="application.last_name" text={client.lastName} />
        </div>
      </div>
      <InfoParagraph name="application.email_for_invoice" email={client.email} />
      <InfoParagraph name="application.phone" phone={client.phone} />
    </div>
    <InfoParagraph name="application.note" text={client.note} />
  </>
);

export default ClientDetails;
