import React from 'react';
import { Container } from '../../../../components/Layout';
import { WelcomeHeader, ButtonRound } from '../../../../components/Common';
import { isMobile } from '../../../../utils/is-mobile';
import './DashboardHome.scss';
import useHeader from '../../../../hooks/useHeader';
import { ApplicationState } from '../../../../reducers';
import { useSelector } from 'react-redux';
import { UserRole } from 'app-types';

const DashboardHome = () => {
  const userDetails = useSelector((state: ApplicationState) => state.user.details!);
  useHeader('');
  return (
    <>
      <WelcomeHeader text="application.let's_work!" className="home-welcome-header" />
      <Container paddingTop>
        <div className="dashboard-home-section">
          {userDetails.role !== UserRole.IncomeAndCost && (
            <>
              <ButtonRound
                to="/dashboard/time-sheets/add/start-stop"
                label="application.time_sheet"
                icon="time_sheets_background"
                half
              />
              <ButtonRound
                to={`/dashboard/mileages/add/${isMobile() ? 'geolocation' : 'noscheme'}`}
                label="application.mileage"
                icon="mileages_background"
                half
              />
            </>
          )}
          <ButtonRound
            to="/dashboard/costs/add"
            half
            label="application.add_cost"
            icon="costs_background"
          />
          {userDetails.role !== UserRole.IncomeAndCost && (<ButtonRound
            to="/dashboard/invoices/add"
            half
            label="application.dashboard_add_invoice"
            icon="invoices_background"
          />)}
          {userDetails.role === UserRole.IncomeAndCost && (<ButtonRound
            to="/dashboard/other-income/add"
            half
            label="application.add_other_income"
            icon="invoices_background"
          />)}
        </div>
      </Container>
    </>
  );
};

export default DashboardHome;
