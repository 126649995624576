import React from 'react';
import { TableFieldSearchableValues, TableSchemaField } from 'app-tables';
import { __ } from '../../services/translation';
import './SearchBar.scss';

interface Props {
  allFields: TableSchemaField[];
  search: { searchBy: string; searchQuery: string };
  sort: { sortBy: string; sortDirBack: boolean } | null;
  onSearchChange: (searchBy: string, searchQuery: string) => void;
  onSortChange?: (sortBy: string, sortDirection: boolean) => void;
  padded?: boolean;
  inModal?: boolean;
}

class SearchBar extends React.Component<Props> {
  private handleSearchQueryChange = (searchQuery: string) => {
    const { onSearchChange, search } = this.props;
    onSearchChange(search.searchBy, searchQuery);
  };

  private handleSearchByChange = (searchBy: string) => {
    const { onSearchChange, search } = this.props;
    onSearchChange(searchBy, search.searchQuery);
  };

  private handleSortByChange = (sortBy: string) => {
    const { onSortChange } = this.props;
    if (typeof onSortChange !== 'undefined') {
      const sortParsed = JSON.parse(sortBy);
      onSortChange(sortParsed.value, sortParsed.dir);
    }
  };

  private getSearchElement = () => {
    const { allFields, search } = this.props;

    const fields = allFields.find((field) => field.field === search.searchBy) as TableSchemaField;
    const searchableValues = fields ? fields.searchableValues : null;

    if (search.searchBy === '') {
      return null;
    } else if (searchableValues && typeof searchableValues === 'object') {
      return (
        <select
          className="input"
          placeholder={__('application.select_proper_value')}
          onChange={(e) => this.handleSearchQueryChange(e.target.value)}
        >
          {Object.keys(searchableValues as TableFieldSearchableValues).map((key) => (
            <option value={key} key={key}>
              {fields.translate ? __(searchableValues[key]) : searchableValues[key]}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <input
          className="search-input"
          type="text"
          placeholder={__('application.enter_requested_value')}
          value={search.searchQuery}
          onChange={(e) => this.handleSearchQueryChange(e.target.value)}
        />
      );
    }
  };

  render() {
    const { inModal, search, allFields, sort } = this.props;
    return (
      <div className={`search-bar ${inModal ? 'in-modal' : ''}`}>
        <div className="search-header-cont">
          {/* <FontAwesomeIcon icon={faSearch} className="search-icon" /> */}
          <label className="search-header" htmlFor="search">
            {__('application.search')}
          </label>
        </div>
        <div className={`search-fields ${inModal ? 'in-modal' : ''}`}>
          <div className="search-field">
            <select
              id="search"
              name="search"
              value={search.searchBy}
              onChange={(e) => this.handleSearchByChange(e.target.value)}
            >
              {search.searchBy === '' ? (
                <option value="">{__('application.select_the_field_you_want_to_search')}</option>
              ) : null}
              {allFields
                .filter((field) => field.searchable === true)
                .map((field) => (
                  <option value={field.field} key={field.field}>
                    {__(field.name)}
                  </option>
                ))}
            </select>
          </div>
          <div className="search-query">{this.getSearchElement()}</div>
        </div>

        {sort && (
          <>
            <div className="search-header-cont">
              <label htmlFor="sort" className="search-header">
                {__('sort.header')}
              </label>
            </div>
            <div className={`search-fields ${inModal ? 'in-modal' : ''}`}>
              <div className="search-field">
                <select
                  name="sort"
                  id="sort"
                  value={JSON.stringify({ value: sort.sortBy, dir: sort.sortDirBack })}
                  onChange={(e) => this.handleSortByChange(e.target.value)}
                >
                  {sort.sortBy === '' ? (
                    <option value="">{__('application.select_sorting_method')}</option>
                  ) : null}
                  {allFields
                    .filter((field) => field.sortable === true)
                    .map((field) => (
                      <React.Fragment key={field.field}>
                        <option
                          value={JSON.stringify({ value: field.field, dir: true })}
                          key={`${field.field}_desc`}
                        >
                          {__(field.name)} {__('sort.descending')}
                        </option>
                        <option
                          value={JSON.stringify({ value: field.field, dir: false })}
                          key={`${field.field}_asc`}
                        >
                          {__(field.name)} {__('sort.ascending')}
                        </option>
                      </React.Fragment>
                    ))}
                </select>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default SearchBar;
