import { mileageCarTable, TableSchema } from 'app-tables';
import React from 'react';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { ApiList } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';
import CostSchemeItem from './Item';

const MileageCarList = () => {
  useHeader('application.cars', '/mileages/add');

  const scheme: TableSchema = new InteractiveTableSchema(mileageCarTable);

  return (
    <>
      <ApiList
        apiEndpointSubUrl="mileage-car/list"
        scheme={scheme}
        component={CostSchemeItem}
        padded
        noSearch
      />
    </>
  );
};
export default MileageCarList;
