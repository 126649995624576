import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserRole } from 'app-types';
import { Page } from '../../../components/Layout';
import AddFromForm from './Add/AddForm';
import AddFromScheme from './Add/AddScheme';
import TimeTracker from './Add/AddTimer/Timer';
import TimeTrackerResults from './Add/AddTimer/Results';
import TimeSheetList from './List';
import AddTimeSheet from './Add';
import SingleTimeSheet from './SingleTimeSheet';
import Dashboard from '../../DashboardCommon';
import YearSummary from './YearSummary';
import SendTimeSheet from './Send';
import { RouteClientAccountant } from '../../../components/Routes';
import { ApplicationState } from '../../../reducers';

const TimeSheet = () => {
  const userDetails = useSelector((state: ApplicationState) => state.user.details!);

  const timeSheetTabs = [
    {
      title: 'application.time_sheet_list',
      link: '/dashboard/time-sheets/list',
      icon: 'time_sheets',
    },
    {
      title: 'application.add_time_sheet',
      link: '/dashboard/time-sheets/add',
      icon: 'plus',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
    {
      title: 'application.time_sheet_schemes',
      link: '/dashboard/time-sheets-schemes',
      icon: 'list',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
    {
      title: 'application.annual_summary',
      link: '/dashboard/time-sheets/summary',
      icon: 'summary',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
    {
      title: 'application.generator',
      link: '/dashboard/generator/list',
      icon: 'generator',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
  ];

  return (
    <>
      <Page light withNavigation>
        <Dashboard navigation={timeSheetTabs}>
          <Switch>
            <Route
              exact
              path="/dashboard/time-sheets"
              render={() => {
                if (userDetails.role === UserRole.IncomeAndCost) {
                  return <Redirect to="/dashboard/time-sheets/list" />;
                }

                return <Redirect to="/dashboard/time-sheets/add" />;
              }}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/time-sheets/add"
              component={AddTimeSheet}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/time-sheets/add/form"
              component={AddFromForm}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/time-sheets/summary"
              component={YearSummary}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/time-sheets/add/use-scheme/:id"
              component={AddFromScheme}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/time-sheets/add/start-stop"
              component={TimeTracker}
            />
            <Route exact path="/dashboard/time-sheets/list" component={TimeSheetList} />
            <RouteClientAccountant
              exact
              path="/dashboard/time-sheets/add/time/:start/:finish"
              component={TimeTrackerResults}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/time-sheets/send/:year"
              component={SendTimeSheet}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/time-sheets/:id"
              component={SingleTimeSheet}
            />
          </Switch>
        </Dashboard>
      </Page>
    </>
  );
};

export default TimeSheet;
