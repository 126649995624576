import React from 'react';
import { TimeSheetEntity } from 'app-types';
import { useDispatch } from 'react-redux';

import { ItfApiForm } from 'itf_formbuilder_react';
import { ButtonsContainer, TopHeader, FormContainer } from '../../components/Layout';
import {
  Button,
  SectionHeader,
  RequiredIndicator,
  Spinner,
  FieldsMapper,
  DateInput,
} from '../../components/Common';

import { apiList, modal, notifications } from '../../actions';

import ApiService from '../../services/api-service';
import { formOnTranslateString } from '../../utils/trans-form';
import { history, flatpickr } from '../../App';
import DateTimeInput from '../../components/Common/DateTimeInput';
import useHeader from '../../hooks/useHeader';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';

interface Props {
  userId: string;
}

// eslint-disable-next-line react/prefer-stateless-function
const EditUserNotificationsOptions = ({ userId }: Props) => {
  const dispatch = useDispatch();
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.edit_user_notifications_options" />
      </ModalHeader>
      <FormContainer>
        <ItfApiForm
          formId="update-user-notifications-options"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() =>
            ApiService.loadForm(`notification/form/update-user-notification/${userId}`)
          }
          sendFetcher={(formId: string, values: any) =>
            ApiService.sendForm(
              `notification/update-user-notifications-options/${userId}`,
              values,
              'PATCH',
            )
          }
          onSavedSuccessfully={(data: TimeSheetEntity) => {
            dispatch(
              notifications.successNotification(
                'application.user_notification_options_have_been_edited',
              ),
            );
            dispatch(modal.hideModal());
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text="application.save"
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={(string: string) => formOnTranslateString(string)}
          onRenderFullField={FieldsMapper}
        />
      </FormContainer>
    </ModalContent>
  );
};

export default EditUserNotificationsOptions;
