import React from 'react';
import { GetCostSchemeRes } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { CostSchemeForm } from '../../components/Common';
import { FormContainer } from '../../components/Layout';

interface Props {
  name: string;
  callback: (cost: GetCostSchemeRes) => void;
}

const AddCostScheme = ({ name, callback }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.add_cost_scheme" />
    </ModalHeader>
    <FormContainer>
      <CostSchemeForm
        name={name}
        formSendUrl="cost/template/new/"
        formUrl={`cost/template/form/add-with-name/${name || ''}`}
        method="POST"
        callback={callback}
      />
    </FormContainer>
  </ModalContent>
);

export default AddCostScheme;
