import React from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { useDispatch } from 'react-redux';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import {
  Button,
  FieldsMapper,
  Paragraph,
  RequiredIndicator,
  Spinner,
} from '../../components/Common';
import { formOnTranslateString } from '../../utils/trans-form';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import ApiService from '../../services/api-service';
import { successNotification } from '../../actions/notifications';
import { hideModal } from '../../actions/modal';

const PremiumAccess = () => {
  const dispatch = useDispatch();

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.premium_access" />
      </ModalHeader>
      <Paragraph translation="application.premium_access_description" />
    </ModalContent>
  );
};

export default PremiumAccess;
