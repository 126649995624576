import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import { ApplicationState } from '../../../reducers';
import InfoButton from '../InfoButton';
import { __ } from '../../../services/translation';
import './SectionHeader.scss';

interface Props {
  text?: string;
  topPadding?: boolean;
  center?: boolean;
  big?: boolean;
  children?: React.ReactNode;
}

const SectionHeader = ({ text, topPadding, center, big, children }: Props) => {
  const { title, info, additionalTitlePart } = useSelector(
    (state: ApplicationState) => state.header,
  );
  const history = useHistory();
  if (!title) return null;
  return (
    <div className="section-header-container">
      <button className="back-button" onClick={() => history.goBack()}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <h1
        className={`section-header ${center ? 'center' : ''} ${big ? 'big' : ''} ${
          topPadding ? 'top-padding' : ''
        }`}
      >
        {__(title)}
        {additionalTitlePart}
      </h1>
      {children}
      {info ? (
        <InfoButton click={info.click} header={info.title} text={info.text} />
      ) : (
        <span className="flex-fill" />
      )}
    </div>
  );
};

export default SectionHeader;
