import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import AddDocument from './Add';
import DocumentsList from './List';
import SingleDocument from './SingleDocument';

const costDocumentTabs = [
  {
    title: 'application.costs',
    link: '/dashboard/costs',
    icon: 'back',
  },
  {
    title: 'application.costs_documents_list',
    link: '/dashboard/costs-documents/list',
    icon: 'list',
  },
  {
    title: 'application.add_cost_document',
    link: '/dashboard/costs-documents/add',
    icon: 'plus',
  },
];

const CostDocument = () => (
  <>
    <Page light withNavigation>
      <Dashboard navigation={costDocumentTabs}>
        <Switch>
          <Route
            exact
            path="/dashboard/categories"
            render={() => <Redirect to="/dashboard/categories/list" />}
          />
          <Route exact path="/dashboard/costs-documents/add" component={AddDocument} />
          <Route exact path="/dashboard/costs-documents/list" component={DocumentsList} />
          <Route exact path="/dashboard/costs-documents/:id" component={SingleDocument} />
        </Switch>
      </Dashboard>
    </Page>
  </>
);

export default CostDocument;
