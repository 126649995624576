import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserRole } from 'app-types';
import { Page } from '../../../components/Layout';
import AddMileageWithoutScheme from './AddMileage/WithoutSchema';
import AddMileageWithScheme from './AddMileage/WithSchema';
import ChooseMileageScheme from './AddMileage';
import MileageList from './List';
import Dashboard from '../../DashboardCommon';
import SingleMileage from './SingleMileage';
import YearSummary from './YearSummary';
import SendMileage from './Send';
import AddWithLocation from './AddMileage/UseGeolocation';
import GeolocationResults from './AddMileage/UseGeolocation/Results';
import { RouteClientAccountant } from '../../../components/Routes';
import { ApplicationState } from '../../../reducers';

const Mileage = () => {
  const userDetails = useSelector((state: ApplicationState) => state.user.details!);

  const mileageTabs = [
    {
      title: 'application.mileage_list',
      link: '/dashboard/mileages/list',
      icon: 'mileages',
    },
    {
      title: 'application.add_mileage',
      link: '/dashboard/mileages/add',
      icon: 'plus',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
    {
      title: 'application.mileage_schemes',
      link: '/dashboard/mileage-schemes',
      icon: 'list',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
    {
      title: 'application.cars',
      link: '/dashboard/mileage-cars/list',
      icon: 'car',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
    {
      title: 'application.annual_summary',
      link: '/dashboard/mileages/summary',
      icon: 'summary',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
    {
      title: 'application.generator',
      link: '/dashboard/generator',
      icon: 'generator',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
  ];

  return (
    <>
      <Page light withNavigation>
        <Dashboard navigation={mileageTabs}>
          <Switch>
            <Route
              exact
              path="/dashboard/mileages"
              render={() => {
                if (userDetails.role === UserRole.IncomeAndCost) {
                  return <Redirect to="/dashboard/mileages/list" />;
                }

                return <Redirect to="/dashboard/mileages/add" />;
              }}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/mileages/add"
              component={ChooseMileageScheme}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/mileages/add/noscheme"
              component={AddMileageWithoutScheme}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/mileages/summary"
              component={YearSummary}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/mileages/add/usescheme/:id"
              component={AddMileageWithScheme}
            />
            <Route exact path="/dashboard/mileages/list/:type?" component={MileageList} />
            <RouteClientAccountant
              exact
              path="/dashboard/mileages/add/geolocation"
              component={AddWithLocation}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/mileages/add/geolocationresults"
              component={GeolocationResults}
            />
            <RouteClientAccountant
              exact
              path="/dashboard/mileages/send/:year"
              component={SendMileage}
            />
            <RouteClientAccountant exact path="/dashboard/mileages/:id" component={SingleMileage} />
          </Switch>
        </Dashboard>
      </Page>
    </>
  );
};

export default Mileage;
