import React from 'react';
import { __ } from '../../../../services/translation';

import './NewCostNameSuggestion.scss';

interface Props {
  name: string;
  onNewNameSelect: (name: string) => void;
}

const NewNameSuggestion = ({ name, onNewNameSelect }: Props) => (
  <li
    role="button"
    onClick={() => {
      onNewNameSelect(name);
    }}
    className="new-cost-name-suggestion-wrapper"
  >
    <span className="new-cost-name-name">{name}</span>
  </li>
);

export default NewNameSuggestion;
