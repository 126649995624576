import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { TimeSheetTemplateDetails } from 'app-types';
import { timeSheetSchemes, modal, header } from '../../../../actions';
import { ButtonsContainer } from '../../../../components/Layout';
import { InfoParagraph, Button, Spinner, Subheader } from '../../../../components/Common';
import { Confirmation, EditTimeSheetScheme } from '../../../../modals';
import { ApplicationState } from '../../../../reducers';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  single: TimeSheetTemplateDetails;
  getSingleTimeSheetScheme: (id: string) => void;
  id: string;
  loading: boolean;
  hideModal: () => void;
  showModal: (content: React.ReactNode) => void;
  setHeader: (title: string) => void;
  deleteTimeSheetScheme: (id: string) => void;
}

class SingleTimeSheetScheme extends Component<Props> {
  componentDidMount() {
    const { getSingleTimeSheetScheme, match, setHeader } = this.props;
    getSingleTimeSheetScheme(match.params.id);
    setHeader('application.single_time_sheet_scheme');
  }

  private confirmSchemeDeletion = () => {
    const { showModal, hideModal, deleteTimeSheetScheme, match } = this.props;
    showModal(
      <Confirmation
        text="application.confirm_time_sheet_scheme_delete"
        confirmCallback={() => deleteTimeSheetScheme(match.params.id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  private timeSheetSchemeEdition = () => {
    const { showModal, match } = this.props;
    showModal(<EditTimeSheetScheme id={match.params.id} />);
  };

  render() {
    const { single, loading } = this.props;
    const { periods } = single;
    if (loading) return <Spinner />;
    return (
      <>
        {periods.map((period) => (
          <>
            <Subheader highlight rawText={period.name} key={period.name} />
            <InfoParagraph name="application.start_time" text={period.start} />
            <InfoParagraph name="application.start_time" text={period.end} />
            <InfoParagraph name="application.client" text={period.clientName} />
          </>
        ))}

        <ButtonsContainer marginTop>
          <Button text="application.edit" secondary noShadow click={this.timeSheetSchemeEdition} />
          <Button
            text="application.delete"
            danger
            noShadow
            click={() => this.confirmSchemeDeletion()}
          />
        </ButtonsContainer>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  single: state.timeSheetScheme.single,
  loading: state.timeSheetScheme.loadingSingle,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSingleTimeSheetScheme: timeSheetSchemes.getSingleTimeSheetScheme,
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      setHeader: header.setHeader,
      deleteTimeSheetScheme: timeSheetSchemes.deleteTimeSheetScheme,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleTimeSheetScheme);
