import React from 'react';
import { Page, Content, BottomContainer } from '../../components/Layout';
import { Logo, WelcomeHeader, Button } from '../../components/Common';
import './Error.scss';
import useHeader from '../../hooks/useHeader';

const Error = () => {
  useHeader('');

  return (
    <Page padded center className="error-page" noMenu>
      <Logo big center dark />
      <Content center>
        <WelcomeHeader text="application.error" />
      </Content>
      {/* For flexbox to work properly */}
      <span />
      <BottomContainer>
        <Button
          click={() => {
            window.location.reload();
          }}
          text="application.try_again"
        />
      </BottomContainer>
    </Page>
  );
};

export default Error;
