import { FieldType, TableSchema } from '../typings';
const statusToText: {
  [key: number]: string;
} = {
  0: 'payment_status.open',
  1: 'payment_status.canceled',
  2: 'payment_status.pending',
  3: 'payment_status.authorized',
  4: 'payment_status.expired',
  5: 'payment_status.failed',
  6: 'payment_status.paid',
};

export const paymentsForUser: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.date',
      field: 'date',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
    },
    {
      name: 'application.price',
      field: 'amount',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Price,
    },
    {
      name: 'application.payment_status',
      field: 'status',
      mobile: true,
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      customValue: value => statusToText[value.status],
      searchableValues: statusToText,
      translate: true,
    },
  ],
};
