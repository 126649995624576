import { addMonths, isAfter, isBefore, startOfDay, addDays } from 'date-fns';
import { UsersSummary } from 'app-types';
import React, { useEffect, useState } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Tooltip,
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
} from 'recharts';

import { Alert, DateInput, InfoParagraph, Spinner, Subheader } from '../../../../components/Common';
import { FieldsGroup, FormContainer } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import ApiService from '../../../../services/api-service';
import { __ } from '../../../../services/translation';
import { formatDate } from '../../../../utils/format-date';
import './Users.scss';

const Users = () => {
  useHeader('admin.stats_users');
  const [stats, setStats] = useState<UsersSummary | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [from, setFrom] = useState<Date>(addMonths(new Date(), -1));
  const [to, setTo] = useState<Date>(new Date());
  const [chartData, setChartData] = useState<{ day: string; value: number }[]>([]);

  const getStats = async () => {
    await ApiService.callFetch('GET', 'dashboard/users-summary', (data: UsersSummary) => {
      setStats(data);
    });
    setLoading(false);
  };

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    if (!stats) return;
    let end = new Date();
    if (to) end = to;
    let start = addMonths(new Date(), -1);
    if (from) start = from;

    let currentDay = startOfDay(start);

    const res = [];

    while (currentDay <= end) {
      const startDay = startOfDay(currentDay);
      const newDay = {
        day: formatDate(startDay),
        value: 0,
      };

      if (stats.joined[startDay.getTime()]) {
        newDay.value = stats.joined[startDay.getTime()];
      }

      res.push(newDay);
      currentDay = addDays(currentDay, 1);
    }
    setChartData(res);
  }, [stats, from, to]);

  const calculateTotal = () => {
    if (!stats) return;
    return Object.entries(stats.joined)
      .filter(([key, value]: [string, number]) => {
        const date = new Date(parseInt(key));
        if (isBefore(date, from)) return false;
        if (isAfter(date, to)) return false;
        return true;
      })
      .reduce((acc, curr) => acc + curr[1], 0);
  };

  if (loading) return <Spinner />;
  if (!stats) return <Alert simple type="error" text="application.error" />;

  return (
    <div className="users-chart">
      <Subheader text="admin.join_count" />

      <FormContainer>
        <form>
          <FieldsGroup>
            <DateInput
              label="admin.stats_from"
              input={{
                value: from,
                onChange: (e: Date) => {
                  setFrom(e);
                },
              }}
              meta={{}}
            />
            <DateInput
              label="admin.stats_to"
              input={{
                value: to,
                onChange: (e: Date) => {
                  setTo(e);
                },
              }}
              meta={{}}
            />
          </FieldsGroup>
        </form>
      </FormContainer>
      <InfoParagraph name="application.total" text={calculateTotal()} />
      <ResponsiveContainer width="100%">
        <ComposedChart data={chartData} margin={{ left: 0, right: 20, top: 20, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="value" fill="#ddd" />
          <Line
            type="linear"
            dataKey="value"
            stroke="#faba1a"
            strokeWidth={2}
            dot
            activeDot={{ r: 8 }}
          />
          <XAxis
            dataKey="day"
            minTickGap={10}
            label={{ position: 'insideBottom', offset: -10 }}
            interval="preserveStartEnd"
          />
          <YAxis dataKey="value" allowDecimals={false} />
          <Tooltip />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Users;
