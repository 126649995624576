import React, { useMemo } from 'react';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { CostEntity, UserRole } from 'app-types';
import { useDispatch, useSelector } from 'react-redux';
import { IsPaidSwitch, ListItem, ListItemBottomContainer, ListItemWrapper } from '../../../../components/Common';
import { formatDate } from '../../../../utils/format-date';
import { formatPrice } from '../../../../utils/format-price';
import { modal, cost } from '../../../../actions';
import { EditCost, Confirmation } from '../../../../modals';
import { SentStatus } from '../../../../components/Common/SentStatus';
import { ApplicationState } from '../../../../reducers';
import { getUnpaidCostsCount, getUnpaidInCategoryCostsCount } from '../../../../actions/cost';
import { useLocation, useParams } from 'react-router';

interface Props {
    item: CostEntity;
}

const CostListItem = ({ item }: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { categoryId } = useParams<{ categoryId?: string}>();
    const userDetails = useSelector((state: ApplicationState) => state.user.details!);

    const { date, id, name, value, category } = item;

    const updatePaidCount = () => {
        if(location.pathname.includes('category')) {
            dispatch(getUnpaidInCategoryCostsCount(categoryId));
        } else {
            dispatch(getUnpaidCostsCount());
        }

    }

    const editCost = () => {
        dispatch(modal.showModal(<EditCost id={id} />));
    };

    const confirmDelete = () => {
        dispatch(
            modal.showModal(
                <Confirmation
                    text="application.are_you_sure_you_want_to_delete_this_cost?"
                    confirmCallback={() => dispatch(cost.deleteCost(item.id))}
                    cancelCallback={() => dispatch(modal.hideModal())}
                />,
            ),
        );
    };

    const paragraphs = useMemo(
        () => [
            { icon: 'calendar', text: formatDate(date) },
            { icon: 'costs', text: formatPrice(value) },
        ],
        [],
    );

    return (
        <ListItemWrapper>
            <ListItem
                header={name}
                icon={faEuroSign}
                paragraphs={
                    category ? [...paragraphs, { icon: 'payments', text: category.title }] : paragraphs
                }
                headerRight={(item.sentTo || []).length > 0 ? <SentStatus /> : null}
                buttons={[
                    {
                        text: 'application.details',
                        to: `/dashboard/costs/${id}`,
                    },
                    {
                        text: 'application.edit',
                        type: 'secondary',
                        click: editCost,
                    },
                    {
                        text: 'application.delete',
                        type: 'danger',
                        click: confirmDelete,
                    },
                ]}
            />
            {userDetails.role !== UserRole.IncomeAndCost && (
                <ListItemBottomContainer>
                    <IsPaidSwitch label='application.is_cost_paid' url={`cost/trigger-payment-mark/${id}`}
                        callback={updatePaidCount} paid={item.isPaid} />
                </ListItemBottomContainer>)
            }
        </ListItemWrapper>
    );
};

export default CostListItem;
