import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IncomeAndCostUserEntity, UserRole } from 'app-types';
import { Page } from '../../../components/Layout';
import AddBlankInvoice from './AddInvoice/AddBlank';
import AddClientInvoice from './AddInvoice/AddClient';
import InvoicePreview from './AddInvoice/Preview';
import Dashboard from '../../DashboardCommon';
import InvoiceList from './List';
import EditInvoice from './EditInvoice';
import SingleInvoice from './SingleInvoice';
import SendInvoice from './Send';
import Creditnota from './Creditnota';
import EditPreview from './AddInvoice/Preview/Edit';
import AddFromLast from './AddInvoice/AddFromLast';
import { RouteClientAccountant } from '../../../components/Routes';
import { FromOffer } from './FromOffer';
import { ApplicationState } from '../../../reducers';

const Invoices = () => {
  const userDetails = useSelector((state: ApplicationState) => state.user.details!);

  const invoiceTabs = [
    {
      title: 'application.add_invoice',
      link: '/dashboard/invoices/add',
      icon: 'plus',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },

    {
      title: 'application.repeat_last_invoice',
      link: '/dashboard/invoices/repeat-last',
      icon: 'repeat',
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
    {
      title: 'application.invoice_list',
      link: '/dashboard/invoices/list',
      icon: 'list',
    },
    {
      title: 'application.offers',
      link: '/dashboard/offers',
      icon: 'list',
      indicator: true,
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },

    {
      title: 'application.reminders',
      link: '/dashboard/reminders',
      icon: 'rotate_clockwise',
      indicator: true,
      disabledBecauseAccountType: userDetails.role === UserRole.IncomeAndCost,
    },
    {
      title: 'application.other_income',
      link: '/dashboard/other-income',
      icon: 'other-income',
      indicator: true,
    },
  ];

  return (
    <>
      <Page light withNavigation>
        <Dashboard navigation={invoiceTabs}>
          <Switch>
            <Route
              exact
              path="/dashboard/invoices"
              render={() => {
                if (
                  userDetails.role === UserRole.IncomeAndCost &&
                  (userDetails as IncomeAndCostUserEntity).invoiceCount > 0
                ) {
                  return <Redirect to="/dashboard/invoices/list" />;
                } else if (userDetails.role === UserRole.IncomeAndCost) {
                  return <Redirect to="/dashboard/other-income" />;
                }

                return <Redirect to="/dashboard/invoices/add" />;
              }}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/invoices/add"
              component={AddBlankInvoice}
            />
            <RouteClientAccountant
              needsFullData
              path="/dashboard/invoices/repeat-last"
              component={AddFromLast}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/invoices/add/preview"
              component={InvoicePreview}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/invoices/add/preview/edit"
              component={EditPreview}
            />
            <RouteClientAccountant
              incomeAndCostAccount
              needsFullData
              exact
              path="/dashboard/invoices/list"
              component={InvoiceList}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/invoices/add/blank"
              component={AddBlankInvoice}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/invoices/edit/:id"
              component={EditInvoice}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/invoices/creditnota/:id"
              component={Creditnota}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/invoices/offer/:offerId"
              component={FromOffer}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/invoices/add/client/:id"
              component={AddClientInvoice}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/invoices/:id"
              component={SingleInvoice}
            />
            <RouteClientAccountant
              needsFullData
              exact
              path="/dashboard/invoices/send/:id"
              component={SendInvoice}
            />
          </Switch>
        </Dashboard>
      </Page>
    </>
  );
};

export default Invoices;
