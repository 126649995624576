import React from 'react';
import { __ } from '../../../../services/translation';
import { Icon } from '../..';

import './NewCategorySuggestion.scss';

interface Props {
  name: string;
  onNewCategorySelect: (name: string) => void;
}

const NewCategorySuggestion = ({ name, onNewCategorySelect }: Props) => (
  <li
    role="button"
    onClick={() => {
      onNewCategorySelect(name);
    }}
    className="new-category-suggestion-wrapper"
  >
    <Icon icon="plus" className="add-category-icon" />
    <span className="new-category-name">{name}</span>
  </li>
);

export default NewCategorySuggestion;
