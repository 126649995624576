import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { header } from '../actions';

const useHeader = (title: string, back?: string, info?: { title: string; text: string }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(header.setHeader(title, back, info));
  }, []);
};

export default useHeader;
