export interface OrderEntity {
  name: string;
  frequency: Frequency;
  count: number;
  nextAt: Date;
}

export enum OrderEventType {
  timeSheet,
  mileage,
}

export interface OrderEvent {
  type: OrderEventType;
  templateId: string;
}

export enum Frequency {
  daily = 'daily',
  workdays = 'workdays',
  weekly = 'weekly',
  monthly = 'monthly',
}
