import React, { ChangeEvent } from 'react';
import { UserContact, UserContactResponse } from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Spinner, Disclaimer } from '../../../components/Common';
import debounce from '../../../utils/debounce';
import { __ } from '../../../services/translation';
import Suggestion from './Suggestion';
import './ContactAutosuggestion.scss';

import ApiService from '../../../services/api-service';
import RequiredIndicator from '../RequiredIndicator';

interface Props {
  input: {
    onChange: (data: any) => void;
    name: string;
    id: string;
    value: string;
  };
  placeholder: string;
  onOptionSelect: (data: UserContact) => void;
  label?: string;
  required?: boolean;
  meta: { error: any; touched: any };
}

interface State {
  active: boolean;
  result: UserContactResponse;
  loading: boolean;
}

class ContactAutosuggestion extends React.Component<Props, State> {
  debouncedSearch: any;

  constructor(props: Props) {
    super(props);
    this.debouncedSearch = debounce(this.fetchList, 200);

    this.state = {
      active: false,
      result: { contacts: [], clients: [] },
      loading: false,
    };
  }

  private fetchList = async (query: string) => {
    const result: UserContactResponse = await ApiService.callFetch(
      'GET',
      `user/contacts/${encodeURIComponent(query) || ''}`,
    );

    this.setState({
      result,
      loading: false,
    });
  };

  private handleChange = (val: string) => {
    const { input } = this.props;

    input.onChange(val);
    this.setState({ loading: true, active: true });
    this.debouncedSearch(val);
  };

  render() {
    const { input, label, onOptionSelect, placeholder, required, meta } = this.props;
    const { result, loading, active } = this.state;
    const inputLabel = label && (
      <label htmlFor={input.name}>
        {__(label)} {required && <RequiredIndicator />}{' '}
      </label>
    );
    return (
      <div className="input-container">
        {inputLabel}

        <div className="contact-sugestion-container">
          <div className="input-group">
            <input
              autoComplete="off"
              className="contact-autosuggestion-input"
              type="text"
              id="contact-autosuggestion-input"
              value={input.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleChange(e.target.value)}
              onFocus={(e: ChangeEvent<HTMLInputElement>) => this.setState({ active: true })}
              onBlur={() => window.setTimeout(() => this.setState({ active: false }), 100)}
              placeholder={__(placeholder)}
            />

            <FontAwesomeIcon icon={faSearch} className="contact-autosuggestion-icon" />
          </div>

          {active && (result.clients.length || result.contacts.length || loading) && (
            <ul className="contact-suggestions">
              {loading && <Spinner overlay />}
              {result.clients.length > 0 && (
                <>
                  <li className="contact-suggestions-separator">
                    {__('application.your_clients')}
                  </li>
                  {result.clients.map((r: UserContact) => (
                    <Suggestion item={r} onSelect={onOptionSelect} key={r.id} />
                  ))}
                </>
              )}
              {result.contacts.length > 0 && (
                <>
                  <li className="contact-suggestions-separator">
                    {__('application.your_contacts')}
                  </li>
                  {result.contacts.map((r: UserContact) => (
                    <Suggestion item={r} onSelect={onOptionSelect} key={r.id} />
                  ))}
                </>
              )}
            </ul>
          )}
          {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
        </div>
      </div>
    );
  }
}

export default ContactAutosuggestion;
