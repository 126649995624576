import React, { ChangeEvent, useEffect, useState } from 'react';
import { AccountantConnectorDetails, AccountantConnectorStatus } from 'app-types';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import ApiService from '../../services/api-service';
import { Alert, Input, Spinner, Subheader } from '../../components/Common';
import './AccountantClients.scss';

interface Props {
    accountantId: string;
}

const AccountantClients = ({ accountantId }: Props) => {
  const [managedUsers, setManagedUsers] = useState<AccountantConnectorDetails[]>();
  const [search, setSearch] = useState<string>();

  const getManaged = async () => {
    await ApiService.callFetch(
      'GET',
      `accountant-connector/managed-by-accountant/${accountantId}`,
      (users: AccountantConnectorDetails[]) => {
        setManagedUsers(users);
      },
    );
  };

  
  useEffect(() => {
    getManaged();
  }, []);

  if (!managedUsers) return <Spinner />;

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.client_list" />
      </ModalHeader>
      <Subheader text="application.managed_users" />
      {managedUsers.length === 0 && <Alert type="notice" text="application.no_managed_users" />}
      {managedUsers.length > 0 && (
        <Input
          type="text"
          onChange={(e) => setSearch(e)}
          placeholder="application.search"
          input={{
            value: search,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
            },
          }}
          meta={{}}
        />
      )}
      <ul>
        {managedUsers
          .filter((user) => !search || user.name.toLowerCase().includes(search.toLowerCase()))
          .map((user) => (
            <li key={user.id} className="single-user">
              <div>
                <div>
                  <strong>{user.name}</strong>
                </div>
                <div>
                  <span><a href={`mailto:${user.email}`}>{user.email}</a></span>
                </div>
                {user.status === AccountantConnectorStatus.ACTIVE && <div className='connector-status active'>{__("application.connector_approved")}</div>}
                {user.status === AccountantConnectorStatus.WAITING_FOR_ACCOUNTANT_CONFIRMATION && <div className='connector-status pending'>{__("application.connector_pending")}</div>}
                {user.status === AccountantConnectorStatus.WAITING_FOR_CLIENT_CONFIRMATION && <div className='connector-status pending'>{__("application.connector_pending")}</div>}
              </div>
            </li>
          ))}
      </ul>
    </ModalContent>
  );
};

export { AccountantClients };
