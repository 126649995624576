import React from 'react';
import { faChessBoard, faHistory, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { MileageEntity, TimeSheetEntity, UserRole } from 'app-types';
import { differenceInMinutes } from 'date-fns';
import { ListItem } from '../../../../../../components/Common';
import { formatDate } from '../../../../../../utils/format-date';
import { formatDistance } from '../../../../../../utils/format-distance';
import { formatDuration } from '../../../../../../utils/format-duration';
import { __ } from '../../../../../../services/translation';
import { ApplicationState } from '../../../../../../reducers';
import { formatTime } from '../../../../../../utils/format-time';

interface Props {
  item: TimeSheetEntity;
  onClick: (id: string) => void;
}

const TimeSheetItem = ({ item, onClick }: Props) => (
  <>
    <ListItem
      header={item.name}
      buttons={[
        {
          text: 'application.detach',
          type: 'danger',
          click: () => onClick(item.id),
        },
      ]}
      paragraphs={[
        {
          icon: 'time_sheets',
          text: (
            <span>
              <strong>
                {formatDuration(
                  differenceInMinutes(new Date(item.finish), new Date(item.start)),
                  true,
                )}
              </strong>
            </span>
          ),
        },
        {
          icon: faHistory,
          text: (
            <span>
              <strong>{formatDate(item.start)}</strong> {formatTime(item.start)}
            </span>
          ),
        },
        {
          icon: faChessBoard,
          text: (
            <span>
              <strong>{formatDate(item.finish)}</strong> {formatTime(item.finish)}
            </span>
          ),
        },
      ]}
    />
  </>
);

export default TimeSheetItem;
