import { ClientEntity } from '../client';
import { InvoiceEntity } from '../invoice';

export enum ReminderType {
  FIRST,
  SECOND,
  LAST,
}

export interface ReminderReq {
  type: ReminderType;
  invoiceIds: string[];
  clientId: string;
  clientName?: string;
}

export interface ReminderEntity {
  client: ClientEntity;
  invoices: InvoiceEntity[];
  type: ReminderType;
}

export interface SendReminderReq extends ReminderReq {
  sendCopyToMe: boolean;
  reminderMailTitle: string;
  reminderMailBody: string;
  email: string;
  captchaCode?: string;
}
