import { Dispatch } from 'redux';
import ApiService from '../services/api-service';

export const getWeeklySalaryForCharts = () => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_WEEKLY_SALARY_FOR_CHARTS_SUCCESS',
    'GET_WEEKLY_SALARY_FOR_CHARTS_FAILED',
    null,
    null,
    'dashboard/chart/weekly-salary',
    'LOADING_INCOME_STATISTICS',
  );
};

export const getMileageForCharts = () => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_MILEAGE_FOR_CHARTS_SUCCESS',
    'GET_MILEAGE_FOR_CHARTS_FAILED',
    null,
    null,
    'dashboard/chart/weekly-mileage',
    'LOADING_MILEAGE_STATISTICS',
  );
};

export const getHourlyIncome = (year?: number) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_HOURLY_INCOME_SUCCESS',
    'GET_HOURLY_INCOME_FAILED',
    null,
    null,
    `dashboard/average-per-hour/${year || 'all'}`,
    'LOADING_INCOME_STATISTICS',
  );
};

export const getWeeklyIncome = (year?: number) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_WEEKLY_INCOME_SUCCESS',
    'GET_WEEKLY_INCOME_FAILED',
    null,
    null,
    `dashboard/average-salary-per-week/${year || 'all'}`,
    'LOADING_INCOME_STATISTICS',
  );
};

export const getWeeklyMileage = (year?: number) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_WEEKLY_MILEAGE_SUCCESS',
    'GET_WEEKLY_MILEAGE_FAILED',
    null,
    null,
    `dashboard/average-distance-per-week/${year || 'all'}`,
    'LOADING_MILEAGE_STATISTICS',
  );
};

export const getFullDistance = (year?: number) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_FULL_DISTANCE_SUCCESS',
    'GET_FULL_DISTANCE_FAILED',
    null,
    null,
    `dashboard/travel-distance/${year || 'all'}`,
    'LOADING_MILEAGE_STATISTICS',
  );
};

export const getMileageFinancialResult = () => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_MILEAGE_FINANCIAL_RESULTS_SUCCESS',
    'GET_MILEAGE_FINANCIAL_RESULTS_FAILED',
    null,
    null,
    'dashboard/financial-result-for-mileage',
    'LOADING_MILEAGE_STATISTICS',
  );
};

export const getIncomeWithTravel = (year?: number) => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_INCOME_WITH_TRAVEL_SUCCESS',
    'GET_INCOME_WITH_TRAVEL_FAILED',
    null,
    null,
    `dashboard/average-per-hour-with-travel/${year || 'all'}`,
    'LOADING_INCOME_STATISTICS',
  );
};

export const getSummary = () => async (dispatch: Dispatch) => {
  await ApiService.get(
    dispatch,
    'GET_STATISTICS_SUCCESS',
    'GET_STATISTICS_FAILED',
    null,
    null,
    'dashboard/summary',
    'LOADING_STATISTICS',
  );
};
