import React from 'react';
import { InfoParagraph } from '../../../../../components/Common';

interface Props {
  returnTime: number;
  returnDistance: number;
}

const ReturnData = ({ returnTime, returnDistance }: Props) => (
  <>
    {returnTime && <InfoParagraph name="mileage.return_time" duration={returnTime} />}
    <InfoParagraph name="mileage.return_distance" distance={returnDistance} />
  </>
);

export default ReturnData;
