import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { addMonths } from 'date-fns';
import { Button, DateInput, Input } from '../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import { notifications } from '../../../../actions';
import ValidationService from '../../../../services/validation-service';
import { __ } from '../../../../services/translation';
import ApiService from '../../../../services/api-service';
import { RootState } from '../../../../reducers';

interface Props extends InjectedFormProps {
  changeField: (form: string, field: string, value: string) => void;
}

const DigitaleBrievenbus = ({ handleSubmit, changeField }: Props) => {
  const dispatch = useDispatch();
  const { managedUser } = useSelector((state: RootState) => state.accounting);

  useEffect(() => {
    if (managedUser?.digitaleBrievenbus) {
      const {
        incoming,
        documents,
        expenses,
        digitalBrievenbusFrom,
      } = managedUser?.digitaleBrievenbus;

      if (documents) {
        changeField('digitaleBrievenbusOptions', 'documents', documents);
      }
      if (expenses) {
        changeField('digitaleBrievenbusOptions', 'expenses', expenses);
      }
      if (incoming) {
        changeField('digitaleBrievenbusOptions', 'incoming', incoming);
      }
      if (digitalBrievenbusFrom) {
        changeField(
          'digitaleBrievenbusOptions',
          'digitalBrievenbusFrom',
          digitalBrievenbusFrom.toString(),
        );
      }
    }
  }, []);

  const submit = (data: any) => {
    ApiService.patch(
      dispatch,
      'UPDATE_DIGITALE_BRIEVENBUS',
      '',
      () => {
        dispatch(notifications.successNotification('application.digitale_brievenbus_was_edited'));
      },
      null,
      `digital-brivenbus/user/${managedUser?.id}`,
      '',
      data,
    );
  };

  return (
    <>
      <FormContainer stretch>
        <form onSubmit={handleSubmit(submit)}>
          <div className="form-fields">
            <Field
              label="application.incoming"
              name="incoming"
              component={Input}
              type="text"
              validate={[ValidationService.email]}
              placeholder="application.email_placeholder"
            />
            <Field
              label="application.expenses"
              name="expenses"
              component={Input}
              type="text"
              validate={[ValidationService.email]}
              placeholder="application.email_placeholder"
            />
            <Field
              name="documents"
              component={Input}
              label="application.documents"
              type="text"
              validate={[ValidationService.email]}
              placeholder="application.email_placeholder"
            />
            <Field
              name="digitalBrievenbusFrom"
              component={DateInput}
              minDate={addMonths(new Date(), -4)}
              label="application.start_date"
              type="text"
              placeholder="application.start_date"
              validate={[ValidationService.required]}
              required
            />
          </div>
          <ButtonsContainer>
            <Button text="application.save" primary type="submit" />
          </ButtonsContainer>
        </form>
      </FormContainer>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeField: change,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(
  reduxForm<any, any>({
    form: 'digitaleBrievenbusOptions',
    enableReinitialize: true,
  })(DigitaleBrievenbus),
);
