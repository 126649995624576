import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { OrderDetails } from 'app-types';

import { ApplicationState } from '../../reducers';
import GeneratorForm from '../../views/DashboardClient/Generator/Form';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { Spinner } from '../../components/Common';
import { modal, notifications, orders } from '../../actions';
import { FormContainer } from '../../components/Layout';

interface Props {
  id: string;
  loading: boolean;
  data: OrderDetails;
  successNotification: (text: string) => void;
  hideModal: () => void;
  getSingleOrder: (id: string) => void;
}

class EditOrder extends React.Component<Props> {
  componentDidMount() {
    const { getSingleOrder, id } = this.props;
    getSingleOrder(id);
  }

  render() {
    const { id, loading, data } = this.props;

    return (
      <ModalContent>
        {loading && <Spinner />}
        <ModalHeader>
          <ModalTitle title="mileage_generator.edit" />
        </ModalHeader>
        <FormContainer>
          <GeneratorForm id={id} data={data} />
        </FormContainer>
      </ModalContent>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.orders.loadingSingleOrder,
  data: state.orders.singleOrder,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      successNotification: notifications.successNotification,
      hideModal: modal.hideModal,
      getSingleOrder: orders.getSingleOrder,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder);
