import Polyglot from 'node-polyglot'; // Ta biblioteka dobrze działa
import { Polish } from 'flatpickr/dist/l10n/pl';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import CONFIG from '../config/app';
import SessionService from './session-service';
import { flatpickr } from '../App';
import ApiService from './api-service';
import { isMobile } from '../utils/is-mobile';
// W konsoli ustawienie `window.DEBUG_SHOW_MISSING_TRANSLATIONS = true` pokaże brakujące translacje
export class I18n {
  loaded: boolean;

  langNames: string[];

  currentLang: string | null;

  polyglot: any;

  polyglotDefault: any;

  constructor() {
    this.loaded = false;
    this.langNames = [];
    this.currentLang = null;
  }

  private _isLoaded(emitWarn = false) {
    if (!this.loaded && emitWarn) {
      console.warn('i18n not loaded!');
    }
    return this.loaded;
  }

  async load(lang: string, clb?: any) {
    const defaultLang = await fetch(`/assets/translations/${CONFIG.DEFAULT_LANG}.json`, {
      cache: 'no-cache',
    });

    const defaultInfoData = await defaultLang.json();
    let i18nInfoData = defaultInfoData;

    try {
      const response = await fetch(`/assets/translations/${lang}.json`, { cache: 'no-cache' });
      i18nInfoData = await response.json();
    } catch (err) {
      console.error(`Problem loading language ${lang}, using ${CONFIG.DEFAULT_LANG} fallback`);
    }

    this.polyglotDefault = new Polyglot({
      phrases: defaultInfoData.phrases,
      locale: CONFIG.DEFAULT_LANG,
      onMissingKey: (key, options) => key,
    });

    // Pobranie listy języków, ale to równie dobrze może być na FE albo najlepiej jako statyczny JSON
    this.polyglot = new Polyglot({
      phrases: i18nInfoData.phrases,
      locale: i18nInfoData.locale,
      onMissingKey: (key, options) => this.__fallback(key, options),
    });
    // Tu warto wybrać język również do biblioteki czasu np. date-fns
    this.currentLang = i18nInfoData.locale;
    this.langNames = i18nInfoData.langNames;
    document.documentElement.lang = i18nInfoData.locale;
    flatpickr.localize(Polish);
    // if (this.currentLang === 'pl') flatpickr.localize(Polish);
    // if (this.currentLang === 'nl') flatpickr.defaultConfig.locale = Dutch;
    // if (this.currentLang === 'en') flatpickr.defaultConfig.locale = 'default';

    this.loaded = true;
    if (typeof clb === 'function') {
      clb();
    }
  }

  __fallback(key: any, options: any) {
    if (!this._isLoaded()) return;
    return this.polyglotDefault.t(key, options);
  }

  __(key: any, options: any) {
    if (!this._isLoaded()) return;
    return this.polyglot.t(key, options);
  }

  changeLang(newLang: any) {
    if (!this._isLoaded()) return;
    if (this.polyglot.currentLocale === newLang) return;
    SessionService.setCurrentLanguage(newLang);
    this.polyglot.locale = newLang;
    this.currentLang = newLang;
    document.documentElement.lang = newLang;
    window.location.reload();
    // Ja tu miałem po prostu zapisanie nowego języka i odświeżenie strony
    // Dobrze to działa, bo inaczej część tekstów byłaby stara, a nowe podmienione itp
  }
}
export const i18n = new I18n();
export const __ = (text: any, args?: any) => i18n.__(text, args);
// Użycie gdziekolwiek:
// __("Hi, my friend"); // 'Cześć, mój przyjacielu'
// __("Hi, ${name}!", { name: "Test" }); // 'Cześć, Test!'
// Więcej info: https://airbnb.io/polyglot.js/
