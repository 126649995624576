import React from 'react';
import { __ } from '../../../services/translation';
import './Paragraph.scss';

interface Props {
  translation: string;
  strong?: boolean;
  narrow?: boolean;
}

const Paragraph = ({ translation, strong, narrow }: Props) => (
  <p className={`paragraph ${strong ? 'strong' : ''} ${narrow ? 'narrow' : ''}`}>
    {__(translation)}
  </p>
);

export default Paragraph;
