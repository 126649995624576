import { AddInvoiceReq, ClientEntity, GetFullInvoiceResponse, NewCost } from 'app-types';
import { Dispatch } from 'redux';
import ApiService from '../services/api-service';
import { history } from '../App';
import { notifications, modal } from '.';
import { refreshApiList } from './api-list';

export const getSingleInvoice = (id: string) => async (dispatch: Dispatch) => {
    await ApiService.get(
        dispatch,
        'GET_SINGLE_INVOICE_SUCCESS',
        'GET_SINGLE_INVOICE_FAILED',
        null,
        null,
        `invoice/detail/${id}`,
        'LOADING_SINGLE_INVOICE',
    );
};

export const getCreditnotaData = (id: string) => async (dispatch: Dispatch) => {
    await ApiService.get(
        dispatch,
        'GET_CREDITNOTA_DATA_SUCCESS',
        'GET_CREDITNOTA_DATA_FAILED',
        null,
        null,
        `invoice/credit-note-for/${id}`,
        'LOADING_CREDITNOTA_DATA',
    );
};

export const deleteSingleInvoice = (id: string) => async (dispatch: Dispatch<any>) => {
    await ApiService.delete(
        dispatch,
        'DELETE_INVOICE_SUCCESS',
        'DELETE_INVOICE_FAILED',
        () => {
            dispatch(notifications.successNotification('application.invoice_deleted.'));
            dispatch(modal.hideModal());
            history.push('/dashboard/invoices/list');
        },
        null,
        `invoice/${id}`,
        'LOADING_INVOICE',
    );
};

export const deleteFileFromInvoice = (fileId: string, invoiceId: string) => async (
    dispatch: Dispatch<any>,
) => {
    await ApiService.delete(
        dispatch,
        'DELETE_FILE_FROM_INVOICE_SUCCESS',
        'DELETE_FILE_FROM_INVOICE_FAILED',
        () => {
            dispatch(modal.hideModal());
            window.location.reload();
        },
        null,
        `invoice/file/${fileId}/form/${invoiceId}`,
        'LOADING_DELETE_FILE_FROM_INVOICE',
    );
};

export const receiveInvoiceData = (values: GetFullInvoiceResponse) => ({
    type: 'RECEIVE_INVOICE_DATA',
    payload: values,
});

export const triggerPaymentMark = (id: string) => async (dispatch: Dispatch) => {
    await ApiService.patch(
        dispatch,
        'TRIGGER_PAYMENT_MARK_SUCCESS',
        'TRIGGER_PAYMENT_MARK_FAILED',
        () => {
            dispatch(notifications.successNotification('application.invoice_edited.'));
        },
        null,
        `invoice/trigger-payment-mark/${id}`,
        'LOADING_TRIGGER_PAYMENT_MARK',
        {},
    );
};

export const markAllInvoicesAsPaid = () => async (dispatch: Dispatch) => {
    await ApiService.patch(
        dispatch,
        'MARK_ALL_INVOICES_AS_PAID_SUCCESS',
        'MARK_ALL_INVOICES_AS_PAID_FAILED',
        () => {
            dispatch(notifications.successNotification('application.all_invoices_marked_as_paid'));
            dispatch(setUnpaidInvoicesCount(0));
            dispatch(refreshApiList());
        },
        null,
        'invoice/mark-all-as-paid',
        'LOADING_MARK_ALL_INVOICES_AS_PAID',
        {},
    );
};

export const getUnpaidInvoicesCount = () => async (dispatch: Dispatch) => {
    await ApiService.get(
        dispatch,
        'SET_INVOICES_UNPAID_COUNT_SUCCESS',
        'SET_INVOICES_UNPAID_COUNT_FAILED',
        null,
        null,
        'invoice/get-not-paid-count',
        'LOADING_INVOICES_UNPAID_COUNT',
    );
};

export const setUnpaidInvoicesCount = (count: number) => ({
    type: 'SET_INVOICES_UNPAID_COUNT_SUCCESS',
    payload: count
})

export const saveInvoice = (
    formValues: AddInvoiceReq & { files: any; costs: NewCost[] },
    id?: string,
    redirectToSend?: boolean,
) => async (dispatch: Dispatch) => {
    const values = { ...formValues };
    const formData = new FormData();

    if (values.files) {
        values.files.forEach((file: any) => {
            if (file.file) formData.append('files', file.file, file.file.name);
            // NECESSARY! Do not remove. Without this assigning coosts without files won't work.
            else formData.append('files', new File([], 'invoice_no_file'), 'invoice_no_file');
        });
    }

    if (values.costs) {
        values.costs.forEach((cost) => {
            if (cost.file) formData.append('costs', cost.file, cost.file.name);
            // NECESSARY! Do not remove. Without this assigning coosts without files won't work.
            else formData.append('costs', new File([], 'invoice_no_file'), 'invoice_no_file');
        });
    }

    const jsonData = JSON.stringify(values);

    const func = id ? ApiService.patch : ApiService.post;

    formData.append('data', jsonData);
    await func(
        dispatch,
        'ADD_INVOICE_SUCCESS',
        'ADD_INVOICE_FAILED',
        (invoice: GetFullInvoiceResponse) => {
            if (id) {
                dispatch(
                    notifications.successNotification(
                        values.isCreditNote ? 'application.creditnote_updated' : 'application.invoice_edited.',
                    ),
                );
            } else {
                dispatch(
                    notifications.successNotification(
                        values.isCreditNote ? 'application.creditnote_added' : 'application.invoice_added.',
                    ),
                );
            }
            if (redirectToSend) history.push(`/dashboard/invoices/send/${invoice.id}`);
            else history.push('/dashboard/invoices/list');
        },
        null,
        id ? `invoice/${id}` : 'invoice/new',
        'LOADING_INVOICE_SEND',
        formData,
        true,
    );
};

export const setInvoiceClient = (client: ClientEntity | null) => ({
    type: 'SET_INVOICE_CLIENT',
    payload: client,
});
