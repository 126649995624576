import { ItfApiForm } from 'itf_formbuilder_react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { CategoryReq } from '../../../../../../heyzzp-back/types';
import { modal, notifications, apiTable } from '../../../../actions';
import { history } from '../../../../App';
import { Button, CostSchemeForm, FieldsMapper, Spinner } from '../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import ApiService from '../../../../services/api-service';
import { formOnTranslateString } from '../../../../utils/trans-form';

const AddCostScheme = () => {
  useHeader('application.add_cost_scheme', '/dashboard/costs-schemes/list');

  const dispatch = useDispatch();

  return (
    <FormContainer>
      <ItfApiForm
        formId="categoryForm"
        schemaMayBeDynamic={false}
        schemaCacheEnabled={false}
        loadingInfoAboveContent
        sendDelay={200}
        loadingInfo={<Spinner overlay halfTransparent />}
        schemaFetcher={() => ApiService.loadForm('category/form/')}
        sendFetcher={(formId: string, values: CategoryReq) =>
          ApiService.sendForm('category', values, 'POST')
        }
        submitButton={
          <ButtonsContainer>
            <Button click={() => dispatch(modal.hideModal())} small text="application.cancel" />
            <Button type="submit" secondary text="application.confirm" />
          </ButtonsContainer>
        }
        onSavedSuccessfully={() => {
          dispatch(notifications.successNotification('application.category_created'));
          dispatch(apiTable.refreshApiTable());
          dispatch(modal.hideModal());
          history.push('/dashboard/categories');
        }}
        onTranslateString={formOnTranslateString}
        onRenderFullField={FieldsMapper}
      />
    </FormContainer>
  );
};

export default AddCostScheme;
