import { TrackingStatus } from 'app-types';

export const setWorkingTime = (time: number) => ({ type: 'SET_WORKING_TIME', payload: time });

export const increaseWorkingTime = () => ({ type: 'INCREASE_WORKING_TIME' });

export const shouldResultsUpdate = (value: boolean) => ({
  type: 'SHOULD_RESULTS_UPDATE',
  payload: value,
});

export const changeTrackingStatus = (value: TrackingStatus) => ({
  type: 'CHANGE_TRACKING_STATUS',
  payload: value,
});

export const setTrackingInterval = (handler: any) => ({
  type: 'SET_TRACKING_INTERVAL',
  payload: handler,
});
export const clearTrackingInterval = () => ({ type: 'CLEAR_TRACKING_INTERVAL' });
