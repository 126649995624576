import React from 'react';
import { TableSchema, otherIncomeForUser } from 'app-tables';
import { ApiList } from '../../../../components/Common';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import useHeader from '../../../../hooks/useHeader';
import OtherIncomeListItem from './Item';

const OtherIncomeList = () => {
  useHeader('application.other_income_list', '/');
  const scheme: TableSchema = new InteractiveTableSchema(otherIncomeForUser);

  return (
    <>
      <ApiList
        apiEndpointSubUrl="other-income/list"
        scheme={scheme}
        component={OtherIncomeListItem}
        padded
      />
    </>
  );
};

export default OtherIncomeList;
