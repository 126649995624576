import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import { useSelector } from 'react-redux';
import { Page, Container } from '../../../components/Layout';
import Basic from './Basic';
import Unlimited from './Unlimited';
import './BuySubscription.scss';
import { Alert, Spinner } from '../../../components/Common';
import { AppSettingsEntity, ClientUserEntity } from 'app-types';
import { ApplicationState } from '../../../reducers';
import { history } from '../../../App';
import { Capacitor } from '@capacitor/core';
import ApiService from '../../../services/api-service';

const BuySubscription = () => {
    const [preventSwiping, setPreventSwiping] = useState<boolean>(false);
    const [settings, setSettings] = useState<AppSettingsEntity>();
    const [loading, setLoading] = useState<boolean>(true);
    const user = useSelector((state: ApplicationState) => state.user.details);

    useEffect(() => {
        if (Capacitor.isNativePlatform()) history.push('/');
        getSettings();
    }, []);

    const getSettings = async() => {
        await ApiService.callFetch('GET', 'settings', async (res: AppSettingsEntity) => {
            setSettings(res);
        });
        setLoading(false);
    }

    if(loading || !settings) return <Spinner transparent />

    return (
        <>
            <Page light>
                <Container paddingTop>
                    {user &&
                        (user as ClientUserEntity).isActiveSub &&
                        (user as ClientUserEntity).subscription &&
                        !(user as ClientUserEntity).trial && (
                            <Alert type="success" text="application.you_have_subscription" />
                        )}
                    {user &&
                        (user as ClientUserEntity).isActiveSub &&
                        !(user as ClientUserEntity).subscription &&
                        !(user as ClientUserEntity).trial && (
                            <Alert type="notice" text="application.your_account_is_still_valid" />
                        )}
                    <Swiper
                        threshold={20}
                        containerClass="subscription-swiper"
                        slidesPerView="auto"
                        centeredSlides
                        spaceBetween={30}
                        shouldSwiperUpdate
                        noSwiping={preventSwiping}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                    >
                        <div className="subscription-option">
                            <Unlimited settings={settings} />
                        </div>
                        <div className="subscription-option">
                            <Basic settings={settings}  />
                        </div>
                    </Swiper>
                </Container>
            </Page>
        </>
    );
}


export default BuySubscription;
