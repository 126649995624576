import React from 'react';
import { TableSchema, mileageSchemaForUserTable } from 'app-tables';
import { CostEntity } from 'app-types';
import { InteractiveTableSchema } from '../../utils/table-shcema-utils';
import { ApiList } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import CostItemForInvoice from './Item';

interface Props {
  invoiceCosts: CostEntity[];
  callback: (cost: CostEntity) => void;
}

class AddCostToInvoice extends React.Component<Props> {
  private scheme: TableSchema;

  constructor(props: Props) {
    super(props);
    this.scheme = new InteractiveTableSchema(mileageSchemaForUserTable);
  }

  render() {
    const { invoiceCosts, callback } = this.props;
    return (
      <>
        <ModalContent>
          <ModalHeader>
            <ModalTitle title="application.add_cost" />
          </ModalHeader>
          <ApiList
            inModal
            apiEndpointSubUrl="cost/list-for-invoice"
            scheme={this.scheme}
            component={(props: any) => <CostItemForInvoice item={props.item} callback={callback} />}
            padded
            filterBy={invoiceCosts}
          />
        </ModalContent>
      </>
    );
  }
}

export default AddCostToInvoice;
