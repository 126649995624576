import React, { Component } from 'react';
import { OrderEvent } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import List from './List';
import Blank from './Blank';
import { Tabs } from '../../components/Common';
import { SingleTab } from '../../types/tabs';

interface Props {
  callback: (event: OrderEvent) => void;
  formVals?: any;
}

interface State {
  activeTab: MileageTab;
}

enum MileageTab {
  LIST = 'list',
  SCHEME = 'scheme',
  BLANK = 'blank',
}

class TimeSheetForInvoiceList extends Component<Props, State> {
  private tabs: SingleTab[] = [
    { label: 'application.add_from_list', value: MileageTab.LIST },
    { label: 'application.add_blank_scheme', value: MileageTab.BLANK },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: MileageTab.BLANK,
    };
  }

  private chooseTab = (tab: MileageTab) => {
    this.setState({ activeTab: tab });
  };

  private showContent = () => {
    const { callback, formVals } = this.props;
    const { activeTab } = this.state;
    switch (activeTab) {
      case MileageTab.LIST:
        return <List callback={callback} />;
      case MileageTab.BLANK:
        return <Blank callback={callback} formVals={formVals} />;
      default:
        break;
    }
  };

  render() {
    const { activeTab } = this.state;
    return (
      <ModalContent>
        <ModalHeader>
          <ModalTitle title="application.choose_mileage_scheme" />
        </ModalHeader>
        <Tabs activeTab={activeTab} chooseTab={this.chooseTab} tabs={this.tabs} />
        {this.showContent()}
      </ModalContent>
    );
  }
}
export default TimeSheetForInvoiceList;
