import React from 'react';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { FormContainer } from '../../components/Layout';
import CategoryForm from '../../components/Common/CategoryForm';
import { CategoryRes } from '../../../../heyzzp-back/types';

interface Props {
  id: string;
  callback: (data: CategoryRes) => void;
}
const EditCategory = ({ id, callback }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_category" />
    </ModalHeader>
    <FormContainer>
      <CategoryForm
        formUrl={`category/form/${id}`}
        formSendUrl={`category/${id}`}
        method="PATCH"
        callback={callback}
      />
    </FormContainer>
  </ModalContent>
);

export default EditCategory;
