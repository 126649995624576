import { FieldType, TableSchema } from '../typings';

export const invoiceForUser: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.number',
      field: 'number',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
    {
      name: 'application.creation_date',
      field: 'createdAt',
      mobile: true,
      searchable: false,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
    },
    {
      name: 'application.payment_term',
      field: 'paymentTerm',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
    },
    {
      name: 'application.payment_status',
      field: 'isPaid',
      mobile: true,
      searchable: false,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
  ],
};
