import React from 'react';
import { ButtonsContainer } from '../../components/Layout';
import { Button, Paragraph } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { AppSettingsEntity } from '../../../../heyzzp-back/types';

interface Props {
  settings: AppSettingsEntity;
  system: 'ios' | 'android';
}

const AppOutdated = ({ settings, system }: Props) => {
  const getLink = () => {
    switch (system) {
      case 'android':
        return settings.googlePlayUrl;
      case 'ios':
        return settings.appStoreUrl;
      default:
        return '';
    }
  };
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.app_outdated" />
      </ModalHeader>
      <Paragraph translation="application.app_outdated_text" />

      <ButtonsContainer marginTop>
        <Button
          className="button-modal"
          to={getLink()}
          blank
          text="application.update"
          primary
          noShadow
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default AppOutdated;
