import { AccountantConnectorDetails } from 'app-types';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Dashboard from '../../../views/DashboardCommon';
import { Page } from '../../../components/Layout';
import ApiService from '../../../services/api-service';
import useHeader from '../../../hooks/useHeader';
import InvitationsList from '../../../components/Common/InvitationsList';
import PendingInvitationsList from './InvitationsList';
import { InviteClient } from './InviteClient';

const invitationTabs = [
  {
    title: 'application.invitations_header',
    link: '/dashboard/invitations/pending-invitations',
    icon: 'list',
  },
  {
    title: 'application.send_invitation_tp_client_header',
    link: '/dashboard/invitations/invite-client',
    icon: 'plus',
  },
  {
    title: 'application.clients_list',
    link: '/dashboard/managed-clients',
    icon: 'user',
  },
];

const Invitations = () => {
  useHeader('application.invitations_header', '/dashboard/client/addons');

  return (
    <Page light withNavigation>
      <Dashboard navigation={invitationTabs}>
        <Switch>
          <Route
            exact
            path="/dashboard/invitations"
            render={() => <Redirect to="/dashboard/invitations/pending-invitations" />}
          />
          <Route
            exact
            path="/dashboard/invitations/pending-invitations"
            component={PendingInvitationsList}
          />
          <Route exact path="/dashboard/invitations/invite-client" component={InviteClient} />
        </Switch>
      </Dashboard>
    </Page>
  );
};

export default Invitations;
