import React from 'react';
import { ListItem } from '../../../../components/Common';
import { __ } from '../../../../services/translation';
import { DownloadService } from '../../../../services/download-service';

interface Props {
  year: number;
}

const SingleTimeSheetSummary = ({ year }: Props) => {
  const downloadCSV = async () => {
    await DownloadService.download(`time-sheet/summary-csv/${year}`);
  };
  const downloadPDF = async () => {
    await DownloadService.download(`time-sheet/summary-pdf/${year}`);
  };
  return (
    <ListItem
      header={`${__('application.time_sheet')} - ${year}`}
      singleRow
      paragraphs={[]}
      buttons={[
        {
          text: 'application.download_pdf',
          click: downloadPDF,
        },
        {
          text: 'application.download_csv',
          click: downloadCSV,
        },
        {
          text: 'application.send_to_client',
          to: `/dashboard/time-sheets/send/${year}`,
        },
      ]}
    />
  );
};

export default SingleTimeSheetSummary;
