import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ClientEntity, MileageEntity, ClientType } from 'app-types';
import { client as clientActions, modal, header } from '../../../../../actions';
import { ButtonsContainer, Section } from '../../../../../components/Layout';
import { Spinner, Button, InfoParagraph, ClientDetails } from '../../../../../components/Common';
import { Confirmation } from '../../../../../modals';
import EditClientButton from '../EditClientButton';
import { ApplicationState } from '../../../../../reducers';
import './SingleClient.scss';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  getSingleClient: (id: string) => void;
  loading: boolean;
  mileage: MileageEntity;
  showModal: (content: React.ReactNode) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
  deleteClient: (id: string) => void;
  hideModal: () => void;
  client: ClientEntity;
}

class SingleClient extends Component<Props> {
  componentDidMount() {
    const { getSingleClient, match, setHeader } = this.props;
    getSingleClient(match.params.id);
    setHeader('application.client');
  }

  private deleteClient = () => {
    const { showModal, deleteClient, hideModal, client } = this.props;
    showModal(
      <Confirmation
        text="application.are_you_sure_you_want_to_delete_this_client?"
        confirmCallback={() => deleteClient(client.id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  render() {
    const { loading, client } = this.props;

    return (
      <>
        <Section>
          {loading && <Spinner overlay />}
          <ClientDetails client={client} />
          <ButtonsContainer marginTop>
            <EditClientButton client={client} />

            <Button
              text="application.delete"
              danger
              noShadow
              click={this.deleteClient}
              rightMargin
              bottomMargin
            />
            <Button
              text="application.issue_invoice"
              success
              to={`/dashboard/invoices/add/client/${client.id}`}
              noShadow
            />
          </ButtonsContainer>
        </Section>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.client.loadingSingleClient,
  client: state.client.singleClient,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSingleClient: clientActions.getSingleClient,
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      deleteClient: clientActions.deleteClient,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleClient);
