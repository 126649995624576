import React from 'react';
import { TableSchema, FieldType, certificateForAdmin } from 'app-tables';
import { CertificateListDetails } from 'app-types';
import { InteractiveTableSchema } from '../../../utils/table-shcema-utils';
import { ApiTable, Button } from '../../../components/Common';
import { __ } from '../../../services/translation';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import useHeader from '../../../hooks/useHeader';
import ApiService from '../../../services/api-service';

const Certificates = () => {
  useHeader('application.certificate_requests');
  const scheme: TableSchema = new InteractiveTableSchema(certificateForAdmin).addFieldAtTheEnd({
    name: 'application.actions',
    field: 'actions',
    mobile: true,
    type: FieldType.Custom,
    customRender: (value, field, values: CertificateListDetails) => getButtons(values),
  });

  const getButtons = (certificate: CertificateListDetails) => (
    <>
      <Button
        primary
        secondary
        text="application.see"
        blank
        to={`${ApiService.url}addons/certificate/${certificate.id}`}
      />
    </>
  );

  return (
    <Page light>
      <Dashboard>
        <ApiTable apiEndpointSubUrl="addons/list-certificates" scheme={scheme} />
      </Dashboard>
    </Page>
  );
};

export default Certificates;
