import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import Payments from './Payments';
import Summary from './Summary';
import UserPayments from '../UserPayments';
import Creditnotas from './Creditnotas';

const paymentTabs = [
  {
    title: 'application.list',
    link: '/admin/payments/list',
    icon: 'list',
  },
  {
    title: 'application.creditnotas_list',
    link: '/admin/payments/creditnotas',
    icon: 'list',
  },
  {
    title: 'application.summary',
    link: '/admin/payments/summary/',
    icon: 'statistics',
  },

];

const AllPayments = () => (
  <>
    <Page light withNavigation>
      <Dashboard navigation={paymentTabs}>
        <Switch>
          <Route
            exact
            path="/admin/payments"
            render={() => <Redirect to="/admin/payments/list" />}
          />
          <Route exact path="/admin/payments/summary" component={Summary} />
          <Route exact path="/admin/payments/list" component={Payments} />
          <Route exact path="/admin/payments/creditnotas" component={Creditnotas} />
          <Route exact path="/admin/payments/:id" component={UserPayments} />
        </Switch>
      </Dashboard>
    </Page>
  </>
);

export default AllPayments;
