import {
  UpdatePassRequest,
  ResetPassLinkRequest,
  ResetPassRequest,
  AuthLoginRequest,
  ClientUserRegisterRequest,
  AccountantUserRegisterRequest,
  AuthLoginResult,
  ApiErrorCode,
} from 'app-types';
import React, { Dispatch } from 'react';
import { Dispatch as DispatchRedux } from 'redux';
import ApiService from '../services/api-service';
import { notifications } from '../actions';
import { history } from '../App';
import { getRememberedRedirect, clearRememberedRedirect } from '../utils/remember-redirect';
import { subscribeUser } from '../subscription';
import SessionService from '../services/session-service';
import { getUserDetails } from './user';
import { AnalitycsService } from '../services/analitycs-service';

export const showActivationMessage = () => ({ type: 'SHOW_ACTIVATION_MESSAGE' });
export const hideActivationMessage = () => ({ type: 'HIDE_ACTIVATION_MESSAGE' });

export const initializeMobile = () => async (dispatch: Dispatch<any>) => {
  const token = await SessionService.getToken();
  dispatch({ type: 'INITIALIZE_MOBILE', payload: token });
  dispatch(getUserDetails());
};

export const login = (userData: AuthLoginRequest) => async (dispatch: DispatchRedux) => {
  await ApiService.post(
    dispatch,
    'LOGIN_SUCCESS',
    'LOGIN_FAILED',
    (response: AuthLoginResult) => {
      subscribeUser();
      dispatch(hideActivationMessage());
      dispatch(notifications.successNotification('application.logged_in!'));
      dispatch({ type: 'REFRESH_API_TABLE' });
      dispatch({ type: 'REFRESH_API_GRID' });

      const redirect = getRememberedRedirect();

      if (redirect && redirect.role === response.user.role) {
        history.push(redirect.to);
        clearRememberedRedirect();
      } else {
        history.push('/dashboard');
      }
    },
    (error: ApiErrorCode) => {
      if (error === ApiErrorCode.NonConfirmedEmail) {
        dispatch(showActivationMessage());
      }
    },
    'auth/login',
    'LOGIN_LOADING',
    userData,
  );
};

export const logout = (notifyAndRedirect = true) => async (dispatch: DispatchRedux) => {
  SessionService.clearUserSession();
  await ApiService.get(
    dispatch,
    'LOGOUT',
    'LOGOUT',
    () => {
      if (notifyAndRedirect) {
        history.push('/');
        dispatch(notifications.successNotification('application.logout_successful!'));
        dispatch({ type: 'REFRESH_API_TABLE' });
        dispatch({ type: 'REFRESH_API_GRID' });
      }
      AnalitycsService.clearUser();
    },
    null,
    'auth/logout',
  );
};

export const logoutEverywhere = () => async (dispatch: DispatchRedux) => {
  SessionService.clearUserSession();
  await ApiService.get(
    dispatch,
    'LOGOUT',
    'LOGOUT',
    () => {
      history.push('/');
      dispatch(notifications.successNotification('application.logout_successful!'));
      dispatch({ type: 'REFRESH_API_TABLE' });
      dispatch({ type: 'REFRESH_API_GRID' });
      AnalitycsService.clearUser();
    },
    null,
    'auth/logout-everywhere',
  );
};

export const registerClient = (clientData: ClientUserRegisterRequest) => async (
  dispatch: DispatchRedux,
) => {
  await ApiService.post(
    dispatch,
    'REGISTER_CLIENT_SUCCESS',
    'REGISTER_CLIENT_FAILED',
    () => {
      dispatch(notifications.successNotification('application.registration_successful'));
      dispatch(showActivationMessage());
      history.push('/login');
    },
    null,
    'user/client',
    'REGISTRATION_LOADING',
    clientData,
  );
};

export const registerAccountant = (clientData: AccountantUserRegisterRequest) => async (
  dispatch: DispatchRedux,
) => {
  await ApiService.post(
    dispatch,
    'REGISTER_ACCOUNTANT_SUCCESS',
    'REGISTER_ACCOUNTANT_FAILED',
    () => {
      dispatch(notifications.successNotification('application.registration_successful'));
      dispatch(showActivationMessage());
      history.push('/login');
    },
    null,
    'user/accountant',
    'REGISTRATION_LOADING',
    clientData,
  );
};

export const sendPasswordResetLink = (resetPasswordData: ResetPassLinkRequest) => async (
  dispatch: DispatchRedux,
) => {
  await ApiService.post(
    dispatch,
    'SEND_RESET_PASSWORD_LINK_SUCCESS',
    'SEND_RESET_PASSWORD_LINK_FAILED',
    () => {
      dispatch(
        notifications.successNotification(
          'application.if_your_email_address_was_correct_you_will_receive_link_to_change_your_password._it_will_be_valid_for_next_24_hours.',
        ),
      );
    },
    null,
    'user/reset-pass/request',
    'LOADING',
    resetPasswordData,
  );
};

export const changePassword = (changePasswordData: UpdatePassRequest) => async (
  dispatch: DispatchRedux,
) => {
  await ApiService.patch(
    dispatch,
    'CHANGE_PASSWORD_SUCCESS',
    'CHANGE_PASSWORD_FAILED',
    () => {
      dispatch(
        notifications.successNotification('application.new_password_has_been_set_correctly.'),
      );
    },
    null,
    'user/pass',
    'LOADING',
    changePasswordData,
  );
};

export const resetPassword = (resetPasswordData: ResetPassRequest) => async (
  dispatch: DispatchRedux,
) => {
  await ApiService.patch(
    dispatch,
    'RESET_PASSWORD_SUCCESS',
    'RESET_PASSWORD_FAILED',
    () => {
      dispatch(
        notifications.successNotification('application.new_password_has_been_set_correctly.'),
      );
      history.push('/');
    },
    null,
    'user/reset-pass/change',
    'LOADING',
    resetPasswordData,
  );
};

export const clearUserSession = () => ({ type: 'CLEAR_USER_SESSION' });
