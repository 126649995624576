import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { differenceInMinutes } from 'date-fns';
import { TimeSheetEntity } from 'app-types';
import { timeSheet as timeSheetActions, modal, header } from '../../../../actions';
import { ButtonsContainer } from '../../../../components/Layout';
import { Spinner, Button, InfoParagraph } from '../../../../components/Common';
import { EditTimeSheet, Confirmation } from '../../../../modals';
import { ApplicationState } from '../../../../reducers';

interface RouteProps {
  id: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  getSingleTimeSheet: (mileageId: string) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
  loading: boolean;
  timeSheet: TimeSheetEntity;
  showModal: (content: React.ReactNode) => void;
  deleteTimeSheet: (id: string) => void;
  hideModal: () => void;
}

class SingleTimeSheet extends Component<Props> {
  componentDidMount() {
    const { getSingleTimeSheet, match, setHeader } = this.props;
    getSingleTimeSheet(match.params.id);
    setHeader('application.time_sheet', '/dashboard/time-sheets/list');
  }

  private deleteTimeSheet = () => {
    const { showModal, deleteTimeSheet, hideModal, timeSheet } = this.props;
    showModal(
      <Confirmation
        text="application.are_you_sure_you_want_to_delete_this_time_sheet?"
        confirmCallback={() => deleteTimeSheet(timeSheet.id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  private editTimeSheet = () => {
    const { showModal, match } = this.props;
    showModal(<EditTimeSheet id={match.params.id} />);
  };

  render() {
    const { loading, timeSheet } = this.props;

    return (
      <>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <InfoParagraph name="application.name" text={timeSheet.name} />
            <InfoParagraph
              name="application.duration_time"
              durationNoUnit={differenceInMinutes(
                new Date(timeSheet.finish),
                new Date(timeSheet.start),
              )}
            />
            <InfoParagraph name="application.start_date" date={timeSheet.start} />
            <InfoParagraph name="application.start_time" time={timeSheet.start} />
            <InfoParagraph name="application.finish_date" date={timeSheet.finish} />
            <InfoParagraph name="application.finish_time" time={timeSheet.finish} />

            <ButtonsContainer marginTop>
              <Button
                text="application.edit"
                secondary
                noShadow
                click={this.editTimeSheet}
                rightMargin
                bottomMargin
              />
              <Button
                text="application.delete"
                danger
                noShadow
                click={this.deleteTimeSheet}
                bottomMargin
              />
            </ButtonsContainer>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.timeSheet.loadingSingle,
  timeSheet: state.timeSheet.single,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSingleTimeSheet: timeSheetActions.getSingleTimeSheet,
      showModal: modal.showModal,
      hideModal: modal.hideModal,
      deleteTimeSheet: timeSheetActions.deleteTimeSheet,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleTimeSheet);
