import React from 'react';
import { useDispatch } from 'react-redux';
import { ButtonsContainer } from '../../components/Layout';
import { Button } from '../../components/Common';
import { modal } from '../../actions';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalContent from '../../components/Common/Modal/ModalContent';
import SuccessIndicator from './SuccessIndicator';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import { __ } from '../../services/translation';
import { history } from '../../App';

const AccountActivated = () => {
  const dispatch = useDispatch();

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.account_activated" />
      </ModalHeader>
      <SuccessIndicator />
      <p>{__('application.you_can_login_now')}</p>
      <ButtonsContainer marginTop>
        <Button
          click={() => {
            dispatch(modal.hideModal());
            history.push('/login');
          }}
          primary
          text="application.go_to_login"
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default AccountActivated;
