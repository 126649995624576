import React, { useEffect } from 'react';
import { TableSchema, invoiceForUser } from 'app-tables';
import { useDispatch, useSelector } from 'react-redux';
import { UserRole } from 'app-types';
import { ApiList } from '../../../../components/Common';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { InvoiceListItem } from './Item';
import useHeader from '../../../../hooks/useHeader';
import { invoice } from '../../../../actions';
import { ApplicationState } from '../../../../reducers';
import { __ } from '../../../../services/translation';
import { getUnpaidInvoicesCount } from '../../../../actions/invoice';

const InvoiceList = () => {
    const userDetails = useSelector((state: ApplicationState) => state.user.details!);
    useHeader('application.invoices');
    const dispatch = useDispatch();
    const unpaidInvoicesCount = useSelector((state: ApplicationState) => state.invoice.unpaidCount);
    const scheme: TableSchema = new InteractiveTableSchema(invoiceForUser);

    const handleClickMarkAllInvoicesAsPaid = async () => {
        dispatch(invoice.markAllInvoicesAsPaid());
    };


    useEffect(() => {
        dispatch(getUnpaidInvoicesCount());
    }, []);

    return (
        <ApiList
            apiEndpointSubUrl="invoice/list"
            scheme={scheme}
            component={InvoiceListItem}
            customButtons={
                userDetails.role === UserRole.IncomeAndCost
                    ? undefined
                    : [
                        {
                            translateKey: 'application.mark_all_invoices_as_paid',
                            translateParams: { count: unpaidInvoicesCount },
                            onClick: handleClickMarkAllInvoicesAsPaid,
                            isHidden: !unpaidInvoicesCount,
                        },
                    ]
            }
        />
    );
};

export default InvoiceList;
