import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './ListParagraph.scss';

interface Props {
  icon: IconProp | string | null;
  text: string | number | React.ReactNode;
  success?: boolean;
  danger?: boolean;
  warning?: boolean;
  strong?: boolean;
}

const ListParagraph = ({ warning, danger, success, icon, text, strong }: Props) => (
  <p
    className={`list-paragraph ${warning ? 'warning' : ''} ${strong ? 'strong' : ''} ${
      danger ? 'danger' : ''
    } ${success ? 'success' : ''}`}
  >
    {icon && (
      <div className="list-paragraph-icon-small-wrapper">
        {typeof icon === 'string' ? (
          <img
            src={`/assets/icons/${icon}.svg`}
            className={`list-paragraph-icon-small ${warning ? 'warning' : ''} ${
              danger ? 'danger' : ''
            } ${success ? 'success' : ''}`}
          />
        ) : (
          <FontAwesomeIcon
            icon={icon}
            className={`list-paragraph-icon-small ${warning ? 'warning' : ''} ${
              danger ? 'danger' : ''
            } ${success ? 'success' : ''}`}
          />
        )}
      </div>
    )}
    {text || '-'}
  </p>
);

export default ListParagraph;
