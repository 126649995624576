import React from 'react';
import { ServiceSchemaEntity } from 'app-types';
import { useDispatch } from 'react-redux';
import { faFileInvoice, faEuroSign, faPercent, faFileAlt } from '@fortawesome/free-solid-svg-icons';

import { modal, notifications } from '../../../actions';
import { ListItem } from '../../../components/Common';
import { percentFromTaxRate } from '../../../utils/tax-rate-percent';
import { formatPrice } from '../../../utils/format-price';
import Confirmation from '../../Confirmation';
import ApiService from '../../../services/api-service';
import EditServiceModal from '../../EditServiceModal';
import ServicesForInvoice from '..';

interface Props {
  item: ServiceSchemaEntity;
  callback: (item: ServiceSchemaEntity) => void;
}

const ServiceForInvoiceItem = ({ item, callback }: Props) => {
  const dispatch = useDispatch();
  const paragraphs = [
    { icon: faFileInvoice, text: item.name },
    { icon: faEuroSign, text: formatPrice(item.price) },
    { icon: faPercent, text: `${percentFromTaxRate(item.taxRate)} %` },
  ];

  const deleteService = async () => {
    await ApiService.callFetch('DELETE', `service-schema/${item.id}`, () => {
      dispatch(notifications.successNotification('application.service_removed'));
      dispatch(modal.hideModal());
    });
  };

  const deleteServiceConfirm = () => {
    dispatch(
      modal.showModal(
        <Confirmation text="application.delete_service_confirm" confirmCallback={deleteService} />,
      ),
    );
  };

  const openEditServiceModal = (id: string) => {
    dispatch(
      modal.showModal(
        <EditServiceModal
          id={id}
          callback={() => {
            dispatch(modal.showModal(<ServicesForInvoice callback={() => callback} />));
          }}
        />,
      ),
    );
  };

  return (
    <>
      <ListItem
        level={2}
        icon={faFileAlt}
        paragraphs={paragraphs}
        buttons={[
          {
            text: 'application.use',
            type: 'success',
            click: () => {
              callback(item);
              dispatch(modal.hideModal());
            },
          },
          {
            text: 'application.edit',

            click: () => {
              openEditServiceModal(item.id);
            },
          },
          {
            text: 'application.delete',
            type: 'danger',
            click: () => {
              deleteServiceConfirm();
            },
          },
        ]}
      />
    </>
  );
};

export default ServiceForInvoiceItem;
