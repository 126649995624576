import React from 'react';
import { FieldType, TableSchema, creditNotasForAdmin } from 'app-tables';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { ApiTable, Button, } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';
import { __ } from '../../../../services/translation';
import ApiService from '../../../../services/api-service';
import { DownloadService } from '../../../../services/download-service';
import { FullPaymentRes } from '../../../../../../heyzzp-back/types/payment';


const Creditnotas = () => {
    useHeader('application.creditnotas_list');

    const downloadCreditnotePDF = async (id: string) => {
        await DownloadService.download(`payment/generate-creditnote/${id}`);
    };

    const scheme: TableSchema = new InteractiveTableSchema(creditNotasForAdmin)
        .addFieldAtTheEnd({
            name: 'application.actions',
            field: 'editButton',
            mobile: true,
            type: FieldType.Custom,
            customRender: (value, field, values) => (
                <Button text="application.download_pdf" click={() => downloadCreditnotePDF(values.id)} />
            ),
        });

    return (
        <ApiTable apiEndpointSubUrl="payment/creditnotas" scheme={scheme} />
    );
};

export default Creditnotas;
