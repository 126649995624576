import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { __ } from '../../../services/translation';

import './Button.scss';

interface ButtonProps {
  text?: string | React.ReactNode;
  to?: string;
  click?: any;
  primary?: boolean;
  outline?: boolean;
  outlinePrimary?: boolean;
  colorPrimary?: boolean;
  disabled?: boolean;
  auto?: boolean;
  type?: 'button' | 'submit' | 'reset';
  big?: boolean;
  small?: boolean;
  extraSmall?: boolean;
  danger?: boolean;
  success?: boolean;
  className?: string;
  blank?: boolean;
  icon?: string;
  img?: string;
  faIcon?: any;
  leftIcon?: boolean;
  rightIcon?: boolean;
  secondary?: boolean;
  mobileMax?: boolean;
  noShadow?: boolean;
  modal?: boolean;
  bottomMargin?: boolean;
  rightMargin?: boolean;
  round?: boolean;
  full?: boolean;
  autoWidth?: boolean;
  children?: React.ReactNode;
  constant?: boolean;
  transparent?: boolean;
}

const Button = ({
  className,
  rightIcon,
  leftIcon,
  rightMargin,
  round,
  primary,
  outline,
  outlinePrimary,
  modal,
  mobileMax,
  secondary,
  danger,
  success,
  big,
  colorPrimary,
  bottomMargin,
  small,
  extraSmall,
  auto,
  disabled,
  transparent,
  noShadow,
  to,
  icon,
  text,
  faIcon,
  blank,
  type,
  click,
  img,
  full,
  autoWidth,
  children,
  constant,
}: ButtonProps) => {
  const buttonClass = `button ${className || ''} ${mobileMax ? 'mobile-max' : ''} ${
    modal ? 'modal' : ''
  } ${rightIcon ? 'button-right-icon' : ''} ${leftIcon ? 'button-left-icon' : ''} ${
    secondary ? 'button-secondary' : ''
  } ${primary ? 'button-primary' : ''} ${round ? 'button-round' : ''}  ${
    danger ? 'button-danger' : ''
  } ${success ? 'button-success' : ''}  ${outline ? 'button-outline' : ''} ${
    outlinePrimary ? 'button-outline-primary' : ''
  } ${colorPrimary ? 'button-color-primary' : ''} ${big ? 'button-big' : ''} ${
    small ? 'button-small' : ''
  } ${extraSmall ? 'button-extra-small' : ''} ${auto ? 'auto' : ''} ${disabled ? 'disabled' : ''} ${
    noShadow ? 'no-shadow' : ''
  } ${full ? 'full' : ''} ${autoWidth ? 'full' : ''} ${bottomMargin ? 'bottom-margin' : ''} ${
    rightMargin ? 'right-margin' : ''
  } ${!text ? 'no-text' : ''} ${constant ? 'constant' : ''}  ${
    transparent ? 'button-transparent' : ''
  }`;
  return to ? (
    disabled ? (
      <a className={buttonClass}>{text && __(text)}</a>
    ) : blank ? (
      <a target="_blank" className={buttonClass} href={to} rel="noreferrer">
        {text && __(text)}
        {children}
      </a>
    ) : (
      <Link className={buttonClass} to={to}>
        {icon ? <img className="button-icon" src={`/assets/icons/${icon}.svg`} /> : null}
        {leftIcon && faIcon ? (
          <div className="button-icon">
            <FontAwesomeIcon icon={faIcon} />
          </div>
        ) : null}
        {text && __(text)}
        {children}
      </Link>
    )
  ) : (
    <button type={type} disabled={disabled} onClick={click} className={buttonClass}>
      {leftIcon && icon ? <img className="button-icon" src={`/assets/icons/${icon}.svg`} /> : null}
      {leftIcon && img ? <img className="button-icon" src={img} /> : null}
      {leftIcon && faIcon ? (
        <div className="button-icon">
          <FontAwesomeIcon icon={faIcon} />
        </div>
      ) : null}
      {text && __(text)}
      {children}
      {rightIcon && icon ? <img className="button-icon" src={`/assets/icons/${icon}.svg`} /> : null}
      {rightIcon && faIcon ? (
        <div className="button-icon">
          <FontAwesomeIcon icon={faIcon} />
        </div>
      ) : null}
    </button>
  );
};

export default Button;
