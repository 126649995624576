import React, { ChangeEvent, useState } from 'react';
import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
  PublicItfFormBuilderComponentApi,
} from 'itf_formbuilder_react';
import ReactSelect from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import { ClientUserEntity } from 'app-types';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import {
  Button,
  FieldsMapper,
  GoogleMapsRouteButton,
  Input,
  MileageFieldsMapper,
  RequiredIndicator,
  Spinner,
} from '../../components/Common';
import { formOnTranslateString } from '../../utils/trans-form';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import ApiService from '../../services/api-service';
import { flatpickr } from '../../App';
import { modal } from '../../actions';
import { ExtendedOnFormGloballyRegisteredProps } from '../../types/extendedOnFormGloballyRegisteredProps';
import { ApplicationState } from '../../reducers';

interface Data {
  startPoint: string;
  finishPoint: string;
  distance: number;
  time: number;
}

interface Props extends Data {
  onChange: (vals: Data) => void;
  customCloseModal?: boolean;
  allowOwnAddress?: boolean;
  returnRoute?: boolean;
}

const MileageRoute = ({
  onChange,
  startPoint,
  finishPoint,
  distance,
  time,
  customCloseModal,
  allowOwnAddress,
  returnRoute,
}: Props) => {
  const [localStartPoint, setLocalStartPoint] = useState('');
  const [localFinishPoint, setLocalFinishPoint] = useState('');
  const [useOwnAddress, setUseOwnAddress] = useState(false);
  const dispatch = useDispatch();
  let itfForm: any;
  const user: ClientUserEntity = useSelector(
    (state: ApplicationState) => state.user.details as ClientUserEntity,
  );
  const { managedUser } = useSelector((state: ApplicationState) => state.accounting);

  const setRef = (form: any) => {
    itfForm = form;
  };

  const getUserAddress = () => {
    let addressStreet: string | null;
    let addressPostCode: string | null;
    let addressCity: string | null;

    if (managedUser) {
      addressStreet = managedUser.addressStreet;
      addressPostCode = managedUser.addressPostCode;
      addressCity = managedUser.addressCity;
    } else {
      addressStreet = user.addressStreet;
      addressPostCode = user.addressPostCode;
      addressCity = user.addressCity;
    }

    const first = addressStreet;
    const second = [addressPostCode, addressCity].filter((el) => el).join(' ');
    const address = [first, second].filter((el) => el).join(', ');

    return address;
  };

  const generateUseOwnAddressInput = (field: any, onFocus: any, onBlur: any) => {
    if (!allowOwnAddress) {
      return null;
    }

    if (
      (field.id === 'startPoint' && !returnRoute) ||
      (field.id === 'finishPoint' && returnRoute)
    ) {
      return (
        <div className="field">
          <Input
            type="checkbox"
            label={`${
              returnRoute ? 'application.use_my_address_as_return' : 'application.use_my_address'
            }`}
            input={{
              name: 'useMyAddressCheckbox',
              id: 'useMyAddressCheckbox',
              placeholder: field.placeholder,
              value: useOwnAddress,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                const isSet = Boolean(e.target.checked);
                setUseOwnAddress(isSet);

                if (isSet) {
                  if (returnRoute) {
                    itfForm.api.setValues({
                      finishPoint: getUserAddress(),
                    });
                  } else {
                    itfForm.api.setValues({
                      startPoint: getUserAddress(),
                    });
                  }
                } else if (returnRoute) {
                  itfForm.api.setValues({
                    finishPoint: '',
                  });
                } else {
                  itfForm.api.setValues({
                    startPoint: '',
                  });
                }
              },
              onFocus,
              onBlur,
            }}
            meta={{}}
          />
        </div>
      );
    }
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.mileage_route" />
      </ModalHeader>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="mileage"
          schemaMayBeDynamic
          schemaCacheEnabled
          onFormGloballyRegistered={(api) => {
            (api as ExtendedOnFormGloballyRegisteredProps).setValues({
              startPoint,
              finishPoint,
              distance,
              time,
            });

            window.globalForms.mileage.valueChangedClb = (newValues) => {
              setLocalStartPoint(newValues.startPoint);
              setLocalFinishPoint(newValues.finishPoint);
            };
          }}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm('mileage/form/mileage')}
          sendFetcher={(formId: string, values: Data) =>
            ApiService.sendForm('mileage/form/valid-mileage-route-form', values, 'POST')
          }
          onSavedSuccessfully={(data: Data) => {
            if (!customCloseModal) {
              dispatch(modal.hideModal());
            }
            onChange(data);
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer marginTop>
              <Button type="submit" text="application.ok" primary />
            </ButtonsContainer>
          }
          thirdPartyComponents={{ ReactSelect, ReactCreatableSelect, flatpickr }}
          onTranslateString={formOnTranslateString}
          registerAsGlobalForm
          enableLiveValuesOfGlobalForm
          onRenderFullField={(
            field: FormFieldToRender,
            errors: any,
            fieldHtmlId: string,
            currentValue: any,
            onChange: ChangeFunction,
            onFocus: FieldAndEventFunction,
            onBlur: FieldAndEventFunction,
          ) => {
            if (field.id === 'startPoint' || field.id === 'finishPoint') {
              {
                return (
                  <>
                    {FieldsMapper(
                      field,
                      errors,
                      fieldHtmlId,
                      currentValue,
                      onChange,
                      onFocus,
                      onBlur,
                    )}
                    {generateUseOwnAddressInput(field, onFocus, onBlur)}
                  </>
                );
              }
            } else if (field.id === 'time') {
              return (
                <>
                  {FieldsMapper(
                    field,
                    errors,
                    fieldHtmlId,
                    currentValue,
                    onChange,
                    onFocus,
                    onBlur,
                  )}
                  <ButtonsContainer marginLittle marginTop>
                    <GoogleMapsRouteButton
                      startPoint={localStartPoint}
                      finishPoint={localFinishPoint}
                    />
                  </ButtonsContainer>
                </>
              );
            }
            return FieldsMapper(
              field,
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
            );
          }}
        />
      </FormContainer>
    </ModalContent>
  );
};

export default MileageRoute;
