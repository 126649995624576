import React from 'react';
import { useDispatch } from 'react-redux';
import { menu } from '../../../../actions';
import { Button } from '../../../Common';
import { __ } from '../../../../services/translation';
import './TrialIndicator.scss';
import { isMobileBuild } from '../../../../utils/is-mobile';

const TrailIndicator = () => {
  const dispatch = useDispatch();
  if (isMobileBuild()) return null;
  return (
    <div className="trial-indicator">
      <p className="trial-indicator-paragraph">{__('application.you_are_using_trial_version.')}</p>{' '}
      <div className="trial-indicator-button-container">
        <Button text="application.upgrade_now." to="/buy-subscription" />
        <button className="trial-close-button" onClick={() => dispatch(menu.hideTrialIndicator())}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default TrailIndicator;
