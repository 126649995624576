import { FieldType, TableSchema } from '../typings';

export const mileageForUserTable: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.id',
      field: 'id',
      mobile: false,
      searchable: false,
      sortable: false,
      defaultSort: false,
      type: FieldType.Text,
    },
    {
      name: 'application.date',
      field: 'date',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
    },
    {
      name: 'application.start_point',
      field: 'startPoint',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
    {
      name: 'application.finish_point',
      field: 'finishPoint',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
    {
      name: 'application.distance',
      field: 'distance',
      mobile: true,
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text,
    },
  ],
};
