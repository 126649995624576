import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Spinner, Button } from '..';
import ApiService from '../../../services/api-service';
import { ButtonsContainer } from '../../Layout';

import './PDFPreview.scss';
import { DownloadService } from '../../../services/download-service';

interface Props {
  url: string;
}

const PDFPreview = ({ url }: Props) => {
  const [numPages, setNumPages] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState<string>();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const getPdfPreview = async () => {
    const res = await ApiService.sendForFile('GET', url, null, true);
    const base64 = res.data;
    setFile(base64);
    setLoading(false);
  };

  useEffect(() => {
    getPdfPreview();
  }, []);

  if (loading) return <Spinner />;

  return (
    <div>
      { file && <Document file={DownloadService.base64ToBlob(file, 'pdf')} onLoadSuccess={onDocumentLoadSuccess} className="preview-container">
        <Page pageNumber={pageNumber} width={500} />
      </Document>}
      {numPages && numPages > 1 && (
        <ButtonsContainer>
          <Button
            faIcon={faChevronLeft}
            leftIcon
            disabled={pageNumber === 1}
            click={() => setPageNumber((num) => num - 1)}
          />
          <Button
            faIcon={faChevronRight}
            leftIcon
            disabled={pageNumber === numPages}
            click={() => setPageNumber((num) => num + 1)}
          />
        </ButtonsContainer>
      )}
    </div>
  );
};

export default PDFPreview;
