import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Page, Content } from '../../components/Layout';
import { Logo, WelcomeHeader } from '../../components/Common';
import EmailPrompt from './EmailPrompt';
import NewPassword from './NewPassword';
import useHeader from '../../hooks/useHeader';

const ResetPassword = () => {
  useHeader('');
  return (
    <>
      <Page stretch padded center primary>
        <Logo center big />
        <Content center>
          <WelcomeHeader text="application.reset_password" />
        </Content>
        <Switch>
          <Route exact path="/restore-pass" component={EmailPrompt} />
          <Route path="/restore-pass/:userId/:updateToken" component={NewPassword} />
        </Switch>
      </Page>
    </>
  );
};

export default ResetPassword;
