import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { EnterKvk, KvkData } from '../../views';

const DashboardNotActivated = () => (
  <Switch>
    <Route exact path="/inactive" render={() => <Redirect to="/inactive/enter-kvk" />} />
    <Route exact path="/inactive/enter-kvk" component={EnterKvk} />
    <Route exact path="/inactive/data/:id" component={KvkData} />
    <Route exact path="/inactive/data" render={() => <Redirect to="/inactive" />} />
  </Switch>
);

export default DashboardNotActivated;
