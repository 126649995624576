import React from 'react';
import { __ } from '../../../../../services/translation';
import { StatisticsSummaryItem } from '../../../../../types/statistics';

import './Summary.scss';

interface Props {
  data: StatisticsSummaryItem[];
}

const Summary = ({ data }: Props) => (
  <div className="income-container">
    {data.map((single: StatisticsSummaryItem) => (
      <div className="income-inner-container">
        <p className="income-amount">{single.amount}</p>
        <p className="income-description">{__(single.label)}</p>
      </div>
    ))}
  </div>
);

export default Summary;
