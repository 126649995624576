import { FullDataRes, DigitaleBrievenbus } from 'app-types';

export interface AccountingState {
  managedUser: FullDataRes | null;
}

const initialState: AccountingState = {
  managedUser: null,
};

interface SetManagedUser {
  type: 'SELECT_MANAGED_USER';
  payload: FullDataRes;
}

interface UpdateDigitaleBrievenbus {
  type: 'UPDATE_DIGITALE_BRIEVENBUS';
  payload: DigitaleBrievenbus;
}

type Action = SetManagedUser | UpdateDigitaleBrievenbus;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SELECT_MANAGED_USER':
      return {
        ...state,
        managedUser: action.payload,
      };

    case 'UPDATE_DIGITALE_BRIEVENBUS':
      if (!state.managedUser) {
        return { ...state };
      }
      return {
        ...state,
        managedUser: {
          ...state.managedUser,
          digitaleBrievenbus: action.payload,
        },
      };

    default:
      return state;
  }
};
