import React from 'react';
import { Logo, Paragraph } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { __ } from '../../services/translation';

import './AppConservationMode.scss';

const AppConservationMode = () => (
  <ModalContent>
    <div className="app-conservation">
      <Logo big center dark />
      <div className="app-conservation-wrapper">
        <Paragraph strong translation="application.app_conservation_mode" />
      </div>
      <img src="/assets/icons/cog.svg" className="app-conservation-icon" />
    </div>
  </ModalContent>
);

export default AppConservationMode;
