import React, { useMemo } from 'react';
import { faCheck, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { CostEntity, DocumentRes } from 'app-types';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem } from '../../../../components/Common';
import { formatDate } from '../../../../utils/format-date';
import { formatPrice } from '../../../../utils/format-price';
import { modal, document } from '../../../../actions';
import { EditCost, Confirmation } from '../../../../modals';
import EditDocument from '../../../../modals/EditDocument';
import { SentStatus } from '../../../../components/Common/SentStatus';

interface Props {
  item: DocumentRes;
}

const DocumentItem = ({ item }: Props) => {
  const dispatch = useDispatch();
  const { date, id, name } = item;

  const editCost = () => {
    dispatch(modal.showModal(<EditDocument id={id} defaultValues={item} />));
  };

  const confirmDelete = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text="application.are_you_sure_you_want_to_delete_this_document"
          confirmCallback={() => dispatch(document.deleteDocument(item.id))}
          cancelCallback={() => dispatch(modal.hideModal())}
        />,
      ),
    );
  };

  const paragraphs = useMemo(() => [{ icon: 'calendar', text: formatDate(date) }], []);

  return (
    <ListItem
      header={name}
      icon={faEuroSign}
      paragraphs={paragraphs}
      headerRight={item.sent ? <SentStatus /> : null}
      buttons={[
        {
          text: 'application.details',
          to: `/dashboard/costs-documents/${id}`,
        },
        {
          text: 'application.edit',
          type: 'secondary',
          click: editCost,
        },
        {
          text: 'application.delete',
          type: 'danger',
          click: confirmDelete,
        },
      ]}
    />
  );
};

export default DocumentItem;
