import React from 'react';
import { RequiredIndicator } from '../..';
import { __ } from '../../../../services/translation';
import Spinner from '../../Spinner';
import './Results.scss';

interface Props {
  input: any;
  label: string;
  required?: boolean;
  readonly?: boolean;
  getInputProps: any;
  getSuggestionItemProps: any;
  suggestions: any;
  loading: boolean;
  placeholder?: string;
  meta: any;
}

const Results = ({
  getInputProps,
  getSuggestionItemProps,
  suggestions,
  loading,
  readonly,
  placeholder,
  meta,
  input,
  label,
  required,
}: Props) => {
  const inputLabel = label && (
    <label htmlFor={input.name}>
      {__(label)} {required && <RequiredIndicator />}
    </label>
  );

  return (
    <div className="autocomplete-root">
      <div className="autosuggestion-wrapper input-container">
        <div className="input-wrapper">
          {inputLabel}
          <input
            {...getInputProps()}
            readOnly={readonly}
            value={input.value}
            placeholder={__(placeholder)}
          />
          <img src="/assets/icons/pin.svg" className="address-icon" alt="" />
        </div>
        {meta.error && meta.touched && <small className="text-danger">{meta.error}</small>}
      </div>
      <div className="autocomplete-dropdown-container">
        {loading && <Spinner overlay />}
        {suggestions.map((suggestion: any) => (
          <div {...getSuggestionItemProps(suggestion)} className="address-suggestion">
            <span>{suggestion.description}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Results;
