import React from 'react';
import { ClientUserEntity, SomeUserEntity, UserRole } from 'app-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';

interface Props {
  user: SomeUserEntity | null;
  exact?: boolean;
  path?: string;
  component: any;
}

const RouteUnsubscribed = ({ user, exact, path, component }: Props) => {
  if (user && user.role === UserRole.Client && !user.isActiveSub) {
    return <Route exact={exact} path={path} component={component} />;
  }
  return <Route render={() => <Redirect to="/" />} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
});

export default connect(mapStateToProps)(RouteUnsubscribed);
