import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaxRate } from 'app-types';
import React from 'react';
import { Card, Subheader } from '../../../../../components/Common';
import { __ } from '../../../../../services/translation';
import { formatPrice } from '../../../../../utils/format-price';
import './TaxPreviewCard.scss';

interface Props {
  rates: TaxRate[];
  title: string;
  net: any;
  tax: any;
  costsTax: number;
  toPay: number;
  toReceive: number;
  next: () => void;
  prev: () => void;
}

const TaxPreviewCard = ({
  rates,
  title,
  net,
  tax,
  costsTax,
  toPay,
  toReceive,
  prev,
  next,
}: Props) => (
  <>
    <Subheader rawText={title}>
      <div className="tax-preview-navigation">
        <FontAwesomeIcon icon={faChevronLeft} onClick={prev} className="tax-preview-icon" />
        <FontAwesomeIcon icon={faChevronRight} onClick={next} className="tax-preview-icon" />
      </div>
    </Subheader>
    <Card
      light
      header={<Subheader level={2} text="application.statistics_income" noMarginBottom />}
      body={
        <div className="tax-preview-details">
          <div className="tax-preview-line">
            <span />
            <strong>{__('application.statistics_income_base')}</strong>
            <strong>{__('application.statistics_income_tax')}</strong>
          </div>
          {rates.map((rate) => (
            <div className="tax-preview-line">
              <span>{TaxRate[rate]}</span>
              <span>{formatPrice(net[rate], false, 0)}</span>
              <span>{formatPrice(tax[rate], false, 0)}</span>
            </div>
          ))}
        </div>
      }
    />
    <Card
      header={
        <Subheader level={2} text="application.statistics_btw_from_costs" noMarginBottom>
          <span className="tax-preview-header-value">{formatPrice(costsTax, false, 0)}</span>
        </Subheader>
      }
      body={
        <div className="tax-preview-detais">
          <div className="tax-preview-line-summary">
            <span>{__('application.statistics_btw_to_pay')}</span>
            <span>{formatPrice(toPay, false, 0)}</span>
          </div>
          <div className="tax-preview-line-summary">
            <span>{__('application.statistics_btw_to_receive')}</span>
            <span>{formatPrice(toReceive, false, 0)}</span>
          </div>
        </div>
      }
    />
  </>
);

export default TaxPreviewCard;
