import React, { useEffect, useState } from 'react';
import { OfferHistoryActions, OfferHistoryRes } from 'app-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import ApiService from '../../services/api-service';
import { formatDateTime } from '../../utils/format-date';
import './offerHistory.scss';

interface Props {
  id: string;
}

const OfferHistory = ({ id }: Props) => {
  const [history, setHistory] = useState<OfferHistoryRes[]>([]);

  const fetchHistory = async () => {
    await ApiService.callFetch('GET', `offer/history/${id}`, (data: OfferHistoryRes[]) => {
      setHistory(data);
    });
  };

  const getIcon = (action: OfferHistoryActions) => {
    switch (action) {
      case OfferHistoryActions.CREATE:
        return <Icon icon="plus" className="offer-history__icon success" />;
      case OfferHistoryActions.EDIT:
        return <Icon icon="pencil" className="offer-history__icon success" />;
      case OfferHistoryActions.ACCEPT:
        return <FontAwesomeIcon icon={faCheck} className="offer-history__icon success" />;
      case OfferHistoryActions.REVOKE_ACCEPT:
        return <Icon icon="back" className="offer-history__icon danger" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title={__('application.offerHistory')} />
      </ModalHeader>

      {(history || [])
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .map((item, index) => (
          <div key={index} className="offer-history">
            <p className="offer-history__date">{formatDateTime(item.date)}</p>
            <p className="offer-history__description">
              {getIcon(item.action)}
              {__(`application.offerHistoryActions.${item.action}`)}
            </p>
          </div>
        ))}
    </ModalContent>
  );
};

export default OfferHistory;
