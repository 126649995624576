import { ItfApiForm } from 'itf_formbuilder_react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { successNotification } from '../../../../actions/notifications';
import { modal, notifications, apiTable } from '../../../../actions';
import { history } from '../../../../App';
import { Button, CostSchemeForm, FieldsMapper, Spinner } from '../../../../components/Common';
import { ButtonsContainer, FormContainer } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import ApiService from '../../../../services/api-service';
import { formOnTranslateString } from '../../../../utils/trans-form';
import DocumentForm from '../Form';

const AddDocument = () => {
  useHeader('application.add_document', '/dashboard/costs-schemes/list');

  const dispatch = useDispatch();

  return (
    <>
      <DocumentForm
        callback={() => {
          dispatch(successNotification('application.document_was_added'));
          history.push('/dashboard/costs-documents/list');
        }}
      />
    </>
  );
};

export default AddDocument;
