import { SomeUserEntity, UserDataEntity } from 'app-types';

interface FetchUserForAdminSuccess {
  type: 'FETCH_USER_DETAILS_FOR_ADMIN_SUCCESS';
  payload: SomeUserEntity;
}
interface LoadingUserForAdmin {
  type: 'LOADING_USER_FOR_ADMIN';
  payload: boolean;
}

type Action = FetchUserForAdminSuccess | LoadingUserForAdmin;

export interface UserForAdminState {
  details: UserDataEntity | null;
  loading: boolean;
}

const initialState: UserForAdminState = {
  details: null,
  loading: true,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'FETCH_USER_DETAILS_FOR_ADMIN_SUCCESS': {
      return { ...state, details: action.payload };
    }
    case 'LOADING_USER_FOR_ADMIN': {
      return { ...state, loading: action.payload };
    }
    default:
      return state;
  }
};
