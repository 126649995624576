import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import useHeader from '../../../hooks/useHeader';
import { __ } from '../../../services/translation';
import ClientReminderList from './List';
import { RouteClientAccountant } from '../../../components/Routes';
import { RemindersForClient } from './ByClient';
import { PrepareReminder } from './PrepareReminder';
import { SendReminder } from './Send';
import Dashboard from '../../DashboardCommon';
import { Page } from '../../../components/Layout';
import Incasso from './Incasso';

const reminderTabs = [
  {
    title: 'application.back',
    link: '/dashboard/invoices',
    icon: 'back',
    exact: true,
  },
  {
    title: 'application.reminders',
    link: '/dashboard/reminders/list',
    icon: 'list',
    exact: true,
  },
  {
    title: 'application.incasso',
    link: '/dashboard/reminders/incasso',
    icon: 'internal',
  },
];

const Reminders = () => {
  useHeader('application.reminders');
  return (
    <Page light withNavigation>
      <Dashboard navigation={reminderTabs}>
        <Switch>
          <Route
            exact
            path="/dashboard/reminders"
            render={() => <Redirect to="/dashboard/reminders/list" />}
          />
          <RouteClientAccountant
            exact
            path="/dashboard/reminders/list"
            component={ClientReminderList}
          />
          <RouteClientAccountant exact path="/dashboard/reminders/incasso" component={Incasso} />

          <RouteClientAccountant
            exact
            path="/dashboard/reminders/:clientId/prepare/:clientName?"
            component={PrepareReminder}
          />

          <RouteClientAccountant
            exact
            path="/dashboard/reminders/:clientId/send"
            component={SendReminder}
          />

          <RouteClientAccountant
            exact
            path="/dashboard/reminders/:clientId/:clientKvk?"
            component={RemindersForClient}
          />
        </Switch>
      </Dashboard>
    </Page>
  );
};

export { Reminders };
