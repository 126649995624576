import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { TrackingStatus, TimeSheetEntity, SomeUserEntity } from 'app-types';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Content } from '../../../../../../components/Layout';
import { TrackingButton, Spinner } from '../../../../../../components/Common';
import { timeTracking, header } from '../../../../../../actions';
import { history } from '../../../../../../App';
import { __ } from '../../../../../../services/translation';
import { ApplicationState } from '../../../../../../reducers';

import ApiService from '../../../../../../services/api-service';
import { secondsToTime } from '../../../../../../utils/seconds-to-time';
import './Timer.scss';

interface Props {
  status: TrackingStatus;
  user: SomeUserEntity | null;
  setWorkingTime: (time: number) => void;
  increaseWorkingTime: () => void;
  setTrackingInterval: (handler: any) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
  clearTrackingInterval: () => void;
  workTime: number;
  loadingTimeTracking: boolean;
  changeTrackingStatus: (status: TrackingStatus) => void;
}

interface State {
  loading: boolean;
}

class Timer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { setHeader, user } = this.props;
    if (user) this.checkCurrentWorkTime();
    setHeader('application.work_time_tracking');
  }

  private checkCurrentWorkTime = async () => {
    const { changeTrackingStatus, setWorkingTime } = this.props;
    await ApiService.callFetch('GET', 'time-sheet/work/time', (time: number) => {
      if (time > 0) {
        this.setState({ loading: false });
        changeTrackingStatus(TrackingStatus.Working);
        setWorkingTime(time);
        this.startTimer();
      } else {
        this.setState({ loading: false });
        changeTrackingStatus(TrackingStatus.Idle);
        setWorkingTime(0);
      }
    });
  };

  private startWorkTime = async () => {
    const { changeTrackingStatus } = this.props;
    await ApiService.callFetch('GET', 'time-sheet/work/start', async () => {
      changeTrackingStatus(TrackingStatus.Working);
      this.startTimer();

      // CAUSES ERROR IN ANDROID SDK MIN 31!
      // const notifs = await LocalNotifications.schedule({
      //   notifications: [
      //     {
      //       title: 'HeyZZP',
      //       body: __('application.tracking_time'),
      //       id: new Date().getTime(),
      //       schedule: { at: new Date(Date.now() + 1000) },
      //       actionTypeId: '',
      //       ongoing: true,
      //       autoCancel: false,
      //       extra: null,
      //       attachments: undefined,
      //     },
      //   ],
      // });
    });
  };

  private finishWorkTime = async () => {
    const { changeTrackingStatus, clearTrackingInterval } = this.props;
    await ApiService.callFetch('GET', 'time-sheet/work/finish', (timesheet: TimeSheetEntity) => {
      changeTrackingStatus(TrackingStatus.Idle);
      clearTrackingInterval();
      const workStart = new Date(timesheet.start).getTime();
      const workFinish = new Date(timesheet.finish).getTime();
      history.push(`/dashboard/time-sheets/add/time/${workStart}/${workFinish}`);
    });
  };

  private resetWorkTime = async () => {
    const { changeTrackingStatus, setWorkingTime, clearTrackingInterval } = this.props;
    await ApiService.callFetch('GET', 'time-sheet/work/reset', () => {
      clearTrackingInterval();
      changeTrackingStatus(TrackingStatus.Idle);
      setWorkingTime(0);
    });
  };

  private startTimer = () => {
    const { setTrackingInterval, clearTrackingInterval } = this.props;
    clearTrackingInterval();
    setTrackingInterval(this.tick);
  };

  private tick = () => {
    const { increaseWorkingTime } = this.props;
    increaseWorkingTime();
  };

  private getStartStopButton = (status: TrackingStatus) => {
    switch (status) {
      case TrackingStatus.Idle:
        return (
          <TrackingButton
            text="application.start"
            onClick={() => {
              this.startWorkTime();
            }}
            start
          />
        );
      case TrackingStatus.Loading:
        return <></>;
      case TrackingStatus.Working:
        return (
          <TrackingButton
            text="application.stop"
            stop
            onClick={() => {
              this.finishWorkTime();
            }}
          />
        );
      default:
        break;
    }
  };

  private getResetButton = (status: TrackingStatus) => {
    const { changeTrackingStatus, setWorkingTime } = this.props;
    switch (status) {
      case TrackingStatus.Idle:
        return <TrackingButton icon={faRedoAlt} inactive small />;
      case TrackingStatus.Loading:
        return <></>;
      case TrackingStatus.Working:
        return (
          <TrackingButton
            active
            icon={faRedoAlt}
            small
            onClick={() => {
              this.resetWorkTime();
            }}
          />
        );
      default:
        break;
    }
  };

  render() {
    const { loadingTimeTracking, status, workTime } = this.props;
    const { loading } = this.state;
    return (
      <>
        {loadingTimeTracking || loading ? (
          <Spinner />
        ) : (
          <Content center>
            <p className="timer-status-header">{__('application.current_time')}</p>
            <div className="timer-time-container">{secondsToTime(workTime)}</div>
            {this.getResetButton(status)}
            {this.getStartStopButton(status)}
          </Content>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  status: state.timeTracking.status,
  loadingTimeTracking: state.timeTracking.loading,
  workTime: state.timeTracking.currentTime,
  user: state.user.details,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setWorkingTime: timeTracking.setWorkingTime,
      increaseWorkingTime: timeTracking.increaseWorkingTime,
      setTrackingInterval: timeTracking.setTrackingInterval,
      clearTrackingInterval: timeTracking.clearTrackingInterval,
      changeTrackingStatus: timeTracking.changeTrackingStatus,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
