import { TimeSheetEntity } from 'app-types';

interface GetSingleTimeSheet {
  type: 'GET_SINGLE_TIME_SHEET_SUCCESS';
  payload: TimeSheetEntity;
}

interface LoadingSingleTimeSheet {
  type: 'LOADING_SINGLE_TIME_SHEET';
  payload: boolean;
}

interface GetTimeSheetYears {
  type: 'GET_TIME_SHEET_YEARS_SUCCESS';
  payload: number[];
}

interface LoadingTimeSheetYears {
  type: 'LOADING_TIME_SHEET_YEARS';
  payload: boolean;
}

export interface TimeSheetState {
  loadingSingle: boolean;
  editStartTime: string;
  editFinishTime: string;
  timeSheetYears: number[];
  loadingTimeSheetYears: boolean;
  single: TimeSheetEntity;
}

const initialState: TimeSheetState = {
  loadingSingle: true,
  editStartTime: '',
  editFinishTime: '',
  timeSheetYears: [],
  loadingTimeSheetYears: true,
  single: {
    id: '',
    name: '',
    start: new Date(),
    finish: new Date(),
    clientName: '',
  },
};

type Action =
  | GetSingleTimeSheet
  | LoadingSingleTimeSheet
  | GetTimeSheetYears
  | LoadingTimeSheetYears;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_SINGLE_TIME_SHEET_SUCCESS': {
      return { ...state, single: action.payload };
    }
    case 'LOADING_SINGLE_TIME_SHEET': {
      return { ...state, loadingSingle: action.payload };
    }

    case 'GET_TIME_SHEET_YEARS_SUCCESS': {
      const timeSheetYears = action.payload;
      return { ...state, timeSheetYears };
    }

    case 'LOADING_TIME_SHEET_YEARS': {
      return { ...state, loadingTimeSheetYears: action.payload };
    }

    default:
      return state;
  }
};
