import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { ApplicationState } from '../../../../../../reducers';
import OfferForm from '../../OfferForm';
import { OfferReq } from 'app-types';

interface Props {
    offerData: OfferReq;
}

const EditOfferPreview = ({ offerData }: Props) => {
  if (!offerData || offerData.services.length === 0) return <Redirect to="/dashboard/offers/list" />;
  return <OfferForm offer={offerData} edit />;
};

const mapStateToProps = (state: ApplicationState) => ({
    offerData: state.offer.receivedOfferData,
});

export default connect(mapStateToProps)(EditOfferPreview);
