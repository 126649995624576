import React, { useEffect, useState } from 'react';
import { MileageCarEntity, MileageCarRes } from 'app-types';
import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
  PublicItfFormBuilderComponentApi,
} from 'itf_formbuilder_react';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import {
  Button,
  CostSchemeForm,
  FieldsMapper,
  MileageCarForm,
  RequiredIndicator,
  Spinner,
} from '../../components/Common';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import ApiService from '../../services/api-service';
import { formOnTranslateString } from '../../utils/trans-form';
import { ExtendedOnFormGloballyRegisteredProps } from '../../types/extendedOnFormGloballyRegisteredProps';

interface Props {
  callback: (values: MileageCarEntity) => void;
  regNumber: string;
  currentVals: MileageCarEntity;
}

const ChangeCar = ({ regNumber, callback, currentVals }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.change_car" />
    </ModalHeader>
    <FormContainer>
      <ItfApiForm
        registerAsGlobalForm
        onFormGloballyRegistered={(api) => {
          const { regNumber, carModel, carType } = currentVals;
          (api as ExtendedOnFormGloballyRegisteredProps).setValues({
            regNumber,
            carModel,
            carType,
          });
        }}
        formId="mileage-car-form"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        requiredIndicator={<RequiredIndicator />}
        schemaFetcher={() => ApiService.loadForm('mileage-car/form/add-item')}
        sendFetcher={(formId: string, values: MileageCarEntity) =>
          ApiService.sendForm('mileage-car/valid', values, 'POST')
        }
        onSavedSuccessfully={(data: MileageCarRes) => {
          callback(data);
        }}
        loadingInfo={<Spinner transparent />}
        submitButton={
          <ButtonsContainer>
            <Button type="submit" className="form-submit-button" text="application.save" primary />
          </ButtonsContainer>
        }
        onTranslateString={(string: string) => formOnTranslateString(string)}
        onRenderFullField={(
          field: FormFieldToRender,
          errors: any,
          fieldHtmlId: string,
          currentValue: any,
          onChange: ChangeFunction,
          onFocus: FieldAndEventFunction,
          onBlur: FieldAndEventFunction,
        ) => FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur)}
      />
    </FormContainer>
  </ModalContent>
);

export default ChangeCar;
