import React from 'react';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalContent from '../../components/Common/Modal/ModalContent';
import { CostSchemeForm } from '../../components/Common';
import { FormContainer } from '../../components/Layout';

interface Props {
  id: string;
}
const EditCostScheme = ({ id }: Props) => (
  <ModalContent>
    <ModalHeader>
      <ModalTitle title="application.edit_cost_scheme" />
    </ModalHeader>
    <FormContainer>
      <CostSchemeForm
        formUrl={`cost/template/form/update-item/${id}`}
        formSendUrl={`cost/template/${id}`}
        method="PATCH"
        edit
      />
    </FormContainer>
  </ModalContent>
);

export default EditCostScheme;
