import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { faUserCircle, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { Page } from '../../../components/Layout';
import Dashboard from '../../DashboardCommon';
import Profile from '../Profile';
import Profits from './Profits';
import useHeader from '../../../hooks/useHeader';
import { statistics } from '../../../actions';
import TaxPreview from './TaxPreview';
import StatisticsOther from './Other';

const statisticsTabs = [
  {
    title: 'application.statistics_profit_results',
    link: '/dashboard/statistics/profits',
    icon: 'profits',
  },
  {
    title: 'application.statistics_btw_preview',
    link: '/dashboard/statistics/btw',
    icon: 'btw',
  },
  {
    title: 'application.statistics_other',
    link: '/dashboard/statistics/other',
    icon: 'statistics',
  },
];

const Statistics = () => {
  useHeader('application.statistics');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(statistics.getSummary());
  }, []);

  return (
    <>
      <Page withNavigation light>
        <Dashboard navigation={statisticsTabs}>
          <Switch>
            <Route
              exact
              path="/dashboard/statistics"
              render={() => <Redirect to="/dashboard/statistics/profits" />}
            />
            <Route exact path="/dashboard/statistics/profits" component={Profits} />
            <Route exact path="/dashboard/statistics/btw" component={TaxPreview} />
            <Route exact path="/dashboard/statistics/other" component={StatisticsOther} />
          </Switch>
        </Dashboard>
      </Page>
    </>
  );
};

export default Statistics;
