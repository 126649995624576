import React from 'react';
import { TableSchema, notificationsTableSchema } from 'app-tables';
import { useDispatch } from 'react-redux';
import { InteractiveTableSchema } from '../../../../utils/table-shcema-utils';
import { ApiList, Button } from '../../../../components/Common';

import NotificationItem from '../Item';
import { ButtonsContainer } from '../../../../components/Layout';
import ApiService from '../../../../services/api-service';
import { apiList } from '../../../../actions';

const System = () => {
  const scheme: TableSchema = new InteractiveTableSchema(notificationsTableSchema);
  const dispatch = useDispatch();
  const markAsRead = async () => {
    await ApiService.callFetch('PATCH', 'notification/read-all-system', () => {
      dispatch(apiList.refreshApiList());
    });
  };

  return (
    <>
      <ButtonsContainer marginLittle>
        <Button text="application.mark_all_as_read" click={markAsRead} />
      </ButtonsContainer>
      <ApiList
        noSearch
        apiEndpointSubUrl="notification/mine/system"
        scheme={scheme}
        component={NotificationItem}
      />
    </>
  );
};

export default System;
