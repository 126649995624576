import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { OtherIncomeDetails } from 'app-types';
import { Confirmation, EditOtherIncome } from '../../../../modals';

import { modal, header, otherIncome } from '../../../../actions';
import { ButtonsContainer } from '../../../../components/Layout';
import { Button, SingleIncomePreview, Spinner, Subheader } from '../../../../components/Common';

import { ApplicationState } from '../../../../reducers';

import './SingleOtherIncome.scss';
import { __ } from '../../../../services/translation';

interface Props extends RouteComponentProps {
  showModal: (content: React.ReactNode) => void;
  deleteIncome: (id: string) => void;
  hideModal: () => void;
  details: OtherIncomeDetails;
  match: any;
  loading: boolean;
  getIncomeDetails: (id: string) => void;
  setHeader: (title: string, back?: string, info?: { title: string; text: string }) => void;
}

class SingleOtherIncome extends React.Component<Props> {
  componentDidMount() {
    const { match, getIncomeDetails, setHeader } = this.props;
    getIncomeDetails(match.params.id);
    setHeader('application.other_income', '/dashboard/other-income');
  }

  private confirmIncomeDelete = () => {
    const { showModal, deleteIncome, hideModal, match } = this.props;
    const { id } = match.params;
    showModal(
      <Confirmation
        text="application.other_income_delete_confirmation"
        confirmCallback={() => deleteIncome(id)}
        cancelCallback={() => hideModal()}
      />,
    );
  };

  private editIncome = () => {
    const { showModal, match } = this.props;
    const { id } = match.params;
    showModal(<EditOtherIncome id={id} />);
  };

  render() {
    const { details, loading } = this.props;
    return (
      <>
        {loading ? (
          <Spinner overlay />
        ) : (
          <div className="single-income">
            <SingleIncomePreview income={details} />
            <ButtonsContainer marginTop>
              <Button
                text="application.edit"
                secondary
                noShadow
                rightMargin
                click={this.editIncome}
                bottomMargin
              />
              <Button
                text="application.delete"
                danger
                noShadow
                click={this.confirmIncomeDelete}
                bottomMargin
              />
            </ButtonsContainer>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.otherIncome.loading,
  details: state.otherIncome.details,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: modal.showModal,
      deleteIncome: otherIncome.deleteOtherIncome,
      hideModal: modal.hideModal,
      getIncomeDetails: otherIncome.getOtherIncomeDetails,
      setHeader: header.setHeader,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleOtherIncome);
