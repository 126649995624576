import React from 'react';
import {
  ChangeFunction,
  FieldAndEventFunction,
  FormFieldToRender,
  ItfApiForm,
} from 'itf_formbuilder_react';
import { useDispatch } from 'react-redux';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import {
  Button,
  FieldsMapper,
  Paragraph,
  RequiredIndicator,
  Spinner,
} from '../../components/Common';
import { formOnTranslateString } from '../../utils/trans-form';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import ApiService from '../../services/api-service';
import { successNotification } from '../../actions/notifications';
import { hideModal } from '../../actions/modal';

const RemoveAccount = () => {
  const dispatch = useDispatch();

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.remove_account" />
      </ModalHeader>
      <Paragraph translation="application.remove_account_description" />
      <FormContainer>
        <ItfApiForm
          formId="remove-account"
          schemaMayBeDynamic
          schemaCacheEnabled={false}
          requiredIndicator={<RequiredIndicator />}
          schemaFetcher={() => ApiService.loadForm('user/form/remove-account')}
          sendFetcher={(formId: string, values: { pwd: string }) =>
            ApiService.sendForm('user/remove-account', values, 'POST')
          }
          onSavedSuccessfully={() => {
            dispatch(successNotification('application.account_was_removed'));
            dispatch(hideModal());
            dispatch({ type: 'LOGOUT' });
          }}
          loadingInfo={<Spinner transparent />}
          submitButton={
            <ButtonsContainer>
              <Button
                type="submit"
                className="form-submit-button"
                text="application.save"
                primary
              />
            </ButtonsContainer>
          }
          onTranslateString={(string: string) => formOnTranslateString(string)}
          onRenderFullField={(
            field: FormFieldToRender,
            errors: any,
            fieldHtmlId: string,
            currentValue: any,
            onChange: ChangeFunction,
            onFocus: FieldAndEventFunction,
            onBlur: FieldAndEventFunction,
          ) => FieldsMapper(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur)}
        />
      </FormContainer>
    </ModalContent>
  );
};

export default RemoveAccount;
